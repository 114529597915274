import { SchemaProperty } from 'schemas/types';

export const fillDataModelPropertyByTypes = (
  property: SchemaProperty,
  dataModel: DataModel,
  types: string[] = [],
) => {
  property.enum = Object.keys(dataModel).reduce((acc: string[], key: string) => {
    return key in dataModel && types.includes(dataModel[key]?.type) ? [...acc, key] : acc;
  }, []);
};
