export const linkedinReaderDataModel: DataModel = {
  name: {
    type: 'string',
  },
  url: {
    type: 'string',
  },
  connected: {
    type: 'date',
  },
  occupation: {
    type: 'string',
  },
  location: {
    type: 'string',
  },
  email: {
    type: 'string',
  },
  phone: {
    type: 'string',
  },
  twitter: {
    type: 'string',
  },
  websites: {
    type: 'string',
  },
  companies: {
    type: 'string',
  },
};

export const LinkedinReader: ProcessorApiModel = {
  name: 'Linkedin (Reader)',
  processor_type: 'LinkedinReader',
  description: 'Linkedin (Reader)',
  type: 'connection',
  tags: ['Linkedin'],
  data_model: linkedinReaderDataModel,
  displayText: 'Reading data about connectors from linkedin.',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/Linkedin-icon.png',
  connections: {
    inputPort: false,
    outputPort: true,
  },
};
