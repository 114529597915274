import React from 'react';
import styled from 'styled-components';

import { useModal, AllModalTypes } from 'modules/modals';
import { Container } from 'components/Table/NameCell.style';

import connectionIcon from '../../assets/connection-icon.png';

const IconWrapper = styled.div`
  margin-right: 20px;
  height: fit-content;
  width: fit-content;
  min-width: 16px;
`;

const TextName = styled.p`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export interface TableNameCellType {
  pk: string;
  title: string;
  modalType: AllModalTypes;
}

export const TableNameCell: React.FC<TableNameCellType> = React.memo(({ title, pk, modalType }) => {
  const { openModal } = useModal();

  const onDoubleClick = React.useCallback(() => {
    openModal({ type: modalType, data: { pk } });
  }, [openModal, pk, modalType]);

  return (
    <Container onDoubleClick={onDoubleClick} style={{ cursor: 'pointer' }}>
      <IconWrapper>
        <img alt="document" src={connectionIcon} />
      </IconWrapper>
      <TextName>{title}</TextName>
    </Container>
  );
});
