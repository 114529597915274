import React, { useContext } from 'react';
import Avatar from 'react-avatar';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Menu, MenuItem, MenuProps, Button } from '@material-ui/core';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { ModalTypes, useModal } from 'modules/modals';
import { UserProfileContext } from 'providers/UserProfileProvider';
import { externalLinks } from 'constants/externalLinks';

import './styles.scss';

const messages = defineMessages({
  applicationSettings: {
    id: 'avatar.settings',
    defaultMessage: 'Admin settings',
  },
  logout: {
    id: 'avatar.logout',
    defaultMessage: 'Logout',
  },
  userProfileSettings: {
    id: 'avatar.user-profile-settings',
    defaultMessage: 'User Profile Settings',
  },
  userSettings: {
    id: 'avatar.user-settings',
    defaultMessage: 'User Settings',
  },
});

const origin: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export const HeaderMenu = () => {
  const { openModal } = useModal();
  const { userProfile, logout, isApplicationAdmin } = useContext(UserProfileContext);
  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null);

  const onAvatarClick = React.useCallback(
    (event: { currentTarget: React.SetStateAction<Nullable<HTMLElement>> }) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onLogoutClick = React.useCallback(() => {
    handleClose();
    logout();
  }, [logout, handleClose]);

  const onUserProfileSettingClick = React.useCallback(() => {
    window.open(externalLinks.userProfileSettings, '_blank')?.focus();
  }, []);

  const onApplicationSettingClick = React.useCallback(() => {
    handleClose();
    openModal({ type: ModalTypes.ApplicationSettingsModal });
  }, [openModal, handleClose]);

  const onUserSettingClick = React.useCallback(() => {
    handleClose();
    openModal({ type: ModalTypes.UserSettingModal });
  }, [openModal, handleClose]);

  return (
    <div className="header-menu">
      {userProfile && (
        <Button onClick={onAvatarClick}>
          <Avatar name={userProfile.name} email={userProfile.email} size="36" round />
          {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
      )}
      <Menu
        keepMounted
        id="menu-appbar"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        {...origin}
      >
        <MenuItem onClick={onUserSettingClick}>
          <FormattedMessage {...messages.userSettings} />
        </MenuItem>
        <MenuItem onClick={onUserProfileSettingClick}>
          <FormattedMessage {...messages.userProfileSettings} />
        </MenuItem>
        {isApplicationAdmin && (
          <MenuItem onClick={onApplicationSettingClick}>
            <FormattedMessage {...messages.applicationSettings} />
          </MenuItem>
        )}
        <MenuItem onClick={onLogoutClick}>
          <FormattedMessage {...messages.logout} />
        </MenuItem>
      </Menu>
    </div>
  );
};
