import React, { useCallback, useState } from 'react';
import { getTableSettingsInitialValue } from '../types/table';
import { defaultTableParams } from '../constants/table';

export const useTable = () => {
  const [tableParams, setTableParams] = useState(getTableSettingsInitialValue());

  const onSearchChange = useCallback(
    (event: React.ChangeEvent<{ value: string }>) => {
      if (event.target.value || event.target.value === '') {
        setTableParams((value) => ({
          ...value,
          page: defaultTableParams.page,
          search: event.target.value,
        }));
      }
    },
    [setTableParams],
  );

  const onPageChange = useCallback(
    (page: number) => {
      setTableParams((value) => ({
        ...value,
        page,
      }));
    },
    [setTableParams],
  );

  const onSizeChange = useCallback(
    (size: number) => {
      setTableParams((value) => ({
        ...value,
        page: defaultTableParams.page,
        size,
      }));
    },
    [setTableParams],
  );

  const onCountChange = useCallback(
    (count: number) => {
      setTableParams((value) => ({
        ...value,
        count,
      }));
    },
    [setTableParams],
  );

  return {
    tableParams,
    onSearchChange,
    onPageChange,
    onSizeChange,
    onCountChange,
    setTableParams,
  };
};
