import React, { useEffect, useCallback } from 'react';

import { useAppDispatch, useTypedSelector } from 'store';
import { useProjectId } from 'hooks/useProjectId';

import { goToSelectedPage, updateSize, changeSorting } from 'modules/automation/workflowsSlice';
import { fetchAutomationList } from 'modules/automation/automationActions';
import { fetchTagList } from 'store/tagsSlice';

import { CommonTable } from 'components/Table/CommonTable';
import { Message } from 'components/Message';

import { useWorkflowsTableContent } from './useWorkflowsTableContent';

export const AutomationsTable = () => {
  const dispatch = useAppDispatch();
  const { projectId } = useProjectId();

  const { lastUpdated: lastUpdatedTag } = useTypedSelector((state) => state.tags);
  const { tableSettings, lastUpdated, workflows } = useTypedSelector((state) => state.workflows);
  const { page, size, order, orderBy, search } = tableSettings;

  const workflowsTableContents = useWorkflowsTableContent(workflows);

  const onGoToPageHandler = useCallback(
    (targetPage: number) => {
      dispatch(goToSelectedPage(targetPage));
    },
    [dispatch],
  );

  const onSizeChangeHandler = useCallback(
    (targetSize: number) => {
      dispatch(updateSize(targetSize));
    },
    [dispatch],
  );

  const onClickSortHandler = useCallback(
    (columnName: string) => {
      dispatch(changeSorting(columnName));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchAutomationList({ page, size, order, orderBy, search }));
  }, [dispatch, page, size, order, orderBy, search, lastUpdated, projectId]);

  useEffect(() => {
    dispatch(fetchTagList());
  }, [dispatch, lastUpdatedTag, projectId]);

  return (
    <>
      <CommonTable
        content={workflowsTableContents}
        page={tableSettings.page}
        totalItems={tableSettings.count}
        size={tableSettings.size}
        goToPage={onGoToPageHandler}
        onSizeChange={onSizeChangeHandler}
        onClickSort={onClickSortHandler}
        order={tableSettings.order}
        orderBy={tableSettings.orderBy}
        isPagination
      />
      <Message
        loading={tableSettings.loading}
        search={tableSettings.search}
        count={tableSettings.count}
        entityName="automations"
      />
    </>
  );
};
