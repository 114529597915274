import React, { useMemo } from 'react';

import { Paper, Slide } from '@material-ui/core';

import { useTypedSelector } from 'store';

import { ModalHeader } from 'components/modal/ModalHeader';
import { ModalContent } from 'components/modal/common';

import { selectWorkflowElementProcessorTypeById } from '../../workflowSelectors';
import { EditProcessorModalContent } from './EditProcessorModalContent';
import {
  EditProcessorModalContext,
  EditProcessorModalContextType,
} from './EditProcessorModalContextProvider';

import './styles.scss';

type EditProcessorModalType = {
  processorId: string;
};

export const EditProcessorModal: React.FC<EditProcessorModalType> = ({ processorId }) => {
  const processorType = useTypedSelector(selectWorkflowElementProcessorTypeById(processorId));

  const { setSelectedProcessorId } =
    React.useContext<EditProcessorModalContextType>(EditProcessorModalContext);

  const onClose = React.useCallback(() => {
    setSelectedProcessorId(undefined);
  }, [setSelectedProcessorId]);

  const content = useMemo(() => {
    if (processorType) {
      return <EditProcessorModalContent processorId={processorId} processorType={processorType} />;
    }

    return (
      <p className="has-align-center has-bolt-text has-top-margin">
        Current processor is not unavailable now or was removed.
      </p>
    );
  }, [processorType, processorId]);

  return (
    <Slide in direction="left" mountOnEnter unmountOnExit>
      <Paper
        id="create-node-modal-menu"
        className="edit-processor-modal-container"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <ModalHeader onClose={onClose}>{processorType}</ModalHeader>
        <ModalContent className="edit-processor-modal-content">{content}</ModalContent>
      </Paper>
    </Slide>
  );
};
