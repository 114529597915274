import * as React from 'react';
import { useParams } from 'react-router-dom';

import { ProjectParams } from 'types';
import { SideMenu } from 'components/SideMenu/SideMenu';

import { DatasetHeader } from '../modules/datasets/DatasetEdit/DatasetHeader';
import { EditDatasetFrom } from '../modules/datasets/DatasetEdit/EditDatasetFrom';

export interface DatasetEditParams extends ProjectParams {
  datasetId: string;
}

export const DatasetEditPage = () => {
  const { datasetId } = useParams<DatasetEditParams>();

  return (
    <div className="page-wrapper">
      <DatasetHeader />
      <SideMenu />
      <EditDatasetFrom datasetId={datasetId} />
    </div>
  );
};
