import React from 'react';

interface TabPanelProps {
  index: number | string;
  value: number | string;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
  <div
    style={{ flexGrow: 1, width: '100%' }}
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
  >
    {value === index && children}
  </div>
);
