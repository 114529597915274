import React from 'react';

import { useTabManager } from 'hooks';

import { a11yProps } from 'utils/a11yProps';
import { VerticalTab, TabPanel, VerticalTabs, VerticalTabsWrapper } from 'components/Tabs';
import { WideDialog } from 'components/modal';
import { ModalHeader } from 'components/modal/ModalHeader';
import { useModal, DatasetsModalTypes } from 'modules/modals';

import { GeneralDatasetSettingsTab } from './GeneralDatasetSettingsTab';
import { AutomationsDatasetSettingsTab } from './AutomationsDatasetSettingsTab';

enum EditDatasetTabs {
  GENERAL,
  AUTOMATIONS,
}

export type DatasetSettingsModalType = {
  id: string;
};

export const DatasetSettingsModal: React.FC<DatasetSettingsModalType> = ({ id }) => {
  const { closeModal } = useModal();
  const { tab, onTabChange } = useTabManager(EditDatasetTabs.GENERAL);

  const onClose = React.useCallback(() => {
    closeModal(DatasetsModalTypes.EditDatasetsModal);
  }, [closeModal]);

  return (
    <WideDialog id="workflow-settings-modal" open onClose={onClose}>
      <ModalHeader onClose={onClose}>Dataset settings</ModalHeader>
      <VerticalTabsWrapper>
        <VerticalTabs orientation="vertical" value={tab} onChange={onTabChange}>
          <VerticalTab label="General Settings" {...a11yProps(0)} />
          <VerticalTab label="Automations" {...a11yProps(1)} />
        </VerticalTabs>
        <TabPanel index={EditDatasetTabs.GENERAL} value={tab}>
          <GeneralDatasetSettingsTab datasetId={id} onClose={onClose} />
        </TabPanel>
        <TabPanel index={EditDatasetTabs.AUTOMATIONS} value={tab}>
          <AutomationsDatasetSettingsTab datasetId={id} />
        </TabPanel>
      </VerticalTabsWrapper>
    </WideDialog>
  );
};
