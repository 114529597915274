import { createSlice } from '@reduxjs/toolkit';

import type { TableSettingsType } from 'types/table';
import { createTableSettingsReducers, getTableSettingsInitialValue } from 'types/table';

import {
  createCustomProcessorAction,
  deleteCustomComponents,
  fetchCustomComponents,
  updateCustomComponent,
} from './customComponentsActions';
import type { CustomComponentApiModel } from './types';

export type CustomComponentsStateType = {
  components: CustomComponentApiModel[];
  tableSettings: TableSettingsType;
};

const initialState: CustomComponentsStateType = {
  components: [],
  tableSettings: getTableSettingsInitialValue(),
};

export const customComponentsSlice = createSlice({
  name: 'customComponentsSlice',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    ...createTableSettingsReducers<CustomComponentsStateType>(initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomComponents.pending, (state) => {
      state.tableSettings.loading = true;
      state.components = [];
    });
    builder.addCase(fetchCustomComponents.fulfilled, (state, { payload }) => {
      state.tableSettings.loading = false;
      state.tableSettings.count = payload.count;
      state.components = payload.results;
    });
    builder.addCase(deleteCustomComponents.fulfilled, (state, { payload }) => {
      state.components = state.components.filter(
        (item: CustomComponentApiModel) => item.pk !== payload,
      );
    });
    builder.addCase(createCustomProcessorAction.fulfilled, (state, { payload }) => {
      state.components = [payload, ...state.components];
    });
    builder.addCase(updateCustomComponent.fulfilled, (state, { payload }) => {
      state.components = state.components.map((item) => (item.pk === payload.pk ? payload : item));
    });
  },
});

export const { reset, changeSorting, updateSize, updateSearch, goToSelectedPage } =
  customComponentsSlice.actions;

export default customComponentsSlice.reducer;
