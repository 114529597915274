import styled from 'styled-components';

export const AddTagSelectWrapper = styled.div`
  width: 200px;
`;

export const MUIAutocompleteWrapper = styled.div`
  .MuiOutlinedInput-root {
    border-radius: 16px;
  }

  .MuiInputLabel-outlined {
    transform: translate(20px, 9px) scale(1);
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
    padding: 6.5px 4px 5px 13px;
  }
`;
