export interface URLBuilderOptions {
  isAnonymousMethod?: boolean;
  service: string;
  submethod?: string;
  projectId?: string;
}

export const buildUrl = ({
  isAnonymousMethod,
  projectId,
  service,
  submethod,
}: Required<URLBuilderOptions>): string => {
  return isAnonymousMethod ? `${service}/${submethod}` : `${service}/${projectId}/${submethod}`;
};
