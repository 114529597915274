import * as React from 'react';

import { ModalStoreType } from 'modules/modals/modalsSlice';
import { ConnectionsModalTypes } from 'modules/modals';

import { CreateGoogleConnectionModal } from './modals/CreateGoogleConnectionModal';
import { CreateEmailConnectorModal } from './modals/CreateEmailConnectorModal';
import { CreateConnectionModal } from './modals/CreateConnectionModal';
import { DeleteConnectionModal, DeleteConnectionModalType } from './modals/DeleteConnectionModal';
import { EditConnectionModal } from './modals/EditConnetionModal/EditConnectionModal';
import { ReplaceConnectionModalContainer } from './modals/ReplaceConnectionModal/ReplaceConnectionModalContainer';
import { ConfirmDeleteModal } from './modals/ConfirmDeleteModal';
import { ConnectionFolderEditModal } from './modals/ConnectionFolderEditModal';
import { AttachedWorkflow } from '../automation/workflowsActions';
import { CreateScraperConnectionModal } from './modals/CreateScraperConnectionModal';
import { CreateLinkedinConnectionModal } from './modals/CreateLinkedinConnectionModal';
import { LinkedinPinModal } from './modals/LinkedinPinModal';

export const getConnectionModalByType = (item: ModalStoreType): React.ReactNode | null => {
  switch (item.type) {
    case ConnectionsModalTypes.ConfirmDeleteModal:
      return (
        <ConfirmDeleteModal
          key="confirm-delete-connection-modal"
          source={item.data?.source as ConnectionApiModel}
          destination={item.data?.destination as ConnectionApiModel}
          workflows={item.data?.workflows as AttachedWorkflow[]}
          isDeleteModal={item.data?.isDeleteModal as boolean}
        />
      );
    case ConnectionsModalTypes.DeleteConnectionModal:
      return (
        <DeleteConnectionModal
          key="delete-connection-modal"
          {...(item.data as DeleteConnectionModalType)}
        />
      );
    case ConnectionsModalTypes.EditConnectionModal:
      return <EditConnectionModal key="connection-edit-modal" pk={item.data?.pk as string} />;
    case ConnectionsModalTypes.ReplaceConnectionModal:
      return (
        <ReplaceConnectionModalContainer
          key="replace-connection-modal"
          pk={item.data?.pk as string}
          isDeleteModal={item.data?.isDeleteModal as boolean}
        />
      );
    case ConnectionsModalTypes.CreateConnectionModal:
      return <CreateConnectionModal key="create-connection-modal" {...item.data} />;
    case ConnectionsModalTypes.CreateGoogleConnectionModal:
      return <CreateGoogleConnectionModal key="create-google-connection-modal" />;
    case ConnectionsModalTypes.CreateScraperConnectionModal:
      return <CreateScraperConnectionModal key="create-scraper-connector-modal" />;
    case ConnectionsModalTypes.CreateEmailConnectorModal:
      return <CreateEmailConnectorModal key="create-email-connector-modal" />;
    case ConnectionsModalTypes.CreateLinkedinConnectionModal:
      return <CreateLinkedinConnectionModal key="create-linkedin-connector-modal" />;
    case ConnectionsModalTypes.LinkedinPinModal:
      return (
        <LinkedinPinModal
          key="linkedin-pin-modal"
          connectionId={item?.data?.connectionId as string}
        />
      );
    case ConnectionsModalTypes.ConnectionFolderEditModal:
      return (
        <ConnectionFolderEditModal
          key="create-email-connector-modal"
          connectionId={item.data?.connectionId as string}
        />
      );
    default:
      return undefined;
  }
};
