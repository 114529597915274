import React, { FC, useState, useEffect, createContext } from 'react';

import { defaultFetchParams } from 'constants/table';

import { CustomComponentApiModel } from 'modules/custom-components/types';
import { DatasetApi } from 'modules/datasets/DatasetApi';
import { fetchCustomComponentsApi } from 'modules/custom-components/customComponentsActions';

import { fetchApiKeys } from '../credentials/apiKeysApi';
import { ConnectionApi } from '../connections/api/ConnectionApi';

export type AutomationContextType = {
  connections: ConnectionApiModel[];
  customComponents: CustomComponentApiModel[];
  datasets: IndexApiModel[];
  apiKeys: ApiKeyApiModel[];
};

export const AutomationContext = createContext<AutomationContextType>({
  connections: [],
  customComponents: [],
  datasets: [],
  apiKeys: [],
});

export const AutomationContextProvider: FC = ({ children }) => {
  const [connections, setConnections] = useState<ConnectionApiModel[]>([]);
  const [customComponents, setCustomComponents] = useState<CustomComponentApiModel[]>([]);
  const [datasets, setDatasets] = useState<IndexApiModel[]>([]);
  const [apiKeys, setApiKeys] = useState<ApiKeyApiModel[]>([]);

  useEffect(() => {
    fetchCustomComponentsApi(defaultFetchParams).then((res) => {
      setCustomComponents(res.results);
    });
    ConnectionApi.fetchConnections(defaultFetchParams).then((data) => {
      setConnections(data.results.connection);
    });
    DatasetApi.getAll(defaultFetchParams).then((res) => {
      setDatasets(res.results);
    });
    fetchApiKeys(defaultFetchParams).then((res) => {
      setApiKeys(res.results);
    });
  }, []);

  return (
    <AutomationContext.Provider value={{ connections, customComponents, datasets, apiKeys }}>
      {children}
    </AutomationContext.Provider>
  );
};
