import React from 'react';
import { Chip } from '@material-ui/core';

import { ActionNames } from 'components/TagsCell/TableTags.helper';
import { Option } from 'components/TagsCell/TagAutocomplete/Autocomplete';
import { AddTagComponent } from './AddTagComponent';
import { Container } from './TagsCell.style';

interface TagsCellProps {
  actionName: ActionNames;
  workflowTags?: TagApiModel[];
  tags?: TagApiModel[];
  filteredTags?: TagApiModel[];
  onDelete: (id: string) => void;
  onClickAdd: () => void;
  onChangeTag: (option: Option | null) => void;
  onCreateTag: (option: string) => void;
  onClickEdit: (tag: TagApiModel) => void;
}
export const TagsCell = ({
  workflowTags = [],
  tags = [],
  filteredTags = [],
  onDelete,
  onClickAdd,
  onChangeTag,
  onCreateTag,
  onClickEdit,
  actionName,
}: TagsCellProps) => {
  const [showAllTags, setShowAllTags] = React.useState(false);

  const onDeleteHandler = (tagId: string) => () => {
    onDelete(tagId);
  };

  const onMoreHandler = React.useCallback(() => {
    setShowAllTags(true);
  }, []);

  const onClickEditHandler = (tag: TagApiModel) => () => {
    onClickEdit(tag);
  };

  const shortenList = [
    ...workflowTags.map((workflowTag, index) => {
      if (index < 3 || showAllTags) {
        return (
          <Chip
            key={workflowTag.id}
            size="medium"
            label={workflowTag.name}
            clickable
            onClick={onClickEditHandler(workflowTag)}
            onDelete={onDeleteHandler(workflowTag.id)}
            variant="outlined"
            style={{ backgroundColor: workflowTag.color }}
          />
        );
      }

      return null;
    }),
    <Chip hidden={showAllTags} size="medium" label="..." clickable onClick={onMoreHandler} />,
  ];

  return (
    <Container>
      {workflowTags?.length > 3
        ? shortenList
        : workflowTags.map((workflowTag) => (
            <Chip
              key={workflowTag.id}
              size="medium"
              label={workflowTag.name}
              clickable
              onClick={onClickEditHandler(workflowTag)}
              onDelete={onDeleteHandler(workflowTag.id)}
              variant="outlined"
              style={{ backgroundColor: workflowTag.color }}
            />
          ))}
      <AddTagComponent
        tags={tags}
        filteredTags={filteredTags}
        onClickAdd={onClickAdd}
        onChangeTag={onChangeTag}
        onCreateTag={onCreateTag}
        isAdd={ActionNames.Add === actionName}
      />
    </Container>
  );
};
