import * as React from 'react';
import { useFormik } from 'formik';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Button } from '@material-ui/core';

import { useAppDispatch } from 'store';

import { useFlag } from 'modules/modals';
import { defaultFetchParams } from 'constants/table';

import { BaseWorkspaceApiModel } from '../../types';
import { WorkspaceApi } from '../../WorkspaceApi';
import { addUserProjectPermission } from '../../workspaceActions';
import { Selector } from '../Selector';
import { RoleSelector } from '../RoleSelector';
import {
  initialValues,
  validationSchema,
  UserWorkspaceSelectorValuesType,
} from './userWorkspaceSelectorSchema';

const messages = defineMessages({
  title: {
    id: 'workspaces.invite-user-input.title',
    defaultMessage: 'Add workspace permission:',
  },
  add: {
    id: 'workspaces.invite-user-input.title',
    defaultMessage: 'Add',
  },
});

export type UserWorkspaceSelectorFormType = {
  userId: string;
  workspacesIds: string[];
  refetchUserWorkspaces: () => void;
};

export const UserWorkspaceSelectorForm: React.FC<UserWorkspaceSelectorFormType> = ({
  workspacesIds,
  userId,
  refetchUserWorkspaces,
}) => {
  const dispatch = useAppDispatch();
  const { showFlag } = useFlag();
  const [workflows, setWorkflows] = React.useState<BaseWorkspaceApiModel[]>([]);

  React.useEffect(() => {
    WorkspaceApi.fetchSimpleWorkspaces(defaultFetchParams).then((res) => {
      setWorkflows(res.results.filter((item) => !workspacesIds.includes(item.pk)));
    });
  }, [setWorkflows, workspacesIds]);

  const onSubmit = React.useCallback(
    async ({ workspaceId, group }: UserWorkspaceSelectorValuesType) => {
      try {
        await dispatch(
          addUserProjectPermission({
            workspaceId,
            params: {
              groups: [group],
              user_id: userId,
            },
          }),
        ).unwrap();
        showFlag({
          title: 'Role updated',
        });

        refetchUserWorkspaces();
      } catch (e) {
        showFlag({
          title: 'Something went wrong.',
          variant: 'error',
        });
      }
    },
    [showFlag, dispatch, userId, refetchUserWorkspaces],
  );

  const { values, handleChange, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit,
  });

  return (
    <form className="has-top-margin" onSubmit={handleSubmit}>
      <p style={{ fontSize: 20, fontWeight: 500 }}>
        <FormattedMessage {...messages.title} />
      </p>

      <div className="user-role-selector-content ">
        <Selector
          name="workspaceId"
          label={errors.workspaceId || 'Select workspace'}
          items={workflows}
          value={values.workspaceId}
          error={Boolean(errors.workspaceId)}
          handleChange={setFieldValue}
        />
        <RoleSelector value={values.group} error={Boolean(errors.group)} onChange={handleChange} />
        <Button size="small" type="submit" variant="contained">
          <FormattedMessage {...messages.add} />
        </Button>
      </div>
    </form>
  );
};
