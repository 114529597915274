import { withStyles } from '@material-ui/core/styles';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const AccordionSummary = withStyles({
  root: {
    padding: 0,
  },
})(MuiAccordionSummary);

export const AccordionDetails = withStyles({
  root: {
    flexDirection: 'column',
    padding: 0,
  },
})(MuiAccordionDetails);

export const Accordion = withStyles({
  root: { background: 'inherit', boxShadow: 'none' },
  expanded: {
    margin: 0,
  },
})(MuiAccordion);
