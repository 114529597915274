export const MergerContent: ProcessorApiModel = {
  name: 'Merger Content',
  processor_type: 'MergerContent',
  description: 'Merger Content',
  type: 'processor',
  tags: ['Transform'],
  data_model: {},
  displayText: 'Test value',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/MergerContent-icon.png',
  connections: {
    inputPort: true,
    outputPort: true,
  },
};
