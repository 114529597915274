import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';

type NodesListBuilderTypePayload = {
  processors: ProcessorApiModel[];
  searchInput: string;
  filterByName?: string[];
  initValue?: Record<string, ProcessorApiModel[]>;
};

type NodesListBuilderTypeOutPut = Record<string, ProcessorApiModel[]>;

export const nodesListBuilder = ({
  processors,
  searchInput,
  filterByName,
  initValue = {},
}: NodesListBuilderTypePayload) => {
  return processors.reduce<NodesListBuilderTypeOutPut>(
    (prev: NodesListBuilderTypeOutPut, cur: ProcessorApiModel) => {
      const tmp: NodesListBuilderTypeOutPut = { ...prev };

      if (!filterByName || (filterByName.length && filterByName.includes(cur.name))) {
        forEach(cur.tags, (tag: string) => {
          if (searchInput.toLowerCase() === '') {
            if (Array.isArray(tmp[tag])) {
              tmp[tag].push(cur);
            } else {
              tmp[tag] = [cur];
            }
          } else if (cur.name.toLowerCase().includes(searchInput.toLowerCase())) {
            if (Array.isArray(tmp[tag])) {
              tmp[tag].push(cur);
            } else {
              tmp[tag] = [cur];
            }
          }
        });

        if (
          (!cur.tags && searchInput.toLowerCase() === '') ||
          (!cur.tags && cur.name.toLowerCase().includes(searchInput.toLowerCase()))
        ) {
          if (Array.isArray(tmp.Other)) {
            tmp.Other.push(cur);
          } else {
            tmp.Other = [cur];
          }
        }
      }

      return tmp;
    },
    cloneDeep(initValue),
  );
};
