import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';
import { buildRequestParams } from 'utils/buildRequestParams';

import type {
  BaseWorkspaceApiModel,
  WorkspaceApiModel,
  MemberApiModel,
  GroupApiModel,
} from './types';

type CreateWorkspacePayload = {
  name: string;
  description: string;
  resource_budget?: number;
};

type UpdateWorkspacePayload = {
  name: string;
  description: string;
  pk: string;
};

export type UpdateUserProjectPermissionPayload = {
  workspaceId: string;
  params: { groups: number[]; user_id: string };
};

export type DeleteUserProjectPermissionPayload = {
  workspaceId: string;
  permissionId: string;
  memberId: string;
};

export interface UserWorkspace {
  id: number;
  object_pk: string;
  workspace: string;
  groups: [number];
}

export const WorkspaceApi = {
  fetchSimpleWorkspaces: async (
    params: TableFilterParams,
  ): Promise<PaginatedResponse<BaseWorkspaceApiModel>> => {
    const res = await requestApi<PaginatedResponse<BaseWorkspaceApiModel>>({
      service: Services.Project,
      submethod: 'project/',
      isAnonymousMethod: true,
      params: buildRequestParams(params),
    });

    return res.data;
  },
  fetchWorkspaces: async (params: TableFilterParams) => {
    const res = await requestApi<PaginatedResponse<WorkspaceApiModel>>({
      service: Services.Project,
      submethod: 'project/bulk/',
      isAnonymousMethod: true,
      params: buildRequestParams(params),
    });

    return res.data;
  },
  createWorkspace: async (data: CreateWorkspacePayload): Promise<WorkspaceApiModel> => {
    const response = await requestApi<WorkspaceApiModel>({
      method: 'POST',
      service: Services.Project,
      submethod: 'project',
      isAnonymousMethod: true,
      data,
    });

    return response.data;
  },
  updateWorkspace: async ({
    pk,
    name,
    description,
  }: UpdateWorkspacePayload): Promise<WorkspaceApiModel> => {
    const response = await requestApi<WorkspaceApiModel>({
      method: 'PATCH',
      service: `project`,
      submethod: `project/${pk}`,
      isAnonymousMethod: true,
      data: {
        name,
        description,
      },
    });

    return response.data;
  },
  deleteWorkspace: async (pk: string): Promise<string> => {
    await requestApi({
      service: Services.Project,
      submethod: `project/${pk}/`,
      isAnonymousMethod: true,
      method: 'DELETE',
    });

    return pk;
  },
  fetchProject: async (id: string): Promise<WorkspaceApiModel> => {
    const res = await requestApi<PaginatedResponse<WorkspaceApiModel>>({
      service: Services.Project,
      submethod: `project/bulk/?id=${id}`,
      isAnonymousMethod: true,
    });

    return res.data.results[0];
  },
  fetchGroupTypes: async (id: string): Promise<GroupApiModel[]> => {
    const res = await requestApi<GroupApiModel[]>({
      service: Services.Project,
      submethod: `project/${id}/members/groups/`,
      isAnonymousMethod: true,
    });

    return res.data;
  },
  fetchMembers: async (id: string): Promise<MemberApiModel[]> => {
    const res = await requestApi<PaginatedResponse<MemberApiModel>>({
      service: `project`,
      submethod: `project/${id}/members`,
      isAnonymousMethod: true,
    });

    return res.data.results;
  },
  fetchUserWorkspaces: async (userId: string): Promise<UserWorkspace[]> => {
    const res = await requestApi<UserWorkspace[]>({
      service: Services.Project,
      isAnonymousMethod: true,
      submethod: `project/user/${userId}/members/workspaces/`,
    });

    return res.data;
  },
  inviteUser: async (workspaceId: string, email: string): Promise<void> => {
    await requestApi({
      method: 'POST',
      service: Services.Project,
      submethod: 'invitations/create-and-send/',
      data: {
        email,
      },
      isAnonymousMethod: true,
      notifyError: false,
    });
  },
  updateUserProjectPermission: async ({
    workspaceId,
    params,
  }: UpdateUserProjectPermissionPayload): Promise<MemberApiModel> => {
    const res = await requestApi<MemberApiModel>({
      method: 'POST',
      service: `project/project/${workspaceId}`,
      submethod: 'members/',
      isAnonymousMethod: true,
      data: params,
    });

    return res.data;
  },
  deleteUserProjectPermissionApi: async ({
    workspaceId,
    permissionId,
    memberId,
  }: DeleteUserProjectPermissionPayload): Promise<string> => {
    await requestApi({
      method: 'DELETE',
      service: Services.Project,
      submethod: `project/${workspaceId}/members/${permissionId}/`,
      isAnonymousMethod: true,
    });

    return memberId;
  },
} as const;
