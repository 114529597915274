import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';
import { buildRequestParams } from 'utils/buildRequestParams';
import { Params } from 'common/Params';
import { Variable } from 'modules/variables/types';

export const AutomationVariablesApi = {
  fetchParams: async (id: string, params?: Params): Promise<Variable[]> => {
    const res = await requestApi<PaginatedResponse<Variable>>({
      service: Services.Params,
      submethod: `params/automation/${id}/`,
      params: params ? buildRequestParams(params) : {},
    });

    return res.data.results;
  },
  createParam: async (id: string, data: Variable): Promise<Variable> => {
    const res = await requestApi<Variable>({
      method: 'POST',
      service: Services.Params,
      submethod: `params/automation/${id}/`,
      data,
    });

    return res.data;
  },
  updateParam: async (id: string, data: Variable): Promise<Variable> => {
    const response = await requestApi<Variable>({
      method: 'PUT',
      service: Services.Params,
      submethod: `params/automation/${id}/${data.id}/`,
      data,
    });

    return response.data;
  },
  removeParam: async (id: string, paramId: string) => {
    await requestApi({
      method: 'DELETE',
      service: Services.Params,
      submethod: `params/automation/${id}/${paramId}/`,
    });

    return id;
  },
} as const;
