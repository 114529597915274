import dagre from 'dagre';
import { isNode } from 'react-flow-renderer';

type WorkflowElementMap = Record<string, WorkflowElement>;

export const getLayoutedElements = (
  elements: WorkflowElement[],
  direction = 'TB',
): WorkflowElementMap => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el: WorkflowElement) => {
    if (isNode(el)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      dagreGraph.setNode(el.id, { width: el.__rf.width, height: el.__rf.height });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.reduce((prev: WorkflowElementMap, el: WorkflowElement) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);

      el.position = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        x: nodeWithPosition.x - el.__rf.width / 2 + Math.random() / 1000,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        y: nodeWithPosition.y - el.__rf.height / 2,
      };

      return {
        ...prev,
        [el.id]: el,
      };
    }

    return prev;
  }, {});
};
