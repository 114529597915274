import * as React from 'react';

type ReplaceConnectionModalSubHeaderProps = {
  connectionName: string;
};

export const ReplaceConnectionModalSubHeader: React.FC<ReplaceConnectionModalSubHeaderProps> = ({
  connectionName,
}) => {
  return (
    <div>
      <p>
        You need to replace the existing connection
        <b> {connectionName}</b> before you can delete it.
      </p>
      <p className="has-top-margin">The delete action cannot be undone.</p>
    </div>
  );
};
