import * as React from 'react';
import { defineMessages } from 'react-intl';

import { ConnectionsModalTypes, useModal } from 'modules/modals';
import { ConnectionItem } from 'modules/automation/modals/ConnectionItem/ConnectionItem';
import { AttachedWorkflow } from 'modules/automation/workflowsActions';

import { Modal } from 'components/modal';

import { ConnectionsCounter } from './ReplaceConnectionModal/ConnectionsCounter';
import { removeConnection } from '../connectionActions';
import { ReplaceConnectionModalSubHeader } from './ReplaceConnectionModal/ReplaceConnectionModalHeader';
import { ConnectionApi } from '../api/ConnectionApi';

const messages = defineMessages({
  replaceTitle: {
    id: 'automation.create-connection-node-modal.continue',
    defaultMessage: 'Confirm replace connection',
  },
  deleteTitle: {
    id: 'automation.create-connection-node-modal.continue',
    defaultMessage: 'Confirm delete connection',
  },
  cancel: {
    id: 'automation.create-connection-node-modal.cancel',
    defaultMessage: 'Change connection',
  },
  replace: {
    id: 'automation.create-connection-node-modal.replace',
    defaultMessage: 'Replace connection',
  },
  delete: {
    id: 'automation.create-connection-node-modal.delete',
    defaultMessage: 'Delete connection',
  },
  submit: {
    id: 'automation.create-connection-node-modal.delete',
    defaultMessage: 'Submit',
  },
});

export const ConfirmDeleteModal: React.FC<{
  source: ConnectionApiModel;
  destination: ConnectionApiModel;
  workflows: AttachedWorkflow[];
  isDeleteModal?: boolean;
}> = ({ source, destination, workflows, isDeleteModal = false }) => {
  const { closeModal } = useModal();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      await ConnectionApi.replaceConnection({
        source: source.pk,
        destination: destination.pk,
      });

      if (isDeleteModal) {
        await removeConnection({
          pk: source.pk,
        });
      }

      closeModal(ConnectionsModalTypes.ConfirmDeleteModal);
      closeModal(ConnectionsModalTypes.ReplaceConnectionModal);
    } catch (e) {
      setIsLoading(false);
    }
  }, [isDeleteModal, isLoading, setIsLoading, closeModal, destination.pk, source.pk]);

  const onClose = React.useCallback(() => {
    closeModal(ConnectionsModalTypes.ConfirmDeleteModal);
  }, [closeModal]);

  return (
    <Modal
      title={isDeleteModal ? messages.deleteTitle : messages.replaceTitle}
      submitMessage={messages.submit}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      {isDeleteModal && <ReplaceConnectionModalSubHeader connectionName={source.name} />}
      <div className="has-top-margin">
        <ConnectionsCounter connectionName={source.name} workflows={workflows} />
        <div
          className="has-top-margin"
          style={{
            borderTop: '1px solid #EDEBE9',
          }}
        >
          <ConnectionItem
            pk={destination.pk}
            description=""
            name={destination.name}
            onClick={() => {}}
          />
        </div>
      </div>
    </Modal>
  );
};
