import * as React from 'react';

import { useAppDispatch, useTypedSelector } from 'store';

import { defaultTableParams, OrderDirection } from 'constants/table';
import { Pagination } from 'components/Table/Pagination';
import { Message } from 'components/Message';

import { WorkspaceCard } from './WorkspaceCard/WorkspaceCard';
import { fetchWorkspaces } from '../workspaceActions';
import { goToSelectedPage, updateSize } from '../workspacesSlice';

export const WorkspaceCardList = React.memo(() => {
  const dispatch = useAppDispatch();
  const workspaces = useTypedSelector((state) => state.workspaces.workspaces);
  const { page, count, size, search, loading } = useTypedSelector(
    (state) => state.workspaces.tableSettings,
  );

  const goToPage = React.useCallback(
    (targetPage: number) => {
      dispatch(goToSelectedPage(targetPage));
    },
    [dispatch],
  );

  const onSizeChange = React.useCallback(
    (targetSize: number) => {
      dispatch(updateSize(targetSize));
    },
    [dispatch],
  );

  React.useEffect(() => {
    dispatch(
      fetchWorkspaces({ order: OrderDirection.ASC, orderBy: 'created_at', page, size, search }),
    );
  }, [dispatch, page, size, search]);

  return (
    <section>
      <Message loading={loading} count={workspaces.length} search={search} />
      <div className="workspace-card-list">
        {workspaces.map((workspace) => (
          <WorkspaceCard key={workspace.pk} workspace={workspace} />
        ))}
      </div>
      {count > defaultTableParams.size && (
        <table style={{ width: '100%' }}>
          <Pagination
            page={page}
            onPageClick={goToPage}
            size={size}
            totalItems={count}
            onChangeSize={onSizeChange}
          />
        </table>
      )}
    </section>
  );
});
