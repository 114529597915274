import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { getRoutePath } from 'utils/path';

import { WebsiteRoutes } from '../constants/routes';
import { useProjectId } from './useProjectId';

export const useNavigation = () => {
  const history = useHistory();
  const { projectId } = useProjectId();

  return useCallback(
    (route: WebsiteRoutes, params?: Record<string, string>) => {
      history.push(
        getRoutePath(route, {
          projectId,
          ...params,
        }),
      );
    },
    [history, projectId],
  );
};
