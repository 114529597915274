import * as React from 'react';

import { ListItem, MenuItem, Select, TextField } from '@material-ui/core';
import { LocalDatasetPropertyType } from './types';

type DatasetsListItemType = {
  id: string;
  fieldName: string;
  fieldType: string;
  onSelect: (fieldName: string, saved: boolean) => void;
  disabled?: boolean;
  updateLocalDataset?: (data: LocalDatasetPropertyType) => void;
  datasetPropertyTypes: DatasetPropertyTypeType[];
};

export const DatasetsListItem: React.FC<DatasetsListItemType> = React.memo(
  ({
    id,
    fieldName,
    onSelect,
    fieldType,
    disabled = false,
    updateLocalDataset,
    datasetPropertyTypes,
  }) => {
    const [name, setName] = React.useState(fieldName);

    React.useEffect(() => {
      setName(fieldName);
    }, [fieldName, setName]);

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();

      setName(event.target.value);
    }, []);

    const onSelectType = React.useCallback(
      (event: React.ChangeEvent<{ value: unknown }>) => {
        event.stopPropagation();

        if (updateLocalDataset) {
          updateLocalDataset({
            id,
            name,
            type: event.target.value as string,
            formData: {},
          });
        }
      },
      [id, name, updateLocalDataset],
    );

    const onBlur = React.useCallback(() => {
      if (updateLocalDataset) {
        updateLocalDataset({
          id,
          name,
          type: fieldType,
          formData: {},
        });
      }
    }, [id, updateLocalDataset, name, fieldType]);

    const onClick = React.useCallback(() => {
      onSelect(id === 'saved' ? fieldName : id, disabled);
    }, [id, fieldName, onSelect, disabled]);

    return (
      <ListItem className="datalist__item" key={fieldName} onClick={onClick} button>
        <TextField
          className="datalist-item__input"
          disabled={disabled}
          variant="outlined"
          onChange={onChange}
          onBlurCapture={onBlur}
          value={name}
        />
        <Select
          className="datalist-item__select"
          variant="outlined"
          onChange={onSelectType}
          disabled={disabled}
          value={fieldType}
        >
          {datasetPropertyTypes.map(({ type }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={index} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </ListItem>
    );
  },
);
