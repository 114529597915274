import React, { useCallback, useState } from 'react';
import { defineMessages } from 'react-intl';
import Form from '@rjsf/material-ui';

import { useFormData } from 'hooks';
import { ModalFooter, ModalWithoutFooter } from 'components/modal';

import { scraperConnectionSchema } from './schemas/scraperConnectionSchema';
import { ConnectionsModalTypes, useModal } from '../../modals';
import { useConnections } from '../useConnections';
import { ConnectorApi } from '../api/ConnectorApi';
import { ConnectionApi } from '../api/ConnectionApi';

const messages = defineMessages({
  title: {
    id: 'connection.create-email-connector-modal.title',
    defaultMessage: 'Email Connection',
  },
  create: {
    id: 'connection.create-email-connector-modal.create',
    defaultMessage: 'Create',
  },
  cancel: {
    id: 'connection.create-email-connector-modal.cancel',
    defaultMessage: 'Cancel',
  },
});

type FormData = ConnectionModel & ScraperConfig;

const initialState: FormData = {
  name: '',
  description: '',
  scheduler: false,
  cron: '',
  proxy_servers: [],
  start_urls: [],
  allowed_domains: [],
  title_xpath: '',
  description_xpath: '',
  content_xpath: '',
  published_time_xpath: '',
  modified_time_xpath: '',
  page_xpath_validation_rule: '',
  urls_on_page_xpath: '',
};

export const CreateScraperConnectionModal = () => {
  const { closeModal } = useModal();
  const fetchConnections = useConnections();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { formData, onChange } = useFormData<FormData>(initialState);

  const onSubmit = useCallback(async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      const { name, description, cron, scheduler, ...config } = formData;

      const connection = await ConnectionApi.createConnection({
        name,
        description,
        source_type: 'site',
        datasource_model: 'scraper',
      });

      await ConnectorApi.createConnector({
        pk: connection.pk,
        type: 'scrapper',
        data: {
          cron,
          scheduler,
          config,
        },
      });

      fetchConnections();

      closeModal(ConnectionsModalTypes.CreateScraperConnectionModal);
    } catch (e) {
      setIsLoading(false);
    }
  }, [fetchConnections, isLoading, closeModal, formData]);

  const onCancel = useCallback(() => {
    closeModal(ConnectionsModalTypes.CreateScraperConnectionModal);
  }, [closeModal]);

  return (
    <ModalWithoutFooter title={messages.title} onClose={onCancel}>
      <Form
        className="application-form"
        onChange={onChange}
        schema={scraperConnectionSchema}
        formData={formData}
      >
        <ModalFooter
          isLoading={isLoading}
          submitMessage={messages.create}
          cancelMessage={messages.cancel}
          hasPadding={false}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Form>
    </ModalWithoutFooter>
  );
};
