export const getStringValue = (val?: string | number): string =>
  val || typeof val === 'number' ? val.toString() : 'N/A';

export const getLocaleDateString = (val: string | null): string => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' } as {
    year: 'numeric';
    month: 'short';
    day: 'numeric';
  };

  if (val) {
    const dateObject = new Date(val);
    return dateObject.toLocaleDateString('en-GB', options);
  }

  return 'N/A';
};
