import React, { useState, useCallback, memo } from 'react';

import Slide from '@material-ui/core/Slide';

import { CreateNodeMenuContainer } from './ProcessorListMenu.style';
import { NodeList } from './NodeList';
import { ModalTabs } from './constants';
import { ProcessorListMenuHeader } from './ProcessorListMenuHeader';

export const ProcessorListMenu = memo(() => {
  const [search, setSearch] = useState('');
  const [activeTab, setActiveTab] = useState<ModalTabs>(ModalTabs.processor);

  const onSearch = useCallback(
    (value: string) => {
      setSearch(value);

      setActiveTab(value.toLowerCase() === '' ? ModalTabs.processor : ModalTabs.search);
    },
    [setActiveTab, setSearch],
  );

  const onTabChange = useCallback((value: ModalTabs) => {
    setActiveTab(value);
    setSearch('');
  }, []);

  return (
    <Slide in direction="right" mountOnEnter unmountOnExit>
      <CreateNodeMenuContainer
        id="create-node-modal-menu"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <ProcessorListMenuHeader
          activeTab={activeTab}
          searchValue={search}
          onSearch={onSearch}
          onTabChange={onTabChange}
        />
        <section className="create-node-menu-content" id="alert-dialog-slide-description">
          <NodeList searchInput={search} activeTab={activeTab} />
        </section>
      </CreateNodeMenuContainer>
    </Slide>
  );
});
