import React from 'react';

import { Autocomplete, Option } from './Autocomplete';

interface AddTagComponentProps {
  tags: TagApiModel[];
  filteredTags?: TagApiModel[];
  onAddTag: (option: Option | null) => void;
  onCreateTag: (option: string) => void;
}

export const TagAutocomplete = ({
  tags,
  filteredTags = [],
  onAddTag,
  onCreateTag,
}: AddTagComponentProps) => (
  <Autocomplete
    options={filteredTags}
    notFilteredOptions={tags}
    onChange={onAddTag}
    onCreate={onCreateTag}
  />
);
