import * as React from 'react';
import { useParams } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { Message } from 'components/Message';

import { AutomationEditParams } from '../../types';
import { fetchAutomationApiKeys } from '../../automationApi';

export const AutomationApiKeys: React.FC = () => {
  const { id } = useParams<AutomationEditParams>();
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [apiKeys, setApiKeys] = React.useState<AssociatedEntity[]>([]);

  React.useEffect(() => {
    setLoading(true);
    fetchAutomationApiKeys(id)
      .then((items) => {
        setApiKeys(items);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>API Key name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apiKeys.map(({ id: apiKeyId, name }) => (
            <TableRow key={apiKeyId}>
              <TableCell style={{ cursor: 'pointer' }}>{name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Message loading={isLoading} search="" entityName="datasets" count={apiKeys.length} />
    </TableContainer>
  );
};
