export const a11yProps = (index: number | string) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
});

export const tabA11yValue = (value: string) => ({
  value,
  id: `vertical-tab-${value}`,
  'aria-controls': `vertical-tabpanel-${value}`,
});
