import React from 'react';

import { useTypedSelector } from 'store';

import { StatisticComponentType } from '../../types';
import {
  selectAutomationProcessorStatisticComponent,
  selectWorkflowElementEntityIdById,
} from '../../workflowSelectors';

type ProcessorStatisticViewType = {
  processorId: string;
};

export const ProcessorStatisticView: React.FC<ProcessorStatisticViewType> = React.memo(
  ({ processorId }) => {
    const entityId = useTypedSelector(selectWorkflowElementEntityIdById(processorId));
    const automationStatisticStatus: Maybe<StatisticComponentType> = useTypedSelector(
      selectAutomationProcessorStatisticComponent(entityId || ''),
    );

    return (
      <div className="edit-processor-modal">
        <ul className="statistics-list">
          <li className="statistics-list__item">
            Running: <span>{automationStatisticStatus?.running_count || 0}</span>
          </li>
          <li className="statistics-list__item">
            Stopped: <span>{automationStatisticStatus?.stopped_count || 0}</span>
          </li>
          <li className="statistics-list__item">
            Invalid: <span>{automationStatisticStatus?.invalid_count || 0}</span>
          </li>
        </ul>
      </div>
    );
  },
);
