import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import store from 'store';

import keycloak from 'keycloak';

import { ModalTypes } from 'modules/modals';
import { openModal } from 'modules/modals/modalsSlice';

import { baseURL } from '../env';
import { URLBuilderOptions, buildUrl } from './buildUrl';

const instance = axios.create({ baseURL });

instance.interceptors.request.use(
  (config) => {
    (config.headers as { [key: string]: string })['x-auth-token'] = `Bearer ${
      keycloak.token || ''
    }`;

    return config;
  },
  (error) => Promise.reject(error),
);

type ApiCallConfig = {
  notifyError?: boolean;
} & URLBuilderOptions &
  AxiosRequestConfig;

export const requestApi = async <T>({
  method = 'GET',
  isAnonymousMethod = false,
  projectId = localStorage.getItem('PROJECT_ID') || '',
  notifyError = true,
  service,
  submethod = '',
  ...rest
}: ApiCallConfig) => {
  try {
    return await instance.request<T>({
      ...rest,
      url: buildUrl({ isAnonymousMethod, submethod, service, projectId }),
      method,
    });
  } catch (e) {
    if (notifyError) {
      store.dispatch(
        openModal({
          type: ModalTypes.ErrorSnackbar,
          data: {
            error: e as AxiosError,
          },
        }),
      );
    }

    throw e;
  }
};

export default instance;
