import * as React from 'react';
import { EdgeProps, getEdgeCenter, getSmoothStepPath } from 'react-flow-renderer';

import { useAppDispatch } from 'store';
import { useMouseHover } from 'hooks';

import { removeEdge } from '../../../workflowSlice';
import { RemoveEdgeIcon } from './RemoveEdgeIcon';
import { TransparentPath } from './CommonEdge.style';
import { CustomEdgeLabel } from './CustomEdgeLabel';

const borderRadius = 5;

export type ProcessorEdgeProps = EdgeProps<WorkflowEdge>;

export const CustomEdge: React.FC<EdgeProps<WorkflowEdge>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const dispatch = useAppDispatch();
  const { onMouseLeave, onMouseEnter, isActionsOpen } = useMouseHover(100);
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY: sourceY - 10,
    sourcePosition,
    targetX,
    targetY: targetY + 12,
    targetPosition,
    borderRadius,
  });
  const edgeCenter = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  const onRemove = React.useCallback(() => {
    dispatch(removeEdge(id));
  }, [dispatch, id]);

  return (
    <>
      <path id={id} className="react-flow__edge-path" d={edgePath} />
      <TransparentPath d={edgePath} onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave} />
      {typeof data?.label === 'string' && (
        <CustomEdgeLabel
          displayDelete={isActionsOpen}
          edgeCenter={edgeCenter}
          label={data?.label}
        />
      )}
      <RemoveEdgeIcon
        displayDelete={isActionsOpen}
        edgeCenter={edgeCenter}
        onRemove={onRemove}
        onMouseOver={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </>
  );
};
