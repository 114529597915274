import React from 'react';
import { defineMessages } from 'react-intl';

import { useAppDispatch } from 'store';
import { TopBarSection } from 'components/TopBarSection';
import { useModal, WorkspaceModalTypes } from 'modules/modals';

import { updateSearch } from '../workspacesSlice';

const messages = defineMessages({
  title: {
    id: 'workspaces.workspaces-header.title',
    defaultMessage: 'Workspaces',
  },
  newWorkspace: {
    id: 'workspaces.workspaces-header.new-workspace',
    defaultMessage: 'New workspace',
  },
});

export const WorkspacesPageTitle = () => {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();

  const onSearch = React.useCallback(
    (value: string) => {
      dispatch(updateSearch(value));
    },
    [dispatch],
  );

  const onNewItem = React.useCallback(() => {
    openModal({
      type: WorkspaceModalTypes.CreateWorkspaceModal,
    });
  }, [openModal]);

  return (
    <TopBarSection
      onSearch={onSearch}
      onNewItem={onNewItem}
      title={messages.title}
      newItemText={messages.newWorkspace}
    />
  );
};
