import { ProcessorWarning } from '../utils/validateProcessorField';

export const emptyFunction = () => {};

export const getWarningContent = (
  warning: ProcessorWarning,
  showProcessorName: boolean,
): string => {
  if (warning.issue === 'connection_error') {
    return `${
      showProcessorName ? `${warning.processorType}:` : ''
    } selected connection has error`.trim();
  }

  return `${showProcessorName ? `${warning.processorType}:` : ''} ${warning.name} ${
    warning.message || 'is empty'
  }`;
};
