import React from 'react';

import { getStringValue, getLocaleDateString } from 'utils/i18n';
import { TableTags } from 'components/TagsCell/TableTags.container';
import type { TableContent, TableRowParams } from 'components/Table';

import { AutomationStatusCell } from './AutomationStatusCell';
import { AutomationNameCell } from './AutomationNameCell';
import { WorkflowTableActionsCell } from './WorkflowTableActionsCell';

export interface WorkflowContent extends TableRowParams {
  id: string;
  name: JSX.Element;
  updated_at: string;
  description: string;
  status: JSX.Element;
  tags: JSX.Element;
  actions: JSX.Element;
}

const workflowTableContent: TableContent<WorkflowContent> = {
  header: {
    name: {
      width: '20%',
      name: 'Name',
      sortable: true,
    },
    updated_at: {
      width: '120px',
      name: 'Last Modified',
      sortable: true,
    },
    status: {
      width: '15%',
      name: 'Status',
      sortable: true,
    },
    description: {
      width: '15%',
      name: 'Description',
      sortable: true,
    },
    tags: {
      name: 'Tags',
      sortable: false,
    },
    actions: {
      name: '',
      sortable: false,
    },
  },
  rows: [],
};

export const useWorkflowsTableContent = (
  items: AutomationApiModel[],
): TableContent<WorkflowContent> => {
  workflowTableContent.rows = items.map((workflow) => ({
    id: workflow.id,
    name: <AutomationNameCell workflow={workflow} />,
    updated_at: getLocaleDateString(workflow.updated_at),
    status: <AutomationStatusCell status={workflow.status} automationId={workflow.id} />,
    description: getStringValue(workflow.description),
    tags: <TableTags workflow={workflow} />,
    actions: <WorkflowTableActionsCell workflowId={workflow.id} />,
  }));

  return workflowTableContent;
};
