import React from 'react';
import styled from 'styled-components';

import { useModal, AllModalTypes } from 'modules/modals';

import { Container } from 'components/Table/NameCell.style';

const TextName = styled.p`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export interface EditItemCellType {
  pk: string;
  title: string;
  modalType: AllModalTypes;
}

export const EditItemCell: React.FC<EditItemCellType> = React.memo(({ title, pk, modalType }) => {
  const { openModal } = useModal();

  const onDoubleClick = React.useCallback(() => {
    openModal({ type: modalType, data: { pk } });
  }, [openModal, pk, modalType]);

  return (
    <Container onDoubleClick={onDoubleClick} style={{ cursor: 'pointer' }}>
      <TextName>{title}</TextName>
    </Container>
  );
});
