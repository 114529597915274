import { isNode, ReactFlowState } from 'react-flow-renderer';
import cloneDeep from 'lodash/cloneDeep';

import { RootState } from 'store';
import { getProcessors } from 'precessors';

import { parseNode } from './components/utils';
import { ModalTabs } from './modals/ProcessorListMenu/constants';
import { nodesListBuilder } from './modals/ProcessorListMenu/nodesListBuilder';
import { emptyAutomation } from './workflowSlice';

import type { BulletinsStatusType, StatisticComponentType, StatisticStatusType } from './types';
import { emptyProcessorStatisticComponent, emptyProcessorStatisticStatus } from './types';

export const selectReactFlowNodes = (state: ReactFlowState) => state.nodes.map(parseNode);

export const selectReactFlowEdges = (state: ReactFlowState) => state.edges;

export const selectNodes = ({ workflow }: RootState): WorkflowNode[] =>
  workflow.graph.filter((item) => isNode(item)) as WorkflowNode[];

export const selectGraph = ({ workflow }: RootState): WorkflowElement[] => workflow.graph;

export const selectParentNodeId =
  (targetId: string) =>
  ({ workflow }: RootState) => {
    return workflow.graph.reduce((prev: Maybe<string>, element: WorkflowElement) => {
      if ('target' in element && element.target === targetId) {
        return element.source;
      }

      return prev;
    }, undefined);
  };

export const selectNodesByCategory =
  (category: string, searchInput: string) => (state: RootState) => {
    const processors = getProcessors();
    const { favoriteNodes } = state.nodes;

    switch (category) {
      case ModalTabs.favorite:
        return nodesListBuilder({
          processors: [...processors],
          searchInput,
          filterByName: favoriteNodes,
        });
      case ModalTabs.connection:
        return nodesListBuilder({
          processors: processors.filter((item) => item.type === 'connection'),
          searchInput,
        });
      case ModalTabs.search:
        return nodesListBuilder({
          processors: [...processors],
          searchInput,
        });
      default:
        return nodesListBuilder({
          processors: [...processors.filter((item) => ['end', 'processor'].includes(item.type))],
          searchInput,
          initValue: cloneDeep({ Logical: [], Transform: [], NLP: [], Output: [], Other: [] }),
        });
    }
  };

export const selectIsSaved = ({ workflow }: RootState): boolean => workflow.isSaved;

export const selectWorkflowElementById =
  (processorId: string) =>
  ({ workflow }: RootState): WorkflowElementData => {
    return workflow.graph.find((item) => item.id === processorId)?.data as WorkflowElementData;
  };

export const selectWorkflowElementProcessorTypeById =
  (processorId: string) =>
  ({ workflow }: RootState): Maybe<ProcessorTypes> => {
    const node = workflow.graph.find((item) => item.id === processorId);

    if (node && isNode(node)) {
      return node?.data?.processorType as ProcessorTypes;
    }

    return undefined;
  };

export const selectWorkflowElementEntityIdById =
  (processorId: string) =>
  (state: RootState): Maybe<string> => {
    return selectWorkflowElementById(processorId)(state)?.entityId;
  };

export const selectAutomationVersionById =
  (automationId: string) =>
  (state: RootState): AutomationApiModel => {
    return (
      state.workflow.automationVersions.find((item) => item.id === automationId) || emptyAutomation
    );
  };

export const selectAutomationStatisticStatus = ({
  workflow,
}: RootState): Maybe<StatisticStatusType> => workflow.automationStatistic?.status;

export const selectAutomationBulletins = ({ workflow }: RootState): BulletinsStatusType[] =>
  workflow.automationStatistic?.bulletins || [];

export const selectAutomationProcessorBulletins =
  (entityId: string) =>
  ({ workflow }: RootState): BulletinsStatusType[] =>
    workflow.automationStatistic?.sub_components_info[entityId]?.bulletins || [];

export const selectAutomationProcessorStatisticStatus =
  (entityId: string) =>
  ({ workflow }: RootState): StatisticStatusType =>
    workflow.automationStatistic?.sub_components_info[entityId]?.status ||
    emptyProcessorStatisticStatus;

export const selectAutomationProcessorStatisticComponent =
  (entityId: string) =>
  ({ workflow }: RootState): StatisticComponentType =>
    workflow.automationStatistic?.sub_components_info[entityId]?.component ||
    emptyProcessorStatisticComponent;

export const selectIsAutomationRunning = ({ workflow }: RootState): boolean =>
  workflow.automation.status === 'running';

export const selectAutomationStatus = ({ workflow }: RootState): AutomationStatuses =>
  workflow.automation.status;
