import cloneDeep from 'lodash/cloneDeep';

import {
  convertMapperToObject,
  convertObjectMapperToArray,
  decodeParameters,
  encodeParameters,
} from './helpers';

const trimTemplate = (str: string): string => {
  return str.replace('{{', '').replace('}}', '');
};

export const createNodeFromDot = (dotNode: DotNode, nodeName: string): WorkflowNode => {
  const parameters = decodeParameters(dotNode.parameters) as Record<string, unknown>;

  if (dotNode.processor_type === 'DatasetWriter' && parameters.dataset_writer_mapper) {
    parameters.mapper = convertObjectMapperToArray(
      parameters.dataset_writer_mapper as Record<string, string>,
    );
  }

  if (dotNode.processor_type === 'GmailSender') {
    if (parameters.text_template && typeof parameters.text_template === 'string') {
      parameters.text_template = trimTemplate(parameters.text_template);
    }
    if (parameters.subject_template && typeof parameters.subject_template === 'string') {
      parameters.subject_template = trimTemplate(parameters.subject_template);
    }
  }

  return {
    id: dotNode.id || nodeName,
    type: dotNode.type,
    data: {
      entityId: dotNode.entity_id,
      processorType: dotNode.processor_type,
      parameters,
    },
    position: {
      x: parseInt(dotNode.x as string, 10),
      y: parseInt(dotNode.y as string, 10),
    },
  };
};

export const createDotNodeFromNode = (node: WorkflowNode): Maybe<DotNode> => {
  if (!node.type || !node.data) return undefined;

  const parameters = cloneDeep(node.data.parameters);

  if (
    node.data?.processorType === 'DatasetWriter' &&
    parameters?.dataset_writer_mapper &&
    Array.isArray(parameters.dataset_writer_mapper)
  ) {
    parameters.dataset_writer_mapper = convertMapperToObject(parameters.dataset_writer_mapper);
  }

  if (node.data?.processorType === 'GmailSender') {
    parameters.text_template = parameters?.text_template ? `{{${parameters?.text_template}}}` : '';
    parameters.subject_template = parameters?.subject_template
      ? `{{${parameters?.subject_template}}}`
      : '';
  }

  const dotNode: DotNode = {
    id: node.id,
    processor_type: node.data.processorType,
    type: node.type as ComponentsTypes,
    parameters: encodeParameters(parameters),
    x: Math.floor(node.position.x),
    y: Math.floor(node.position.y),
  };

  if (node.data.entityId) {
    dotNode.entity_id = node.data.entityId;
  }

  return dotNode;
};
