import * as React from 'react';

import { useAppDispatch, useTypedSelector } from 'store';
import { useConnectionsWorkflows } from 'hooks/useConnectionsWorkflows';
import { selectConnectionByPk } from 'modules/connections/connectionsSelectors';
import { fetchConnection } from 'modules/connections/connectionActions';

import { ReplaceConnectionModal } from './ReplaceConnectionModal';

type ReplaceConnectionModalContainerType = {
  pk: string;
  isDeleteModal?: boolean;
};

export const ReplaceConnectionModalContainer: React.FC<ReplaceConnectionModalContainerType> = ({
  isDeleteModal = false,
  pk,
}) => {
  const dispatch = useAppDispatch();
  const { workflows } = useConnectionsWorkflows(pk);
  const connectionData = useTypedSelector(selectConnectionByPk(pk));

  React.useEffect(() => {
    dispatch(fetchConnection({ pk }));
  }, [dispatch, pk]);

  if (!connectionData) {
    return null;
  }

  return (
    <ReplaceConnectionModal
      isDeleteModal={isDeleteModal}
      workflows={workflows}
      connectionData={connectionData}
    />
  );
};
