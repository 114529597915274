import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ChevronLeft } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import { WebsiteRoutes } from 'constants/routes';
import { getRoutePath } from 'utils/path';
import { AppBarStyled, ToolbarStyled } from 'components/Header';

import { DrawerButtonWrapper } from 'components/HeaderHomeLink/HeaderHomeLink.style';

type DatasetHeaderParamsType = {
  projectId: string;
  datasetId: string;
};

export const DatasetHeader = React.memo(() => {
  const history = useHistory();
  const { projectId } = useParams<DatasetHeaderParamsType>();

  const onBackClick = React.useCallback(() => {
    history.push(getRoutePath(WebsiteRoutes.Datasets, { projectId }));
  }, [history, projectId]);

  return (
    <AppBarStyled>
      <ToolbarStyled>
        <DrawerButtonWrapper>
          <IconButton onClick={onBackClick}>
            <ChevronLeft />
          </IconButton>
        </DrawerButtonWrapper>
      </ToolbarStyled>
    </AppBarStyled>
  );
});
