import * as React from 'react';
import { useParams } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { Message } from 'components/Message';

import { fetchAutomationConnections } from '../../automationApi';
import { AutomationEditParams } from '../../types';

export const AutomationConnections = () => {
  const { id } = useParams<AutomationEditParams>();
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [connections, setConnections] = React.useState<AssociatedEntity[]>([]);

  React.useEffect(() => {
    setLoading(true);
    fetchAutomationConnections(id)
      .then((items) => {
        setConnections(items);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Connection name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {connections.map(({ id: connectionId, name }) => (
            <TableRow key={connectionId}>
              <TableCell data-id={connectionId} style={{ cursor: 'pointer' }}>
                {name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Message loading={isLoading} search="" entityName="connections" count={connections.length} />
    </TableContainer>
  );
};
