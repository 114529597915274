import React, { useEffect } from 'react';

import { useAppDispatch, useTypedSelector } from 'store';
import { useProjectId } from 'hooks';

import { CommonTable } from 'components/Table/CommonTable';
import { Message } from 'components/Message';

import { fetchDockerCredentials } from '../../credentialsActions';
import { useCredentialsTableContent } from './useDockerCredetialsTableContent';
import { changeSorting, goToSelectedPage, updateSize } from '../../credentialsSlice';

export const DockerCredentialsTable = () => {
  const { projectId } = useProjectId();
  const dispatch = useAppDispatch();

  const { page, count, size, order, orderBy, search, loading } = useTypedSelector(
    (store) => store.credentials.tableSettings,
  );

  const credentials: DockerCredentialApiModel[] = useTypedSelector(
    (store) => store.credentials.dockerCredentials,
  );

  const onGoToPageHandler = React.useCallback(
    (targetPage: number) => {
      dispatch(goToSelectedPage(targetPage));
    },
    [dispatch],
  );

  const onSizeChangeHandler = React.useCallback(
    (targetSize: number) => {
      dispatch(updateSize(targetSize));
    },
    [dispatch],
  );

  const onClickSortHandler = React.useCallback(
    (columnName: string) => {
      dispatch(changeSorting(columnName));
    },
    [dispatch],
  );

  const tableContent = useCredentialsTableContent(credentials);

  useEffect(() => {
    dispatch(fetchDockerCredentials({ page, size, order, orderBy, search }));
  }, [dispatch, page, size, order, orderBy, search, projectId]);

  return (
    <>
      <CommonTable
        content={tableContent}
        page={page}
        totalItems={count}
        size={size}
        goToPage={onGoToPageHandler}
        onSizeChange={onSizeChangeHandler}
        onClickSort={onClickSortHandler}
        order={order}
        orderBy={orderBy}
        isPagination
      />
      <Message loading={loading} search={search} count={count} entityName="docker credentials" />
    </>
  );
};
