import * as React from 'react';
import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

export const ModalHeaderStyled = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #edebe9;
  padding-left: 16px;
`;

export const ModalTitleStyled = styled.h3`
  font-size: 22px;
  font-weight: 600;
`;

type ModalHeaderType = {
  fontWeight?: number;
  fontSize?: number;
  onClose: () => void;
};

export const ModalHeader: React.FC<ModalHeaderType> = (props) => {
  const { onClose, fontSize = 22, fontWeight = 600, children } = props;

  return (
    <ModalHeaderStyled>
      <ModalTitleStyled style={{ fontSize, fontWeight }}>{children}</ModalTitleStyled>
      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
    </ModalHeaderStyled>
  );
};
