import { CustomComponentApiModel } from 'modules/custom-components/types';

import { getDataModel } from './getDataModel';
import { getParentsNodes } from './getParentsNodes';

type GetOutputArgs = {
  processorId?: string;
  graph: WorkflowElement[];
  customComponents: CustomComponentApiModel[];
};

export const getProcessorOutput = ({
  graph,
  processorId,
  customComponents,
}: GetOutputArgs): DataModel => {
  if (!processorId) {
    return {};
  }

  return getParentsNodes(processorId, graph).reduce(
    (processorsOutput: DataModel, item: WorkflowNode): DataModel => {
      const processorType = item?.data?.processorType as Maybe<ProcessorTypes>;

      if (!processorType) {
        return processorsOutput;
      }

      const dataModel = getDataModel(processorType, customComponents, item?.data?.parameters);

      return { ...processorsOutput, ...dataModel };
    },
    {},
  );
};
