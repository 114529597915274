import { getProcessorSchemaByType } from 'precessors';

export enum WarningTypes {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export type IssueType = 'empty' | 'connection_error' | 'invalid_value';

export type ProcessorWarning = {
  name: string;
  type: WarningTypes;
  issue: IssueType;
  processorId: string;
  processorType: ProcessorTypes;
  message?: string;
};

const warningSort = (value: ProcessorWarning): number => {
  return value.type === WarningTypes.ERROR ? -1 : 1;
};

export const validateProcessorField = (
  processorId: string,
  parameters: WorkflowElementDataParameters,
  processorType: ProcessorTypes,
): ProcessorWarning[] => {
  const schema = getProcessorSchemaByType(processorType);
  const warnings: ProcessorWarning[] = [];

  for (const property of Object.keys(schema.properties)) {
    if (property in schema.properties) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!parameters[property]) {
        warnings.push({
          processorId,
          type: schema.required?.includes(property) ? WarningTypes.ERROR : WarningTypes.WARNING,
          name: property,
          processorType,
          issue: 'empty',
        });
      }
    }
  }

  return warnings.sort(warningSort);
};

export const validateProcessorConnections = (
  nodes: WorkflowNode[],
  connections: ConnectionApiModel[],
): ProcessorWarning[] => {
  return nodes.reduce((acc: ProcessorWarning[], node): ProcessorWarning[] => {
    const connectionId = node.data?.parameters.connection_uuid as string;

    if (connectionId && connections.find((item) => item.pk === connectionId)?.status === 'Error') {
      return [
        ...acc,
        {
          processorId: node.id,
          type: WarningTypes.ERROR,
          name: 'connection_uuid',
          processorType: node?.data?.processorType as ProcessorTypes,
          issue: 'connection_error',
        },
      ];
    }

    return acc;
  }, []);
};

export const validateProcessorFields = (nodes: WorkflowNode[]): ProcessorWarning[] => {
  const warnings: ProcessorWarning[] = nodes.reduce(
    (acc: ProcessorWarning[], node: WorkflowNode) => {
      const { parameters, processorType } = node.data as WorkflowElementData;

      if (parameters) {
        return [...acc, ...validateProcessorField(node.id, parameters, processorType)];
      }
      return acc;
    },
    [],
  );

  return warnings.sort(warningSort);
};
