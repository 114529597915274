import styled from 'styled-components';

import { drawerWidth, drawerCollapsedWidth } from '../constants';

export const getContentWrapperOffset = (isDrawerOpen: boolean): number => {
  return isDrawerOpen ? drawerWidth : drawerCollapsedWidth;
};

interface ContentWrapperProps {
  offset: number;
}

export const PageContentWrapper = styled.main<ContentWrapperProps>`
  padding: 78px 32px 32px;
  margin: 0;
  box-sizing: border-box;
  max-width: 100%;
  width: ${({ offset }) => {
    return offset ? `calc(100% - ${offset}px);` : '100%';
  }};
`;
