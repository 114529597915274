import * as React from 'react';

import { useAppDispatch, useTypedSelector } from 'store';

import connectionsIcon from './assets/connections.png';
import activityIcon from './assets/activity.png';
import inactiveIcon from './assets/inactive.png';
import warningIcon from './assets/warning.png';
import './styles.scss';

import { SummaryItem } from './SummaryItem';
import { updateConnectionsTableFilter } from '../../connectionsSlice';
import { ConnectionTableFilterType } from '../../types';

export const ConnectionsSummary: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();

  const filterType = useTypedSelector((state) => state.connections.filter);
  const { count, error, inactive, active } = useTypedSelector(
    (state): ConnectionsInfo => state.connections.info,
  );

  const onClick = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement>) => {
      dispatch(
        updateConnectionsTableFilter({
          filterType: e.currentTarget.dataset.type as ConnectionTableFilterType,
        }),
      );
    },
    [dispatch],
  );

  return (
    <div className="summary-wrapper">
      <ul className="summary-items-list">
        <SummaryItem
          key="connections"
          tooltipText="All connections"
          icon={connectionsIcon}
          onClick={onClick}
          filterType={undefined}
          isActive={false}
        >
          <p>All connections</p>
          <p>{count}</p>
        </SummaryItem>
        <SummaryItem
          key="error"
          tooltipText="With error"
          icon={warningIcon}
          onClick={onClick}
          filterType="Error"
          isActive={filterType === 'Error'}
        >
          <p>Errors</p>
          <p>{error}</p>
        </SummaryItem>
        <SummaryItem
          key="inactive"
          tooltipText="No error and cron off"
          icon={inactiveIcon}
          onClick={onClick}
          filterType="Inactive"
          isActive={filterType === 'Inactive'}
        >
          <p>Inactive</p>
          <p>{inactive}</p>
        </SummaryItem>
        <SummaryItem
          key="active"
          tooltipText="No error and cron on"
          icon={activityIcon}
          onClick={onClick}
          filterType="Active"
          isActive={filterType === 'Active'}
        >
          <p>Active</p>
          <p>{active}</p>
        </SummaryItem>
      </ul>
    </div>
  );
});
