import { Schema } from 'schemas/types';

export const GmailSenderSchema: Schema = {
  type: 'object',
  properties: {
    label: {
      title: 'Label',
      type: 'string',
      default: '',
    },
    connection_uuid: {
      enum: [],
      enumNames: [],
      entityName: 'connection',
      title: 'Connection',
      type: 'string',
      filter: { fieldName: 'source_type', value: 'gmail' },
    },
    recipients: {
      title: 'Recipients',
      type: 'string',
      default: '',
    },
    subject_template: {
      title: 'Subject template',
      type: 'string',
      enum: [],
      enumNames: [],
      default: '',
      entityName: 'data_model',
    },
    text_template: {
      title: 'Text template',
      type: 'string',
      enum: [],
      enumNames: [],
      default: '',
      entityName: 'data_model',
    },
  },
  required: ['connection_uuid'],
};
