import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTypedSelector } from 'store';
import { useProjectId } from 'hooks';

import { WebsiteRoutes } from 'constants/routes';
import { useModal } from 'modules/modals';
import { WorkspaceModalTypes } from 'modules/modals/constants';

import { selectWorkspaceName } from '../../workspaceSelectors';
import { FirstStepModal } from './FirstStepModal';
import { Actions } from './Action';

const ACTION_ROUTE_MAP: Record<Actions, WebsiteRoutes> = {
  [Actions.NEW_AUTOMATION]: WebsiteRoutes.Workflows,
  [Actions.NEW_CONNECTION]: WebsiteRoutes.Connections,
  [Actions.NEW_DATA_MODEL]: WebsiteRoutes.Datasets,
};

export type FirstStepModalContainerType = { id: string };

export const FirstStepModalContainer: React.FC<FirstStepModalContainerType> = ({ id }) => {
  const history = useHistory();
  const { setProjectId } = useProjectId();
  const [action, setAction] = React.useState<Actions>(Actions.NEW_AUTOMATION);
  const { closeModal, openModal } = useModal();
  const projectName = useTypedSelector(selectWorkspaceName);

  const onContinue = React.useCallback(() => {
    closeModal(WorkspaceModalTypes.FirstStepModal);
    setProjectId(id);
    history.push(ACTION_ROUTE_MAP[action].replace(':projectId', id));
  }, [setProjectId, id, action, history, closeModal]);

  const onSelect = React.useCallback(
    (newAction: Actions) => {
      setAction(newAction);
    },
    [setAction],
  );

  const onClose = React.useCallback(() => {
    closeModal(WorkspaceModalTypes.FirstStepModal);
  }, [closeModal]);

  const goBack = React.useCallback(() => {
    openModal({ type: WorkspaceModalTypes.SetWorkspacePermissionModal, data: { id } });
    closeModal(WorkspaceModalTypes.FirstStepModal);
  }, [openModal, closeModal, id]);

  return (
    <FirstStepModal
      selectedAction={action}
      projectName={projectName}
      onContinue={onContinue}
      onSelect={onSelect}
      onClose={onClose}
      goBack={goBack}
    />
  );
};
