import React, { useCallback, useState } from 'react';
import { FormDataChangeEvent } from '../types';

export const useFormData = <T>(
  initialState: T,
): {
  onChange: (data: FormDataChangeEvent<T>) => void;
  formData: [T, React.Dispatch<React.SetStateAction<T>>][0];
} => {
  const [formData, setFormData] = useState<T>(initialState);

  const onChange = useCallback(
    (data: FormDataChangeEvent<T>) => {
      setFormData(data.formData);
    },
    [setFormData],
  );

  return { formData, onChange };
};
