import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Chip } from '@material-ui/core';

import { Option } from 'components/TagsCell/TagAutocomplete/Autocomplete';
import { TagAutocomplete } from 'components/TagsCell/TagAutocomplete/TagAutocomplete';

interface AddTagComponentProps {
  tags?: TagApiModel[];
  filteredTags?: TagApiModel[];
  onClickAdd: () => void;
  onChangeTag: (option: Option | null) => void;
  onCreateTag: (option: string) => void;
  isAdd: boolean;
}

export const AddTagComponent = ({
  tags = [],
  filteredTags = [],
  onClickAdd,
  onChangeTag,
  onCreateTag,
  isAdd,
}: AddTagComponentProps) => {
  return (
    <>
      {isAdd ? (
        <TagAutocomplete
          tags={tags}
          filteredTags={filteredTags}
          onAddTag={onChangeTag}
          onCreateTag={onCreateTag}
        />
      ) : (
        <Chip
          size="medium"
          label="Add"
          clickable
          onClick={onClickAdd}
          color="primary"
          onDelete={onClickAdd}
          deleteIcon={<AddIcon />}
        />
      )}
    </>
  );
};
