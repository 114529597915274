import React from 'react';
import Form from '@rjsf/material-ui';

import { DialogActions, DialogContent, Button, Dialog } from '@material-ui/core';

import { useAppDispatch, useTypedSelector } from 'store';
import { Schema } from 'schemas/types';
import { AlertMediaApiModel, createAlert, getAlerts, updateAlert } from 'store/alertMediaSlice';

import { ModalHeader } from 'components/modal/ModalHeader';
import { useModal, SettingsModalTypes } from 'modules/modals/index';

export type FormData = {
  pk?: string;
  name: string;
  config_type: string;
  config: Record<string, string>;
};

type OnChangeType = {
  formData: FormData;
};

const initialFormDataState: FormData = {
  name: '',
  config_type: 'email',
  config: {
    email: '',
  },
};

const alertMediaFormData: Schema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      title: 'Name',
    },
    config_type: {
      type: 'string',
      title: 'Type',
      enum: ['email'],
    },
  },
  dependencies: {
    config_type: {
      properties: {
        config: {
          type: 'object',
          title: '',
          properties: {
            email: { type: 'string', title: 'Email' },
          },
        },
      },
      required: ['type'],
    },
  },
};

export type CreateEditAlertDialogProps = {
  id?: string;
};

export const CreateEditAlertDialog = ({ id }: CreateEditAlertDialogProps) => {
  const { closeModal } = useModal();
  const dispatch = useAppDispatch();
  const medias = useTypedSelector(({ alertMedias }) => alertMedias.alertMedias);
  const [formData, setFormData] = React.useState<FormData>(initialFormDataState);

  const onCancel = React.useCallback(() => {
    closeModal(SettingsModalTypes.CreateEditAlertDialog);
  }, [closeModal]);

  const onChange = React.useCallback(
    (data: OnChangeType) => {
      setFormData(data.formData);
    },
    [setFormData],
  );

  const onSubmitCLick = React.useCallback(async () => {
    if (id) {
      await dispatch(
        updateAlert({
          mediaId: id,
          data: formData,
        }),
      );
    } else {
      await dispatch(
        createAlert({
          data: formData,
        }),
      );
    }

    dispatch(getAlerts());
    closeModal(SettingsModalTypes.CreateEditAlertDialog);
  }, [formData, dispatch, closeModal, id]);

  React.useEffect(() => {
    if (id) {
      const media = medias.find((el) => el.pk === id) as Maybe<AlertMediaApiModel>;

      if (media) {
        setFormData(media);
      }
    }
  }, [id, medias]);

  return (
    <Dialog
      open
      aria-labelledby="create-edit-alert-dialog-title"
      aria-describedby="create-edit-alert-dialog-description"
    >
      <ModalHeader onClose={onCancel}>{id ? 'Edit Alert' : 'Create Alert'}</ModalHeader>
      <DialogContent>
        <Form schema={alertMediaFormData} formData={formData} onChange={onChange}>
          <></>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmitCLick} color="primary" variant="contained">
          {id ? 'Update' : 'Create'}
        </Button>
        <Button onClick={onCancel} color="primary" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
