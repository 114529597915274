import React from 'react';
import styled from 'styled-components';

import { WebsiteRoutes } from 'constants/routes';

import { NameCell } from 'components/Table/NameCell';

import automationIcon from '../../assets/automation-icon.png';

interface AutomationNameCellProps {
  workflow: AutomationApiModel;
}

export const NameCellIcon = styled.div`
  margin-right: 20px;
  height: 16px;
  width: 16px;
`;

export const AutomationNameCell: React.FC<AutomationNameCellProps> = ({ workflow }) => {
  const icon = (
    <NameCellIcon>
      <img alt="document" src={automationIcon} />
    </NameCellIcon>
  );

  return (
    <NameCell
      icon={icon}
      route={WebsiteRoutes.WorkflowEdit}
      id={workflow.id}
      name={workflow.name}
    />
  );
};
