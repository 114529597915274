import React from 'react';

import { EdgeLabelWrapper } from './CommonEdge.style';

export type CustomEdgeLabelType = {
  displayDelete: boolean;
  edgeCenter: number[];
  label: string;
};

export const CustomEdgeLabel: React.FC<CustomEdgeLabelType> = ({
  edgeCenter,
  displayDelete,
  label,
}) => {
  const opacity = displayDelete ? 0 : 100;

  return (
    <EdgeLabelWrapper
      height="18"
      width="40"
      x={edgeCenter[0] - 20}
      y={edgeCenter[1] - 9}
      opacity={opacity}
    >
      {label}
    </EdgeLabelWrapper>
  );
};
