import * as React from 'react';

import ListItem from '@material-ui/core/ListItem';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import {
  CategoryDescription,
  CategoryName,
  InnerListItem,
  StyledCollapsed,
} from './NodeList.style';
import { NodeListItem } from './NodeListItem';

type ListItemRendererType = {
  onCategoryClick: (category: string) => void;
  activeList: ProcessorApiModel[];
  category: string;
  isOpen: boolean;
};

export const ListItemRenderer: React.FC<ListItemRendererType> = ({
  onCategoryClick,
  activeList,
  category,
  isOpen,
}) => {
  const onClick = React.useCallback(() => {
    onCategoryClick(category);
  }, [onCategoryClick, category]);

  return (
    <ListItem className="CategoryItem" key={category}>
      <CategoryName onClick={onClick}>
        <CategoryDescription>{category}</CategoryDescription>
        {isOpen ? <ExpandLess className="expand-icon" /> : <ExpandMore className="expand-icon" />}
      </CategoryName>
      <StyledCollapsed in={isOpen}>
        <InnerListItem>
          {activeList.map((processor) => (
            <NodeListItem key={`${category}-${processor.processor_type}`} processor={processor} />
          ))}
        </InnerListItem>
      </StyledCollapsed>
    </ListItem>
  );
};
