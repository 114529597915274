import React from 'react';
import get from 'lodash/get';

import { useAppDispatch, useTypedSelector } from 'store';
import { useTabManager } from 'hooks';
import { TabPanel, Tabs, Tab } from 'components/Tabs';

import { DatasetPropertyListPanel } from './DatasetPropertyListPanel';
import { DatasetDataPanel } from './DatasetDataPanel';
import { selectDataset } from '../datasetsSelectors';
import { fetchDataset, fetchDatasetPropertyTypes, updateDataset } from '../datasetsActions';
import { LocalDatasetPropertyType } from './types';

import './styles.scss';

enum DatasetEditTabs {
  SCHEMA,
  DATA,
}

type EditDatasetFromProps = {
  datasetId: string;
};
export const EditDatasetFrom = ({ datasetId }: EditDatasetFromProps) => {
  const dispatch = useAppDispatch();
  const dataset = useTypedSelector(selectDataset);
  const { tab, onTabChange } = useTabManager<DatasetEditTabs>(DatasetEditTabs.SCHEMA);

  React.useEffect(() => {
    dispatch(fetchDataset(datasetId));
    dispatch(fetchDatasetPropertyTypes());
  }, [dispatch, datasetId]);

  const addPropertyToDataset = React.useCallback(
    (property: LocalDatasetPropertyType) => {
      if (dataset) {
        const newProperty: DatasetPropertyType = {
          type: property.type,
          ...property?.formData,
        };

        const index: Partial<IndexApiModel> = {
          mapping: {
            properties: {
              ...get(dataset, ['mapping', 'properties'], {}),
              [property.name]: newProperty,
            },
          },
        };

        dispatch(
          updateDataset({
            id: datasetId,
            dataset: index,
          }),
        );
      }
    },
    [datasetId, dispatch, dataset],
  );

  if (!dataset) {
    return null;
  }

  return (
    <div className="dataset-edit">
      <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={onTabChange}>
        <Tab label="Schema" />
        <Tab label="Data" />
      </Tabs>
      <TabPanel index={DatasetEditTabs.SCHEMA} value={tab}>
        <DatasetPropertyListPanel dataset={dataset} addPropertyToDataset={addPropertyToDataset} />
      </TabPanel>
      <TabPanel index={DatasetEditTabs.DATA} value={tab}>
        <DatasetDataPanel dataset={dataset} />
      </TabPanel>
    </div>
  );
};
