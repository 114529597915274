export const DetectSentiment: ProcessorApiModel = {
  name: 'Detect Sentiment',
  processor_type: 'DetectSentiment',
  description: 'Detect Sentiment',
  type: 'processor',
  tags: ['NLP'],
  data_model: {
    sentiment: {
      type: 'string',
    },
  },
  displayText: 'Applying sentiment to :text parameter.',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/DetectSentiment-icon.png',
  connections: {
    inputPort: true,
    outputPort: true,
  },
};
