import { ConnectionsModalTypes } from 'modules/modals';

export const types = [
  {
    label: 'Email',
    type: 'email',
  },
  {
    label: 'GMail',
    type: 'gmail',
  },
  {
    label: 'Website',
    type: 'scraper',
  },
  {
    label: 'Linkedin',
    type: 'linkedin',
  },
];

const CONNECTION_TYPE_MODAL_MAP: Record<ConnectionTypeType, ConnectionsModalTypes> = {
  email: ConnectionsModalTypes.CreateEmailConnectorModal,
  gmail: ConnectionsModalTypes.CreateGoogleConnectionModal,
  scraper: ConnectionsModalTypes.CreateScraperConnectionModal,
  linkedin: ConnectionsModalTypes.CreateLinkedinConnectionModal,
};

export const getModalType = (connectionType: ConnectionTypeType): ConnectionsModalTypes => {
  return CONNECTION_TYPE_MODAL_MAP[connectionType];
};
