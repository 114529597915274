import React from 'react';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

const getOptionLabel = (option: SelectorItemType): string => option.name;

export type SelectorItemType = {
  pk: string;
  name: string;
};

interface SelectorType {
  name: string;
  label: string;
  value: string;
  error: boolean;
  items: SelectorItemType[];

  handleChange(name: string, value: string): void;
}

export const Selector: React.FC<SelectorType> = ({
  name,
  value,
  label,
  handleChange,
  items,
  error,
}) => {
  const renderInput = React.useCallback(
    (params) => (
      <div style={{ position: 'relative' }}>
        <TextField name={name} {...params} value={value} label={label} error={error} />
      </div>
    ),
    [value, label, error, name],
  );

  const onChange = React.useCallback(
    (_, item: SelectorItemType) => {
      handleChange(name, item.pk);
    },
    [name, handleChange],
  );

  return (
    <Autocomplete
      id="user-search"
      className="search-user"
      size="small"
      popupIcon={false}
      onChange={onChange}
      disableClearable
      options={items}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
    />
  );
};
