import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'utils/httpClient';

import { defaultTableParams } from 'constants/table';
import { Services } from 'constants/services';

export interface UpdateTagRequest {
  id?: string;
  name: string;
  description?: string;
  color?: string;
}

export const fetchTagList = createAsyncThunk('tags/fetchTagList', async () => {
  const response = await requestApi<TagApiModel[]>({
    service: Services.NifiManager,
    submethod: 'tag/',
  });

  return response.data;
});

export const updateTagAction = createAsyncThunk(
  'tags/updateTagAction',
  async (values: TagApiModel) => {
    const response = await requestApi<TagApiModel>({
      method: 'PATCH',
      service: Services.NifiManager,
      submethod: `tag/${values.id}/`,
      data: values,
    });

    return response.data;
  },
);

export const createTagAction = createAsyncThunk('tags/createTagAction', async (tagName: string) => {
  const response = await requestApi<TagApiModel>({
    method: 'POST',
    service: Services.NifiManager,
    submethod: 'tag/',
    data: {
      name: tagName,
    },
  });

  return response.data;
});

export const deleteTagAction = createAsyncThunk('tags/deleteTagAction', async (tagId: string) => {
  await requestApi({
    method: 'DELETE',
    service: Services.NifiManager,
    submethod: `tag/${tagId}`,
  });
});

export interface State {
  tags: TagApiModel[];
  page: number;
  size: number;
  count: number;
  error: string;
  loading: boolean;
  lastUpdated: string;
}

const initialState: State = {
  tags: [],
  page: defaultTableParams.page,
  size: defaultTableParams.size,
  count: defaultTableParams.count,
  error: '',
  loading: false,
  lastUpdated: new Date().toISOString(),
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    updateTagList(state) {
      state.lastUpdated = new Date().toISOString();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTagList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTagList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchTagList.fulfilled, (state, { payload }) => {
      state.tags = payload;
      state.loading = false;
    });

    builder.addCase(deleteTagAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTagAction.fulfilled, (state) => {
      state.loading = false;
      state.lastUpdated = new Date().toISOString();
    });
    builder.addCase(deleteTagAction.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createTagAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTagAction.fulfilled, (state) => {
      state.loading = false;
      state.lastUpdated = new Date().toISOString();
    });
    builder.addCase(createTagAction.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTagAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTagAction.fulfilled, (state) => {
      state.loading = false;
      state.lastUpdated = new Date().toISOString();
    });
    builder.addCase(updateTagAction.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { updateTagList } = tagsSlice.actions;
export default tagsSlice.reducer;
