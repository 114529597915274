import get from 'lodash/get';

import type { TableRowParams, TableContent } from 'components/Table';
import { trunc } from 'utils/trunc';

import { DatasetDataType } from './types';

const emptyTableContent = {
  header: {},
  rows: [],
};

const VISIBLE_LENGTH = 40;

export const useDatasetDataTableContent = (
  items: DatasetDataType[],
): TableContent<TableRowParams> => {
  if (items.length === 0) {
    return emptyTableContent;
  }

  const keys = Object.keys(items[0]);
  return {
    header: keys.reduce((acc, item) => {
      return { ...acc, [item]: { name: item } };
    }, {}),
    rows: items.map((item, index) =>
      keys.reduce(
        (acc, key) => {
          return { ...acc, [key]: trunc((get(item, [key]) as string) || 'N/A', VISIBLE_LENGTH) };
        },
        { id: index },
      ),
    ),
  };
};
