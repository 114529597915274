import React from 'react';

import type { TableContent, TableRowParams } from 'components/Table';

import { CredentialActionCell } from '../CredentialActionCell';

interface CredentialsContent extends TableRowParams {
  id: string;
  name: JSX.Element;
  automation_count: JSX.Element;
  actions: JSX.Element;
}

const credentialsTableContent: TableContent<CredentialsContent> = {
  header: {
    name: {
      width: '30%',
      name: 'Name',
      sortable: true,
    },
    automation_count: {
      width: '10%',
      name: 'Automations',
      sortable: true,
    },
    actions: {
      name: '',
      sortable: false,
    },
  },
  rows: [],
};

export const useApiKeysTableContent = (items: ApiKeyApiModel[]) => {
  credentialsTableContent.rows = items.map(({ pk, name, automation_count }) => ({
    id: pk,
    name: <p>{name}</p>,
    automation_count: <p>{automation_count}</p>,
    actions: <CredentialActionCell credentialId={pk} credentialType="api-key" />,
  }));

  return credentialsTableContent;
};
