import React from 'react';
import { useFormik } from 'formik';
import { Button } from '@material-ui/core';

import { TagsTableSectionStrings } from 'components/TagsCell/en';
import { UpdateTagRequest } from 'store/tagsSlice';
import { FormTextField } from 'components/FormTextField';
import { initialValues, validationSchema } from './EditTagForm.helper';
import { FormStyled, RowFields, ColorFieldWrapper, DialogActionsStyled } from './EditTagForm.style';

interface EditTagFormProps {
  onSubmit: (values: UpdateTagRequest) => void;
  onCancel: () => void;
  editableTag: UpdateTagRequest | null;
  tags: TagApiModel[];
}

export const EditTagForm = ({ onSubmit, onCancel, editableTag, tags }: EditTagFormProps) => {
  let initialFormValues = initialValues;

  if (editableTag) {
    initialFormValues = editableTag;
  }

  const initialColor = {
    color: editableTag?.color || initialValues.color,
  };
  const formValues = {
    ...initialFormValues,
    ...initialColor,
  };

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit: (values: UpdateTagRequest, { setErrors }) => {
      const isTag = Boolean(
        tags.find((tag) => tag.name === values.name && values.name !== initialFormValues.name),
      );

      if (isTag) {
        setErrors({
          name: TagsTableSectionStrings.TagNameError,
        });
        return;
      }
      onSubmit(values);
    },
  });

  const { handleSubmit, values, handleBlur, handleChange, setFieldTouched, errors } = formik;

  return (
    <FormStyled onSubmit={handleSubmit}>
      <RowFields>
        <FormTextField
          onChange={handleChange}
          onBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          value={values.name}
          label={TagsTableSectionStrings.TagName}
          name="name"
          errorText={errors.name}
        />
        <ColorFieldWrapper>
          <FormTextField
            onChange={handleChange}
            onBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            value={values.color}
            label={TagsTableSectionStrings.TagColor}
            type="color"
            name="color"
            errorText={errors.color}
          />
        </ColorFieldWrapper>
      </RowFields>
      <FormTextField
        onChange={handleChange}
        onBlur={handleBlur}
        setFieldTouched={setFieldTouched}
        value={values.description}
        label={TagsTableSectionStrings.TagDescription}
        name="description"
        multiline
        rowsMax={3}
        errorText={errors.description}
      />

      <DialogActionsStyled>
        <Button type="submit" variant="contained" color="primary" autoFocus={false}>
          {TagsTableSectionStrings.EditPrimaryButton}
        </Button>

        <Button variant="outlined" onClick={onCancel} color="primary" autoFocus>
          {TagsTableSectionStrings.EditSecondaryButton}
        </Button>
      </DialogActionsStyled>
    </FormStyled>
  );
};
