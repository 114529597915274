import styled from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import { Collapse, List } from '@material-ui/core';

export const InnerListItem = withStyles({
  root: {
    flexDirection: 'column',
  },
})(List);

export const CategoryName = styled(`div`)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const CategoryDescription = styled(`span`)`
  font-weight: 600;
  padding: 12px;
`;

export const StyledCollapsed = styled(Collapse)`
  width: 100%;
`;
