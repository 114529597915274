import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';

import { Button } from '@material-ui/core';

import { useAppDispatch, useTypedSelector } from 'store';
import { useFlag } from 'modules/modals';

import { selectUsersThatNotMember } from '../../workspaceSelectors';
import { addUserProjectPermission } from '../../workspaceActions';
import { MemberSelector } from './MemberSelector';
import {
  initialValues,
  MemberSelectorValuesType,
  validationSchema,
} from './memberSelectorValidation';
import { RoleSelector } from '../RoleSelector';

import './styes.scss';

const messages = defineMessages({
  title: {
    id: 'workspaces.invite-user-input.title',
    defaultMessage: 'Add new member:',
  },
  add: {
    id: 'workspaces.invite-user-input.title',
    defaultMessage: 'Add',
  },
});

type MemberSelectorFormType = {
  workspaceId: string;
};

export const MemberSelectorForm: React.FC<MemberSelectorFormType> = ({ workspaceId }) => {
  const dispatch = useAppDispatch();
  const { showFlag } = useFlag();

  const users = useTypedSelector(selectUsersThatNotMember);

  const onSubmit = React.useCallback(
    async ({ userId, group }: MemberSelectorValuesType) => {
      try {
        await dispatch(
          addUserProjectPermission({
            workspaceId,
            params: {
              groups: [group],
              user_id: userId,
            },
          }),
        ).unwrap();
        showFlag({
          title: 'Role updated',
        });
      } catch (e) {
        showFlag({
          title: 'Something went wrong.',
          variant: 'error',
        });
      }
    },
    [showFlag, workspaceId, dispatch],
  );

  const { values, handleChange, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit} className="has-top-margin has-bottom-margin">
      <FormattedMessage {...messages.title} tagName="p" />
      <div className="user-role-selector-content has-top-margin">
        <MemberSelector
          value={values.userId}
          users={users}
          handleChange={setFieldValue}
          label={errors.userId || 'Select user'}
          error={Boolean(errors.userId)}
        />
        <RoleSelector value={values.group} error={Boolean(errors.group)} onChange={handleChange} />
        <Button size="small" type="submit" variant="contained">
          <FormattedMessage {...messages.add} />
        </Button>
      </div>
    </form>
  );
};
