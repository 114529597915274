import * as React from 'react';

type UseModalStateProps = [boolean, () => void, () => void, (value: boolean) => void];

export const useModalState = (value = false): UseModalStateProps => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(value);

  const closeModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const openModal = React.useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  return [isModalOpen, openModal, closeModal, setIsModalOpen];
};
