import * as React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { SubmitButton } from './SubmitButton';

const ModalFooterStyled = styled(DialogActions)`
  border-top: #edebe9 1px solid;
`;

type ModalFooterProps = {
  cancelMessage?: MessageDescriptor;
  submitMessage: MessageDescriptor;
  hasPadding?: boolean;
  onCancel?: () => void;
  onSubmit?: (e: React.SyntheticEvent) => void;
  submitDisabled?: boolean;
  isLoading?: boolean;
};

export const ModalFooter: React.FC<ModalFooterProps> = React.memo(
  ({
    onSubmit,
    onCancel,
    cancelMessage,
    submitMessage,
    hasPadding = true,
    submitDisabled = false,
    isLoading = false,
  }) => (
    <ModalFooterStyled style={{ padding: `16px ${hasPadding ? 24 : 0}px` }}>
      {onCancel && cancelMessage && (
        <Button className="has-margin-right" variant="outlined" onClick={onCancel} color="primary">
          <FormattedMessage {...cancelMessage} />
        </Button>
      )}
      {onSubmit && (
        <SubmitButton
          onClick={onSubmit}
          message={submitMessage}
          disabled={submitDisabled}
          isLoading={isLoading}
        />
      )}
    </ModalFooterStyled>
  ),
);
