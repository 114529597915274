import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';

export const LinkedinApi = {
  updatePin: async (connectionId: string, pin: string): Promise<void> => {
    await requestApi({
      service: Services.Connectors,
      submethod: `${connectionId}/user_input/`,
      data: { pin },
      isAnonymousMethod: true,
    });
  },
} as const;
