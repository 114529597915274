import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { List } from '@material-ui/core';

import { RootState, useTypedSelector } from 'store';

import { ModalHeader } from 'components/modal/ModalHeader';
import { WideDialog } from 'components/modal';

import { useModal, WorkflowsModalTypes } from 'modules/modals';

import { AutomationEditParams } from '../../types';
import { AutomationHistoryItem } from './AutomationHistoryItem';

const messages = defineMessages({
  title: {
    id: 'automation.user-settings-modal.title',
    defaultMessage: 'Automation history',
  },
});

export const AutomationHistoryModal = () => {
  const { closeModal } = useModal();
  const { id } = useParams<AutomationEditParams>();
  const automationVersions = useTypedSelector(
    ({ workflow }: RootState) => workflow.automationVersions,
  );

  const onClose = React.useCallback(() => {
    closeModal(WorkflowsModalTypes.AutomationHistoryModal);
  }, [closeModal]);

  return (
    <WideDialog id="automation-history-modal" open onClose={onClose}>
      <ModalHeader onClose={onClose}>
        <FormattedMessage {...messages.title} />
      </ModalHeader>
      <List disablePadding>
        {automationVersions.map((automation: AutomationApiModel) => (
          <AutomationHistoryItem
            key={automation.id}
            automation={automation}
            currentVersionId={id}
            isLastVersion={automation.id === id}
          />
        ))}
      </List>
    </WideDialog>
  );
};
