import React, { useMemo } from 'react';

import TextField, { TextFieldProps } from '@material-ui/core/TextField';

interface FormTextFieldProps {
  errorText?: string | null;
  setFieldTouched?: (name: string, isTouched: boolean) => void;
}

export const FormTextField: React.FC<TextFieldProps & FormTextFieldProps> = (props) => {
  const {
    label,
    name = '',
    value = '',
    setFieldTouched,
    errorText,
    type = 'text',
    onChange,
    onBlur = () => {},
    disabled = false,
    variant = 'standard',
    autoComplete = 'off',
    multiline = false,
    fullWidth = false,
    size = undefined,
    className,
  } = props;

  const labelValue = useMemo(() => {
    return errorText || label;
  }, [errorText, label]);

  const onBlurHandler = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (setFieldTouched) {
      setFieldTouched(name, true);
    }
    onBlur(event);
  };

  return (
    <TextField
      className={className}
      autoComplete={autoComplete}
      disabled={disabled}
      multiline={multiline}
      name={name}
      error={Boolean(errorText)}
      label={labelValue}
      value={value}
      type={type}
      onChange={onChange}
      onBlur={onBlurHandler}
      variant={variant}
      fullWidth={fullWidth}
      size={size}
    />
  );
};
