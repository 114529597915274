import * as React from 'react';

import { Container } from 'components/Table/Container';
import type { TableContent, TableRowParams } from 'components/Table';

import { getLogStatusIcon } from './helpers/getLogStatusIcon';
import { formatLogTime } from './helpers/formatLogTime';

interface ConnectionsContent extends TableRowParams {
  id: number;
  time: JSX.Element;
  status: JSX.Element;
  message: JSX.Element;
}

const logsTableContent: TableContent<ConnectionsContent> = {
  header: {
    time: {
      width: '15%',
      name: 'Time',
      sortable: false,
    },
    status: {
      width: '10%',
      name: 'Type',
      sortable: false,
    },
    message: {
      width: '65%',
      name: 'Message',
      sortable: false,
    },
  },
  rows: [],
};

export const useLogsTableContent = (logs: LogApiModel[]): TableContent<ConnectionsContent> => {
  logsTableContent.rows = logs.map(({ msg, timestamp }) => ({
    id: timestamp,
    time: <Container>{formatLogTime(timestamp)}</Container>,
    status: getLogStatusIcon(msg),
    message: <Container>{msg}</Container>,
  }));

  return logsTableContent;
};
