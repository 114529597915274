import { Button, withStyles } from '@material-ui/core';

export const WorkflowDeleteButton = withStyles({
  root: {
    backgroundColor: '#D83B01',
    borderRadius: 0,
    padding: '6px 20px',
    '& .MuiButton-label': {
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#A52B02FF',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#A52B02FF',
    },
  },
})(Button);
