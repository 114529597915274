import { useCallback } from 'react';

import { useAppDispatch, useTypedSelector } from 'store';

import { fetchConnectionList } from './connectionActions';

export const useConnections = (): (() => void) => {
  const dispatch = useAppDispatch();
  const { order, orderBy, page, size, search } = useTypedSelector(
    (state) => state.connections.tableSettings,
  );
  const filter = useTypedSelector((state) => state.connections.filter);

  return useCallback(() => {
    dispatch(
      fetchConnectionList({
        order,
        orderBy,
        page,
        size,
        search,
        filter,
      }),
    );
  }, [dispatch, order, orderBy, page, size, search, filter]);
};
