import * as React from 'react';

import { RootState, useAppDispatch, useTypedSelector } from 'store';
import { useProjectId } from 'hooks/useProjectId';

import { CommonTable } from 'components/Table/CommonTable';
import { Message } from 'components/Message';

import { changeSorting, goToSelectedPage, updateSize } from '../../connectionsSlice';
import { fetchConnectionList } from '../../connectionActions';

import { useConnectionsTableContent } from './ConnesctionTable.helper';

export const ConnectionsTable: React.FC = () => {
  const { projectId } = useProjectId();
  const dispatch = useAppDispatch();
  const connections = useTypedSelector((state: RootState) => state.connections.connections);
  const { page, count, size, order, orderBy, search, loading } = useTypedSelector(
    (state) => state.connections.tableSettings,
  );
  const filter = useTypedSelector((state) => state.connections.filter);

  const contentTableContents = useConnectionsTableContent(connections);

  const onGoToPageHandler = React.useCallback(
    (targetPage: number) => {
      dispatch(goToSelectedPage(targetPage));
    },
    [dispatch],
  );

  const onSizeChangeHandler = React.useCallback(
    (targetSize: number) => {
      dispatch(updateSize(targetSize));
    },
    [dispatch],
  );

  const onClickSortHandler = React.useCallback(
    (columnName: string) => {
      dispatch(changeSorting(columnName));
    },
    [dispatch],
  );

  React.useEffect(() => {
    dispatch(
      fetchConnectionList({
        order,
        orderBy,
        page,
        size,
        search,
        filter,
      }),
    );
  }, [filter, dispatch, page, size, search, projectId, order, orderBy]);

  return (
    <>
      <CommonTable
        content={contentTableContents}
        page={page}
        totalItems={count}
        size={size}
        goToPage={onGoToPageHandler}
        onSizeChange={onSizeChangeHandler}
        onClickSort={onClickSortHandler}
        order={order}
        orderBy={orderBy}
        isPagination
      />
      <Message loading={loading} search={search} count={count} entityName="connections" />
    </>
  );
};
