import * as React from 'react';

import { ListItem, ListItemText } from '@material-ui/core';
import type { OnChangeEvent } from 'types';

import { RoleSelector } from '../RoleSelector';

type UserWorkspacesListItemType = {
  workspaceName: string;
  workspaceId: string;
  group: number;
  onGroupChange: (data: { workspaceId: string; group: number }) => void;
};

export const UserWorkspacesListItem: React.FC<UserWorkspacesListItemType> = ({
  workspaceId,
  workspaceName,
  group,
  onGroupChange,
}) => {
  const onChange = React.useCallback(
    (event: OnChangeEvent) => {
      if (event.target.value) {
        onGroupChange({
          workspaceId,
          group: event.target.value as number,
        });
      }
    },
    [onGroupChange, workspaceId],
  );

  return (
    <ListItem style={{ padding: '8px 0' }}>
      <ListItemText primary={workspaceName} />
      <RoleSelector value={group} error={false} onChange={onChange} />
    </ListItem>
  );
};
