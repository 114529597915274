import cloneDeep from 'lodash/cloneDeep';

import { Schema } from 'schemas/types';

import { ProviderType } from '../../api/ConnectorApi';

const emailConnectionSchema: Schema = {
  type: 'object',
  required: ['name', 'type', 'server', 'port', 'username', 'password'],
  properties: {
    provider: {
      title: 'Provider',
      type: 'string',
      enum: [],
    },
    name: {
      type: 'string',
      title: 'Name',
    },
    description: {
      type: 'string',
      description: 'Connection description',
      title: 'Description',
      default: '',
    },
    scheduler: {
      type: 'boolean',
      default: false,
      description: 'Enable cron',
      title: 'Enable cron',
    },
    cron: {
      type: 'string',
      default: '* * * * *',
      description: 'Cron interval (min)',
      title: 'Cron interval',
    },
    type: {
      title: 'Protocol',
      enum: ['imap', 'pop3', 'smtp'],
    },
    server: {
      type: 'string',
      title: 'Host',
    },
    port: {
      type: 'number',
      title: 'Port',
    },
    ssl: {
      type: 'boolean',
      title: 'SSL enabled',
    },
    starttls: {
      type: 'boolean',
      title: 'STARTTLS enabled',
    },
    username: {
      type: 'string',
      title: 'Username',
    },
    password: {
      type: 'string',
      title: 'Password',
    },
  },
  dependencies: {
    provider: {
      oneOf: [
        {
          properties: {
            provider: {
              enum: ['SendGrid(SMTP)'],
            },
            username: {
              title: 'Name Apikey',
            },
            password: {
              title: 'Apikey',
            },
            email_from: {
              title: 'From email(SendGrid Sender Identity)',
              type: 'string',
            },
          },
        },
      ],
    },
  },
};

export const fillEmailConnectionSchema = (providers: ProviderType[]): Schema => {
  const schema: Schema = cloneDeep(emailConnectionSchema);

  schema.properties.provider.enum = providers.map((item) => item.name);

  schema.properties.provider.enum.unshift('Custom');

  return schema;
};
