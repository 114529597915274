import * as React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';

import './styles.css';

import defaultConnectionImg from './default-img.png';

type ConnectionItemType = {
  pk: string;
  img?: string;
  name: string;
  description: string;
  selected?: boolean;
  onClick(pk: string): void;
  onDoubleClick?(pk: string): void;
};

export const ConnectionItem: React.FC<ConnectionItemType> = ({
  name,
  description,
  img = defaultConnectionImg,
  onClick,
  pk,
  onDoubleClick,
  selected = false,
}) => {
  const onClickHandler = React.useCallback(() => {
    onClick(pk);
  }, [onClick, pk]);

  const onDoubleClickHandler = React.useCallback(() => {
    if (onDoubleClick) {
      onDoubleClick(pk);
    }
  }, [onDoubleClick, pk]);

  return (
    <ListItem
      button
      selected={selected}
      data-id={pk}
      className="connection-item"
      onClick={onClickHandler}
      onDoubleClick={onDoubleClickHandler}
    >
      <div className="connection-icon-wrapper has-black-background">
        <img src={img} alt="Connection Icon" />
      </div>
      <ListItemText primary={name} secondary={description} />
    </ListItem>
  );
};
