import React from 'react';

export const useTabManager = <T>(value: T) => {
  const [tab, setTab] = React.useState<T>(value);

  const onTabChange = React.useCallback(
    (event: React.ChangeEvent<unknown>, newValue: T) => {
      setTab(newValue);
    },
    [setTab],
  );

  return { tab, setTab, onTabChange };
};
