import * as React from 'react';
import { defineMessages } from 'react-intl';

import { useAppDispatch, useTypedSelector } from 'store';

import { useModal, ConnectionsModalTypes } from 'modules/modals';
import { Modal } from 'components/modal';

import { selectConnectionByPk } from '../connectionsSelectors';
import { removeConnection, fetchConnectionList } from '../connectionActions';

const messages = defineMessages({
  title: {
    id: 'automation.delete-connection-modal.continue',
    defaultMessage: 'Delete Connection',
  },
  cancel: {
    id: 'automation.delete-connection-modal.cancel',
    defaultMessage: 'Cancel',
  },
  submit: {
    id: 'automation.delete-connection-modal.continue',
    defaultMessage: 'Delete it',
  },
});

export type DeleteConnectionModalType = {
  pk: string;
};

export const DeleteConnectionModal: React.FC<DeleteConnectionModalType> = ({ pk }) => {
  const { closeModal, openModal } = useModal();
  const dispatch = useAppDispatch();
  const { tableSettings, filter } = useTypedSelector((state) => state.connections);

  const connection: Maybe<ConnectionApiModel> = useTypedSelector(selectConnectionByPk(pk));

  const onClose = React.useCallback(() => {
    closeModal(ConnectionsModalTypes.DeleteConnectionModal);
  }, [closeModal]);

  const onSubmit = React.useCallback(async () => {
    if (!connection) {
      return;
    }

    if (connection.automation_count !== 0) {
      openModal({
        type: ConnectionsModalTypes.ReplaceConnectionModal,
        data: {
          pk,
          isDeleteModal: true,
        },
      });
    } else {
      await removeConnection({ pk });

      dispatch(
        fetchConnectionList({
          ...tableSettings,
          filter,
        }),
      );
    }

    closeModal(ConnectionsModalTypes.DeleteConnectionModal);
  }, [filter, pk, dispatch, tableSettings, closeModal, openModal, connection]);

  return (
    <Modal
      width={560}
      height={250}
      onClose={onClose}
      onSubmit={onSubmit}
      cancelMessage={messages.cancel}
      submitMessage={messages.submit}
      title={messages.title}
    >
      <p>
        Are you sure you want to delete<b> {connection?.name}</b>? This action cannot be undone.
      </p>
    </Modal>
  );
};
