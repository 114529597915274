import React, { useCallback, ChangeEvent } from 'react';

import TextFieldMui from '@material-ui/core/TextField';

import { ModalLabel } from 'components/modal';

type TextFieldProps = {
  name: string;
  value: string | number;
  label?: string;
  placeholder?: string;
  disable?: boolean;
  onChange: (name: string, value: string | number) => void;
};

export const TextField = (props: TextFieldProps) => {
  const { name, value, onChange, label, placeholder, disable = false } = props;

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(name, event.target.value);
    },
    [onChange, name],
  );

  return (
    <div className="has-top-margin">
      <ModalLabel htmlFor={name}>{label || name}</ModalLabel>
      <TextFieldMui
        id={name}
        size="small"
        disabled={disable}
        variant="outlined"
        placeholder={placeholder || label}
        value={value}
        onChange={onChangeHandler}
      />
    </div>
  );
};
