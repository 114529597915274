import React from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';

import Cloud from '@material-ui/icons/Cloud';
import DeveloperBoard from '@material-ui/icons/DeveloperBoard';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import SecurityIcon from '@material-ui/icons/Security';
import StorageIcon from '@material-ui/icons/Storage';

import { WebsiteRoutes } from 'constants/routes';

const messages = defineMessages({
  workspaces: {
    id: 'side-menu.workspaces',
    defaultMessage: 'Workspaces',
  },
  workflows: {
    id: 'side-menu.workflows',
    defaultMessage: 'Automations',
  },
  connections: {
    id: 'side-menu.connections',
    defaultMessage: 'Connections',
  },
  datasets: {
    id: 'side-menu.datasets',
    defaultMessage: 'Datasets',
  },
  customComponents: {
    id: 'side-menu.datasets',
    defaultMessage: 'User Processor',
  },
  credentials: {
    id: 'side-menu.credentials',
    defaultMessage: 'Credentials',
  },
});

export const checkIsActiveItem = (pathname: string, route: string) => pathname.indexOf(route) >= 0;

export interface IMenuItem {
  icon: React.ReactElement;
  iconActive: React.ReactElement;
  text: MessageDescriptor;
  route: WebsiteRoutes;
}

export const menuItems: IMenuItem[] = [
  {
    icon: <DeviceHubIcon />,
    iconActive: <DeviceHubIcon color="primary" />,
    text: messages.workflows,
    route: WebsiteRoutes.Workflows,
  },
  {
    icon: <Cloud />,
    iconActive: <Cloud color="primary" />,
    text: messages.connections,
    route: WebsiteRoutes.Connections,
  },
  {
    icon: <StorageIcon />,
    iconActive: <StorageIcon color="primary" />,
    text: messages.datasets,
    route: WebsiteRoutes.Datasets,
  },
  {
    icon: <DeveloperBoard />,
    iconActive: <DeveloperBoard color="primary" />,
    text: messages.customComponents,
    route: WebsiteRoutes.CustomComponents,
  },
  {
    icon: <SecurityIcon />,
    iconActive: <SecurityIcon color="primary" />,
    text: messages.credentials,
    route: WebsiteRoutes.Credentials,
  },
];
