import React from 'react';

import { ModalTypes, useModal } from 'modules/modals';

import { CommonActionsCell } from 'components/CommonActionsCell';
import { ModalLink } from 'components/Table/ModalLink';

type ActionsCellType = {
  pk: string;
};

export const UserPermissionActionsCell: React.FC<ActionsCellType> = ({ pk }) => {
  const { openModal } = useModal();

  const onEdit = React.useCallback(() => {
    openModal({
      type: ModalTypes.UserWorkspacesPermissionModal,
      data: {
        userId: pk,
      },
    });
  }, [openModal, pk]);

  return (
    <CommonActionsCell>
      <ModalLink fullWidth onClick={onEdit}>
        Edit
      </ModalLink>
    </CommonActionsCell>
  );
};
