import * as React from 'react';

export type DrawerContextType = {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (value: boolean) => void;
  toggleDrawer: () => void;
};

const initialValue: DrawerContextType = {
  isDrawerOpen: false,
  setIsDrawerOpen: () => ({}),
  toggleDrawer: () => ({}),
};

export const DrawerContext = React.createContext<DrawerContextType>(initialValue);

export const DrawerProvider: React.FC = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

  const toggleDrawer = React.useCallback(
    () => setIsDrawerOpen((value) => !value),
    [setIsDrawerOpen],
  );

  return (
    <DrawerContext.Provider
      value={{
        isDrawerOpen,
        toggleDrawer,
        setIsDrawerOpen,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
