import React from 'react';

import { TableCell, TableHead, TableRow } from '@material-ui/core';

import { SortableTableCell } from 'components/Table/SortableTableCell';
import { Sortable } from 'common/Params';

type VariablesTableHeaderProps = {
  showActions?: boolean;
} & Sortable;

export const VariablesTableHeader = ({
  sortable = false,
  showActions,
  params,
  onClickSort,
}: VariablesTableHeaderProps) => {
  if (sortable && params && onClickSort) {
    return (
      <TableHead>
        <TableRow>
          <SortableTableCell
            sortable={sortable}
            columnName="key"
            order={params.order}
            orderBy={params.orderBy}
            onClickSort={onClickSort}
          >
            Key
          </SortableTableCell>
          <SortableTableCell
            sortable={sortable}
            columnName="value"
            order={params.order}
            orderBy={params.orderBy}
            onClickSort={onClickSort}
          >
            Value
          </SortableTableCell>
          {showActions && <TableCell align="right">Actions</TableCell>}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell>Key</TableCell>
        <TableCell>Value</TableCell>
        {showActions && <TableCell align="right">Actions</TableCell>}
      </TableRow>
    </TableHead>
  );
};
