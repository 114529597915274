import { createAsyncThunk } from '@reduxjs/toolkit';

import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';

import { fetchApiKeys } from './apiKeysApi';

export const fetchApiKeysAction = createAsyncThunk<
  PaginatedResponse<ApiKeyApiModel>,
  TableFilterParams
>('credential/fetchApiKeysAction', fetchApiKeys);

export type CreateApiKeyCredentialType = {
  name: string;
  apikey: string;
};

export const createApiKeyCredential = createAsyncThunk<ApiKeyApiModel, CreateApiKeyCredentialType>(
  'credential/createApiKeyCredential',
  async (data): Promise<ApiKeyApiModel> => {
    const res = await requestApi<ApiKeyApiModel>({
      method: 'POST',
      service: Services.CustomProcessor,
      submethod: 'api-key/',
      data,
    });

    return res.data;
  },
);

export const updateApiKey = createAsyncThunk<ApiKeyApiModel, ApiKeyApiModel>(
  'credential/updateApiKey',
  async (data) => {
    const res = await requestApi<ApiKeyApiModel>({
      method: 'PUT',
      service: Services.CustomProcessor,
      submethod: 'api-key/',
      data,
    });

    return res.data;
  },
);

export const deleteApiKey = createAsyncThunk<string, string>(
  'credential/deleteApiKey',
  async (pk: string): Promise<string> => {
    await requestApi({
      method: 'DELETE',
      service: Services.CustomProcessor,
      submethod: `api-key/${pk}/`,
    });

    return pk;
  },
);
