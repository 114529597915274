import { createAsyncThunk } from '@reduxjs/toolkit';

import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';

export type UpdateWorkflowRequest = {
  id?: string;
  name?: string;
  dot_content?: string;
  description?: string;
  status?: string;
  tag_objects?: TagApiModel[];
  tags?: string[];
  updated_at?: string;
};

export type AttachedWorkflow = {
  id: string;
  name: string;
};

export const updateWorkflowTagAction = createAsyncThunk<
  undefined,
  { workflowId: string; data: UpdateWorkflowRequest }
>('tags/updateWorkflowTagAction', async ({ workflowId, data }) => {
  await requestApi({
    method: 'PATCH',
    service: Services.NifiManager,
    submethod: `flow/${workflowId}/`,
    data,
  });

  return undefined;
});

export const getWorkflowsByConnectionPk = async (pk: string) => {
  const createdWorkflowPayload = await requestApi<AttachedWorkflow[]>({
    method: 'GET',
    service: 'connectors',
    submethod: `automation/${pk}/`,
    isAnonymousMethod: true,
  });

  return createdWorkflowPayload.data;
};
