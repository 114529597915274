import React, { memo } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Search } from './Search';

export const Container = styled.div`
  margin-bottom: 50px;
  display: flex;
  align-items: center;
`;

export const TopBarTitle = styled(Typography)`
  user-select: none;
  margin-bottom: 0;
`;

export const SearchWrapper = styled.div`
  margin-left: 54px;
`;

export const PrimaryButton = styled(Button)`
  margin-left: auto;
`;

type TopBarSectionType = {
  onSearch?: (value: string) => void;
  onNewItem: () => void;
  title: MessageDescriptor;
  newItemText: MessageDescriptor;
};

export const TopBarSection = memo(
  ({ onNewItem, title, onSearch, newItemText }: TopBarSectionType) => {
    return (
      <Container>
        <TopBarTitle variant="h4" gutterBottom>
          <FormattedMessage {...title} />
        </TopBarTitle>
        {onSearch && (
          <SearchWrapper>
            <Search onSearch={onSearch} />
          </SearchWrapper>
        )}
        <PrimaryButton variant="contained" color="primary" onClick={onNewItem}>
          <FormattedMessage {...newItemText} />
        </PrimaryButton>
      </Container>
    );
  },
);
