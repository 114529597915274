import styled from 'styled-components';
import { Handle } from 'react-flow-renderer';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import node_source from '../Edge/assets/node_source.svg';

export const CustomSource = styled(Handle)`
  width: 20px;
  height: 20px;
  bottom: -15px;
  left: 50%;
  border: none;
  background: url(${node_source}) no-repeat 4px 4px;
`;

export const ConditionalSourceTooltip = withStyles({
  tooltip: { margin: 0 },
})(Tooltip);

export const ConditionalSource = styled(Handle)`
  width: 20px;
  height: 20px;
  border: none;
  background: url(${node_source}) no-repeat 4px 4px;
`;
