import { toApiOrdering } from 'utils/apiMappers';
import { RequestParams } from 'utils/buildRequestParams';

type FetchConnectionLogsRequestParamsType = RequestParams & {
  level?: LogsLevel;
  processorId?: string;
};

export const buildFetchConnectionLogsRequestParams = (
  tableParams: TableFilterParams,
  level: LogsLevel,
  processorId?: string,
): FetchConnectionLogsRequestParamsType => {
  const params: FetchConnectionLogsRequestParamsType = {
    page: tableParams.page,
    page_size: tableParams.size,
    ordering: toApiOrdering(tableParams.order, tableParams.orderBy || 'updated_at'),
  };

  if (tableParams.search) {
    params.q = tableParams.search;
  }

  if (level !== 'all') {
    params.level = level;
  }

  if (processorId) {
    params.processorId = processorId;
  }

  return params;
};
