import React from 'react';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

const getOptionLabel = (option: UserApiModel): string => option.preferred_username;

interface SearchUserType {
  label: string;
  users: UserApiModel[];
  value: string;
  error: boolean;

  handleChange(name: string, value: string): void;
}

export const MemberSelector: React.FC<SearchUserType> = ({
  value,
  label,
  handleChange,
  users,
  error,
}) => {
  const renderInput = React.useCallback(
    (params) => (
      <div style={{ position: 'relative' }}>
        <TextField name="email" {...params} value={value} label={label} error={error} />
      </div>
    ),
    [value, label, error],
  );

  const onChange = React.useCallback(
    (_, user: UserApiModel) => {
      handleChange('userId', user.sub);
    },
    [handleChange],
  );

  return (
    <Autocomplete
      id="user-search"
      className="search-user has-top-margin"
      size="small"
      popupIcon={false}
      onChange={onChange}
      disableClearable
      options={users}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
    />
  );
};
