import React, { memo, useContext, useCallback, useMemo } from 'react';
import { NodeProps, Position } from 'react-flow-renderer';

import { ClickAwayListener } from '@material-ui/core';

import { getProcessorByType } from 'precessors';
import { useMouseHover } from 'hooks';

import './styles.scss';

import {
  EditProcessorModalContext,
  EditProcessorModalContextType,
} from '../../../modals/EditProcessorModal/EditProcessorModalContextProvider';
import { CustomTarget, NodeWrapper } from './Node.style';
import { getProcessorIconByWarningStatusType } from './utils/getProcessorIconByWarningStatusType';
import { NodeActions } from './NodeActions';
import { NodeOutput } from './NodeOutput';
import { NodeHeader } from './NodeHeader';
import { NodeBody } from './NodeBody';
import { useProcessorsWarnings } from '../../../hooks/useProcessorsWarnings';

export type ProcessorNodeProps = NodeProps<WorkflowElementData>;

export const Node: React.FC<ProcessorNodeProps> = memo(({ data, selected, id }) => {
  const type = getProcessorByType(data.processorType);
  const { isActionsOpen, onMouseLeave, onMouseEnter, closeActionsOpen } = useMouseHover();
  const { selectedProcessorId, setSelectedProcessorId } =
    useContext<EditProcessorModalContextType>(EditProcessorModalContext);
  const warnings = useProcessorsWarnings(id);

  const onClick = useCallback(() => {
    if (selectedProcessorId) {
      setSelectedProcessorId(id);
    }
  }, [setSelectedProcessorId, selectedProcessorId, id]);

  const processorWarning = useMemo(() => {
    return warnings[0]?.type && getProcessorIconByWarningStatusType(warnings[0]?.type);
  }, [warnings]);

  const processorType = data.processorType as ProcessorTypes;
  const hasInputPort = type?.connections?.inputPort;
  const hasOutputPort = type?.connections?.outputPort;

  return (
    <NodeWrapper
      selected={selected}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <ClickAwayListener disableReactTree onClickAway={closeActionsOpen}>
        <>
          {hasInputPort && <CustomTarget type="target" position={Position.Top} />}
          <NodeHeader
            label={data.parameters.label}
            processorType={processorType}
            iconUrl={type?.icon_url}
            entityId={data.entityId}
          />
          <NodeBody
            entityId={data.entityId}
            displayText={type?.displayText}
            parameters={data.parameters}
            processorId={id}
          />
          {processorWarning}
          {hasOutputPort && <NodeOutput processorType={processorType} />}
          {isActionsOpen && (
            <NodeActions
              id={id}
              closeActionsOpen={closeActionsOpen}
              processorType={processorType}
              connection_uuid={data.parameters?.connection_uuid}
            />
          )}
        </>
      </ClickAwayListener>
    </NodeWrapper>
  );
});
