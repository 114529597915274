export const Wait: ProcessorApiModel = {
  name: 'Wait',
  processor_type: 'Wait',
  description: 'Wait',
  type: 'processor',
  tags: ['Logical'],
  data_model: {},
  displayText: 'Test value',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/Wait-icon.png',
  connections: {
    inputPort: true,
    outputPort: true,
  },
};
