import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';

import { toZeroBasedIndex, toOneBasedIndex } from 'utils/apiMappers';

interface PaginatorProps {
  totalItems: number;
  page: number;
  size: number;
  onChangeSize: (size: number) => void;
  onPageClick: (page: number) => void;
}

const rowsPerPageOptions = [2, 5, 10, 25];

export const Pagination: React.FC<PaginatorProps> = ({
  page,
  size,
  onChangeSize,
  totalItems,
  onPageClick,
}) => {
  const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onPageClick(toOneBasedIndex(newPage));
  };

  const handleChangeRowsPerPage = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const rowsNumber = parseInt(event.target.value, 10);
      onChangeSize(rowsNumber);
    },
    [onChangeSize],
  );

  return (
    <tfoot style={{ width: '100%' }}>
      <tr>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={totalItems}
          page={toZeroBasedIndex(page)}
          rowsPerPage={size}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={onPageChange}
        />
      </tr>
    </tfoot>
  );
};
