const {
  REACT_APP_KEYCLOAK_BASE_URL: url = '',
  REACT_APP_OPENID_CLIENT_REALM: realm = '',
  REACT_APP_OPENID_CLIENT_ID: clientId = '',
  REACT_APP_API_BASE_URL: baseURL = '',
} = process.env;

export { url, realm, clientId, baseURL };

export const GMAIL_REDIRECT_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://admin.new.perceptiv.co/workspaces'
    : 'http://localhost:8080';
