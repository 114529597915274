import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Button, DialogActions } from '@material-ui/core';

import { useAppDispatch, useTypedSelector } from 'store';

import { TabContent } from 'components/Tabs';
import { TextField } from 'components/Table/TextField';

import { updateDatasetSettings } from '../../datasetsActions';

const messages = defineMessages({
  save: {
    id: 'modal.dataset-settings.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'modal.dataset-settings.save',
    defaultMessage: 'Cancel',
  },
});

type GeneralDatasetSettingsTabType = {
  datasetId: string;
  onClose: () => void;
};

export const GeneralDatasetSettingsTab: React.FC<GeneralDatasetSettingsTabType> = ({
  datasetId,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const originalDataset: IndexApiModel = useTypedSelector(
    (state) => state.datasets.datasets.find((dataset) => dataset.pk === datasetId) as IndexApiModel,
  );

  const [dataset, setDataset] = React.useState<IndexApiModel>(originalDataset);

  const onChange = React.useCallback(
    (key: string, value: boolean | string | number) => {
      setDataset((item) => ({ ...item, [key]: value }));
    },
    [setDataset],
  );

  const onSubmit = React.useCallback(() => {
    dispatch(
      updateDatasetSettings({
        id: datasetId,
        dataset,
      }),
    );

    onClose();
  }, [datasetId, dataset, dispatch, onClose]);

  return (
    <>
      <TabContent>
        <TextField label="Automation name" name="name" value={dataset.name} onChange={onChange} />
        <TextField
          label="Dataset name"
          name="description"
          value={dataset.description}
          onChange={onChange}
        />
      </TabContent>
      <DialogActions>
        <Button variant="outlined" disableElevation color="primary" onClick={onClose}>
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button variant="contained" disableElevation color="primary" onClick={onSubmit}>
          <FormattedMessage {...messages.save} />
        </Button>
      </DialogActions>
    </>
  );
};
