import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { getRoutePath } from 'utils/path';
import { ProjectParams } from 'types';
import { WebsiteRoutes } from 'constants/routes';
import { Message } from 'components/Message';
import { TabContent } from 'components/Tabs';

import { DatasetApi } from '../../DatasetApi';

type GeneralDatasetSettingsTabType = {
  datasetId: string;
};

export const AutomationsDatasetSettingsTab: React.FC<GeneralDatasetSettingsTabType> = ({
  datasetId,
}) => {
  const history = useHistory();
  const { projectId } = useParams<ProjectParams>();
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [automations, setAutomations] = React.useState<AssociatedEntity[]>([]);

  React.useEffect(() => {
    setLoading(true);
    DatasetApi.getAssociatedAutomations(datasetId)
      .then((res) => {
        setAutomations(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [datasetId]);

  const onClick = React.useCallback(
    (e: React.SyntheticEvent<HTMLTableCellElement>) => {
      if ('dataset' in e.currentTarget) {
        const id = e.currentTarget.dataset.id as string;
        history.push(getRoutePath(WebsiteRoutes.WorkflowEdit, { projectId, id }));
      }
    },
    [history, projectId],
  );

  return (
    <TabContent>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Automation name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {automations.map(({ id, name }) => (
              <TableRow key={id}>
                <TableCell data-id={id} onClick={onClick} style={{ cursor: 'pointer' }}>
                  {name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Message loading={isLoading} search="" count={automations.length} />
      </TableContainer>
    </TabContent>
  );
};
