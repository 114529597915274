import React, { useCallback, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';

import { TabContent } from 'components/Tabs';
import { DialogActions } from 'components/modal';
import { ModalTitleStyled } from 'components/modal/ModalHeader';
import { TextField } from 'components/Table/TextField';

import { useFlag } from 'modules/modals';

import { LinkedinApi } from '../../../api/LinkedinApi';

const messages = defineMessages({
  title: {
    id: 'connections.edit-connection-modal.title',
    defaultMessage: 'Linkedin Pin',
  },
  save: {
    id: 'connections.edit-connection-modal.save',
    defaultMessage: 'Save',
  },
});

type LinkedinPinSettingsProps = {
  connectionId: string;
};

export const LinkedinPinSettings = ({ connectionId }: LinkedinPinSettingsProps) => {
  const { showFlag } = useFlag();
  const [pin, setPin] = useState('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onChange = useCallback((_: string, value) => {
    setPin(value);
  }, []);

  const onSubmit = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await LinkedinApi.updatePin(connectionId, pin);

      showFlag({ title: 'Pin updated.' });
    } catch (e) {
      showFlag({ title: 'Please try again.' });
    }
  }, [isLoading, pin, connectionId]);

  return (
    <>
      <TabContent>
        <ModalTitleStyled>
          <FormattedMessage {...messages.title} />
        </ModalTitleStyled>
        <TextField
          name="pin"
          label="Update linkedin pin"
          placeholder="pin"
          value={pin}
          onChange={onChange}
        />
      </TabContent>
      <DialogActions>
        <Button variant="contained" disableElevation color="primary" onClick={onSubmit}>
          <FormattedMessage {...messages.save} />
        </Button>
      </DialogActions>
    </>
  );
};
