import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export const palette: PaletteOptions = {
  error: {
    main: '#d83b01',
  },
  primary: {
    main: '#201F1E',
    dark: '#201F1E',
  },
  secondary: {
    main: '#E1DFDD',
    light: '#8A8886',
  },
};
