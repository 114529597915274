import styled from 'styled-components';

import { IconButton } from '@material-ui/core';

export const Button = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.main};

  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.secondary.light};
  }
`;
