export const CustomComponent: ProcessorApiModel = {
  name: 'Custom Processor',
  processor_type: 'CustomComponent',
  description: 'Custom Processor',
  type: 'processor',
  tags: ['Output'],
  data_model: {},
  displayText: 'Test value',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/CustomProcessor-icon.png',
  connections: {
    inputPort: true,
    outputPort: true,
  },
};
