import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import icomoon1 from './assets/fonts/icomoon.eot?bgs79g';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import icomoon2 from './assets/fonts/icomoon.eot?bgs79g#iefix';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import icomoon3 from './assets/fonts/icomoon.ttf?bgs79g';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import icomoon4 from './assets/fonts/icomoon.woff?bgs79g';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import icomoon5 from './assets/fonts/icomoon.svg?bgs79g#icomoon';

export const GlobalStyle = createGlobalStyle`
  ${reset}
  body {
    min-width: 320px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.common.white};
    font-family: 'Open Sans', sans-serif;
  }

  code {
    font-family: source-code-pro, 'Open Sans', Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  a,
  button {
    cursor: pointer;
  }

  svg {
    flex-shrink: 0;
    display: inline-block;
    transform-origin: center;
    transition: fill 0.2s, transform 0.2s;
  }

  img,
  video {
    flex-shrink: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border: none;
  }

  @font-face {
    font-family: 'icomoon';
    src: url(${icomoon1});
    src: url(${icomoon2}) format('embedded-opentype'),
    url(${icomoon3}) format('truetype'),
    url(${icomoon4}) format('woff'),
    url(${icomoon5}) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-all:before {
    content: "\\e903";
  }

  .icon-favorite:before {
    content: "\\e901";
  }

  .icon-apps:before {
    content: "\\e900";
  }

  .icon-fluid:before {
    content: "\\e902";
  }

  .icon-movable:before {
    content: "\\e904";
    color: #e1dfdd;
  }

  .icon-database:before {
    content: "\\e900";
  }
`;
