import { RootState } from 'store';
import { UpdateCustomComponentType } from './components/CustomComponentsTable/types';
import { CustomComponentApiModel } from './types';

const transformCustomComponent = (
  customComponent: CustomComponentApiModel,
): CustomComponentApiModel => {
  let componentCopy = { ...customComponent };

  if (componentCopy && Array.isArray(componentCopy.command)) {
    componentCopy = {
      ...componentCopy,
      command: componentCopy.command.join(),
    };
  }

  if (componentCopy && !componentCopy.docker_credentials) {
    const { docker_credentials: inNull, ...component } = componentCopy;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return component;
  }

  return customComponent;
};

export const selectCustomComponentByPk =
  (pk: string) =>
  ({ customComponents }: RootState): Maybe<UpdateCustomComponentType> => {
    const customComponent = customComponents.components.find((item) => item.pk === pk);

    return customComponent && transformCustomComponent(customComponent);
  };
