import * as React from 'react';

import { useTypedSelector } from 'store';

import { CategoryList } from './ProcessorListMenu.style';
import { ModalTabs } from './constants';

import { ListItemRenderer } from './ListItemRenderer';
import { selectNodesByCategory } from '../../workflowSelectors';

interface NodeListType {
  searchInput: string;
  activeTab: ModalTabs;
}

export const NodeList: React.FC<NodeListType> = ({ searchInput, activeTab }) => {
  const [open, setOpen] = React.useState<Record<string, boolean>>({});
  const activeList = useTypedSelector(selectNodesByCategory(activeTab, searchInput));

  const onCategoryClick = React.useCallback((category: string) => {
    setOpen((value) => ({ [category]: !value[category] }));
  }, []);

  const isCategoryOpened = (category: string): boolean => {
    if (activeTab === ModalTabs.search) {
      return true;
    }

    return open[category];
  };

  return (
    <CategoryList>
      {Object.keys(activeList).map((category) => (
        <ListItemRenderer
          key={category}
          onCategoryClick={onCategoryClick}
          activeList={activeList[category]}
          category={category}
          isOpen={isCategoryOpened(category)}
        />
      ))}
    </CategoryList>
  );
};
