import { FormValidation } from '@rjsf/core';

import { CreateCustomProcessorType } from '../components/CustomComponentsTable/types';

const isJSONValid = (value: string): boolean => {
  try {
    return !!JSON.parse(value);
  } catch (e) {
    return false;
  }
};

export const customProcessorValidation = (
  formData: CreateCustomProcessorType,
  errors: FormValidation,
): FormValidation => {
  if (formData.schema && !isJSONValid(formData.schema)) {
    errors.schema.addError('Schema is not valid JSON.');
  }

  if (formData.data_model && !isJSONValid(formData.data_model)) {
    errors.data_model.addError('Schema is not valid JSON.');
  }

  return errors;
};
