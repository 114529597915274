import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

type NodeActionsPropsType = { count: number };

export const NodeActionsWrapper = styled.div<NodeActionsPropsType>`
  position: absolute;
  top: calc(50% - 14px);
  z-index: 1000;
  right: ${({ count }): string => `-${count * 43}px`};
  padding-left: 10px;
`;

export const NodeAction = styled(IconButton)`
  background: #f3f2f1;
  width: 28px;
  height: 28px;
  margin-right: 12px;
`;
