import styled from 'styled-components';

import { Cancel } from '@material-ui/icons';

export const CancelIcon = styled(Cancel)`
  height: 16px;
  width: 16px;
  color: #a4262c;
  border-radius: 50%;
  transform: scale(0.8);
  box-sizing: border-box;
`;

export const TransparentPath = styled.path`
  fill: none;
  stroke-width: 10;
  stroke-opacity: 0;
`;

export const RemoveIconWrapper = styled.foreignObject`
  cursor: pointer;
  opacity: ${(props) => props.opacity};
  transition: opacity 0.2s;
  background-color: #fff;
  border-radius: 50%;
`;

export const EdgeLabelWrapper = styled.foreignObject`
  text-transform: capitalize;
  transition: opacity 0.2s;
  text-align: center;
  opacity: ${(props) => props.opacity};
  background-color: #fff;
`;
