import { Schema } from 'schemas/types';

export const LinkedinReaderSchema: Schema = {
  type: 'object',
  properties: {
    label: {
      title: 'Label',
      type: 'string',
      default: '',
    },
    connection_uuid: {
      type: 'string',
      title: 'Connection',
      enum: [],
      enumNames: [],
      entityName: 'connection',
      filter: { fieldName: 'source_type', value: 'linkedin' },
    },
  },
  required: ['connection_uuid'],
};
