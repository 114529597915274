import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';
import { Params } from 'common/Params';
import { buildRequestParams } from 'utils/buildRequestParams';

import { Variable } from './types';

export const WorkspaceVariablesApi = {
  fetchParams: async (params?: Params): Promise<Variable[]> => {
    const res = await requestApi<PaginatedResponse<Variable>>({
      service: Services.Params,
      submethod: `params/`,
      params: params ? buildRequestParams(params) : {},
    });

    return res.data.results;
  },
  createParam: async (data: Variable): Promise<Variable> => {
    const res = await requestApi<Variable>({
      method: 'POST',
      service: Services.Params,
      submethod: `params/`,
      data,
    });

    return res.data;
  },
  updateParam: async (data: Variable): Promise<Variable> => {
    const res = await requestApi<Variable>({
      method: 'PUT',
      service: Services.Params,
      submethod: `params/${data.id}/`,
      data,
    });

    return res.data;
  },
  removeParam: async (id: string) => {
    await requestApi({
      method: 'DELETE',
      service: Services.Params,
      submethod: `params/${id}/`,
    });

    return id;
  },
} as const;
