import { requestApi } from 'utils/httpClient';
import { buildRequestParams } from 'utils/buildRequestParams';

import { Services } from 'constants/services';

type DatasetDocumentType = Record<string, unknown>;

export const DatasetDocumentApi = {
  getDatasetDocument: async (
    id: string,
    params: TableFilterParams,
  ): Promise<PaginatedResponse<DatasetDocumentType>> => {
    const response = await requestApi<PaginatedResponse<DatasetDocumentType>>({
      method: 'POST',
      service: Services.Search,
      submethod: `index/${id}/documents`,
      params: buildRequestParams(params),
    });

    return response.data;
  },
  getDatasetPropertyTypes: async (): Promise<DatasetPropertyTypeType[]> => {
    const response = await requestApi<DatasetPropertyTypeType[]>({
      service: Services.Search,
      submethod: 'index/types/',
    });

    return response.data;
  },
} as const;
