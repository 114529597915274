import React, { useCallback, useState } from 'react';

import { useDebounce, useTable, useLogsTableContent } from 'hooks';

import { getTableSettingsInitialValue } from 'types/table';

import { defaultTableParams } from 'constants/table';
import { LogsLevelSelector } from 'components/LogsLevelSelector';
import { TabContent } from 'components/Tabs';
import { CommonTable } from 'components/Table/CommonTable';
import { Message } from 'components/Message';
import { SearchInput, ControlWrapper } from 'components/common';

import { LogsManagerApi } from '../../../LogsManagerApi';

type ConnectionLogsProps = {
  connectionId: string;
};

export const ConnectionLogs: React.FC<ConnectionLogsProps> = ({ connectionId }) => {
  const [level, setLevel] = useState<LogsLevel>('all');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [logs, setLogs] = useState<LogApiModel[]>([]);
  const { tableParams, onSizeChange, onSearchChange, onCountChange, onPageChange, setTableParams } =
    useTable();
  const tableContent = useLogsTableContent(logs);

  const { search, page } = tableParams;
  const debouncedSearch = useDebounce(search, 300);

  const onLevelChange = useCallback(
    (value) => {
      setLevel(value);
      onPageChange(defaultTableParams.page);

      if (value === 'all') {
        setTableParams(getTableSettingsInitialValue());
      }
    },
    [setLevel, onPageChange, setTableParams],
  );

  const getConnectionLogs = () => {
    setLoading(true);

    LogsManagerApi.fetchConnectionLogs({ connectionId, tableParams, level }).then(
      ({ results, count }) => {
        onCountChange(count);
        setLogs(results);
        setLoading(false);
      },
    );
  };

  React.useEffect(() => {
    getConnectionLogs();
  }, [debouncedSearch, page, level, level]);

  return (
    <TabContent>
      <ControlWrapper>
        <SearchInput placeholder="Search" onChange={onSearchChange} value={tableParams.search} />
        <LogsLevelSelector level={level} onChange={onLevelChange} />
      </ControlWrapper>
      <CommonTable
        content={tableContent}
        totalItems={tableParams.count}
        size={tableParams.size}
        page={tableParams.page}
        goToPage={onPageChange}
        onSizeChange={onSizeChange}
        isPagination
      />
      <Message loading={isLoading} count={tableParams.count} search={tableParams.search || level} />
    </TabContent>
  );
};
