import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import { useAppDispatch, useTypedSelector } from 'store';
import { useProjectId } from 'hooks';

import {
  checkPermissions,
  selectPermissionsChecked,
  selectHasPermissions,
} from 'store/userSettingsSlice';
import { WebsiteRoutes } from 'constants/routes';

export const ProjectRoute: React.FC<RouteProps> = (props) => {
  const dispatch = useAppDispatch();
  const { projectId } = useProjectId();
  const { initialized, keycloak } = useKeycloak();

  const permissionsChecked = useTypedSelector(selectPermissionsChecked);
  const hasPermissions = useTypedSelector(selectHasPermissions);

  useEffect(() => {
    if (initialized && keycloak.authenticated && projectId) {
      dispatch(checkPermissions({ projectId }));
    }
  }, [projectId, initialized, keycloak.authenticated, dispatch]);

  if (!projectId) {
    return <Redirect to={WebsiteRoutes.Workspaces} />;
  }

  if (!initialized) {
    return null;
  }

  if (!keycloak.authenticated) {
    keycloak.login();

    return null;
  }

  if (permissionsChecked && !hasPermissions) {
    keycloak.logout();

    return null;
  }

  return <Route {...props} />;
};
