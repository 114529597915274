import React, { useCallback, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Input, List } from '@material-ui/core';

import { useDebounce } from 'hooks';

import { useModal, ConnectionsModalTypes } from 'modules/modals';
import { ConnectionItem } from 'modules/automation/modals/ConnectionItem/ConnectionItem';
import { Modal } from 'components/modal';

import { getModalType, types } from './helpers';

const message = defineMessages({
  title: {
    id: 'modal.create-connections-modal.content',
    defaultMessage: 'New Connection',
  },
  cancel: {
    id: 'modal.create-connections-modal.content',
    defaultMessage: 'Cancel',
  },
  continue: {
    id: 'modal.create-connections-modal.content',
    defaultMessage: 'Continue',
  },
  content: {
    id: 'modal.create-connections-modal.content',
    defaultMessage:
      'Did not find what you were looking for? Make a request for a service and we will do our best to add support for it.',
  },
});

export const CreateConnectionModal: React.FC = () => {
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState<Maybe<ConnectionTypeType>>(undefined);
  const debouncedSearchValue = useDebounce(inputValue, 750);
  const { closeModal, openModal } = useModal();

  const filterConnectionType = useMemo(() => {
    return types.filter((item) =>
      item.type.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
    );
  }, [debouncedSearchValue]);

  const onClose = React.useCallback(() => {
    closeModal(ConnectionsModalTypes.CreateConnectionModal);
  }, [closeModal]);

  const onDoubleClick = React.useCallback(
    (type: ConnectionTypeType) => {
      const modalType = getModalType(type);

      if (modalType) {
        openModal({
          type: modalType,
        });
      }

      onClose();
    },
    [onClose, openModal],
  );

  const onSubmit = React.useCallback(() => {
    if (!selected) {
      return;
    }

    const modalType = getModalType(selected);

    if (modalType) {
      openModal({
        type: modalType,
      });
    }

    onClose();
  }, [selected, onClose, openModal]);

  const onSearch = React.useCallback((value: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(value.target.value);
  }, []);

  const onClick = useCallback((value: string) => {
    setSelected(value as ConnectionTypeType);
  }, []);

  return (
    <Modal
      title={message.title}
      cancelMessage={message.cancel}
      submitMessage={message.continue}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Input
        onChange={onSearch}
        placeholder="Search"
        style={{ width: '100%', marginBottom: '10px' }}
      />
      <FormattedMessage {...message.content} tagName="p" />
      <List className="has-no-top-padding">
        {filterConnectionType.map((type) => (
          <ConnectionItem
            selected={type.type === selected}
            key={type.type}
            pk={type.type}
            name={type.label}
            description=""
            onClick={onClick}
            onDoubleClick={onDoubleClick}
          />
        ))}
        {filterConnectionType.length === 0 && (
          <p style={{ textAlign: 'center', fontWeight: 600 }}>
            No result according to search value
          </p>
        )}
      </List>
    </Modal>
  );
};
