import { isScalarValue } from './utils';
import { Schema, SchemaProperty } from './types';

export type Fields = Record<string, ScalarValue | 'array'>;

type SchemaProperties = Record<string, SchemaProperty>;

export const createSchemaFromObject = (fields: Fields): Schema => {
  const properties: SchemaProperties = Object.keys(fields).reduce(
    (prev: SchemaProperties, name: string): SchemaProperties => {
      const type = typeof fields[name];

      if (Array.isArray(fields[name])) {
        return {
          ...prev,
          [name]: {
            title: name,
            type: 'array',
            items: {
              type: 'string',
              default: '',
            },
          },
        };
      }
      if (isScalarValue(type)) {
        return {
          ...prev,
          [name]: {
            title: name,
            type: 'string',
          },
        };
      }

      return prev;
    },
    {},
  );

  return {
    properties,
  };
};
