import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

import { AllModalTypes } from './constants';

type DataType = Record<string, unknown>;

export type ModalStoreType = {
  type: AllModalTypes;
  data?: DataType;
};

type InitialStateType = {
  modals: ModalStoreType[];
};

export const initialState: InitialStateType = {
  modals: [],
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state, { payload }: PayloadAction<{ type: AllModalTypes; data: DataType }>) {
      const { type, data = {} } = payload;

      state.modals.push({
        type,
        data,
      });
    },
    closeModal(state, { payload }: PayloadAction<{ type: AllModalTypes }>) {
      state.modals = state.modals.filter((item) => item.type !== payload.type);
    },
  },
});

export const selectIsModalOpen =
  (modalType: AllModalTypes) =>
  (store: RootState): boolean =>
    Boolean(store.modals.modals.find((item) => item.type === modalType));

export default modalSlice.reducer;

export const { openModal, closeModal } = modalSlice.actions;
