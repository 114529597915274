import styled from 'styled-components';
import { DialogActions } from '@material-ui/core';

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const RowFields = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const ColorFieldWrapper = styled.div`
  margin-left: 10px;
  width: 70px;

  > div {
    display: flex;
  }
`;

export const DialogActionsStyled = styled(DialogActions)`
  margin-top: 10px;
`;
