import React, { useCallback, useState } from 'react';
import { defineMessages } from 'react-intl';

import Form from '@rjsf/material-ui';

import { uiSchema } from 'schemas';
import { useFormData } from 'hooks';
import { ConnectionsModalTypes, useModal } from 'modules/modals';
import { ModalFooter, ModalWithoutFooter } from 'components/modal';

import { linkedinConnectionSchema } from './schemas/linkedinConnectionSchema';
import { ConnectorApi } from '../api/ConnectorApi';
import { ConnectionApi } from '../api/ConnectionApi';

const messages = defineMessages({
  title: {
    id: 'connection.create-email-connector-modal.title',
    defaultMessage: 'Linkedin Connection',
  },
  create: {
    id: 'connection.create-email-connector-modal.create',
    defaultMessage: 'Create',
  },
  cancel: {
    id: 'connection.create-email-connector-modal.cancel',
    defaultMessage: 'Cancel',
  },
  warning: {
    id: 'connection.create-email-connector-modal.warning',
    defaultMessage: `Don't forget to enable access via POP3 in your account settings.`,
  },
  mailchimpWarning: {
    id: 'connection.create-email-connector-modal.mailchimpWarning',
    defaultMessage: `If you have a free account you can send emails only inside the organization or the domain.`,
  },
});

type FormData = {
  config: LinkedinConfig;
} & ConnectionModel;

const initialFormDataState: FormData = {
  name: '',
  description: '',
  scheduler: true,
  cron: '* * * * *',
  config: {
    username: '',
    password: '',
    mode: 'contacts',
    contacts_scroll_limit: 1,
  },
};

export const CreateLinkedinConnectionModal = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { closeModal, openModal } = useModal();
  const { formData, onChange } = useFormData<FormData>(initialFormDataState);

  const onSubmit = useCallback(async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      const { name, description, cron, scheduler, config } = formData;

      const connection = await ConnectionApi.createConnection({
        name,
        description,
        source_type: 'linkedin',
        datasource_model: 'linkedin',
      });

      await ConnectorApi.createConnector({
        pk: connection.pk,
        type: 'linkedin',
        data: {
          cron,
          scheduler,
          config,
        },
      });

      await ConnectorApi.forceSyncConnection(connection.pk);

      openModal({
        type: ConnectionsModalTypes.LinkedinPinModal,
        data: {
          connectionId: connection.pk,
        },
      });
      closeModal(ConnectionsModalTypes.CreateLinkedinConnectionModal);
    } catch (e) {
      setIsLoading(false);
    }
  }, [isLoading, closeModal, openModal, formData]);

  const onClose = useCallback(() => {
    if (isLoading) return;
    closeModal(ConnectionsModalTypes.CreateLinkedinConnectionModal);
  }, [isLoading, closeModal]);

  return (
    <ModalWithoutFooter title={messages.title} onClose={onClose}>
      <Form
        uiSchema={uiSchema}
        onChange={onChange}
        schema={linkedinConnectionSchema}
        formData={formData}
      >
        <ModalFooter
          submitMessage={messages.create}
          cancelMessage={messages.cancel}
          hasPadding={false}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </Form>
    </ModalWithoutFooter>
  );
};
