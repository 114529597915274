import * as React from 'react';

import MenuItem from '@material-ui/core/MenuItem';

import { useProjectId } from 'hooks';
import { WorkspaceApi } from 'modules/workspaces/WorkspaceApi';
import { WorkspaceApiModel, BaseWorkspaceApiModel } from 'modules/workspaces/types';
import { defaultFetchParams } from 'constants/table';

import { AppBarStyled, HeaderWrapper, ToolbarStyled, WorkspaceSelector } from './Header.style';
import { HeaderMenu } from '../HeaderMenu';
import { HeaderHomeLink } from '../HeaderHomeLink';

const PREV_WORKSPACES_KEY = 'prev-workspaces';

const getPrevWorkspaces = () => {
  const data = localStorage.getItem(PREV_WORKSPACES_KEY);

  if (!data) {
    return [];
  }

  return (JSON.parse(data) as BaseWorkspaceApiModel[]) || [];
};

export const Header = () => {
  const { projectId, setProjectId } = useProjectId();
  const [workflows, setWorkflows] = React.useState<BaseWorkspaceApiModel[]>(getPrevWorkspaces);

  const onProjectChange = React.useCallback(
    ({ target }: React.ChangeEvent<{ value: unknown }>) => {
      setProjectId(target.value as string);
    },
    [setProjectId],
  );

  React.useEffect(() => {
    WorkspaceApi.fetchSimpleWorkspaces(defaultFetchParams).then((res) => {
      setWorkflows(res.results);
      localStorage.setItem(PREV_WORKSPACES_KEY, JSON.stringify(res.results));
    });
  }, [setWorkflows]);

  return (
    <AppBarStyled position="fixed">
      <ToolbarStyled>
        <HeaderHomeLink />
        <HeaderWrapper>
          <WorkspaceSelector
            variant="outlined"
            placeholder="Choose workspace"
            labelId="workspace-select-label"
            id="workspace-select"
            value={projectId}
            onChange={onProjectChange}
          >
            {workflows.map((item: BaseWorkspaceApiModel) => (
              <MenuItem key={item.pk} value={item.pk}>
                {item.name}
              </MenuItem>
            ))}
          </WorkspaceSelector>
        </HeaderWrapper>
        <HeaderMenu />
      </ToolbarStyled>
    </AppBarStyled>
  );
};
