import React from 'react';
import { Position } from 'react-flow-renderer';

import { ConditionalSource, ConditionalSourceTooltip, CustomSource } from './NodeOutput.style';

type NodeOutputType = {
  processorType: ProcessorTypes;
};

export const NodeOutput: React.FC<NodeOutputType> = ({ processorType }) => {
  if (processorType === 'If') {
    return (
      <>
        <ConditionalSourceTooltip title="False">
          <ConditionalSource
            id="false"
            type="source"
            style={{ left: 80, bottom: -16 }}
            position={Position.Bottom}
          />
        </ConditionalSourceTooltip>
        <ConditionalSourceTooltip title="True">
          <ConditionalSource
            id="true"
            type="source"
            style={{ right: 80, bottom: -16, left: 'unset' }}
            position={Position.Bottom}
          />
        </ConditionalSourceTooltip>
      </>
    );
  }

  return <CustomSource id="common" type="source" position={Position.Bottom} />;
};
