import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'store';
import { CustomComponentModalTypes } from 'modules/modals/constants';
import { useModal } from 'modules/modals';
import { Modal } from 'components/modal';

import { deleteCustomComponents } from '../customComponentsActions';

const messages = defineMessages({
  title: {
    id: 'automation.delete-datasets-modal.title',
    defaultMessage: 'Delete Component',
  },
  confirmation: {
    id: 'automation.delete-datasets-modal.confirmation',
    defaultMessage: 'Are you sure that you want to delete this component?',
  },
  continue: {
    id: 'automation.delete-datasets-modal.create',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'automation.delete-datasets-modal.cancel',
    defaultMessage: 'Cancel',
  },
});

export type DeleteCustomComponentModalType = {
  pk: string;
};

export const DeleteCustomComponentModal: React.FC<DeleteCustomComponentModalType> = ({ pk }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { closeModal } = useModal();
  const dispatch = useAppDispatch();

  const onSubmit = React.useCallback(async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await dispatch(deleteCustomComponents(pk)).unwrap();

      closeModal(CustomComponentModalTypes.DeleteCustomComponentModal);
    } catch (e) {
      setIsLoading(false);
    }
  }, [setIsLoading, isLoading, dispatch, closeModal, pk]);

  const onClose = React.useCallback(() => {
    if (isLoading) return;
    closeModal(CustomComponentModalTypes.DeleteCustomComponentModal);
  }, [isLoading, closeModal]);

  return (
    <Modal
      title={messages.title}
      cancelMessage={messages.cancel}
      submitMessage={messages.continue}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <div className="has-bottom-margin">
        <FormattedMessage tagName="p" {...messages.confirmation} />
      </div>
    </Modal>
  );
};
