import { Schema } from 'schemas/types';

export const CustomComponentSchema: Schema = {
  type: 'object',
  properties: {
    label: {
      title: 'Label',
      type: 'string',
      default: '',
    },
    custom_processor_uuid: {
      enum: [],
      enumNames: [],
      entityName: 'custom-component',
      title: 'Custom Processor',
      type: 'string',
    },
  },
  required: ['custom_processor_uuid'],
};
