import * as React from 'react';
import { IntlProvider } from 'react-intl';

import { useLanguage } from 'hooks';

export const LanguageProvider: React.FC = ({ children }) => {
  const { lang, locale } = useLanguage();

  return (
    <IntlProvider
      locale={locale}
      messages={lang}
      onError={(e) => {
        // Hide error hot-fix
        // eslint-disable-next-line no-console
        console.log(e.message);
      }}
    >
      {children}
    </IntlProvider>
  );
};
