import { ArrowHeadType } from 'react-flow-renderer';

import { generateId } from 'utils/generateId';

type CreateEdgeArgsType = {
  source: string;
  target: string;
  condition?: string;
  isConditional?: boolean;
  id?: string;
};

export const createEdge = ({
  source,
  target,
  condition,
  id = generateId(),
  isConditional = false,
}: CreateEdgeArgsType): WorkflowEdge => {
  if (isConditional && condition) {
    return {
      id,
      animated: false,
      source,
      target,
      type: 'conditional',
      arrowHeadType: ArrowHeadType.ArrowClosed,
      sourceHandle: condition,
      data: {
        condition: condition as WorkflowEdgeCondition,
        label: condition,
      },
    };
  }

  return {
    id,
    animated: false,
    source,
    target,
    type: 'custom',
    arrowHeadType: ArrowHeadType.ArrowClosed,
  };
};

export const isConditionalEdge = (handle: string) => ['true', 'false'].includes(handle);
