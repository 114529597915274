import React from 'react';

import { DatasetsModalTypes, useModal } from 'modules/modals';

import { CommonActionsCell } from 'components/CommonActionsCell';
import { ModalLink } from 'components/Table/ModalLink';

type ActionsCellType = {
  id: string;
  automationCount: number;
};

export const ActionsCell: React.FC<ActionsCellType> = React.memo(({ id, automationCount }) => {
  const { openModal } = useModal();

  const onEditClick = React.useCallback(() => {
    openModal({
      type: DatasetsModalTypes.EditDatasetsModal,
      data: {
        id,
      },
    });
  }, [openModal, id]);

  const onDeleteClick = React.useCallback(() => {
    openModal({
      type: DatasetsModalTypes.DeleteDatasetModal,
      data: { id, automationCount },
    });
  }, [openModal, id, automationCount]);

  return (
    <CommonActionsCell>
      <ModalLink onClick={onEditClick}>Edit</ModalLink>
      <ModalLink onClick={onDeleteClick}>Delete</ModalLink>
    </CommonActionsCell>
  );
});
