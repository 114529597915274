import React from 'react';

import { Container } from '@material-ui/core';

import type { TableContent, TableRowParams } from 'components/Table';
import { NameCell } from 'components/Table/NameCell';
import { WebsiteRoutes } from 'constants/routes';

import { ActionsCell } from './ActionsCell';

export interface DatasetsContent extends TableRowParams {
  id: string;
  name: JSX.Element;
  description: JSX.Element;
  entries: JSX.Element;
  automationCount: JSX.Element;
  actions: JSX.Element;
}

const datasetsTableContent: TableContent<DatasetsContent> = {
  header: {
    name: {
      width: '15%',
      name: 'Name',
      sortable: true,
    },
    description: {
      width: '15%',
      name: 'Description',
      sortable: true,
    },
    entries: {
      width: '10%',
      name: 'Entries',
      sortable: true,
    },
    automationCount: {
      width: '10%',
      name: 'Automation',
      sortable: true,
    },
    actions: {
      name: '',
      sortable: false,
    },
  },
  rows: [],
};

export const useDatasetsTableContent = (items: IndexApiModel[]): TableContent<DatasetsContent> => {
  datasetsTableContent.rows = items.map(({ pk, name, description, entries, automation_count }) => ({
    id: pk,
    name: (
      <NameCell id={pk} name={name} parameterName="datasetId" route={WebsiteRoutes.DatasetsEdit} />
    ),
    description: (
      <Container id={pk} style={{ paddingLeft: 0 }}>
        {description}
      </Container>
    ),
    entries: <p className="has-align-center">{entries}</p>,
    automationCount: <p className="has-align-center">{automation_count}</p>,
    actions: <ActionsCell id={pk} automationCount={automation_count} />,
  }));

  return datasetsTableContent;
};
