import * as React from 'react';
import { MenuItem, Select } from '@material-ui/core';

import { useTypedSelector } from 'store';
import type { OnChangeEvent } from 'types';

import { selectWorkspaceGroups } from '../workspaceSelectors';
import { GroupApiModel } from '../types';

type RoleSelectorType = {
  value: number;
  error: boolean;
  onChange: (event: OnChangeEvent) => void;
};

export const RoleSelectorImp: React.FC<
  RoleSelectorType & { groups: GroupApiModel[]; width?: number }
> = ({ onChange, value, error, groups, width = 150 }) => (
  <Select name="group" value={value} error={error} style={{ width }} onChange={onChange}>
    {groups.map((item) => (
      <MenuItem key={`member-selector-item-${item.id}`} value={item.id}>
        {item.name}
      </MenuItem>
    ))}
  </Select>
);

export const RoleSelector: React.FC<RoleSelectorType> = ({ onChange, value, error }) => {
  const groups = useTypedSelector(selectWorkspaceGroups);

  return <RoleSelectorImp groups={groups} onChange={onChange} value={value} error={error} />;
};
