import React, { useEffect, useState, useCallback, FC, useMemo } from 'react';
import { defineMessages } from 'react-intl';

import Form from '@rjsf/material-ui';

import { useAppDispatch, useTypedSelector } from 'store';
import { customProcessorUiSchema } from 'schemas';
import { FormDataChangeEvent } from 'types';
import { Schema } from 'schemas/types';

import { fetchDockerCredentials } from 'modules/credentials/credentialsActions';
import { CustomComponentModalTypes } from 'modules/modals/constants';
import { useModal } from 'modules/modals';
import { Modal } from 'components/modal';
import { defaultFetchParams } from 'constants/table';

import { customProcessorValidation } from '../customProcessorValidation';
import { createCustomProcessorAction } from '../../customComponentsActions';
import { CreateCustomProcessorType } from '../../components/CustomComponentsTable/types';
import { fillDockerCredentialsField } from '../fillUpdateCustomProcessorSchema';
import { createCustomProcessorSchema } from './customProcessorModalSchema';

const messages = defineMessages({
  title: {
    id: 'automation.create-dataset-writer-node-modal.title',
    defaultMessage: 'Create Custom Processor',
  },
  create: {
    id: 'automation.create-dataset-writer-node-modal.create',
    defaultMessage: 'Create',
  },
  cancel: {
    id: 'automation.create-dataset-writer-node-modal.cancel',
    defaultMessage: 'Back',
  },
});

const initialFormDataState: CreateCustomProcessorType = {
  name: '',
  description: '',
  docker_image: 'nginx',
  docker_tag: 'latest',
  command: '',
  uri_path: 'nginx',
  service_port: 80,
  replica_count: 1,
  schema: '',
  data_model: '',
};

export const CreateCustomProcessorModal: FC = () => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<CreateCustomProcessorType>(initialFormDataState);

  const credentials: DockerCredentialApiModel[] = useTypedSelector(
    (store) => store.credentials.dockerCredentials,
  );

  const schema: Schema = useMemo(
    () => fillDockerCredentialsField(createCustomProcessorSchema, credentials),
    [credentials],
  );

  const onClose = useCallback(() => {
    if (isLoading) return;
    closeModal(CustomComponentModalTypes.CreateCustomComponentModal);
  }, [isLoading, closeModal]);

  const onSubmit = useCallback(async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      await dispatch(createCustomProcessorAction(data)).unwrap();

      closeModal(CustomComponentModalTypes.CreateCustomComponentModal);
    } catch (e) {
      setIsLoading(false);
    }
  }, [setIsLoading, isLoading, closeModal, data, dispatch]);

  const onChange = React.useCallback(
    ({ formData }: FormDataChangeEvent<CreateCustomProcessorType>) => {
      setData(formData);
    },
    [setData],
  );

  useEffect(() => {
    dispatch(fetchDockerCredentials(defaultFetchParams));
  }, [dispatch]);

  return (
    <Modal
      title={messages.title}
      cancelMessage={messages.cancel}
      submitMessage={messages.create}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Form
        liveValidate
        schema={schema}
        formData={data}
        onChange={onChange}
        validate={customProcessorValidation}
        uiSchema={customProcessorUiSchema}
      >
        <></>
      </Form>
    </Modal>
  );
};
