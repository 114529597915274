import styled from 'styled-components';
import ReactFlow from 'react-flow-renderer';

import { Container } from '@material-ui/core';

export const WorkflowEditContainer = styled(Container)`
  height: 100%;
  max-width: 100%;
  padding: 0;
`;

export const ReactFlowStyled = styled(ReactFlow)`
  height: 100%;
`;
