import styled from 'styled-components';
import { Handle } from 'react-flow-renderer';

import { Warning } from '@material-ui/icons';

import node_target from '../Edge/assets/node_target.svg';

interface NodeWrapperProps {
  selected: boolean;
}

export const NodeWrapper = styled.div<NodeWrapperProps>`
  width: 350px;
  max-width: 350px;
  padding: 8px;
  border-radius: 3px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid ${({ theme }) => theme.palette.secondary.light};
  background: ${({ theme, selected }) => (selected ? '#d6d5e6' : theme.palette.common.white)};

  &:hover {
    background: #d6d5e6;
  }
`;

export const UnconfiguredIcon = styled(Warning)`
  position: absolute;
  padding: 0;
  left: -6px;
  bottom: -6px;
  font-size: 20px;
`;

export const CustomTarget = styled(Handle)`
  width: 20px;
  height: 20px;
  top: -15px;
  left: 50%;
  border: none;
  background: url(${node_target}) no-repeat 2px 12px;
`;
