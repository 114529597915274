import { SchemaProperty } from 'schemas/types';

import { EntityMapName } from '../../types';
import { fillSchemaProperty } from './fillSchemaProperty';

type FillSchemaPropertiesPayload = {
  properties: Record<string, SchemaProperty>;
  entities: EntityMapName;
  datasetId?: string;
  dataModel: DataModel;
};

export const fillSchemaProperties = ({
  properties,
  entities,
  dataModel,
  datasetId,
}: FillSchemaPropertiesPayload) => {
  for (const key in properties) {
    if (key in properties) {
      const property = properties[key];

      fillSchemaProperty(property, entities, dataModel, datasetId);
    }
  }
};
