import { CustomComponentApiModel } from 'modules/custom-components/types';

import { getCustomComponentDataModelById } from './getCustomComponentDataModelById';
import { getHubspotDataModelById } from './getHubspotDataModelById';
import { getProcessors, getProcessorDataModelByType } from '../../components';

export const getDataModel = (
  processorType: ProcessorTypes,
  customComponents: CustomComponentApiModel[],
  parameters: Maybe<WorkflowElementDataParameters>,
): DataModel => {
  if (processorType === 'CustomComponent' && parameters?.custom_processor_uuid) {
    return getCustomComponentDataModelById(customComponents, parameters.custom_processor_uuid);
  }

  if (processorType === 'Hubspot' && parameters?.hubspot_fields_mapper) {
    const nodes: ProcessorApiModel[] = getProcessors();

    return getHubspotDataModelById(nodes, parameters?.hubspot_fields_mapper);
  }

  return getProcessorDataModelByType(processorType);
};
