import React, { useMemo, useCallback, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useTypedSelector } from 'store';

import './StatisticAccordion.styles.scss';

import { selectIsAutomationRunning } from '../../../workflowSelectors';
import { AccordionDetails, AccordionSummary, Accordion } from './StatisticAccordion.style';
import { NodeStatistic } from './NodeStatistic';
import { useProcessorsWarnings } from '../../../hooks/useProcessorsWarnings';
import { WarningListWithTitle } from '../../WarningList';
import {
  EditProcessorModalContext,
  EditProcessorModalContextType,
} from '../../../modals/EditProcessorModal/EditProcessorModalContextProvider';

const messages = defineMessages({
  title: {
    id: 'title',
    defaultMessage: 'Detailed information',
  },
  warning: {
    id: 'warning',
    defaultMessage: 'Automation should be running to show statistic.',
  },
  noEntityIdWarning: {
    id: 'no-entity-id-warning',
    defaultMessage: 'Entity id is not detected. Redeploying should help.',
  },
});

const stopPropagationHandler = (e: React.MouseEvent) => {
  e.stopPropagation();
};

type NodeStatisticAccordionType = {
  entityId?: string;
  processorId: string;
};

export const NodeDetailsAccordion: React.FC<NodeStatisticAccordionType> = ({
  entityId,
  processorId,
}) => {
  const isAutomationRunning = useTypedSelector(selectIsAutomationRunning);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { setSelectedProcessorId } =
    React.useContext<EditProcessorModalContextType>(EditProcessorModalContext);
  const warnings = useProcessorsWarnings(processorId);

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      setIsOpen((value) => !value);
    },
    [setIsOpen],
  );

  const onWarningItemClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setSelectedProcessorId(e.currentTarget.dataset.id as string);
    },
    [setSelectedProcessorId],
  );

  const content = useMemo(() => {
    if (!isAutomationRunning) {
      return <FormattedMessage tagName="p" {...messages.warning} />;
    }

    return entityId ? (
      <NodeStatistic entityId={entityId} />
    ) : (
      <FormattedMessage tagName="p" {...messages.noEntityIdWarning} />
    );
  }, [isAutomationRunning, entityId]);

  return (
    <Accordion onDoubleClick={stopPropagationHandler} expanded={isOpen}>
      <AccordionSummary onClick={onClick} expandIcon={<ExpandMoreIcon />}>
        <FormattedMessage {...messages.title} />
      </AccordionSummary>
      <AccordionDetails>
        <WarningListWithTitle
          warnings={warnings}
          showProcessorName={false}
          disablePadding
          onClick={onWarningItemClick}
        />
        <div className="has-top-margin">{content}</div>
      </AccordionDetails>
    </Accordion>
  );
};
