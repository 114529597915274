import { Schema } from 'schemas/types';

export const HubspotSchema: Schema = {
  type: 'object',
  required: ['http_method', 'url', 'apikey_uuid', 'jsonpath'],
  properties: {
    label: {
      title: 'Label',
      type: 'string',
      default: '',
    },
    http_method: {
      title: 'HTTP Method',
      enum: ['GET', 'POST'],
      enumNames: ['GET', 'POST(testing)'],
      type: 'string',
      default: 'GET',
    },
    url: {
      title: 'Url endpoint',
      type: 'string',
    },
    apikey_uuid: {
      title: 'API key',
      type: 'string',
      enum: [],
      enumNames: [],
      entityName: 'api-key',
    },
    hubspot_fields_mapper: {
      type: 'array',
      title: 'Output fields',
      items: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
          },
          jsonpath: {
            type: 'string',
          },
        },
      },
    },
  },
  dependencies: {
    http_method: {
      oneOf: [
        {
          properties: {
            http_method: {
              enum: ['GET'],
            },
            jsonpath: {
              title: 'JSONPath',
              type: 'string',
              default: '$.results',
            },
          },
        },
        {
          properties: {
            http_method: {
              enum: ['POST'],
            },
            body: {
              type: 'string',
              title: 'Body',
              default: '',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              entityName: 'data_model',
              enum: [],
              enumNames: [],
            },
          },
        },
      ],
    },
  },
};
