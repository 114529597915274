import React from 'react';

import { useProjectId } from 'hooks';
import { WebsiteRoutes } from 'constants/routes';

import { RouterLinkStyled, Container } from './NameCell.style';

export interface NameCellProps {
  id: string;
  route: WebsiteRoutes;
  name: string;
  parameterName?: string;
  icon?: React.ReactNode;
}

export const NameCell = ({ id, route, name, icon, parameterName = 'id' }: NameCellProps) => {
  const { projectId } = useProjectId();

  return (
    <Container>
      {!!icon && icon}
      <RouterLinkStyled route={route} params={{ [parameterName]: id, projectId: projectId || '' }}>
        {name}
      </RouterLinkStyled>
    </Container>
  );
};
