import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import TextField from '@material-ui/core/TextField';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export const AutomationPublicUrl = () => {
  const currentUrl = window.location.href;

  return (
    <section className="has-top-margin">
      <TextField
        label="Automation public url"
        variant="filled"
        placeholder="Automation public url"
        disabled
        value={currentUrl}
        style={{ width: '100%' }}
      />
      <CopyToClipboard text={currentUrl}>
        <FileCopyIcon
          style={{
            position: 'absolute',
            transform: 'translate(-26px, 7px)',
            height: '18px',
            cursor: 'pointer',
          }}
        />
      </CopyToClipboard>
      <p style={{ paddingBottom: '64px', color: '#8A8886' }}>
        The public URL of the automation for use with external webhooks
      </p>
    </section>
  );
};
