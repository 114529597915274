import * as React from 'react';
import { useSnackbar, VariantType } from 'notistack';

import { generateId } from 'utils/generateId';

export const CLEAR_TIMOUT = 10000;

type ShowFlagOptions = {
  title: string;
  description?: string;
  variant?: VariantType;
};

type UseFlagType = {
  showFlag: (options: ShowFlagOptions) => void;
};

export const useFlag = (): UseFlagType => {
  const flagRef = React.useRef<Maybe<NodeJS.Timeout>>(undefined);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showFlag = React.useCallback(
    ({ title, description, variant = 'info' }: ShowFlagOptions) => {
      const key = generateId();
      enqueueSnackbar(
        <div>
          <p>{title}</p>
          {description && <p style={{ whiteSpace: 'pre-line' }}>{description}</p>}
        </div>,
        { key, variant },
      );

      flagRef.current = setTimeout(() => {
        closeSnackbar(key);
      }, CLEAR_TIMOUT);
    },
    [enqueueSnackbar, closeSnackbar],
  );

  React.useEffect(
    () => () => {
      if (flagRef.current) {
        clearTimeout(flagRef.current);
      }
    },
    [],
  );

  return {
    showFlag,
  };
};
