import React, { FC, memo, useCallback, useMemo, useState } from 'react';

import Form from '@rjsf/material-ui';
import type { IChangeEvent } from '@rjsf/core';

import { uiSchema, Schema } from 'schemas';
import { useSchema } from 'hooks';
import { useAppDispatch, useTypedSelector } from 'store';

import { selectParentNodeId, selectWorkflowElementById } from '../../workflowSelectors';
import { updateElementParameters } from '../../workflowSlice';
import { useProcessorsWarnings } from '../../hooks/useProcessorsWarnings';
import { WarningListWithTitle } from '../../components/WarningList';
import { HubspotControlPanel } from './Hubspot/HubspotControlPanel';
import { ProcessorWarning } from '../../components/utils/validateProcessorField';

type GeneralEditProcessorParamsViewType = {
  processorId: string;
  processorType: ProcessorTypes;
};

export const GeneralEditProcessorParamsView: FC<GeneralEditProcessorParamsViewType> = memo(
  ({ processorId, processorType }) => {
    const dispatch = useAppDispatch();
    const originWarnings = useProcessorsWarnings(processorId);
    const [localWarnings, setLocalWarnings] = useState<ProcessorWarning[]>([]);
    const parentId = useTypedSelector(selectParentNodeId(processorId));
    const processorData: WorkflowElementData = useTypedSelector(
      selectWorkflowElementById(processorId),
    );

    const schema: Schema = useSchema({
      parentId,
      processorType,
      datasetId: processorData.parameters?.dataset_uuid,
      customComponentId: processorData.parameters?.custom_processor_uuid,
    });

    const onChange = useCallback(
      (data: IChangeEvent<Record<string, unknown>>) => {
        if (schema.$id !== data.schema.$id) {
          return;
        }

        dispatch(
          updateElementParameters({
            nodeId: processorId,
            parameters: data.formData,
          }),
        );
      },
      [dispatch, schema.$id, processorId],
    );

    const setWarnings = useCallback((ws: ProcessorWarning[]) => {
      setLocalWarnings(ws);
    }, []);

    const warnings = useMemo(
      () => [...localWarnings, ...originWarnings],
      [localWarnings, originWarnings],
    );

    if (processorType === 'End') {
      return (
        <p className="has-top-margin has-align-center">
          The End professor does not support any configurable data.
        </p>
      );
    }

    return (
      <>
        <WarningListWithTitle warnings={warnings} showProcessorName={false} disablePadding />
        <Form
          className="application-form"
          schema={schema}
          onChange={onChange}
          uiSchema={uiSchema}
          formData={processorData.parameters}
        >
          <></>
        </Form>
        {processorType === 'Hubspot' && (
          <HubspotControlPanel
            processorId={processorId}
            method={processorData.parameters.http_method}
            apikey={processorData.parameters.apikey_uuid}
            url={processorData.parameters.url}
            setWarnings={setWarnings}
          />
        )}
      </>
    );
  },
);
