import React, { FC, useMemo } from 'react';
import { useStoreState } from 'react-flow-renderer';

import { PlayArrowOutlined as PlayArrowOutlinedIcon } from '@material-ui/icons';

import { Button } from './AutomationHeader.style';
import { selectReactFlowNodes } from '../../workflowSelectors';
import { validateProcessorFields, WarningTypes } from '../utils/validateProcessorField';

type DeployButtonType = {
  isSaved: boolean;
  onDeploy: () => void;
};

export const DeployButton: FC<DeployButtonType> = React.memo(({ isSaved, onDeploy }) => {
  const nodes = useStoreState(selectReactFlowNodes);
  const isWorkflowValid = useMemo(() => {
    const warnings = validateProcessorFields(nodes);

    return warnings.length === 0 || (warnings[0] && warnings[0].type !== WarningTypes.ERROR);
  }, [nodes]);

  return (
    <Button key="deploy" onClick={onDeploy} disabled={!isWorkflowValid || isSaved}>
      <PlayArrowOutlinedIcon />
    </Button>
  );
});
