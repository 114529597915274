import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDebounce, useTable, useLogsTableContent } from 'hooks';
import { getTableSettingsInitialValue } from 'types/table';

import { TabContent } from 'components/Tabs';
import { defaultTableParams } from 'constants/table';
import { CommonTable } from 'components/Table/CommonTable';
import { Message } from 'components/Message';
import { LogsLevelSelector } from 'components/LogsLevelSelector';
import { SearchInput, ControlWrapper } from 'components/common';

import { fetchAutomationLogs } from '../../automationApi';
import type { AutomationEditParams } from '../../types';

export const AutomationLogs: React.FC = () => {
  const { id: automationId } = useParams<AutomationEditParams>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [level, setLevel] = useState<LogsLevel>('all');
  const [logs, setLogs] = useState<LogApiModel[]>([]);
  const { tableParams, onSizeChange, onSearchChange, onCountChange, onPageChange, setTableParams } =
    useTable();

  const { search, page, size } = tableParams;

  const tableContent = useLogsTableContent(logs);
  const debouncedSearch = useDebounce(search, 300);

  const onLevelChange = useCallback(
    (value) => {
      setLevel(value);
      onPageChange(defaultTableParams.page);
      if (value === 'all') {
        setTableParams(getTableSettingsInitialValue());
      }
    },
    [setLevel, onPageChange, setTableParams],
  );

  const getAutomationLogs = () => {
    setLoading(true);

    fetchAutomationLogs({ automationId, tableParams, level }).then(({ results, count }) => {
      onCountChange(count);
      setLogs(results);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    getAutomationLogs();
  }, [debouncedSearch, page, level, size]);

  return (
    <TabContent>
      <ControlWrapper>
        <SearchInput placeholder="Search" onChange={onSearchChange} value={tableParams.search} />
        <LogsLevelSelector level={level} onChange={onLevelChange} />
      </ControlWrapper>
      <CommonTable
        content={tableContent}
        totalItems={tableParams.count}
        size={tableParams.size}
        page={tableParams.page}
        goToPage={onPageChange}
        onSizeChange={onSizeChange}
        isPagination
      />
      <Message loading={isLoading} count={tableParams.count} search={tableParams.search || level} />
    </TabContent>
  );
};
