import { PayloadAction } from '@reduxjs/toolkit';

import { defaultTableParams, OrderDirection } from '../constants/table';

export type TableSettingsType = {
  page: number;
  count: number;
  size: number;
  orderBy: string;
  order: OrderDirection;
  loading: boolean;
  error: boolean;
  search: string;
};

const tableSettingsInitialValue: TableSettingsType = {
  page: defaultTableParams.page,
  count: 0,
  size: defaultTableParams.size,
  orderBy: '',
  loading: false,
  error: false,
  search: '',
  order: defaultTableParams.order,
};

export const getTableSettingsInitialValue = (): TableSettingsType => {
  return { ...tableSettingsInitialValue };
};

export const createTableSettingsReducers = <T extends { tableSettings: TableSettingsType }>(
  initialState: T,
) => {
  return {
    goToSelectedPage(state: T, action: PayloadAction<number>) {
      state.tableSettings.page = action.payload;
    },
    updateSize(state: T, action: PayloadAction<number>) {
      state.tableSettings.size = action.payload;
      state.tableSettings.page = initialState.tableSettings.page;
    },
    changeSorting(state: T, action: PayloadAction<string>) {
      const columnName = action.payload;
      const isAsc =
        state.tableSettings.orderBy === columnName &&
        state.tableSettings.order === defaultTableParams.order;
      state.tableSettings.order = isAsc ? OrderDirection.DESC : OrderDirection.ASC;
      state.tableSettings.orderBy = columnName;
    },
    updateSearch(state: T, action: PayloadAction<string>) {
      state.tableSettings.search = action.payload;
    },
  };
};
