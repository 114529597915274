import { RootState } from 'store';
import { Schema } from 'schemas/types';

export const selectDataset = (state: RootState): Maybe<IndexApiModel> => state.datasets?.dataset;

export const selectDatasetPropertyTypes = (state: RootState): DatasetPropertyTypeType[] =>
  state.datasets.types;

export const selectDatasetPropertySchemaByType = (state: RootState, type: string): Schema => {
  return {
    type: 'object',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    properties:
      state.datasets.types.find((item: DatasetPropertyTypeType) => item.type === type)?.schema ||
      {},
  };
};
