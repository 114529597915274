import React, { FC, useState, useCallback } from 'react';
import { defineMessages } from 'react-intl';
import Form from '@rjsf/material-ui';

import { useAppDispatch } from 'store';
import { uiSchema } from 'schemas';

import { CredentialsModalTypes } from 'modules/modals/constants';
import { useModal } from 'modules/modals';
import { Modal } from 'components/modal';

import type { CreateApiKeyCredentialType } from '../apiKeyCredenetialActions';
import { createApiKeyCredential } from '../apiKeyCredenetialActions';
import { apiKeyCredentialsSchema } from './schemas';

const messages = defineMessages({
  title: {
    id: 'automation.create-api-key-credential.title',
    defaultMessage: 'Create Api Key',
  },
  create: {
    id: 'automation.create-api-key-credential.create',
    defaultMessage: 'Create',
  },
  cancel: {
    id: 'automation.create-api-key-credential.cancel',
    defaultMessage: 'Close',
  },
});

const initialState: CreateApiKeyCredentialType = {
  name: '',
  apikey: '',
};

export const CreateApiKeyCredentialModal: FC = () => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<CreateApiKeyCredentialType>(initialState);

  const onClose = React.useCallback(() => {
    if (isLoading) return;

    closeModal(CredentialsModalTypes.CreateApiKeyCredentialModal);
  }, [isLoading, closeModal]);

  const onSubmit = React.useCallback(async () => {
    try {
      if (isLoading) return;

      setIsLoading(true);
      dispatch(createApiKeyCredential(formData));

      closeModal(CredentialsModalTypes.CreateApiKeyCredentialModal);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }, [setIsLoading, isLoading, dispatch, closeModal, formData]);

  const onChange = useCallback(
    (data: { formData: CreateApiKeyCredentialType }) => {
      setFormData(data.formData);
    },
    [setFormData],
  );

  return (
    <Modal
      title={messages.title}
      cancelMessage={messages.cancel}
      submitMessage={messages.create}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Form
        uiSchema={uiSchema}
        schema={apiKeyCredentialsSchema}
        onChange={onChange}
        formData={formData}
      >
        <></>
      </Form>
    </Modal>
  );
};
