const findParentNodeIds = (elements: WorkflowElement[], processorId: string): string[] => {
  return elements.reduce((prev: string[], element: WorkflowElement) => {
    return 'source' in element && element.target === processorId
      ? [element.source, ...findParentNodeIds(elements, element.source)]
      : prev;
  }, []);
};

export const getParentsNodes = (processorId: string, graph: WorkflowElement[]): WorkflowNode[] => {
  const parentIds: string[] = [processorId, ...findParentNodeIds(graph, processorId)];

  return graph.filter((item: WorkflowElement): boolean =>
    parentIds.includes(item.id),
  ) as WorkflowNode[];
};
