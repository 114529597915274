import { Schema } from 'schemas/types';

export const createCustomProcessorSchema: Schema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      title: 'Name',
    },
    description: {
      type: 'string',
      title: 'Description',
    },
    docker_image: {
      type: 'string',
      title: 'Docker Image',
    },
    docker_tag: {
      type: 'string',
      title: 'Docker tag',
    },
    command: {
      type: 'string',
      title: 'Command',
    },
    uri_path: {
      type: 'string',
      title: 'URI',
    },
    service_port: {
      type: 'number',
      title: 'Service port',
    },
    replica_count: {
      type: 'number',
      title: 'Replicate_count',
    },
    schema: {
      type: 'string',
      title: 'Schema',
    },
    data_model: {
      type: 'string',
      title: 'Data model',
    },
    docker_credentials: {
      type: 'string',
      title: 'Docker Credentials',
      enum: [],
      enumNames: [],
    },
  },
};
