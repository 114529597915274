import * as React from 'react';

import { DatasetsModalTypes } from 'modules/modals';

import { ModalStoreType } from '../../modals/modalsSlice';
import { CreateDatasetsModal } from './CreateDatasetsModal/CreateDatasetsModal';
import { DeleteDatasetModal } from './DeleteDatasetModal';
import {
  DatasetSettingsModal,
  DatasetSettingsModalType,
} from './DatasetSettingsModal/DatasetSettingsModal';

export const getDatasetsModalByType = (item: ModalStoreType): React.ReactNode | null => {
  switch (item.type) {
    case DatasetsModalTypes.CreateDatasetsModal:
      return <CreateDatasetsModal key="create-datasets-modal" />;
    case DatasetsModalTypes.DeleteDatasetModal:
      return (
        <DeleteDatasetModal
          key="key-datasets-modal"
          id={item?.data?.id as string}
          automationCount={item?.data?.automationCount as number}
        />
      );
    case DatasetsModalTypes.EditDatasetsModal:
      return (
        <DatasetSettingsModal
          key="create-datasets-modal"
          {...(item.data as DatasetSettingsModalType)}
        />
      );
    default:
      return null;
  }
};
