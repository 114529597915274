import type { PersistConfig } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

export const createPersistConfig = <T>(
  key: string,
  whitelist = ['tableSettings'],
): PersistConfig<T> => ({
  key,
  storage,
  whitelist,
});
