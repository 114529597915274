import { isNode, XYPosition } from 'react-flow-renderer';

import { PayloadAction } from '@reduxjs/toolkit';

import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import type { WorkflowState } from './workflowSlice';
import { getLayoutedElements } from './components/utils';

export const removeEdgeReducer = (state: WorkflowState, { payload }: PayloadAction<string>) => {
  state.graph = state.graph.filter((el) => payload !== el.id);

  state.isSaved = isEqual(state.graph, state.snapshotGraph);
};

export const addNodesReducer = (
  state: WorkflowState,
  { payload }: PayloadAction<WorkflowElement[]>,
) => {
  state.graph = state.graph.concat(payload);

  state.isSaved = isEqual(state.graph, state.snapshotGraph);
};

export const updateElementParametersReducer = (
  state: WorkflowState,
  { payload }: PayloadAction<{ nodeId: string; parameters: Record<string, unknown> }>,
) => {
  state.graph = state.graph.map((item: WorkflowElement): WorkflowElement => {
    if (item.id === payload.nodeId && item.data && 'parameters' in item.data) {
      item.data.parameters = payload.parameters;
    }

    return item;
  });

  state.isSaved = isEqual(state.graph, state.snapshotGraph);
};

export const removeNodeReducer = (
  state: WorkflowState,
  { payload: nodeId }: PayloadAction<string>,
) => {
  state.graph = state.graph.filter((item) => {
    if ('source' in item) {
      return item?.source !== nodeId && item?.target !== nodeId;
    }

    return item.id !== nodeId;
  });

  state.isSaved = isEqual(state.graph, state.snapshotGraph);
};

export const updateElementPositionReducer = (
  state: WorkflowState,
  { payload }: PayloadAction<{ nodeId: string; position: XYPosition }>,
) => {
  state.graph = state.graph.map((item: WorkflowElement) =>
    item.id === payload.nodeId
      ? {
          ...item,
          position: payload.position,
        }
      : item,
  );

  state.isSaved = isEqual(state.graph, state.snapshotGraph);
};

export const rearrangeElementsReducer = (
  state: WorkflowState,
  { payload }: PayloadAction<WorkflowElement[]>,
) => {
  const elements = getLayoutedElements(payload);

  state.graph = state.graph.map((item: WorkflowElement) => {
    const element: Maybe<WorkflowElement> = get(elements, [item.id]);

    if (element && isNode(element)) {
      return {
        ...item,
        position: element.position,
      };
    }

    return item;
  });

  state.isSaved = isEqual(state.graph, state.snapshotGraph);
};
