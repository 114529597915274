import { decode, encode } from 'js-base64';

export const convertMapperToObject = (mapper: DatasetWriterMapperItem[]): Record<string, string> =>
  mapper.reduce((acc: Record<string, string>, item: DatasetWriterMapperItem): Record<
    string,
    string
  > => {
    return {
      ...acc,
      [item.datamodel_property]: item.dataset_property,
    };
  }, {});

export const convertObjectMapperToArray = (
  mapper: Record<string, string>,
): DatasetWriterMapperItem[] =>
  Object.keys(mapper).map((key) => ({
    datamodel_property: key,
    dataset_property: mapper[key],
  }));

export const encodeParameters = (parameters: WorkflowElementData['parameters']): string => {
  return encode(JSON.stringify(parameters));
};

export const decodeParameters = (encodedParameters: string) => {
  if (!encodedParameters) {
    return {};
  }

  const parameters = decode(encodedParameters);
  if (!parameters) {
    return {};
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return JSON.parse(parameters);
};
