export const If: ProcessorApiModel = {
  name: 'IF',
  processor_type: 'If',
  description: 'IF',
  type: 'processor',
  tags: ['Logical'],
  data_model: {},
  displayText: 'Test :attribute :function :value',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/If-icon.svg',
  connections: {
    inputPort: true,
    outputPort: true,
  },
};
