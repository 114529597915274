import React, { FC, useEffect } from 'react';

import { useProjectId } from 'hooks';
import { useAppDispatch, useTypedSelector } from 'store';
import { CommonTable } from 'components/Table/CommonTable';
import { Message } from 'components/Message';

import { fetchApiKeysAction } from '../../apiKeyCredenetialActions';
import { useApiKeysTableContent } from './useApiKeysTableContent';
import { changeSorting, goToSelectedPage, updateSize } from '../../credentialsSlice';

export const ApiKeysTable: FC = () => {
  const { projectId } = useProjectId();
  const dispatch = useAppDispatch();

  const { page, count, size, order, orderBy, search, loading } = useTypedSelector(
    (store) => store.credentials.tableSettings,
  );

  const credentials: ApiKeyApiModel[] = useTypedSelector(
    (store): ApiKeyApiModel[] => store.credentials.apiKeys,
  );

  const tableContent = useApiKeysTableContent(credentials);

  const onGoToPageHandler = React.useCallback(
    (targetPage: number) => {
      dispatch(goToSelectedPage(targetPage));
    },
    [dispatch],
  );

  const onSizeChangeHandler = React.useCallback(
    (targetSize: number) => {
      dispatch(updateSize(targetSize));
    },
    [dispatch],
  );

  const onClickSortHandler = React.useCallback(
    (columnName: string) => {
      dispatch(changeSorting(columnName));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchApiKeysAction({ page, size, order, orderBy, search }));
  }, [dispatch, page, size, order, orderBy, search, projectId]);

  return (
    <>
      <CommonTable
        content={tableContent}
        page={page}
        totalItems={count}
        size={size}
        goToPage={onGoToPageHandler}
        onSizeChange={onSizeChangeHandler}
        onClickSort={onClickSortHandler}
        order={order}
        orderBy={orderBy}
        isPagination
      />
      <Message loading={loading} search={search} count={count} entityName="api keys" />
    </>
  );
};
