import { CustomComponentApiModel } from 'modules/custom-components/types';

export const getCustomComponentDataModelById = (
  components: CustomComponentApiModel[],
  id: string,
): DataModel => {
  const customComponent = components.find((component) => component.pk === id);

  if (customComponent?.data_model) {
    return JSON.parse(customComponent.data_model) as DataModel;
  }

  return {};
};
