import React, { FC, useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';

import { useNavigation } from 'hooks';
import { WebsiteRoutes } from 'constants/routes';

const messages = defineMessages({
  description: {
    id: 'custom-components.credentials-message.description',
    defaultMessage: 'To connect to the custom processor, we use docker credentials.',
  },
  buttonText: {
    id: 'custom-components.credentials-message.button-text',
    defaultMessage: 'Click to see full list',
  },
});

export const ContainerCredentialsMessage = styled.section`
  border-radius: 4px;
  margin-top: 16px;
  border: 1px solid #e1dfdd;
  padding: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CredentialsMessage: FC = () => {
  const navigate = useNavigation();

  const onClick = useCallback(() => {
    navigate(WebsiteRoutes.Credentials);
  }, [navigate]);

  return (
    <ContainerCredentialsMessage>
      <FormattedMessage tagName="p" {...messages.description} />
      <Button variant="contained" size="small" onClick={onClick}>
        <FormattedMessage {...messages.buttonText} />
      </Button>
    </ContainerCredentialsMessage>
  );
};
