import React from 'react';

import { Container, withStyles } from '@material-ui/core';

import type { TableContent, TableRowParams } from 'components/Table';
import { ConnectionsModalTypes } from 'modules/modals';
import { TableActionsCell } from 'modules/connections/components/ConnectionsTable/ActionsCell';

import { TableNameCell } from './NameCell';

const CommonContainer = withStyles({
  root: {
    paddingLeft: '0',
  },
})(Container);

export interface ConnectionsContent extends TableRowParams {
  id: string;
  name: JSX.Element;
  type: JSX.Element;
  automation_count: JSX.Element;
  description: JSX.Element;
  status: JSX.Element;
  actions: JSX.Element;
}

const connectionsTableContent: TableContent<ConnectionsContent> = {
  header: {
    name: {
      width: '10%',
      name: 'Connection name',
      sortable: true,
    },
    type: {
      width: '10%',
      name: 'Type',
      sortable: true,
    },
    automation_count: {
      width: '10%',
      name: 'Automations',
      sortable: true,
    },
    description: {
      width: '10%',
      name: 'Description',
      sortable: false,
    },
    status: {
      width: '10%',
      name: 'Status',
      sortable: false,
    },
    actions: {
      width: '10%',
      name: '',
      sortable: false,
    },
  },
  rows: [],
};

export const useConnectionsTableContent = (
  items: ConnectionApiModel[],
): TableContent<ConnectionsContent> => {
  connectionsTableContent.rows = items.map((connection) => ({
    id: connection.pk,
    name: (
      <TableNameCell
        title={connection.name}
        pk={connection.pk}
        modalType={ConnectionsModalTypes.EditConnectionModal}
      />
    ),
    type: (
      <CommonContainer style={{ textTransform: 'capitalize' }}>
        {connection.source_type}
      </CommonContainer>
    ),
    automation_count: (
      <Container style={{ textTransform: 'capitalize', color: '#0078d4' }}>
        {connection.automation_count} Active
      </Container>
    ),
    description: <CommonContainer>{connection.description}</CommonContainer>,
    status: <CommonContainer>{connection.status}</CommonContainer>,
    actions: <TableActionsCell pk={connection.pk} automationsCount={connection.automation_count} />,
  }));

  return connectionsTableContent;
};
