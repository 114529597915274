import * as React from 'react';

import { Checkbox, FormControlLabel } from '@material-ui/core';

type FormControlCheckboxType = {
  name: string;
  label?: string;
  value: boolean;
  onChange: (name: string, value: boolean) => void;
};

export const FormControlCheckbox: React.FC<FormControlCheckboxType> = React.memo(
  ({ name, value, onChange, label }) => {
    const onChangeHandler = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(name, event.target.checked);
      },
      [onChange, name],
    );

    return (
      <FormControlLabel
        key={name}
        control={<Checkbox checked={value} onChange={onChangeHandler} name={name} />}
        label={label}
      />
    );
  },
);
