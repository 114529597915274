import * as React from 'react';

import { useAppDispatch, useTypedSelector } from 'store';

import { DeployStatus } from './DeployStatus';
import { updateDeployStatus } from '../../workflowSlice';

export const DeployStatusContainer = React.memo(() => {
  const dispatch = useAppDispatch();
  const deployStatus = useTypedSelector(({ workflow }) => workflow.deployStatus);

  React.useEffect(() => {
    return () => {
      dispatch(updateDeployStatus({ deployStatus: undefined }));
    };
  }, [dispatch]);

  const onClose = React.useCallback(() => {
    dispatch(updateDeployStatus({ deployStatus: undefined }));
  }, [dispatch]);

  if (!deployStatus || deployStatus === 'deploying') {
    return null;
  }

  return <DeployStatus deployStatus={deployStatus} onClose={onClose} />;
});
