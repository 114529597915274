import * as Yup from 'yup';

import { MemberRoles } from 'constants/roles';

export interface MemberSelectorValuesType {
  userId: string;
  group: number;
}

export const initialValues: MemberSelectorValuesType = {
  userId: '',
  group: MemberRoles.VIEW,
};

export const validationSchema = () =>
  Yup.object().shape({
    userId: Yup.string().required('User is required'),
    group: Yup.string().min(1, 'Should not be empty').required('Role is required'),
  });
