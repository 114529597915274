import React from 'react';

import { Table as MuiTable, TableBody, TableContainer } from '@material-ui/core';

import { defaultTableParams, OrderDirection } from 'constants/table';

import { Pagination } from './Pagination';
import { TableRow } from './TableRow';
import { TableHeader } from './TableHeader';
import type { TableContent, TableRowParams } from './Table.types';

interface CommonTableProps {
  content: TableContent<TableRowParams>;
  isPagination?: boolean;
  onClickSort?: (property: string) => void;
  order?: OrderDirection;
  orderBy?: string;
  page?: number;
  totalItems?: number;
  size?: number;
  goToPage?: (page: number) => void;
  onSizeChange?: (size: number) => void;
}

export const CommonTable: React.FC<CommonTableProps> = ({
  content: { rows, header },
  isPagination = false,
  onClickSort = () => {},
  order = OrderDirection.ASC,
  orderBy = '',
  page = defaultTableParams.page,
  totalItems = 0,
  size = defaultTableParams.size,
  goToPage = () => {},
  onSizeChange = () => {},
}) => {
  return (
    <TableContainer>
      <MuiTable>
        <TableHeader header={header} orderBy={orderBy} order={order} onClickSort={onClickSort} />
        <TableBody>
          {rows.map((row: TableRowParams) => (
            <TableRow key={row.id as string} row={row} />
          ))}
        </TableBody>
        {isPagination && (
          <Pagination
            page={page}
            onPageClick={goToPage}
            size={size}
            totalItems={totalItems}
            onChangeSize={onSizeChange}
          />
        )}
      </MuiTable>
    </TableContainer>
  );
};
