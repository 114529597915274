import React, { useCallback, ChangeEvent } from 'react';

import TextFieldMui from '@material-ui/core/TextField';

import { ParamBox } from './ParamInput.style';

type ParamFieldProps = {
  name: string;
  value: string;
  placeholder?: string;
  isDisabled?: boolean;
  onChange: (name: string, value: string) => void;
  error?: boolean;
};

export const ParamField = (props: ParamFieldProps) => {
  const { name, value, onChange, placeholder, isDisabled = false, error = false } = props;

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(name, event.target.value);
    },
    [onChange, name],
  );

  if (isDisabled) {
    return <ParamBox>{value}</ParamBox>;
  }

  return (
    <TextFieldMui
      id={name}
      size="small"
      error={error}
      disabled={isDisabled}
      variant="outlined"
      placeholder={placeholder}
      value={value}
      onChange={onChangeHandler}
    />
  );
};
