import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';
import { buildRequestParams } from 'utils/buildRequestParams';

import { ConnectionTableFilterType } from '../types';

type ConnectionRequestPayload = {
  name: string;
  description?: string;
  source_type: 'email' | 'site' | 'gmail' | 'linkedin';
  datasource_model?: 'scraper' | 'gmail' | 'linkedin';
};

export const ConnectionApi = {
  createConnection: async (payload: ConnectionRequestPayload): Promise<ConnectionApiModel> => {
    const res = await requestApi<ConnectionApiModel>({
      method: 'POST',
      service: Services.Connection,
      submethod: `source/`,
      data: payload,
    });

    return res.data;
  },
  fetchConnection: async ({ pk }: { pk: string }): Promise<BaseConnectionApiModel> => {
    const response = await requestApi<BaseConnectionApiModel>({
      service: Services.Connection,
      submethod: `source/${pk}/`,
    });

    return response.data;
  },
  fetchConnections: async (
    tableParams: TableFilterParams & { filter?: ConnectionTableFilterType },
  ) => {
    const params = buildRequestParams(tableParams);

    if (tableParams.filter) {
      params.status = tableParams.filter;
    }

    const response = await requestApi<PaginatedConnectionResponse<ConnectionApiModel>>({
      service: Services.Connection,
      submethod: `source/bulk/`,
      params,
    });

    return response.data;
  },
  updateConnection: async (data: ConnectionApiModel): Promise<ConnectionApiModel> => {
    const res = await requestApi<ConnectionApiModel>({
      service: Services.Connection,
      submethod: `source/${data.pk}/`,
      method: 'PUT',
      data,
    });

    return res.data;
  },
  replaceConnection: async ({
    source,
    destination,
  }: {
    source: string;
    destination: string;
  }): Promise<number> => {
    const response = await requestApi({
      method: 'PUT',
      service: Services.Connection,
      submethod: 'source/replace/',
      data: {
        source,
        destination,
      },
    });

    return response.status;
  },
} as const;
