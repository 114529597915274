import * as React from 'react';

import { useTypedSelector } from 'store';

import { selectAutomationProcessorBulletins } from '../../../workflowSelectors';
import './NodeHeader.styles.scss';
import { AutomationBulletinsTooltip } from '../AutomationBulletinsTooltip';

const DEFAULT_ICON =
  'https://raw.githubusercontent.com/ValentynTishchenko97/datapost-static/main/processor-icons/gmail-icon.png';

type NodeHeaderType = {
  processorType: ProcessorTypes;
  label?: string;
  iconUrl?: string;
  entityId?: string;
};

export const NodeHeader: React.FC<NodeHeaderType> = ({
  label,
  processorType,
  entityId,
  iconUrl = DEFAULT_ICON,
}) => {
  const bulletins = useTypedSelector(selectAutomationProcessorBulletins(entityId || ''));

  return (
    <div className="node-header">
      <div className="node-header__icon-wrapper">
        <img className="node-type-icon" src={iconUrl} alt="Gmail icon" />
      </div>
      <div className="node-header__title">
        <p className="title__processor-type">{processorType}</p>
        {label && <p className="title__label">{label}</p>}
      </div>
      {bulletins.length !== 0 && (
        <div style={{ marginLeft: 'auto' }}>
          <AutomationBulletinsTooltip bulletins={bulletins} />
        </div>
      )}
    </div>
  );
};
