import withStyles from '@material-ui/core/styles/withStyles';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';

export const Tabs = MuiTabs;

export const Tab = withStyles({
  root: {
    minWidth: 0,
    textTransform: 'none',
    padding: '10px 0',
    marginRight: '24px',
    fontSize: '16px',
  },
})(MuiTab);
