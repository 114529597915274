import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

// https://github.com/tannerlinsley/react-charts
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Chart } from 'react-charts';

import type { ConnectionActivitiesChartType } from '../../../types';

const messages = defineMessages({
  rps: {
    id: 'connections.edit-connection-modal.rps-title',
    defaultMessage: 'RPS',
  },
  duration: {
    id: 'connections.edit-connection-modal.duration-title',
    defaultMessage: 'Duration',
  },
});

const styles = {
  width: '650px',
  height: '210px',
};

const axes = [
  {
    primary: true,
    position: 'bottom',
    type: 'time',
    show: true,
  },
  { position: 'left', type: 'linear', show: true },
];

type ActivitiesChartProps = {
  activities: ConnectionActivitiesChartType;
};

export const ActivitiesChart: React.FC<ActivitiesChartProps> = ({ activities }) => {
  const chartData = React.useMemo(
    () => ({
      rps: [
        {
          label: 'Success',
          specialLabel: 'Success',
          data: activities.success,
        },
        {
          label: 'Failure',
          specialLabel: 'Failure',
          data: activities.failure,
        },
      ],
      duration: [
        {
          label: 'Duration',
          data: activities.duration,
        },
      ],
    }),
    [activities],
  );

  return (
    <section className="has-top-margin">
      <div style={styles}>
        <FormattedMessage {...messages.rps} tagName="p" />
        <Chart label="RPS" data={chartData.rps} axes={axes} tooltip />
      </div>
      <div className="has-top-margin" style={styles}>
        <FormattedMessage {...messages.duration} tagName="p" />
        <Chart label="Duration" data={chartData.duration} axes={axes} tooltip />
      </div>
    </section>
  );
};
