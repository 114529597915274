import React, { useCallback, ChangeEvent, SyntheticEvent } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import { IFilterIcon, InputSearch, SearchIcon } from './ProcessorListMenu.style';
import { ModalTabs } from './constants';

type ProcessorListMenuHeaderProps = {
  searchValue: string;
  activeTab: ModalTabs;
  onSearch: (value: string) => void;
  onTabChange: (value: ModalTabs) => void;
};

export const ProcessorListMenuHeader = ({
  searchValue,
  onSearch,
  activeTab,
  onTabChange,
}: ProcessorListMenuHeaderProps) => {
  const onSearchHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onSearch(event.target.value);
    },
    [onSearch],
  );

  const onClick = useCallback(
    (e: SyntheticEvent<HTMLElement>) => {
      const { tab } = e.currentTarget.dataset;

      onTabChange(tab as ModalTabs);
    },
    [onTabChange],
  );

  return (
    <header id="alert-dialog-slide-title">
      <div className="search-wrapper">
        <InputSearch
          placeholder="Type to search…"
          inputProps={{ 'aria-label': 'search' }}
          onInputCapture={onSearchHandler}
          value={searchValue}
        />
        <SearchIcon />
      </div>
      <div className="filter-wrapper">
        <Tooltip key="processors" title="Processors">
          <IFilterIcon
            data-tab={ModalTabs.processor}
            active={activeTab === ModalTabs.processor}
            className="icon-all"
            onClick={onClick}
          />
        </Tooltip>
        <Tooltip key="connections" title="Connections">
          <IFilterIcon
            data-tab={ModalTabs.connection}
            active={activeTab === ModalTabs.connection}
            className="icon-fluid"
            onClick={onClick}
          />
        </Tooltip>
        <Tooltip key="user-components" title="User components">
          <IFilterIcon
            data-tab={ModalTabs.user}
            active={activeTab === ModalTabs.user}
            className="icon-apps"
            onClick={onClick}
          />
        </Tooltip>
        <Tooltip key="favorite" title="Favorite">
          <IFilterIcon
            data-tab={ModalTabs.favorite}
            active={activeTab === ModalTabs.favorite}
            className="icon-favorite"
            onClick={onClick}
          />
        </Tooltip>
      </div>
    </header>
  );
};
