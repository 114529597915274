import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  TableSettingsType,
  getTableSettingsInitialValue,
  createTableSettingsReducers,
} from 'types/table';

import { updateConnection, fetchConnectionList } from './connectionActions';
import { ConnectionTableFilterType } from './types';

export type ConnectionStateType = {
  connections: ConnectionApiModel[];
  info: ConnectionsInfo;
  filter: ConnectionTableFilterType;
  lastUpdated: string;
  tableSettings: TableSettingsType;
};

const emptyConnectionsInfo: ConnectionsInfo = {
  active: 0,
  count: 0,
  error: 0,
  inactive: 0,
};

export const initialState: ConnectionStateType = {
  connections: [],
  info: emptyConnectionsInfo,
  filter: undefined,
  lastUpdated: new Date().toISOString(),
  tableSettings: getTableSettingsInitialValue(),
};

const connectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    ...createTableSettingsReducers<ConnectionStateType>(initialState),
    updateConnectionsTableFilter(
      state,
      { payload: { filterType } }: PayloadAction<{ filterType: ConnectionTableFilterType }>,
    ) {
      state.filter = state.filter === filterType ? undefined : filterType;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchConnectionList.fulfilled,
      (
        state,
        {
          payload: {
            results: { connection, info },
            count,
          },
        },
      ) => {
        state.connections = connection;
        state.info = info;
        state.tableSettings.count = count;
      },
    );
    builder.addCase(updateConnection.fulfilled, (state, { payload }) => {
      state.connections = state.connections.map((item) =>
        item.pk === payload.pk
          ? { ...item, description: payload.description, name: payload.name }
          : item,
      );
    });
  },
});

export const {
  changeSorting,
  goToSelectedPage,
  updateSize,
  updateSearch,
  updateConnectionsTableFilter,
} = connectionsSlice.actions;
export default connectionsSlice.reducer;
