export const End: ProcessorApiModel = {
  name: 'End',
  processor_type: 'End',
  description: 'End',
  type: 'end',
  tags: ['Logical'],
  data_model: {},
  displayText: 'End of flow.',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/End-icon.png',
  connections: {
    inputPort: true,
    outputPort: false,
  },
};
