import React, { useCallback, useEffect } from 'react';

import { useAppDispatch, useTypedSelector } from 'store';
import { useProjectId } from 'hooks';

import { Message } from 'components/Message';
import { CommonTable } from 'components/Table/CommonTable';

import { fetchCustomComponents } from '../../customComponentsActions';
import { changeSorting, goToSelectedPage, updateSize } from '../../customComponentsSlice';

import { useCustomComponentsContent } from './CustomComponentsTable.helper';

export const CustomComponentsTable = () => {
  const { projectId } = useProjectId();
  const dispatch = useAppDispatch();
  const components = useTypedSelector((state) => state.customComponents.components);
  const { page, count, size, order, orderBy, search, loading } = useTypedSelector(
    (state) => state.customComponents.tableSettings,
  );
  const contentTableContents = useCustomComponentsContent(components);

  const onGoToPageHandler = React.useCallback(
    (targetPage: number) => {
      dispatch(goToSelectedPage(targetPage));
    },
    [dispatch],
  );

  const onSizeChangeHandler = React.useCallback(
    (targetSize: number) => {
      dispatch(updateSize(targetSize));
    },
    [dispatch],
  );

  const onClickSortHandler = useCallback(
    (columnName: string) => {
      dispatch(changeSorting(columnName));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchCustomComponents({ page, size, order, orderBy, search }));
  }, [dispatch, projectId, page, size, order, orderBy, search]);

  return (
    <>
      <CommonTable
        content={contentTableContents}
        page={page}
        totalItems={count}
        size={size}
        goToPage={onGoToPageHandler}
        onSizeChange={onSizeChangeHandler}
        onClickSort={onClickSortHandler}
        order={order}
        orderBy={orderBy}
        isPagination
      />
      <Message loading={loading} search="" count={components.length} />
    </>
  );
};
