const EmailReaderDataModel: DataModel = {
  url: {
    type: 'string',
  },
  body: {
    type: 'string',
  },
  uuid: {
    type: 'string',
  },
  doc_uuid: {
    type: 'string',
  },
  mail_from: {
    type: 'string',
  },
  timestamp: {
    type: 'number',
  },
  title: {
    type: 'string',
  },
  subproject: {
    type: 'string',
  },
  datasource_owner_uuid: {
    type: 'string',
  },
  mime_subtype: {
    type: 'string',
  },
};

export const EmailReader: ProcessorApiModel = {
  name: 'Email Reader',
  processor_type: 'EmailReader',
  description: 'Email Reader',
  type: 'connection',
  tags: ['Email'],
  data_model: EmailReaderDataModel,
  displayText: 'Reading emails from :connection_uuid.',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/GmailReader-icon.svg',
  connections: {
    inputPort: false,
    outputPort: true,
  },
};
