import * as React from 'react';

import { Paper } from '@material-ui/core';

import './styles.scss';

import icon from './icon.png';

export enum Actions {
  NEW_AUTOMATION = 'newAutomation',
  NEW_CONNECTION = 'newConnection',
  NEW_DATA_MODEL = 'newDataModel',
}

type ActionType = {
  action: Actions;
  isActive?: boolean;
  children: React.ReactNode;
  onSelect: (action: Actions) => void;
};

export const Action: React.FC<ActionType> = ({ children, isActive = false, onSelect, action }) => {
  const onClick = React.useCallback(() => {
    onSelect(action);
  }, [onSelect, action]);

  return (
    <Paper elevation={isActive ? 5 : 1} className="new-project-action" onClick={onClick}>
      <p>
        <img className="action-icon" src={icon} alt="" />
      </p>
      <p>{children}</p>
    </Paper>
  );
};
