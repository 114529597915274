import * as React from 'react';
import { Link } from 'react-router-dom';

import { Apps as AppsIcon } from '@material-ui/icons';

import { WebsiteRoutes } from 'constants/routes';

import { DrawerButtonWrapper, DrawerToggleButton } from './HeaderHomeLink.style';

export const HeaderHomeLink = () => (
  <DrawerButtonWrapper>
    <Link to={WebsiteRoutes.Workspaces}>
      <DrawerToggleButton>
        <AppsIcon />
      </DrawerToggleButton>
    </Link>
  </DrawerButtonWrapper>
);
