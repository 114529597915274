import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import MenuItem from '@material-ui/core/MenuItem';

import { useAppDispatch } from 'store';
import { Modal } from 'components/modal';
import { Selector } from 'components/common';

import { ConnectionsModalTypes, useModal } from 'modules/modals';

import { updateConnector } from '../connectionActions';
import { ConnectorApi } from '../api/ConnectorApi';

const messages = defineMessages({
  title: {
    id: 'connections.connection-folder-edit-modal.title',
    defaultMessage: 'Folder Settings',
  },
  description: {
    id: 'connections.connection-folder-edit-modal.title',
    defaultMessage: 'You need to set initial value for connector folder.',
  },
  submitMessage: {
    id: 'connections.connection-folder-edit-modal.continue',
    defaultMessage: 'Update',
  },
  cancelMessage: {
    id: 'automation.delete-connection-modal.cancel',
    defaultMessage: 'Cancel',
  },
});

export type ConnectionFolderEditModalProps = {
  connectionId: string;
};

export const ConnectionFolderEditModal: React.FC<ConnectionFolderEditModalProps> = ({
  connectionId,
}) => {
  const { closeModal } = useModal();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [folders, setFolders] = React.useState<FolderApiModel[]>([]);
  const [connectorData, setConnectorData] = React.useState<Maybe<ConnectorApiModel>>(undefined);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const folder = event.target.value as string[];

      if (connectorData?.folder) {
        setConnectorData({
          ...connectorData,
          folder,
        });
      }
    },
    [connectorData, setConnectorData],
  );

  const onClose = React.useCallback(() => {
    if (isLoading) {
      return;
    }

    closeModal(ConnectionsModalTypes.ConnectionFolderEditModal);
  }, [isLoading, closeModal]);

  const onSubmit = React.useCallback(async () => {
    if (isLoading || !connectorData) {
      return;
    }

    setIsLoading(true);

    await dispatch(
      updateConnector({
        id: connectionId,
        connectorData,
      }),
    ).unwrap();

    closeModal(ConnectionsModalTypes.ConnectionFolderEditModal);
  }, [closeModal, dispatch, setIsLoading, isLoading, connectorData, connectionId]);

  React.useEffect(() => {
    ConnectorApi.fetchConnectorFolders(connectionId).then(setFolders);
    ConnectorApi.fetchConnector({ pk: connectionId }).then(setConnectorData);
  }, [connectionId, setFolders]);

  return (
    <Modal
      isLoading={isLoading}
      title={messages.title}
      submitMessage={messages.submitMessage}
      cancelMessage={messages.cancelMessage}
      onClose={onClose}
      onSubmit={onSubmit}
      height={300}
    >
      <FormattedMessage tagName="p" {...messages.description} />
      <div className="has-top-margin">
        <Selector
          multiple
          value={connectorData?.folder || []}
          variant="outlined"
          onChange={handleChange}
          style={{ width: 252, padding: 6 }}
        >
          {folders.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Selector>
      </div>
    </Modal>
  );
};
