import React from 'react';

import type { TableContent, TableRowParams } from 'components/Table';

import { CredentialActionCell } from '../CredentialActionCell';

export interface CredentialsContent extends TableRowParams {
  id: string;
  name: JSX.Element;
  server: JSX.Element;
  username: JSX.Element;
  actions: JSX.Element;
}

const credentialsTableContent: TableContent<CredentialsContent> = {
  header: {
    name: {
      width: '20%',
      name: 'Name',
      sortable: true,
    },
    server: {
      width: '20%',
      name: 'Server',
      sortable: true,
    },
    username: {
      width: '20%',
      name: 'Username',
      sortable: true,
    },
    actions: {
      name: '',
      sortable: false,
    },
  },
  rows: [],
};

export const useCredentialsTableContent = (items: DockerCredentialApiModel[]) => {
  credentialsTableContent.rows = items.map(({ pk, name, server, username }) => ({
    id: pk,
    name: <p>{name}</p>,
    server: <p>{server}</p>,
    username: <p>{username}</p>,
    actions: <CredentialActionCell credentialId={pk} credentialType="docker" />,
  }));

  return credentialsTableContent;
};
