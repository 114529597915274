import { createSlice } from '@reduxjs/toolkit';

import {
  createTableSettingsReducers,
  getTableSettingsInitialValue,
  TableSettingsType,
} from 'types/table';

import {
  fetchDockerCredentials,
  createDockerCredential,
  updateDockerCredential,
  deleteDockerCredential,
} from './credentialsActions';
import {
  fetchApiKeysAction,
  createApiKeyCredential,
  deleteApiKey,
  updateApiKey,
} from './apiKeyCredenetialActions';

type CredentialsSliceType = {
  apiKeys: ApiKeyApiModel[];
  dockerCredentials: DockerCredentialApiModel[];
  tableSettings: TableSettingsType;
};

const initialState: CredentialsSliceType = {
  apiKeys: [],
  dockerCredentials: [],
  tableSettings: getTableSettingsInitialValue(),
};

export const credentialsSlice = createSlice({
  name: 'credentialsSlice',
  initialState,
  reducers: {
    ...createTableSettingsReducers(initialState),
    reset: (): CredentialsSliceType => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDockerCredentials.fulfilled, (state, { payload }) => {
      state.dockerCredentials = payload.results;
      state.tableSettings.count = payload.count;
      state.tableSettings.loading = false;
    });
    builder.addCase(createDockerCredential.fulfilled, (state, { payload }) => {
      state.dockerCredentials = [payload, ...state.dockerCredentials];
    });
    builder.addCase(deleteDockerCredential.fulfilled, (state, { payload }) => {
      state.dockerCredentials = state.dockerCredentials.filter((item) => item.pk !== payload);
    });
    builder.addCase(updateDockerCredential.fulfilled, (state, { payload }) => {
      state.dockerCredentials = state.dockerCredentials.map((item) =>
        item.pk === payload.pk ? payload : item,
      );
    });
    builder.addCase(fetchApiKeysAction.fulfilled, (state, { payload }) => {
      state.apiKeys = payload.results;
      state.tableSettings.count = payload.count;
      state.tableSettings.loading = false;
    });
    builder.addCase(createApiKeyCredential.fulfilled, (state, { payload }) => {
      state.apiKeys = [payload, ...state.apiKeys];
    });
    builder.addCase(deleteApiKey.fulfilled, (state, { payload }) => {
      state.apiKeys = state.apiKeys.filter((item: ApiKeyApiModel): boolean => item.pk !== payload);
    });
    builder.addCase(updateApiKey.fulfilled, (state, { payload }) => {
      state.apiKeys = state.apiKeys.map((item) => (item.pk === payload.pk ? payload : item));
    });
  },
});

export default credentialsSlice.reducer;

export const { goToSelectedPage, updateSize, changeSorting, updateSearch, reset } =
  credentialsSlice.actions;
