import styled from 'styled-components';

import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer';

import { drawerWidth, drawerCollapsedWidth, appBarHeight } from '../constants';

export const ListItemStyled = styled(ListItem)`
  position: relative;

  &.Mui-selected {
    .MuiTypography-root {
      font-weight: 600;
    }

    :before {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      background: #0078d4;
      left: 0;
    }
  }
`;

export const BottomList = styled.div`
  width: 100%;
  position: absolute;
  bottom: 60px;
  min-height: 50px;
`;

interface DrawerStyledProps {
  open: boolean;
}

export const DrawerStyled = styled(Drawer)<DrawerStyledProps>`
  flex-shrink: 0;
  white-space: nowrap;
  width ${({ open }) => (open ? drawerWidth : drawerCollapsedWidth)}px;
  transition: width 0.2s ease-in;
  overflow-x: hidden;

  .MuiDrawer-paper {
    top: ${appBarHeight};
    width ${({ open }) => (open ? drawerWidth : drawerCollapsedWidth)}px;
    transition: width 0.2s ease-in;
    overflow-x: hidden;
  }
`;
