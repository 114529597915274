import React from 'react';
import { defineMessages } from 'react-intl';

import { useAppDispatch } from 'store';
import { TopBarSection } from 'components/TopBarSection';
import { ConnectionsModalTypes, useModal } from 'modules/modals';

import { updateSearch } from '../connectionsSlice';

const messages = defineMessages({
  title: {
    id: 'connections.connections-header.title',
    defaultMessage: 'Connections',
  },
  newConnection: {
    id: 'connections.connections-header.new-connection',
    defaultMessage: 'New Connection',
  },
});

export const ConnectionPageTitle = () => {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();

  const onSearch = React.useCallback(
    (value: string) => {
      dispatch(updateSearch(value));
    },
    [dispatch],
  );

  const onNewConnectionClick = React.useCallback(() => {
    openModal({
      type: ConnectionsModalTypes.CreateConnectionModal,
    });
  }, [openModal]);

  return (
    <TopBarSection
      title={messages.title}
      newItemText={messages.newConnection}
      onNewItem={onNewConnectionClick}
      onSearch={onSearch}
    />
  );
};
