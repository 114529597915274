import { ReactFlowProps } from 'react-flow-renderer';

import { Node } from './Node/Node';
import { CustomEdge } from './Edge/CustomEdge';

export const reactFlowConfig: Partial<ReactFlowProps> = {
  zoomOnScroll: false,
  zoomOnDoubleClick: false,
  zoomOnPinch: false,
  snapToGrid: true,
  defaultZoom: 1,
  preventScrolling: true,
  maxZoom: 1.1,
};

export const nodeTypes = {
  processor: Node,
  connection: Node,
  end: Node,
};

export const edgeTypes = {
  custom: CustomEdge,
  conditional: CustomEdge,
};
