import React, { FC, useCallback } from 'react';

import { TableSortLabel, TableCell } from '@material-ui/core';

import { defaultTableParams, OrderDirection } from 'constants/table';

type SortableTableCellProps = {
  columnName: string;
  width?: string;
  sortable: boolean;
  orderBy?: string;
  order?: OrderDirection;
  onClickSort: (value: string) => void;
};

export const SortableTableCell: FC<SortableTableCellProps> = (props) => {
  const { sortable, width = 'auto', columnName, order, orderBy, onClickSort, children } = props;

  const onClick = useCallback(() => {
    onClickSort(columnName);
  }, [onClickSort, columnName]);

  if (sortable) {
    return (
      <TableCell key={columnName} style={{ width }}>
        <TableSortLabel
          active={orderBy === columnName}
          direction={orderBy === columnName ? order : defaultTableParams.order}
          onClick={onClick}
        >
          {children}
        </TableSortLabel>
      </TableCell>
    );
  }

  return (
    <TableCell key={columnName} style={{ width }}>
      {children}
    </TableCell>
  );
};
