import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import Warning from '@material-ui/icons/Warning';

import { useModal } from 'modules/modals';
import { WorkflowsModalTypes } from 'modules/modals/constants';
import { WebsiteRoutes } from 'constants/routes';

import { AutomationEditParams } from '../types';
import { useDeploy } from '../hooks/useDeploy';

import './styles.scss';

const messages = defineMessages({
  title: {
    id: 'automation.automation-edit-page.confirm-away-edit-automation-dialog.title',
    defaultMessage: 'Unsaved Changes',
  },
  content: {
    id: 'automation.automation-edit-page.confirm-away-edit-automation-dialog.content',
    defaultMessage:
      'You don`t deploy your changes. If you leave this page all changes will be lost. What do you wish to do?',
  },
  deploy: {
    id: 'automation.automation-edit-page.confirm-away-edit-automation-dialog.deploy',
    defaultMessage: 'Deploy and exit',
  },
  exit: {
    id: 'automation.automation-edit-page.confirm-away-edit-automation-dialog.exit',
    defaultMessage: 'Exit Without Saving',
  },
  cancel: {
    id: 'automation.automation-edit-page.confirm-away-edit-automation-dialog.cancel',
    defaultMessage: 'Cancel',
  },
});

export const ConfirmAwayEditWorkflowDialog = () => {
  const { id } = useParams<AutomationEditParams>();
  const { deploy } = useDeploy(id);
  const history = useHistory();
  const { closeModal } = useModal();

  const onCancel = useCallback(() => {
    closeModal(WorkflowsModalTypes.ConfirmAwayEditWorkflowDialog);
  }, [closeModal]);

  const onExit = useCallback(() => {
    closeModal(WorkflowsModalTypes.ConfirmAwayEditWorkflowDialog);

    history.push(WebsiteRoutes.Workflows);
  }, [history, closeModal]);

  const onDeploy = useCallback(async () => {
    await deploy();

    closeModal(WorkflowsModalTypes.ConfirmAwayEditWorkflowDialog);

    history.push(WebsiteRoutes.Workflows);
  }, [closeModal, history, deploy]);

  return (
    <Dialog open aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" className="warning-title">
        <Warning />
        <FormattedMessage tagName="span" {...messages.title} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage {...messages.content} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDeploy} color="primary" variant="contained" autoFocus>
          <FormattedMessage {...messages.deploy} />
        </Button>
        <Button onClick={onExit} variant="outlined">
          <FormattedMessage {...messages.exit} />
        </Button>
        <Button onClick={onCancel} color="primary" variant="outlined">
          <FormattedMessage {...messages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
