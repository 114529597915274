import React, { useCallback, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Button } from '@material-ui/core';

import { DialogActions } from 'components/modal';
import { TabContent } from 'components/Tabs';

import { useFlag } from 'modules/modals';

import type { ConnectionActivitiesChartType } from '../../../types';
import { ActivitiesChart } from './ActivitiesChart';
import { convertResponseToChartValidDate } from '../utils';
import { ConnectorApi } from '../../../api/ConnectorApi';

const messages = defineMessages({
  error: {
    id: 'connections.edit-connection-modal.status',
    defaultMessage: 'Error: {error}',
  },
  status: {
    id: 'connections.edit-connection-modal.status',
    defaultMessage: 'Status: {status}',
  },
  forceStop: {
    id: 'connections.edit-connection-modal.update-token',
    defaultMessage: 'Force stop',
  },
  forceSync: {
    id: 'connections.edit-connection-modal.update-token',
    defaultMessage: 'Force sync',
  },
});

const initialState: ConnectionActivitiesChartType = {
  duration: [],
  failure: [],
  success: [],
};

type ActivitiesTabProps = {
  connectionId: string;
  connectorData: ConnectorApiModel;
};

export const ActivitiesTab = ({ connectionId, connectorData }: ActivitiesTabProps) => {
  const { showFlag } = useFlag();
  const [activities, setActivities] = useState<ConnectionActivitiesChartType>(initialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onForceStopClick = useCallback(async () => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        await ConnectorApi.forceStopConnection(connectionId);

        setIsLoading(false);
        showFlag({
          title: 'Force stop executed.',
        });
      } catch (e) {
        setIsLoading(false);
      }
    }
  }, [showFlag, isLoading, connectionId]);

  const onForceSyncClick = useCallback(async () => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        await ConnectorApi.forceSyncConnection(connectionId);

        setIsLoading(false);
        showFlag({
          title: 'Force sync started.',
        });
      } catch (e) {
        setIsLoading(false);
      }
    }
  }, [showFlag, isLoading, connectionId]);

  useEffect(() => {
    ConnectorApi.fetchConnectorActivities(connectionId).then((items) => {
      setActivities(convertResponseToChartValidDate(items));
    });
  }, [connectionId]);

  return (
    <>
      <TabContent>
        <div>
          <FormattedMessage
            {...messages.status}
            values={{ status: connectorData.status }}
            tagName="p"
          />
          <FormattedMessage
            {...messages.error}
            values={{ error: connectorData.error || 'None' }}
            tagName="p"
          />
        </div>
        <ActivitiesChart activities={activities} />
      </TabContent>
      <DialogActions>
        <Button variant="outlined" disableElevation color="primary" onClick={onForceStopClick}>
          <FormattedMessage {...messages.forceStop} />
        </Button>
        <Button variant="outlined" disableElevation color="primary" onClick={onForceSyncClick}>
          <FormattedMessage {...messages.forceSync} />
        </Button>
      </DialogActions>
    </>
  );
};
