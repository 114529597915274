import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Form from '@rjsf/material-ui';
import { Button } from '@material-ui/core';

import { useTypedSelector } from 'store';

import { datasetPropertyFormUISchema, Schema } from 'schemas';

import { LocalDatasetPropertyType } from './types';

import { selectDatasetPropertySchemaByType } from '../datasetsSelectors';

const messages = defineMessages({
  cancel: {
    id: 'datasets.dataset-edit.dataset-property-from.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'datasets.dataset-edit.dataset-property-from.save-field',
    defaultMessage: 'Save Field',
  },
});

export type FormData = Record<string, string>;

type OnChangeType = {
  formData: FormData;
};

type DatasetEditFormType = {
  property: LocalDatasetPropertyType;
  save: (property: LocalDatasetPropertyType) => void;
  onCancel: () => void;
};

export const DatasetPropertyForm: React.FC<DatasetEditFormType> = ({
  property: { id, type, name, formData: selectedFormData },
  save,
  onCancel,
}) => {
  const schema: Schema = useTypedSelector((store) =>
    selectDatasetPropertySchemaByType(store, type),
  );

  const [formData, setFormData] = React.useState<FormData>(selectedFormData || {});

  React.useEffect(() => {
    setFormData(selectedFormData || {});
  }, [id, selectedFormData]);

  const onChange = React.useCallback(
    (data: OnChangeType) => {
      setFormData(data.formData);
    },
    [setFormData],
  );

  const onSave = React.useCallback(() => {
    save({
      id,
      name,
      type,
      formData,
    });
  }, [id, save, name, formData, type]);

  return (
    <div className="property-edit-form">
      <h2 className="property-edit-form__title">
        {name} <span>Type: {type}</span>
      </h2>
      <div className="property-edit-form__controls">
        <Button onClick={onCancel} variant="outlined">
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button onClick={onSave} variant="contained" color="primary">
          <FormattedMessage {...messages.save} />
        </Button>
      </div>
      <div>
        <Form
          uiSchema={datasetPropertyFormUISchema}
          schema={schema}
          formData={formData}
          onChange={onChange}
        >
          <></>
        </Form>
      </div>
    </div>
  );
};
