export const GmailSender: ProcessorApiModel = {
  name: 'Gmail and Email  (Sender)',
  processor_type: 'GmailSender',
  description: 'Gmail and Email (Sender)',
  type: 'connection',
  tags: ['Gmail'],
  data_model: {},
  displayText: 'Sent emails from :connection_uuid.',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/GmailWriter-icon.png',
  connections: {
    inputPort: true,
    outputPort: false,
  },
};
