import React, { memo } from 'react';

import { Layout } from 'components/Layout';

import { ConnectedModal } from 'modules/modals';
import { getCustomComponentsModalByType } from 'modules/custom-components/modals/getCustomComponentsModalByType';
import { CredentialsMessage } from 'modules/custom-components/components/CredentialsMessage';
import { CustomComponentsTable } from 'modules/custom-components/components/CustomComponentsTable/CustomComponentsTable';
import { CustomComponentsPageTitle } from 'modules/custom-components/components/CustomComponentsPageTitle';

export const CustomComponentsPage = memo(() => {
  return (
    <Layout>
      <ConnectedModal modalCreator={getCustomComponentsModalByType} />
      <CustomComponentsPageTitle />
      <CredentialsMessage />
      <CustomComponentsTable />
    </Layout>
  );
});
