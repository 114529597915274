import * as Yup from 'yup';

import { MemberRoles } from 'constants/roles';

export interface UserWorkspaceSelectorValuesType {
  workspaceId: string;
  group: number;
}

export const initialValues: UserWorkspaceSelectorValuesType = {
  workspaceId: '',
  group: MemberRoles.VIEW,
};

export const validationSchema = () =>
  Yup.object().shape({
    workspaceId: Yup.string().required('Workspace is required'),
    group: Yup.string().min(1, 'Should not be empty').required('Role is required'),
  });
