import React, { FC, useCallback } from 'react';

import { CommonActionsCell } from 'components/CommonActionsCell';
import { ModalLink } from 'components/Table/ModalLink';

import { useModal, CustomComponentModalTypes } from 'modules/modals';

type CustomComponentActionCellType = {
  pk: string;
};

export const CustomComponentActionCell: FC<CustomComponentActionCellType> = ({ pk }) => {
  const { openModal } = useModal();

  const onEditClick = useCallback(() => {
    openModal({
      type: CustomComponentModalTypes.EditCustomComponentModal,
      data: { pk },
    });
  }, [openModal, pk]);

  const onDeleteClick = useCallback(() => {
    openModal({
      type: CustomComponentModalTypes.DeleteCustomComponentModal,
      data: { pk },
    });
  }, [openModal, pk]);

  return (
    <CommonActionsCell>
      <ModalLink onClick={onEditClick}>Edit</ModalLink>
      <ModalLink onClick={onDeleteClick}>Delete</ModalLink>
    </CommonActionsCell>
  );
};
