import React, { useRef, useState, useCallback, useEffect, useContext } from 'react';
import {
  Background,
  BackgroundVariant,
  Connection,
  ControlButton,
  Controls,
  Edge,
  OnConnectStartParams,
  useStoreState,
  useZoomPanHelper,
} from 'react-flow-renderer';

import { AccountTreeOutlined } from '@material-ui/icons';

import { useAppDispatch, useTypedSelector } from 'store';

import { ModalTypes, useModal } from 'modules/modals';
import { selectIsModalOpen } from 'modules/modals/modalsSlice';

import { DataTransferType } from '../../types';
import { TRANSFER_DATA } from '../constants';
import { ProcessorListMenu } from '../../modals/ProcessorListMenu';
import { EditProcessorModal } from '../../modals/EditProcessorModal';
import {
  EditProcessorModalContext,
  EditProcessorModalContextType,
} from '../../modals/EditProcessorModal/EditProcessorModalContextProvider';
import { createEdge, createNode, isRootCanvas, isConditionalEdge } from '../utils';
import { selectGraph } from '../../workflowSelectors';
import { ReactFlowStyled, WorkflowEditContainer } from './AutomationCanvas.style';
import { AutomationPanel } from '../AutomationPanel/AutomationPanel';
import { reactFlowConfig, nodeTypes, edgeTypes } from './react-flow-config';
import { addNodes, rearrangeElements, updateElementPosition } from '../../workflowSlice';

const onDragOver = (event: React.DragEvent) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
};

export const AutomationCanvas: React.FC = () => {
  const dispatch = useAppDispatch();
  const { openModal, closeModal } = useModal();
  const { fitView, project } = useZoomPanHelper();
  const nodes = useStoreState((state) => state.nodes);
  const edges = useStoreState((state) => state.edges);
  const graph = useTypedSelector(selectGraph);
  const isLoading = useTypedSelector(({ workflow }) => workflow.status === 'loading');
  const isNodeModalOpen = useTypedSelector(selectIsModalOpen(ModalTypes.CreateNodeMenu));
  const [shouldFitView, setShouldFitView] = useState<boolean>(false);
  const { selectedProcessorId, setSelectedProcessorId } =
    useContext<EditProcessorModalContextType>(EditProcessorModalContext);

  const sourceRef = useRef<Maybe<string>>(undefined);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);

  const onAutoLayout = useCallback(() => {
    dispatch(rearrangeElements([...nodes, ...edges]));

    setShouldFitView(true);
  }, [dispatch, nodes, edges]);

  const onNodeDoubleClick = useCallback(
    (_, { id }: WorkflowNode) => {
      if (isNodeModalOpen) {
        closeModal(ModalTypes.CreateNodeMenu);
      }
      setSelectedProcessorId(id);
    },
    [isNodeModalOpen, closeModal, setSelectedProcessorId],
  );

  const onNodeDragStop = useCallback(
    (_, { id: nodeId, position }: WorkflowNode) => {
      dispatch(
        updateElementPosition({
          nodeId,
          position,
        }),
      );
    },
    [dispatch],
  );

  const onConnect = useCallback(
    ({ source, target, sourceHandle }: Edge | Connection) => {
      if (source && target && sourceHandle) {
        const edge = createEdge({
          source,
          target,
          condition: sourceHandle,
          isConditional: isConditionalEdge(sourceHandle),
        });

        dispatch(addNodes([edge]));
      }
    },
    [dispatch],
  );

  const onDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();

      if (reactFlowWrapper.current) {
        const reactFlowBounds: DOMRect = reactFlowWrapper.current.getBoundingClientRect();

        const { processorType, type, source } = JSON.parse(
          event.dataTransfer.getData(TRANSFER_DATA),
        ) as DataTransferType;

        const position = project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });

        const node = createNode({ processorType, position, type });

        if (source) {
          const edge = createEdge({
            source,
            target: node.id,
          });

          dispatch(addNodes([edge, node]));
        } else {
          dispatch(addNodes([node]));
        }
      }
    },
    [dispatch, project],
  );

  const onConnectEnd = useCallback(
    (e: MouseEvent): void => {
      if (reactFlowWrapper.current && isRootCanvas(e)) {
        const reactFlowBounds: DOMRect = reactFlowWrapper.current.getBoundingClientRect();

        const position = project({
          x: e.clientX - reactFlowBounds.left,
          y: e.clientY - reactFlowBounds.top,
        });

        openModal({
          type: ModalTypes.CreateNodeMenu,
          data: {
            source: {
              id: sourceRef.current,
              position,
            },
          },
        });
      }
    },
    [openModal, project],
  );

  const onConnectStart = useCallback((_, params: OnConnectStartParams) => {
    if (params.nodeId) {
      sourceRef.current = params.nodeId;
    }
  }, []);

  useEffect(() => {
    if (!isNodeModalOpen) {
      sourceRef.current = undefined;
    }
  }, [isNodeModalOpen]);

  useEffect(() => {
    if (!isLoading) {
      fitView();
    }
  }, [isLoading, fitView]);

  useEffect(() => {
    if (shouldFitView) {
      fitView();

      setShouldFitView(false);
    }
  }, [setShouldFitView, shouldFitView, fitView]);

  return (
    <WorkflowEditContainer ref={reactFlowWrapper}>
      <AutomationPanel />
      <ReactFlowStyled
        {...reactFlowConfig}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onNodeDoubleClick={onNodeDoubleClick}
        elements={graph}
        onNodeDragStop={onNodeDragStop}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onConnect={onConnect}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <Controls>
          <ControlButton onClick={onAutoLayout}>
            <AccountTreeOutlined />
          </ControlButton>
        </Controls>
        <Background variant={BackgroundVariant.Lines} />
      </ReactFlowStyled>
      {isNodeModalOpen && <ProcessorListMenu />}
      {selectedProcessorId && <EditProcessorModal processorId={selectedProcessorId} />}
    </WorkflowEditContainer>
  );
};
