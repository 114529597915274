import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

type PermissionsTabProps = {
  connectionData: ConnectionApiModel;
};

export const PermissionsTab: React.FC<PermissionsTabProps> = ({ connectionData }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell align="right">Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{connectionData.owner_email}</TableCell>
            <TableCell align="right">Owner</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
