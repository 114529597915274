import React, { useCallback, useEffect } from 'react';
import { defineMessages } from 'react-intl';

import { useAppDispatch } from 'store';
import { useTabManager } from 'hooks';

import { Layout } from 'components/Layout';
import { TopBarSection } from 'components/TopBarSection';
import { TabPanel, Tabs, Tab } from 'components/Tabs';

import { ConnectedModal, CredentialsModalTypes, useModal } from 'modules/modals';
import { DockerCredentialsTable } from 'modules/credentials/components/DockerCredentialsTable/DockerCredentialsTable';
import { ApiKeysTable } from 'modules/credentials/components/ApiKeysTable/ApiKeysTable';
import { getCredentialsModalByType } from 'modules/credentials/modals/getCredentialsModalByType';
import { reset } from 'modules/credentials/credentialsSlice';

const messages = defineMessages({
  title: {
    id: 'credentials.top-bar-section.title',
    defaultMessage: 'Credentials',
  },
  createCredentials: {
    id: 'credentials.top-bar-section.create-credential',
    defaultMessage: 'New credential',
  },
});

enum CredentialsPageTab {
  DOCKER = 'DOCKER',
  API_TOKEN = 'API_TOKEN',
}

export const CredentialsPage = () => {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const { onTabChange, tab } = useTabManager<CredentialsPageTab>(CredentialsPageTab.DOCKER);

  const onClick = useCallback(() => {
    openModal({
      type:
        tab === CredentialsPageTab.DOCKER
          ? CredentialsModalTypes.CreateDockerCredentialModal
          : CredentialsModalTypes.CreateApiKeyCredentialModal,
    });
  }, [openModal, tab]);

  useEffect(() => {
    dispatch(reset());
  }, [dispatch, tab]);

  return (
    <Layout>
      <ConnectedModal modalCreator={getCredentialsModalByType} />
      <TopBarSection
        onNewItem={onClick}
        title={messages.title}
        newItemText={messages.createCredentials}
      />
      <Tabs value={tab} onChange={onTabChange}>
        <Tab value={CredentialsPageTab.DOCKER} label="Docker credentials" />
        <Tab value={CredentialsPageTab.API_TOKEN} label="Api tokens" />
      </Tabs>
      <TabPanel index={CredentialsPageTab.DOCKER} value={tab}>
        <DockerCredentialsTable />
      </TabPanel>
      <TabPanel index={CredentialsPageTab.API_TOKEN} value={tab}>
        <ApiKeysTable />
      </TabPanel>
    </Layout>
  );
};
