import styled from 'styled-components';

import MUIModal from '@material-ui/core/Modal';
import MuiDialogActions from '@material-ui/core/DialogActions';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiDialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';

export const ModalStyled = styled(MUIModal)`
  display: flex;
  height: 790px;
  max-height: 95%;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.main`
  padding: 12px 24px 0;
  overflow-y: auto;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  max-height: 90%;
  border: 2px solid #000;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[5]};
`;

export const DialogActions = styled(MuiDialogActions)`
  padding-top: 8px;
`;

export const WideDialog = withStyles({
  paper: { maxWidth: '890px', height: '660px' },
})(MuiDialog);

export const ModalLabel = withStyles({
  root: {
    color: '#201F1E',
    paddingBottom: '5px',
    paddingTop: '30px',
    '&:nth-of-type(1)': {
      paddingTop: 0,
    },
  },
})(InputLabel);
