import React from 'react';

import { ConnectionsModalTypes, useModal } from 'modules/modals';

import { CommonActionsCell } from 'components/CommonActionsCell';
import { ModalLink } from 'components/Table/ModalLink';

type ActionsCellType = {
  pk: string;
  automationsCount: number;
};

export const TableActionsCell: React.FC<ActionsCellType> = ({ pk, automationsCount }) => {
  const { openModal } = useModal();
  const openModalByType = React.useCallback(
    (type: ConnectionsModalTypes) => {
      openModal({
        type,
        data: {
          pk,
        },
      });
    },
    [pk, openModal],
  );

  const onEdit = React.useCallback(() => {
    openModalByType(ConnectionsModalTypes.EditConnectionModal);
  }, [openModalByType]);

  const onReplace = React.useCallback(() => {
    openModalByType(ConnectionsModalTypes.ReplaceConnectionModal);
  }, [openModalByType]);

  const onDelete = React.useCallback(() => {
    openModalByType(ConnectionsModalTypes.DeleteConnectionModal);
  }, [openModalByType]);

  return (
    <CommonActionsCell>
      <ModalLink fullWidth onClick={onEdit}>
        Edit
      </ModalLink>
      <ModalLink disabled={automationsCount === 0} fullWidth onClick={onReplace}>
        Replace
      </ModalLink>
      <ModalLink fullWidth onClick={onDelete}>
        Delete
      </ModalLink>
    </CommonActionsCell>
  );
};
