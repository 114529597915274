import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { useAppDispatch, useTypedSelector } from 'store';
import { useTabManager } from 'hooks';
import { tabA11yValue } from 'utils/a11yProps';

import { ModalHeader } from 'components/modal/ModalHeader';
import { WideDialog } from 'components/modal';
import { VerticalTab, VerticalTabs, VerticalTabsWrapper, TabPanel } from 'components/Tabs';

import { useModal, ConnectionsModalTypes } from 'modules/modals';

import { fetchConnection, fetchConnectorData } from '../../connectionActions';
import { selectConnectionByPk } from '../../connectionsSelectors';
import { resetConnectorData } from '../../connectionSlice';
import { GeneralSettingsTab } from './EditConnetionModalTabs/GeneralSettingsTab';
import { CronSettings } from './EditConnetionModalTabs/CronSettings';
import { WorkflowsTab } from './EditConnetionModalTabs/WorkflowsTab';
import { PermissionsTab } from './EditConnetionModalTabs/PermissionsTab';
import { ActivitiesTab } from './EditConnetionModalTabs/ActivitiesTab';
import { ConnectionLogs } from './EditConnetionModalTabs/ConnectionLogs';
import { CredentialsSettings } from './EditConnetionModalTabs/CredentialsSettings';
import { ConfigurationSettings } from './EditConnetionModalTabs/ConfigurationSettings';
import { LinkedinPinSettings } from './EditConnetionModalTabs/LinkedinPinSettings';

export enum EditConnectionTabs {
  GENERAL = 'GENERAL',
  CREDENTIALS = 'CREDENTIALS',
  CONFIG = 'CONFIG',
  CRON = 'CRON',
  PIN = 'PIN',
  AUTOMATIONS = 'AUTOMATIONS',
  PERMISSIONS = 'PERMISSIONS',
  ACTIVITIES = 'ACTIVITIES',
  LOGS = 'LOGS',
}

type EditConnectionModalProps = {
  pk: string;
};

export const EditConnectionModal = ({ pk }: EditConnectionModalProps) => {
  const { closeModal } = useModal();
  const dispatch = useAppDispatch();
  const connectionData = useTypedSelector(selectConnectionByPk(pk));
  const { tab, onTabChange } = useTabManager<EditConnectionTabs>(EditConnectionTabs.GENERAL);

  const connectorData: Maybe<ConnectorApiModel> = useTypedSelector(
    ({ connection }) => connection.connectionData,
  );

  const lastUpdated: string = useTypedSelector(({ connection }) => connection.lastUpdated);

  const onClose = React.useCallback(() => {
    dispatch(resetConnectorData());

    closeModal(ConnectionsModalTypes.EditConnectionModal);
  }, [closeModal, dispatch]);

  React.useEffect(() => {
    dispatch(fetchConnection({ pk }));
    dispatch(fetchConnectorData({ pk, notifyError: false }));
  }, [dispatch, pk, lastUpdated]);

  if (!connectionData || !connectorData) {
    return null;
  }

  return (
    <WideDialog id="edit-connection-modal" open onClose={onClose}>
      <ModalHeader onClose={onClose}>{connectionData?.name}</ModalHeader>
      <VerticalTabsWrapper>
        <VerticalTabs
          orientation="vertical"
          value={tab}
          onChange={onTabChange}
          aria-label="Vertical tabs example"
        >
          <VerticalTab label="General Settings" {...tabA11yValue(EditConnectionTabs.GENERAL)} />
          {!isEmpty(connectorData?.credentials) && (
            <VerticalTab label="Credentials" {...tabA11yValue(EditConnectionTabs.CREDENTIALS)} />
          )}
          {!isEmpty(connectorData?.config) && (
            <VerticalTab label="Configuration" {...tabA11yValue(EditConnectionTabs.CONFIG)} />
          )}
          <VerticalTab label="Cron" {...tabA11yValue(EditConnectionTabs.CRON)} />
          {connectionData.source_type === 'linkedin' && (
            <VerticalTab label="Linkedin pin" {...tabA11yValue(EditConnectionTabs.PIN)} />
          )}
          <VerticalTab label="Automations" {...tabA11yValue(EditConnectionTabs.AUTOMATIONS)} />
          <VerticalTab label="Permissions" {...tabA11yValue(EditConnectionTabs.PERMISSIONS)} />
          <VerticalTab label="Activities" {...tabA11yValue(EditConnectionTabs.ACTIVITIES)} />
          <VerticalTab label="Logs" {...tabA11yValue(EditConnectionTabs.LOGS)} />
        </VerticalTabs>
        <TabPanel value={tab} index={EditConnectionTabs.GENERAL}>
          <GeneralSettingsTab connectionData={connectionData} />
        </TabPanel>
        {connectorData.credentials && (
          <TabPanel value={tab} index={EditConnectionTabs.CREDENTIALS}>
            <CredentialsSettings
              connectorData={connectorData}
              credentials={connectorData.credentials}
              sourceType={connectionData.source_type}
            />
          </TabPanel>
        )}
        {connectorData.config && (
          <TabPanel value={tab} index={EditConnectionTabs.CONFIG}>
            <ConfigurationSettings connectorData={connectorData} config={connectorData.config} />
          </TabPanel>
        )}
        <TabPanel value={tab} index={EditConnectionTabs.PIN}>
          <LinkedinPinSettings connectionId={pk} />
        </TabPanel>
        <TabPanel value={tab} index={EditConnectionTabs.CRON}>
          <CronSettings
            connectionId={connectionData.pk}
            sourceType={connectionData.source_type}
            connectorData={connectorData}
          />
        </TabPanel>
        <TabPanel value={tab} index={EditConnectionTabs.AUTOMATIONS}>
          <WorkflowsTab pk={pk} />
        </TabPanel>
        <TabPanel value={tab} index={EditConnectionTabs.PERMISSIONS}>
          <PermissionsTab connectionData={connectionData} />
        </TabPanel>
        <TabPanel value={tab} index={EditConnectionTabs.ACTIVITIES}>
          <ActivitiesTab connectionId={connectionData.pk} connectorData={connectorData} />
        </TabPanel>
        <TabPanel value={tab} index={EditConnectionTabs.LOGS}>
          <ConnectionLogs connectionId={connectionData.pk} />
        </TabPanel>
      </VerticalTabsWrapper>
    </WideDialog>
  );
};
