import * as React from 'react';
import { defineMessages } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import Form from '@rjsf/material-ui';

import { useAppDispatch } from 'store';
import { uiSchema } from 'schemas';
import { useSchema } from 'hooks';
import { FormDataChangeEvent } from 'types';
import { Schema } from 'schemas/types';
import { useModal, WorkflowsModalTypes } from 'modules/modals';
import { Modal } from 'components/modal';

import { NodeSourceType } from '../components/types';
import { createEdge, createNode } from '../components/utils';

import { addNodes } from '../workflowSlice';

export const messages = defineMessages({
  title: {
    id: 'automation.create-node-modal.title',
    defaultMessage: 'Add new node',
  },
  cancel: {
    id: 'automation.create-node-modal.cancel-button',
    defaultMessage: 'Cancel',
  },
  addNode: {
    id: 'automation.create-node-modal.add-node',
    defaultMessage: 'Add',
  },
});

export type CreateNodeModalType = {
  processorType: ProcessorTypes;
  source?: NodeSourceType;
  type: ComponentsTypes;
};

export const CreateNodeModal: React.FC<CreateNodeModalType> = ({ source, processorType, type }) => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();
  const [formData, setFormData] = React.useState<Record<string, unknown>>({});

  const schema: Schema = useSchema({
    datasetId: formData?.dataset_uuid as string,
    customComponentId: formData?.custom_processor_uuid as string,
    processorType,
    parentId: source?.id,
  });

  const onClose = React.useCallback(() => {
    closeModal(WorkflowsModalTypes.CreateNodeModal);
  }, [closeModal]);

  const onSubmit = React.useCallback(() => {
    const nodes: WorkflowElement[] = [];
    const hasYShift = !!source?.parentPosition;

    const node: WorkflowNode = createNode({
      type,
      processorType,
      parameters: formData,
      position: source?.position,
      hasYShift,
    });

    nodes.push(node);

    if (source?.id) {
      const edge: WorkflowEdge = createEdge({
        source: source.id,
        target: node.id,
        isConditional: false,
      });

      nodes.push(edge);
    }

    dispatch(addNodes(nodes));

    closeModal(WorkflowsModalTypes.CreateNodeModal);
  }, [dispatch, closeModal, formData, processorType, source, type]);

  const onChange = React.useCallback(
    (data: FormDataChangeEvent<Record<string, unknown>>) => {
      const newFormData: Record<string, unknown> = cloneDeep(data.formData);

      setFormData(newFormData);
    },
    [setFormData],
  );

  return (
    <Modal
      title={messages.title}
      cancelMessage={messages.cancel}
      submitMessage={messages.addNode}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Form
        className="application-form"
        uiSchema={uiSchema}
        schema={schema}
        formData={formData}
        onChange={onChange}
      >
        <></>
      </Form>
    </Modal>
  );
};
