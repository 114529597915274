import { Schema } from 'schemas/types';

export const DatasetWriterSchema: Schema = {
  type: 'object',
  properties: {
    label: {
      title: 'Label',
      type: 'string',
      default: '',
    },
    dataset_uuid: {
      type: 'string',
      title: 'Dataset',
      enum: [],
      entityName: 'dataset',
    },
    dataset_writer_mapper: {
      type: 'array',
      title: 'Field mapper',
      items: {
        type: 'object',
        properties: {
          datamodel_property: {
            type: 'string',
            enum: [],
          },
          dataset_property: {
            type: 'string',
            enum: [],
          },
        },
      },
    },
  },
  required: ['dataset_uuid'],
};
