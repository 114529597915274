import { Schema } from 'schemas/types';

const hubspotTestSchema: Schema = {
  type: 'object',
  properties: {
    label: {
      title: 'Label',
      type: 'string',
      default: '',
    },
    method: {
      title: 'HTTP Method',
      enum: ['GET', 'POST'],
      enumNames: ['GET', 'POST(testing)'],
      type: 'string',
      default: 'GET',
    },
    url: {
      title: 'Url endpoint',
      type: 'string',
    },
    apikey_uuid: {
      title: 'API key',
      type: 'string',
      enum: [],
      enumNames: [],
      entityName: 'api-key',
    },
    jsonpath: {
      title: 'JSONPath',
      type: 'string',
      default: '$.results',
    },
    hubspot_fields_mapper: {
      type: 'array',
      title: 'Body params',
      items: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
          },
          jsonpath: {
            type: 'string',
          },
        },
      },
    },
  },
  dependencies: {
    method: {
      oneOf: [
        {
          properties: {
            method: {
              enum: ['GET'],
            },
          },
        },
        {
          properties: {
            method: {
              enum: ['POST'],
            },
            body: {
              type: 'array',
              title: 'Body',
              default: [],
              items: {
                type: 'object',
                properties: {
                  name: {
                    title: 'Name',
                    type: 'string',
                    default: '',
                  },
                  dataset_property: {
                    title: 'Dataset Property',
                    type: 'string',
                    default: '',
                  },
                },
              },
            },
          },
        },
      ],
    },
  },
};

export const getHubspotTestSchema = (apiKeys: ApiKeyApiModel[]) => {
  hubspotTestSchema.properties.apikey_uuid.enumNames = apiKeys.map((item) => item.name);
  hubspotTestSchema.properties.apikey_uuid.enum = apiKeys.map((item) => item.pk);

  return hubspotTestSchema;
};
