import { Graph } from 'graphlib';
import dot from 'graphlib-dot';
import { Node } from 'react-flow-renderer';

import { createEdge } from './createEdge';
import { createDotNodeFromNode, createNodeFromDot } from './dot-transformation';

export const createEmptyGraph = () => {
  const graph = new Graph({ multigraph: true });

  return dot.write(graph);
};

export const toDot = (nodes: WorkflowNode[] = [], edges: WorkflowEdge[] = []) => {
  const graph = new Graph({ multigraph: true });

  nodes.forEach((node) => {
    const dotNode = createDotNodeFromNode(node);

    if (dotNode) {
      graph.setNode(dotNode.id, dotNode);
    }
  });

  edges.forEach((edge) => {
    if (edge.data?.condition) {
      graph.setEdge(edge.source, edge.target, {
        condition: edge.data?.condition,
        type: 'conditional',
      });
    } else {
      graph.setEdge(edge.source, edge.target);
    }
  });

  return dot.write(graph);
};

type EdgeLabel = {
  type: 'conditional' | 'custom';
  condition: string;
};

export const fromDot = (dotContent: string) => {
  const elements: WorkflowElement[] = [];
  const graph = dot.read(dotContent);

  graph.nodes().forEach((nodeName) => {
    const dotNode = graph.node(nodeName) as DotNode;

    elements.push(createNodeFromDot(dotNode, nodeName));
  });

  graph.edges().forEach((edge) => {
    const { type, condition } = graph.edge(edge) as EdgeLabel;

    elements.push(
      createEdge({
        source: edge.v,
        target: edge.w,
        condition,
        isConditional: type === 'conditional',
      }),
    );
  });

  return elements;
};

// NOTE @see {@link https://github.com/wbkd/react-flow/issues/603} and {@link https://github.com/wbkd/react-flow/commit/2db5a7bce24f830fab91bdd68e0549f2efe8077e}
export const parseNode = <T = WorkflowElementData>(node: Node<T>) => {
  const position = (node.__rf as { position: typeof node.position })?.position;

  if (position) {
    node.position = position;
  }

  return node;
};
