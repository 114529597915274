import React, { FC } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { WarningList, WarningListType } from './WarningList';

const messages = defineMessages({
  warnings: {
    id: 'warning',
    defaultMessage: 'Warnings:',
  },
});

export const WarningListWithTitle: FC<WarningListType> = (props) => {
  const { warnings } = props;

  if (warnings.length === 0) return null;

  return (
    <>
      <FormattedMessage {...messages.warnings} tagName="p" />
      <WarningList {...props} />
    </>
  );
};
