import styled from 'styled-components';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Close from '@material-ui/icons/Close';
import Warning from '@material-ui/icons/Warning';
import Error from '@material-ui/icons/Error';
import Info from '@material-ui/icons/Info';

export const CloseIcon = styled(Close)`
  width: 28px;
  height: 28px;
`;

export const WarningIcon = styled(Warning)`
  color: #f39200;
`;

export const ErrorIcon = styled(Error)`
  color: #f30000;
`;

export const InfoIcon = styled(Info)`
  color: #2196f3;
`;

export const SuccessIcon = styled(CheckCircleIcon)`
  color: #43a047;
`;
