import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Modal } from 'components/modal';
import { FormTextField } from 'components/FormTextField';
import { ConnectionsModalTypes, useFlag, useModal } from 'modules/modals';

import { LinkedinApi } from '../api/LinkedinApi';
import { useConnections } from '../useConnections';

const messages = defineMessages({
  title: {
    id: 'automation.create-automation-modal.title',
    defaultMessage: 'Set Linkedin pin',
  },
  cancelButtonText: {
    id: 'automation.create-automation-modal.cancel-button-text',
    defaultMessage: 'Cancel',
  },
  message: {
    id: 'automation.create-automation-modal.message',
    defaultMessage: 'You will receive a pin in your email. ',
  },
  message2: {
    id: 'automation.create-automation-modal.message2',
    defaultMessage: 'Please put the received pun to finish creating the connection.',
  },
  submitButtonText: {
    id: 'automation.create-automation-modal.submit-button-text',
    defaultMessage: 'Apply',
  },
  pinLabel: {
    id: 'automation.create-automation-modal.submit-button-text',
    defaultMessage: 'Pin',
  },
});

type InitialValuesType = {
  pin: string;
};

export const initialValues: InitialValuesType = {
  pin: '',
};

export const validationSchema = () =>
  Yup.object().shape({
    pin: Yup.number().typeError('Pin should be a number').required('Pin should not be empty'),
  });

type LinkedinPinModalProps = {
  connectionId: string;
};

export const LinkedinPinModal = ({ connectionId }: LinkedinPinModalProps) => {
  const { closeModal } = useModal();
  const { showFlag } = useFlag();
  const fetchConnections = useConnections();

  const onSubmit = useCallback(
    async (data: InitialValuesType) => {
      try {
        await LinkedinApi.updatePin(connectionId, data.pin);

        fetchConnections();

        showFlag({ title: 'Pin updated.' });

        closeModal(ConnectionsModalTypes.LinkedinPinModal);
      } catch (e) {
        showFlag({ title: 'Please try again.' });
      }
    },
    [connectionId, showFlag, fetchConnections, closeModal],
  );

  const { handleSubmit, errors, handleChange, values } = useFormik<InitialValuesType>({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  const onClose = useCallback(() => {
    closeModal(ConnectionsModalTypes.LinkedinPinModal);
  }, [closeModal]);

  return (
    <Modal
      height={400}
      title={messages.title}
      submitMessage={messages.submitButtonText}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <p className="has-top-margin">
        <FormattedMessage {...messages.message} />
      </p>
      <p className="has-top-margin">
        <FormattedMessage {...messages.message2} />
      </p>
      <form className="has-top-margin" onSubmit={handleSubmit}>
        <FormattedMessage {...messages.pinLabel} />
        <FormTextField
          name="pin"
          value={values.pin}
          variant="outlined"
          fullWidth
          size="small"
          errorText={errors.pin}
          onChange={handleChange}
        />
      </form>
    </Modal>
  );
};
