import React from 'react';
import { defineMessages } from 'react-intl';

import { TopBarSection } from 'components/TopBarSection';
import { CustomComponentModalTypes, useModal } from 'modules/modals';

const messages = defineMessages({
  title: {
    id: 'custom-components.custom-components-header.title',
    defaultMessage: 'Custom Processors',
  },
  newDataset: {
    id: 'custom-components.custom-components-header.new-custom-components',
    defaultMessage: 'New Custom Processor',
  },
});

export const CustomComponentsPageTitle = () => {
  const { openModal } = useModal();

  const onNewConnectionClick = React.useCallback(() => {
    openModal({
      type: CustomComponentModalTypes.CreateCustomComponentModal,
    });
  }, [openModal]);

  return (
    <TopBarSection
      title={messages.title}
      newItemText={messages.newDataset}
      onNewItem={onNewConnectionClick}
    />
  );
};
