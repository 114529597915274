import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { a11yProps } from 'utils/a11yProps';

import { useTabManager } from 'hooks';
import { useAppDispatch, useTypedSelector } from 'store';

import { useModal, WorkspaceModalTypes } from 'modules/modals';

import { ModalHeader } from 'components/modal/ModalHeader';
import { VerticalTab, TabPanel, VerticalTabs, VerticalTabsWrapper } from 'components/Tabs';
import { WideDialog } from 'components/modal';
import { ContentLoader } from 'components/ContentLoader';

import { fetchWorkspace } from '../../workspaceActions';
import { EditWorkspaceTabPanel } from './EditWorkspaceTabPanel';
import { UserPermissionListTab } from '../UserPermissionListTab';
import { WorkspaceVariables } from './WorkspaceVariables';

const messages = defineMessages({
  title: {
    id: 'automation.create-project-modal.title',
    defaultMessage: 'Workspace Settings',
  },
});

export type EditWorkspaceModalType = {
  id: string;
};

enum EditWorkspaceModalTabs {
  GENERAL,
  PERMISSION,
  VARIABLES,
}

export const EditWorkspaceModal: React.FC<EditWorkspaceModalType> = ({ id }) => {
  const { closeModal } = useModal();
  const dispatch = useAppDispatch();
  const { workspace, loading } = useTypedSelector((state) => state.workspace);
  const { tab, onTabChange } = useTabManager<EditWorkspaceModalTabs>(
    EditWorkspaceModalTabs.GENERAL,
  );

  const onClose = React.useCallback(() => {
    closeModal(WorkspaceModalTypes.EditWorkspaceModal);
  }, [closeModal]);

  React.useEffect(() => {
    dispatch(fetchWorkspace(id));
  }, [dispatch, id]);

  return (
    <WideDialog id="edit-workspace-modal" open onClose={onClose}>
      <ModalHeader onClose={onClose}>
        <FormattedMessage {...messages.title} />
      </ModalHeader>
      <VerticalTabsWrapper>
        <VerticalTabs
          orientation="vertical"
          value={tab}
          onChange={onTabChange}
          aria-label="Vertical tabs example"
        >
          <VerticalTab label="General" {...a11yProps(0)} />
          <VerticalTab label="Permission" {...a11yProps(1)} />
          <VerticalTab label="Variables" {...a11yProps(2)} />
        </VerticalTabs>
        <ContentLoader isLoading={loading}>
          {workspace && (
            <>
              <TabPanel index={EditWorkspaceModalTabs.GENERAL} value={tab}>
                <EditWorkspaceTabPanel workspace={workspace} />
              </TabPanel>
              <TabPanel index={EditWorkspaceModalTabs.PERMISSION} value={tab}>
                <UserPermissionListTab
                  listClassName="edit-workspace-modal-permission-list"
                  workspaceId={workspace.pk}
                />
              </TabPanel>
              <TabPanel index={EditWorkspaceModalTabs.VARIABLES} value={tab}>
                <WorkspaceVariables />
              </TabPanel>
            </>
          )}
        </ContentLoader>
      </VerticalTabsWrapper>
    </WideDialog>
  );
};
