import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';

export const ProgressBar = styled(LinearProgress)`
  background-color: ${({ theme }) => theme.palette.secondary.light};

  .MuiLinearProgress-bar {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const AutomationEditPanelWrapper = styled.section`
  width: 100%;
  position: absolute;
  z-index: 1000;
`;
