import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';

import { ResponseStatutes } from '../types';

export type AlertMediaApiModel = {
  pk: string;
  config_type: string;
  name: string;
  config: Record<string, string>;
};

type StateType = {
  alertMedias: AlertMediaApiModel[];
  status: ResponseStatutes;
};

const initialState: StateType = {
  alertMedias: [],
  status: 'loading',
};

export const getAlerts = createAsyncThunk<PaginatedResponse<AlertMediaApiModel>>(
  'alertMedias/fetchAlerts',
  async () => {
    const { data } = await requestApi({
      service: Services.Alert,
      submethod: 'media/',
      isAnonymousMethod: true,
    });

    return data as PaginatedResponse<AlertMediaApiModel>;
  },
);

type AlertActionPayload = { id: string };

export const deleteAlert = createAsyncThunk<boolean, AlertActionPayload>(
  'alertMedias/deleteAlerts',
  async ({ id }) => {
    await requestApi({
      service: Services.Alert,
      method: 'DELETE',
      submethod: `media/${id}`,
      isAnonymousMethod: true,
    });

    return true;
  },
);

type UpdateAlertParamsType = {
  mediaId: string;
  data: Omit<AlertMediaApiModel, 'pk'>;
};

export const updateAlert = createAsyncThunk<AlertMediaApiModel, UpdateAlertParamsType>(
  'alertMedias/updateAlert',
  async ({ mediaId, data }) => {
    const res = await requestApi<AlertMediaApiModel>({
      service: Services.Alert,
      submethod: `media/${mediaId}`,
      method: 'PATCH',
      isAnonymousMethod: true,
      data,
    });

    return res.data;
  },
);

type CreateAlertParamsType = {
  data: Omit<AlertMediaApiModel, 'pk'>;
};

export const createAlert = createAsyncThunk<boolean, CreateAlertParamsType>(
  'alertMedias/createAlert',
  async ({ data }) => {
    await requestApi({
      service: Services.Alert,
      submethod: `media/`,
      method: 'POST',
      isAnonymousMethod: true,
      data,
    });

    return true;
  },
);

const alertMediaSlice = createSlice({
  name: 'alertMedias',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAlerts.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getAlerts.fulfilled, (state, { payload: { results } }) => {
      state.alertMedias = results;
      state.status = 'finished';
    });
    builder.addCase(getAlerts.rejected, (state) => {
      state.status = 'failed';
    });

    builder.addCase(deleteAlert.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(deleteAlert.fulfilled, (state) => {
      state.status = 'finished';
    });
    builder.addCase(deleteAlert.rejected, (state) => {
      state.status = 'failed';
    });

    builder.addCase(createAlert.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(createAlert.fulfilled, (state) => {
      state.status = 'finished';
    });
    builder.addCase(createAlert.rejected, (state) => {
      state.status = 'failed';
    });

    builder.addCase(updateAlert.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(updateAlert.fulfilled, (state) => {
      state.status = 'finished';
    });
    builder.addCase(updateAlert.rejected, (state) => {
      state.status = 'failed';
    });
  },
});

export default alertMediaSlice.reducer;
export const { reset } = alertMediaSlice.actions;
