import React, { FC, useCallback, useState } from 'react';

import { Button, IconButton, styled } from '@material-ui/core';

import { useAppDispatch } from 'store';
import { useFlag } from 'modules/modals';

import './styles.scss';

import { WorkspaceAvatar } from '../WorkspaceCard/WorkspaceAvatar';
import { uploadWorkspaceAvatar } from '../../workspacesActions';

const Input = styled('input')({
  display: 'none',
});

type UploadWorkspaceAvatarFormType = {
  workspaceId: string;
  workspaceName: string;
  workspaceAvatarUrl: string;
};

export const UploadWorkspaceAvatarForm: FC<UploadWorkspaceAvatarFormType> = ({
  workspaceAvatarUrl,
  workspaceName,
  workspaceId,
}) => {
  const { showFlag } = useFlag();
  const dispatch = useAppDispatch();
  const [workspaceAvatar, setWorkspaceAvatar] = useState(workspaceAvatarUrl);

  const onImageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setWorkspaceAvatar(URL.createObjectURL(e.target.files[0]));
    }
  }, []);

  const onLogoUpdate = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();

      if (workspaceAvatarUrl === workspaceAvatar) {
        showFlag({ title: 'Select a new avatar first' });
        return;
      }

      try {
        await dispatch(uploadWorkspaceAvatar({ workspaceId, avatarUrl: workspaceAvatar })).unwrap();

        showFlag({ title: 'Workspace avatar updated' });
      } catch (e) {
        showFlag({ title: `Workspace avatar can't be updated` });
      }
    },
    [workspaceAvatarUrl, dispatch, showFlag, workspaceId, workspaceAvatar],
  );

  return (
    <form onSubmit={onLogoUpdate} className="upload-workspace-avatar-form">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="icon-button-file">
        Change a new workspace logo:
        <Input
          accept="image/*"
          id="icon-button-file"
          onChange={onImageChange}
          name="icon-button-file"
          type="file"
        />
        <IconButton
          onChange={onImageChange}
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <WorkspaceAvatar name={workspaceName} src={workspaceAvatar} />
        </IconButton>
      </label>
      <Button type="submit" className="upload-button" variant="contained">
        Upload logo
      </Button>
    </form>
  );
};
