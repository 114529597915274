import styled from 'styled-components';

export const ParamContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const ParamInputsContainer = styled.div`
  display: flex;
  flex-direction: row;

  div:first-child {
    margin-right: 12px;
  }
`;

export const ParamBox = styled.p`
  padding: 14px;
`;
