import React, { memo } from 'react';

import { Layout } from 'components/Layout';

import { ConnectedModal } from 'modules/modals/ConnectedModal';
import { getConnectionModalByType } from 'modules/connections/getConnectionModalByType';
import { ConnectionsSummary } from 'modules/connections/components/ConnectionsSummary/ConnectionsSummary';
import { ConnectionsTable } from 'modules/connections/components/ConnectionsTable/ConnectionsTable';
import { ConnectionPageTitle } from 'modules/connections/components/ConnectionPageTitle';

export const ConnectionsPage = memo(() => {
  return (
    <Layout>
      <ConnectedModal modalCreator={getConnectionModalByType} />
      <ConnectionPageTitle />
      <ConnectionsSummary />
      <ConnectionsTable />
    </Layout>
  );
});
