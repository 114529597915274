import styled from 'styled-components';

import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';

export const VerticalTabs = withStyles({
  root: {
    width: '230px',
    height: '600px',
    borderRight: '1px solid #EDEBE9',
  },
  indicator: {
    backgroundColor: '#0078D4',
    width: '2px',
    left: 0,
  },
})(Tabs);

export const VerticalTab = withStyles({
  wrapper: {
    textTransform: 'none',
    alignItems: 'start',
    paddingLeft: '20px',
  },
})(MuiTab);

export const VerticalTabsWrapper = styled.div`
  flex-grow: 1;
  display: flex;
`;

export const TabContent = styled.div`
  padding: 24px 24px 16px;
  height: 515px;
  overflow: hidden;
  overflow-y: auto;
`;
