import { OrderDirection } from 'constants/table';

export const toOneBasedIndex = (page: number) => {
  return page + 1;
};

export const toZeroBasedIndex = (page: number) => {
  return page - 1;
};

export const toApiOrdering = (order: OrderDirection = OrderDirection.ASC, orderBy: string) => {
  const apiOrder = OrderDirection.ASC === order ? '-' : '';

  if (!orderBy) {
    return null;
  }

  return `${apiOrder}${orderBy}`;
};
