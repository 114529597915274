import React from 'react';
import { CancelIcon, RemoveIconWrapper } from './CommonEdge.style';

export type RemoveEdgeIconProps = {
  displayDelete: boolean;
  edgeCenter: number[];
  onRemove: () => void;
  onMouseOver: () => void;
  onMouseLeave: () => void;
};

export const RemoveEdgeIcon = ({
  displayDelete = false,
  edgeCenter,
  onRemove,
  onMouseOver,
  onMouseLeave,
}: RemoveEdgeIconProps) => {
  const opacity = displayDelete ? 100 : 0;

  return (
    <RemoveIconWrapper
      height="16"
      width="16"
      x={edgeCenter[0] - 8}
      y={edgeCenter[1] - 8}
      opacity={opacity}
      onClick={onRemove}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <CancelIcon />
    </RemoveIconWrapper>
  );
};
