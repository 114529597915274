import { requestApi } from 'utils/httpClient';
import { buildRequestParams } from 'utils/buildRequestParams';

import { Services } from 'constants/services';

export const DatasetApi = {
  getAll: async (params: TableFilterParams): Promise<PaginatedResponse<IndexApiModel>> => {
    const response = await requestApi<PaginatedResponse<IndexApiModel>>({
      service: Services.Search,
      submethod: 'index/',
      params: buildRequestParams(params),
    });

    return response.data;
  },
  get: async (id: string): Promise<IndexApiModel> => {
    const response = await requestApi<IndexApiModel>({
      service: Services.Search,
      submethod: `index/${id}/`,
    });

    return response.data;
  },
  create: async (
    { name, description }: { name: string; description: string },
    projectId: string,
  ): Promise<IndexApiModel> => {
    const response = await requestApi<IndexApiModel>({
      method: 'POST',
      service: Services.Search,
      submethod: 'index/',
      data: {
        name,
        description,
        mapping: {
          properties: {},
        },
        project: projectId,
        tags: [],
        timestamp_field: new Date().toDateString(),
      },
    });

    return response.data;
  },
  update: async ({
    id,
    dataset,
  }: {
    id: string;
    dataset: Partial<IndexApiModel>;
  }): Promise<IndexApiModel> => {
    const res = await requestApi<IndexApiModel>({
      method: 'PATCH',
      service: Services.Search,
      submethod: `index/${id}`,
      data: dataset,
    });

    return res.data;
  },
  delete: async (id: string): Promise<void> => {
    await requestApi({
      method: 'DELETE',
      service: Services.Search,
      submethod: `index/${id}`,
    });
  },
  getAssociatedAutomations: async (id: string): Promise<AssociatedEntity[]> => {
    const res = await requestApi<AssociatedEntity[]>({
      service: Services.Search,
      submethod: `automations/${id}/`,
    });

    return res.data;
  },
} as const;
