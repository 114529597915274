export const Filter: ProcessorApiModel = {
  name: 'Filter',
  processor_type: 'Filter',
  description: 'Filter',
  type: 'processor',
  tags: ['Logical'],
  data_model: {
    alerts: {
      type: 'array',
    },
  },
  displayText: 'Test value',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/Filter-icon.png',
  connections: {
    inputPort: true,
    outputPort: true,
  },
};
