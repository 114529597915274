import * as React from 'react';

import { Button } from '@material-ui/core';

import { useTypedSelector } from 'store';

import { CloseIcon } from 'components/icons';

import { ModalTypes, useModal } from 'modules/modals';
import { selectIsModalOpen } from 'modules/modals/modalsSlice';
import createNodeButtonIcon from '../../assets/create-node-button-icon.png';

import { AddProcessorButtonPaper } from './AddProcessorButton.style';

export const AddProcessorButton: React.FC = () => {
  const { openModal, closeModal } = useModal();
  const isCreateNodeMenuOpen = useTypedSelector(selectIsModalOpen(ModalTypes.CreateNodeMenu));

  const onClick = React.useCallback(() => {
    if (isCreateNodeMenuOpen) {
      closeModal(ModalTypes.CreateNodeMenu);
    } else {
      openModal({
        type: ModalTypes.CreateNodeMenu,
      });
    }
  }, [isCreateNodeMenuOpen, openModal, closeModal]);

  return (
    <AddProcessorButtonPaper elevation={3} style={{ left: isCreateNodeMenuOpen ? '300px' : '0' }}>
      <Button onClick={onClick}>
        {isCreateNodeMenuOpen ? (
          <CloseIcon />
        ) : (
          <img src={createNodeButtonIcon} alt="Create Node" />
        )}
      </Button>
    </AddProcessorButtonPaper>
  );
};
