import React from 'react';

import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Settings from '@material-ui/icons/Settings';

import { DrawerContext, DrawerContextType } from 'providers';
import { useProjectId } from 'hooks';

import { useModal, WorkspaceModalTypes } from 'modules/modals';

import { menuItems } from './SideMenu.helper';
import { ListItemStyled, BottomList, DrawerStyled } from './SideMenu.style';
import { SideMenuItem } from './SideMenuItem';

export const SideMenu = React.memo(() => {
  const { openModal } = useModal();
  const { projectId } = useProjectId();

  const { setIsDrawerOpen, isDrawerOpen, toggleDrawer } =
    React.useContext<DrawerContextType>(DrawerContext);

  const onDoubleClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      toggleDrawer();
    },
    [toggleDrawer],
  );

  const onOpenWorkflowSettingsClick = React.useCallback(() => {
    openModal({
      type: WorkspaceModalTypes.EditWorkspaceModal,
      data: {
        id: projectId,
      },
    });
  }, [openModal, projectId]);

  const onListItemClick = React.useCallback(() => {
    setIsDrawerOpen(!isDrawerOpen);
  }, [setIsDrawerOpen, isDrawerOpen]);

  return (
    <DrawerStyled onDoubleClick={onDoubleClick} open={isDrawerOpen} variant="permanent">
      <List disablePadding style={{ height: '100%' }}>
        {menuItems.map((item) => (
          <SideMenuItem key={item.route} projectId={projectId} item={item} />
        ))}
        <BottomList>
          <ListItemStyled button onClick={onOpenWorkflowSettingsClick}>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Setting" />
          </ListItemStyled>
          <ListItemStyled button onClick={onListItemClick}>
            <ListItemIcon>{isDrawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}</ListItemIcon>
            <ListItemText primary="Collapse" />
          </ListItemStyled>
        </BottomList>
      </List>
    </DrawerStyled>
  );
});
