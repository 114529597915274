import React, { useEffect, useState, useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';

import { useAppDispatch } from 'store';

import { TabContent } from 'components/Tabs';
import { DialogActions } from 'components/modal';
import { useFlag } from 'modules/modals';
import { ModalTitleStyled } from 'components/modal/ModalHeader';
import { FormControlCheckbox } from 'components/Table/FormControlCheckbox';
import { TextField } from 'components/Table/TextField';

import { updateConnector } from '../../../connectionActions';
import { ConnectionFoldersSettings } from './ConnectionFoldersSettings';

const messages = defineMessages({
  saveSettings: {
    id: 'connections.edit-connection-modal.save-settings',
    defaultMessage: 'Save settings',
  },
});

type CronSettingsProps = {
  connectionId: string;
  sourceType: ConnectionTypeType;
  connectorData: ConnectorApiModel;
};

export const CronSettings = ({ connectionId, sourceType, connectorData }: CronSettingsProps) => {
  const dispatch = useAppDispatch();
  const { showFlag } = useFlag();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [localConnectorData, setLocalConnectorData] = useState<ConnectorApiModel>(connectorData);

  const onSubmit = useCallback(async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      await dispatch(
        updateConnector({
          id: connectionId,
          connectorData: localConnectorData,
        }),
      ).unwrap();

      setIsLoading(false);
      showFlag({ title: 'Settings was updated' });
    } catch (e) {
      setIsLoading(false);
    }
  }, [localConnectorData, isLoading, dispatch, showFlag, connectionId]);

  const onConnectorDataChange = useCallback((key: string, value: ScalarValue) => {
    setLocalConnectorData((data) => ({
      ...data,
      [key]: value,
    }));
  }, []);

  useEffect(() => {
    setLocalConnectorData(connectorData);
  }, [connectorData]);

  return (
    <>
      <TabContent>
        <ModalTitleStyled>Cron Settings</ModalTitleStyled>
        <FormControlCheckbox
          name="scheduler"
          label="Enable cron"
          value={localConnectorData.scheduler}
          onChange={onConnectorDataChange}
        />
        <TextField
          name="cron"
          label="Cron interval"
          value={localConnectorData.cron}
          onChange={onConnectorDataChange}
        />
        {sourceType === 'gmail' && (
          <ConnectionFoldersSettings
            connectionId={connectionId}
            onConnectorDataChange={onConnectorDataChange}
            folder={localConnectorData.folder}
          />
        )}
      </TabContent>
      <DialogActions>
        <Button variant="contained" disableElevation color="primary" onClick={onSubmit}>
          <FormattedMessage {...messages.saveSettings} />
        </Button>
      </DialogActions>
    </>
  );
};
