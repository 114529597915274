import { createAsyncThunk } from '@reduxjs/toolkit';

import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';

export const updateWorkspaceArchiveStatus = createAsyncThunk<
  { pk: string; archive: boolean },
  { pk: string; archive: boolean }
>('workspaces/updateWorkspaceArchiveStatus', async ({ pk, archive }) => {
  await requestApi({
    method: 'POST',
    service: Services.Project,
    isAnonymousMethod: true,
    submethod: `project/${pk}/archive/`,
    data: {
      archive,
    },
  });

  return { pk, archive };
});

export const uploadWorkspaceAvatar = createAsyncThunk<
  { workspaceId: string; avatarUrl: string },
  { workspaceId: string; avatarUrl: string }
>('uploadWorkspaceAvatar', async ({ workspaceId, avatarUrl }) => {
  const formData = new FormData();

  const avatar = await fetch(avatarUrl);
  const blob = await avatar.blob();

  formData.append('file_uploaded', blob);

  await requestApi({
    service: Services.Project,
    method: 'PUT',
    isAnonymousMethod: true,
    submethod: `project/${workspaceId}/image/`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return { avatarUrl, workspaceId };
});
