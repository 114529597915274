import React, { useEffect, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Form from '@rjsf/material-ui';
import Alert from '@material-ui/lab/Alert';

import { uiSchema } from 'schemas';

import { ModalFooter, ModalWithoutFooter } from 'components/modal';
import { useModal, ConnectionsModalTypes } from 'modules/modals';

import { ConnectorApi, ProviderType } from '../api/ConnectorApi';
import { ConnectionApi } from '../api/ConnectionApi';
import { useConnections } from '../useConnections';
import { fillEmailConnectionSchema } from './schemas/emailConnectionSchema';

const messages = defineMessages({
  title: {
    id: 'connection.create-email-connector-modal.title',
    defaultMessage: 'Email Connection',
  },
  create: {
    id: 'connection.create-email-connector-modal.create',
    defaultMessage: 'Create',
  },
  cancel: {
    id: 'connection.create-email-connector-modal.cancel',
    defaultMessage: 'Cancel',
  },
  warning: {
    id: 'connection.create-email-connector-modal.warning',
    defaultMessage: `Don't forget to enable access via POP3 in your account settings.`,
  },
  mailchimpWarning: {
    id: 'connection.create-email-connector-modal.mailchimpWarning',
    defaultMessage: `If you have a free account you can send emails only inside the organization or the domain.`,
  },
});

type FormData = {
  provider?: string;
} & ConnectionModel &
  EmailCredentials;

const customProvider: ProviderType = {
  name: '',
  type: 'imap',
  server: '',
  port: 8080,
  ssl: false,
};

const initialFormDataState: FormData = {
  name: '',
  description: '',
  scheduler: true,
  cron: '* * * * *',
  type: 'imap',
  provider: 'Custom',
  server: '',
  port: 8080,
  username: '',
  password: '',
  ssl: false,
  starttls: false,
};

export const CreateEmailConnectorModal: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { closeModal } = useModal();
  const fetchConnections = useConnections();
  const [formData, setFormData] = React.useState<FormData>(initialFormDataState);
  const [providers, setProviders] = React.useState<ProviderType[]>([]);
  const isPop3Type = formData.type === 'pop3';
  const schema = useMemo(() => fillEmailConnectionSchema(providers), [providers]);

  const onClose = React.useCallback(() => {
    if (isLoading) return;
    closeModal(ConnectionsModalTypes.CreateEmailConnectorModal);
  }, [isLoading, closeModal]);

  const onSubmit = React.useCallback(async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const { name, description, provider, cron, scheduler, ...config } = formData;
      const connection = await ConnectionApi.createConnection({
        name,
        description,
        source_type: 'email',
      });

      if (config.email_from && provider !== 'SendGrid(SMTP)') {
        delete config.email_from;
      }

      await ConnectorApi.createConnector({
        pk: connection.pk,
        type: 'linkedin',
        data: {
          cron,
          scheduler,
          config,
        },
      });

      fetchConnections();

      closeModal(ConnectionsModalTypes.CreateEmailConnectorModal);
    } catch (e) {
      setIsLoading(false);
    }
  }, [fetchConnections, formData, closeModal, setIsLoading, isLoading]);

  const onChange = React.useCallback(
    (data: { formData: FormData }) => {
      if (data.formData.provider !== formData.provider) {
        data.formData.email_from = '';
        const provider =
          data.formData.provider === 'Custom'
            ? customProvider
            : providers.find((item) => item.name === data.formData.provider);

        if (provider) {
          setFormData((item) => ({
            ...item,
            ...provider,
            provider: data.formData.provider,
          }));
        } else {
          setFormData((item) => ({
            ...item,
            provider: data.formData.provider,
          }));
        }
        return;
      }

      setFormData(data.formData);
    },
    [setFormData, formData, providers],
  );

  useEffect(() => {
    ConnectorApi.fetchProviders().then(setProviders);
  }, []);

  return (
    <ModalWithoutFooter title={messages.title} onClose={onClose}>
      {isPop3Type && (
        <Alert severity="warning">
          <FormattedMessage {...messages.warning} />
        </Alert>
      )}
      {formData.provider === 'Mailchimp(SMTP)' && (
        <Alert severity="warning">
          <FormattedMessage {...messages.mailchimpWarning} />
        </Alert>
      )}
      <Form uiSchema={uiSchema} onChange={onChange} schema={schema} formData={formData}>
        <ModalFooter
          submitMessage={messages.create}
          cancelMessage={messages.cancel}
          hasPadding={false}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </Form>
    </ModalWithoutFooter>
  );
};
