export enum OrderDirection {
  DESC = 'desc',
  ASC = 'asc',
}

export interface Pagination {
  page: number;
  size: number;
  count: number;
  order: OrderDirection;
}

export const defaultTableParams: Pagination = {
  order: OrderDirection.ASC,
  count: 1,
  page: 1,
  size: 5,
};

export const defaultFetchParams: TableFilterParams = {
  page: defaultTableParams.page,
  size: 10000,
  orderBy: 'created_at',
  search: '',
  order: OrderDirection.ASC,
};
