export const Hubspot: ProcessorApiModel = {
  name: 'Hubspot',
  processor_type: 'Hubspot',
  description: 'Hubspot',
  type: 'processor',
  tags: ['Other'],
  data_model: {
    hubspot: {
      type: 'string',
    },
  },
  displayText: 'Makes a request to the Hubspot API on every piece of incoming data',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/Hubspot-icon.png',
  connections: {
    inputPort: true,
    outputPort: true,
  },
};
