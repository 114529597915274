import React from 'react';
import { defineMessages } from 'react-intl';

import { useAppDispatch } from 'store';
import { TopBarSection } from 'components/TopBarSection';
import { useModal, WorkflowsModalTypes } from 'modules/modals';

import { updateSearch } from '../workflowsSlice';

const messages = defineMessages({
  title: {
    id: 'workflows.top-bar-section.title',
    defaultMessage: 'Automations',
  },
  createAutomation: {
    id: 'workflows.top-bar-section.create-automation',
    defaultMessage: 'New Automation',
  },
});

export const AutomationsPageTitle = () => {
  const { openModal } = useModal();
  const dispatch = useAppDispatch();

  const onClick = React.useCallback(() => {
    openModal({
      type: WorkflowsModalTypes.CreateWorkflowModal,
    });
  }, [openModal]);

  const onSearch = React.useCallback(
    (value: string) => {
      dispatch(updateSearch(value));
    },
    [dispatch],
  );

  return (
    <TopBarSection
      title={messages.title}
      newItemText={messages.createAutomation}
      onNewItem={onClick}
      onSearch={onSearch}
    />
  );
};
