import React, { useCallback, DragEvent } from 'react';

import { ListItem, ListItemAvatar, ListItemText, Tooltip } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';

import { useTypedSelector } from 'store';
import { selectIsFavoriteNode } from 'store/nodesSelectors';

import { ModalTypes, useModal, WorkflowsModalTypes } from 'modules/modals';

import './styles.scss';

import { TRANSFER_DATA } from '../../components/constants';
import { NodeSourceType } from '../../components/types';
import { FavoriteIcon } from './FavoriteIcon';
import defaultIcon from './default.png';

type NodeListItemProps = {
  processor: ProcessorApiModel;
};

export const NodeListItem = ({ processor }: NodeListItemProps) => {
  const { openModal, closeModal } = useModal();
  const source: Maybe<NodeSourceType> = useTypedSelector(
    (state) =>
      state.modals.modals.find((item) => item.type === ModalTypes.CreateNodeMenu)?.data
        ?.source as NodeSourceType,
  );
  const isFavoriteNode = useTypedSelector(selectIsFavoriteNode(processor.name));

  const onItemClick = useCallback(() => {
    const { processor_type: processorType, type } = processor;

    closeModal(ModalTypes.CreateNodeMenu);
    openModal({
      type: WorkflowsModalTypes.CreateNodeModal,
      data: {
        processorType,
        source,
        type,
      },
    });
  }, [processor, source, closeModal, openModal]);

  const onDragStart = useCallback(
    (event: DragEvent) => {
      const { processor_type: processorType, type } = processor;

      event.dataTransfer.setData(
        TRANSFER_DATA,
        JSON.stringify({
          source: source?.id,
          processorType,
          type,
        }),
      );

      event.dataTransfer.effectAllowed = 'move';
    },
    [processor, source],
  );

  return (
    <ListItem
      button
      draggable
      onClick={onItemClick}
      onDragStart={onDragStart}
      style={{ paddingRight: '12px' }}
    >
      <i className="icon-movable" />
      <ListItemAvatar>
        <div className="item-icon-wrapper">
          <img
            className="item-icon"
            src={processor.icon_url || defaultIcon}
            alt={`${processor.name} processor icon`}
          />
        </div>
      </ListItemAvatar>
      <ListItemText primary={processor.name} />
      <Tooltip title={processor.description}>
        <InfoIcon style={{ height: '18px' }} />
      </Tooltip>
      <FavoriteIcon isFavorite={isFavoriteNode} name={processor.name} />
    </ListItem>
  );
};
