import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import get from 'lodash/get';

import { List, IconButton } from '@material-ui/core';
import { ControlPoint } from '@material-ui/icons';

import { DatasetsListItem } from './DatasetsListItem';
import { LocalDatasetPropertyType } from './types';
import { useTypedSelector } from '../../../store';
import { selectDatasetPropertyTypes } from '../datasetsSelectors';

const messages = defineMessages({
  noProperties: {
    id: 'datasets.dataset-edit.dataset-property-list.no-properties',
    defaultMessage: 'There are no saved properties. Please add first one.',
  },
});

type DatasetPropertyListType = {
  dataset: IndexApiModel;
  localProperties: LocalDatasetPropertyType[];
  addDataset: () => void;
  onSelect: (fieldName: string, saved: boolean) => void;
  updateLocalDataset: (data: LocalDatasetPropertyType) => void;
};

export const DatasetPropertyList: React.FC<DatasetPropertyListType> = ({
  dataset,
  localProperties,
  onSelect,
  addDataset,
  updateLocalDataset,
}) => {
  const datasetPropertyTypes: DatasetPropertyTypeType[] = useTypedSelector(
    selectDatasetPropertyTypes,
  );

  const properties = React.useMemo(() => {
    const propertiesList = Object.keys(get(dataset, ['mapping', 'properties'], []));

    if (propertiesList.length === 0) {
      return (
        <div className="no-properties-message">
          <FormattedMessage tagName="p" {...messages.noProperties} />
        </div>
      );
    }

    return propertiesList.map((value) => (
      <DatasetsListItem
        id="saved"
        disabled
        key={value}
        onSelect={onSelect}
        fieldName={value}
        datasetPropertyTypes={datasetPropertyTypes}
        fieldType={get(dataset.mapping, ['properties', value, 'type'], '')}
      />
    ));
  }, [datasetPropertyTypes, dataset, onSelect]);

  const lProperties = React.useMemo(
    () =>
      localProperties.map(({ id, name, type }) => (
        <DatasetsListItem
          id={id}
          key={id}
          onSelect={onSelect}
          fieldName={name}
          fieldType={type}
          datasetPropertyTypes={datasetPropertyTypes}
          updateLocalDataset={updateLocalDataset}
        />
      )),
    [datasetPropertyTypes, updateLocalDataset, localProperties, onSelect],
  );

  return (
    <List className="datalist__list">
      <div>
        <h2 className="datalist__list-title">Schema</h2>
        {properties}
        {lProperties}
        <div className="datalist__list-control">
          <IconButton size="medium" onClick={addDataset}>
            <ControlPoint className="add-icon" />
          </IconButton>
        </div>
      </div>
    </List>
  );
};
