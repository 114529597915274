import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { generateLastUpdate } from 'utils/generateLastUpdate';

import {
  fetchConnection,
  fetchConnectorData,
  updateConnection,
  updateConnector,
} from './connectionActions';

type ConnectionStateType = {
  data: unknown;
  loading: boolean;
  error: boolean;
  connectionData: Maybe<ConnectorApiModel>;
  lastUpdated: string;
};

const initialState: ConnectionStateType = {
  data: {},
  connectionData: undefined,
  loading: false,
  error: false,
  lastUpdated: generateLastUpdate(),
};

const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    resetConnectorData(state) {
      state.connectionData = initialState.connectionData;
    },
    updateConnectionData(state, { payload }: PayloadAction<Record<string, ScalarValue>>) {
      if (state.connectionData) {
        state.connectionData = {
          ...state.connectionData,
          credentials: payload,
        };
        state.lastUpdated = generateLastUpdate();
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConnection.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(fetchConnection.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(fetchConnectorData.fulfilled, (state, { payload }) => {
      state.connectionData = payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(fetchConnectorData.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(updateConnection.fulfilled, (state) => {
      state.lastUpdated = generateLastUpdate();
    });
    builder.addCase(updateConnector.fulfilled, (state, { payload }) => {
      state.connectionData = payload;
      state.lastUpdated = generateLastUpdate();
    });
  },
});

export const { resetConnectorData, updateConnectionData } = connectionSlice.actions;

export default connectionSlice.reducer;
