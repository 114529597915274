import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TableSettingsType,
  getTableSettingsInitialValue,
  createTableSettingsReducers,
} from 'types/table';

import { updateWorkflowTagAction } from './workflowsActions';
import {
  deleteAutomation,
  fetchAutomationList,
  setEnableAutomationStatus,
  SetEnableAutomationStatusPayload,
} from './automationActions';

export type WorkflowStateType = {
  workflows: AutomationApiModel[];
  lastUpdated: string;
  tableSettings: TableSettingsType;
};

const initialState: WorkflowStateType = {
  workflows: [],
  lastUpdated: new Date().toISOString(),
  tableSettings: getTableSettingsInitialValue(),
};

const workflowsSlice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {
    ...createTableSettingsReducers(initialState),
    updateWorkflowsTable(state) {
      state.lastUpdated = new Date().toISOString();
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateWorkflowTagAction.fulfilled, (state) => {
      state.lastUpdated = new Date().toISOString();
    });
    builder.addCase(fetchAutomationList.pending, (state) => {
      state.tableSettings.loading = true;
      state.workflows = [];
    });
    builder.addCase(fetchAutomationList.fulfilled, (state, { payload }) => {
      state.workflows = payload.results;
      state.tableSettings.count = payload.count;
      state.tableSettings.loading = false;
    });
    builder.addCase(deleteAutomation.fulfilled, (state, { payload }: PayloadAction<string>) => {
      state.workflows = state.workflows.filter((item) => item.id !== payload);
      state.lastUpdated = new Date().toISOString();
    });
    builder.addCase(deleteAutomation.rejected, (state) => {
      state.tableSettings.loading = false;
    });
    builder.addCase(
      setEnableAutomationStatus.fulfilled,
      (state, { payload }: PayloadAction<SetEnableAutomationStatusPayload>) => {
        state.workflows = state.workflows.map((item) => {
          if (item.id === payload.automationId) {
            return {
              ...item,
              status: payload.enable ? 'running' : 'stopped',
            };
          }
          return item;
        });
      },
    );
  },
});

export const {
  goToSelectedPage,
  updateSize,
  updateWorkflowsTable,
  changeSorting,
  updateSearch,
  reset,
} = workflowsSlice.actions;
export default workflowsSlice.reducer;
