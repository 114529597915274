import { Schema } from 'schemas/types';

export const RawSaverSchema: Schema = {
  type: 'object',
  properties: {
    label: {
      title: 'Label',
      type: 'string',
      default: '',
    },
    path: {
      type: 'string',
      title: 'Path attribute',
      fillSchemaFieldTypes: ['string'],
    },
    data: {
      type: 'string',
      title: 'Data attribute',
      fillSchemaFieldTypes: ['string'],
    },
  },
};
