import React, { FC } from 'react';

import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import WarningIcon from '@material-ui/icons/Warning';

import { ProcessorWarning } from '../utils/validateProcessorField';
import { emptyFunction, getWarningContent } from './getWarningContent';
import { getWarningIconColor } from '../utils/getWarningIconColor';

export type WarningListType = {
  warnings: ProcessorWarning[];
  onClick?: React.MouseEventHandler;
  showProcessorName?: boolean;
  disablePadding?: boolean;
};

export const WarningList: FC<WarningListType> = ({
  warnings,
  onClick = emptyFunction,
  showProcessorName = true,
  disablePadding = false,
}) => {
  if (warnings.length === 0) {
    return null;
  }

  return (
    <List disablePadding={disablePadding}>
      {warnings.map((warning) => (
        <ListItem
          button
          onClick={onClick}
          style={{
            padding: disablePadding ? '8px 0' : '8px 16px',
          }}
          data-id={warning.processorId}
          key={`${warning.processorId}-${warning.name}`}
        >
          <WarningIcon
            style={{
              color: getWarningIconColor(warning.type),
              marginRight: '8px',
            }}
          />
          {getWarningContent(warning, showProcessorName)}
        </ListItem>
      ))}
    </List>
  );
};
