import * as React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { ModalStyled, ModalContent, ModalWrapper } from './common';
import { ModalHeader } from './ModalHeader';

const style = { height: 550, maxHeight: 550 };

type ModalWithoutFooterProps = {
  title: MessageDescriptor;
  isLoading?: boolean;
  onClose(): void;
  width?: number;
};

export const ModalWithoutFooter: React.FC<ModalWithoutFooterProps> = React.memo(
  ({ children, title, onClose, width = 420 }) => {
    return (
      <ModalStyled open onClose={onClose}>
        <ModalWrapper style={{ width }}>
          <ModalHeader onClose={onClose}>
            <FormattedMessage {...title} />
          </ModalHeader>
          <ModalContent style={style}>{children}</ModalContent>
        </ModalWrapper>
      </ModalStyled>
    );
  },
);
