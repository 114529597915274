import React from 'react';
import { useHistory } from 'react-router-dom';

import { TableCell } from '@material-ui/core';

import { useProjectId } from 'hooks';
import { getRoutePath } from 'utils/path';

import { useModal } from 'modules/modals/hooks/useModal';
import { ConnectionsModalTypes } from 'modules/modals/constants';
import { WebsiteRoutes } from 'constants/routes';

type WorkflowsTabCellProps = { id: string; name: string };

export const WorkflowsTabCell: React.FC<WorkflowsTabCellProps> = ({ id, name }) => {
  const { closeModal } = useModal();
  const history = useHistory();
  const { projectId } = useProjectId();

  const onClick = React.useCallback(() => {
    closeModal(ConnectionsModalTypes.EditConnectionModal);
    history.push(
      getRoutePath(WebsiteRoutes.WorkflowEdit, {
        projectId,
        id,
      }),
    );
  }, [projectId, id, closeModal, history]);

  return (
    <TableCell onClick={onClick} style={{ cursor: 'pointer' }}>
      {name}
    </TableCell>
  );
};
