import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import OauthPopup from 'react-oauth-popup';

import Button from '@material-ui/core/Button';

import { useAppDispatch } from 'store';
import { useFlag } from 'modules/modals';

import { updateConnectionData } from '../../connectionSlice';
import type { GmailAuthDataSourceType } from '../../api/GmailConnectorApi';
import { GmailConnectorApi } from '../../api/GmailConnectorApi';

const messages = defineMessages({
  updateToken: {
    id: 'connections.edit-connection-modal.update-token',
    defaultMessage: 'Update token',
  },
});

type GmailReconnectButtonProps = {
  connectionId: string;
  cron: string;
  scheduler: boolean;
};
export const GmailReconnectButton = ({
  connectionId,
  cron,
  scheduler,
}: GmailReconnectButtonProps) => {
  const dispatch = useAppDispatch();
  const { showFlag } = useFlag();

  const googleButtonRef = React.useRef<Nullable<HTMLInputElement>>(null);
  const [connected, setConnected] = React.useState<boolean>(false);
  const [gmailAuthDataSource, setGmailAuthDataSource] =
    React.useState<Maybe<GmailAuthDataSourceType>>(undefined);

  const resetData = () => {
    setConnected(false);
    setGmailAuthDataSource(undefined);
  };

  const onSuccessGoogleLogin = React.useCallback(
    async (code: string) => {
      try {
        if (gmailAuthDataSource?.origin_redirect_url) {
          const { state, redirect_url: redirectUrl, connectionId: id, scope } = gmailAuthDataSource;
          const credentials = await GmailConnectorApi.addGmailConnectorSource({
            id,
            query_params: {
              code,
              scope,
              state,
            },
            redirect_url: redirectUrl,
          });

          dispatch(updateConnectionData(credentials));
          showFlag({
            title: 'Token updated.',
          });
          resetData();
        }
      } catch (e) {
        resetData();
      }
    },
    [dispatch, showFlag, gmailAuthDataSource],
  );

  const onReconnectClick = React.useCallback(async () => {
    const gmailAuthDataSourceResponse = await GmailConnectorApi.fetchGmailAuthDataSourceApi({
      id: connectionId,
      cron,
      scheduler,
    });

    setGmailAuthDataSource({ connectionId, ...gmailAuthDataSourceResponse });
  }, [connectionId, cron, scheduler]);

  React.useLayoutEffect(() => {
    if (!connected && gmailAuthDataSource?.origin_redirect_url) {
      setConnected(true);
      googleButtonRef.current?.click?.();
    }
  }, [setConnected, connected, gmailAuthDataSource]);

  return (
    <>
      <Button variant="outlined" disableElevation color="primary" onClick={onReconnectClick}>
        <FormattedMessage {...messages.updateToken} />
      </Button>
      <OauthPopup
        title="Chose user"
        width={500}
        height={700}
        url={gmailAuthDataSource?.origin_redirect_url || ''}
        onCode={onSuccessGoogleLogin}
        onClose={resetData}
      >
        <div className="has-display-none" ref={googleButtonRef} />
      </OauthPopup>
    </>
  );
};
