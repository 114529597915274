import { UiSchema } from '@rjsf/core';

const textareaSchema = {
  'ui:widget': 'textarea',
  'ui:options': {
    rows: 5,
  },
};

const passwordSchema = {
  'ui:widget': 'password',
};

export const uiSchema: UiSchema = {
  specification: textareaSchema,
  'jolt-spec': textareaSchema,
  password: passwordSchema,
  'ui:options': {
    orderable: false,
  },
  config: {
    password: passwordSchema,
  },
  credentials: {
    password: passwordSchema,
  },
};

export const customProcessorUiSchema = {
  schema: textareaSchema,
  data_model: textareaSchema,
};

export const datasetPropertyFormUISchema = {
  null_value: textareaSchema,
};
