import type {
  ActivityType,
  ActivityChartType,
  ConnectionActivitiesChartType,
  ConnectionActivitiesType,
} from '../../types';

const convert = (item: ActivityType): ActivityChartType => [new Date(item[0] * 1000), item[1]];

export const convertResponseToChartValidDate = (
  items: ConnectionActivitiesType,
): ConnectionActivitiesChartType => ({
  duration: items.duration.map(convert),
  failure: items.failure.map(convert),
  success: items.success.map(convert),
});
