import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestApi } from 'utils/httpClient';
import { buildRequestParams } from 'utils/buildRequestParams';
import { Services } from 'constants/services';

import type { CustomComponentApiModel } from './types';
import {
  CreateCustomProcessorType,
  UpdateCustomComponentType,
} from './components/CustomComponentsTable/types';

export const fetchCustomComponentsApi = async (
  params: TableFilterParams,
): Promise<PaginatedResponse<CustomComponentApiModel>> => {
  const res = await requestApi<PaginatedResponse<CustomComponentApiModel>>({
    service: Services.CustomProcessor,
    params: buildRequestParams(params),
  });

  return res.data;
};

export const fetchCustomComponents = createAsyncThunk<
  PaginatedResponse<CustomComponentApiModel>,
  TableFilterParams
>('customComponents/fetchCustomComponents', fetchCustomComponentsApi);

export const createCustomProcessorAction = createAsyncThunk<
  CustomComponentApiModel,
  CreateCustomProcessorType
>('customComponents/createCustomProcessorAction', async (data) => {
  const prepared = {
    ...data,
    command: data.command.length === 0 ? [] : data.command?.trim().split(' ') || [],
  };

  const res = await requestApi<CustomComponentApiModel>({
    method: 'POST',
    service: Services.CustomProcessor,
    data: prepared,
  });

  return res.data;
});

export const updateCustomComponent = createAsyncThunk<
  CustomComponentApiModel,
  UpdateCustomComponentType
>('customComponents/updateCustomProcessorAction', async (data) => {
  const prepared = {
    ...data,
    command: data.command?.trim().split(' ') || [],
  };

  const res = await requestApi<CustomComponentApiModel>({
    method: 'PUT',
    service: Services.CustomProcessor,
    submethod: `${data.pk}/`,
    data: prepared,
  });

  return res.data;
});

export const deleteCustomComponents = createAsyncThunk<string, string>(
  'customComponents/deleteCustomComponents',
  async (pk: string): Promise<string> => {
    await requestApi({
      method: 'DELETE',
      service: Services.CustomProcessor,
      submethod: `${pk}/`,
    });

    return pk;
  },
);
