import React from 'react';
import { useAppDispatch } from 'store';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';

import { useNavigation } from 'hooks';
import { WebsiteRoutes } from 'constants/routes';
import { useModal, WorkflowsModalTypes } from 'modules/modals';

import { deleteAutomation } from '../automationActions';

const messages = defineMessages({
  title: {
    id: 'automation.modal.confirm-automation-remove-modal.title',
    defaultMessage: 'Remove automation confirmation',
  },
  contentText: {
    id: 'automation.modal.confirm-automation-remove-modal.content-text',
    defaultMessage: 'That looks dangerous, let’s make sure it’s you before continuing.',
  },
  cancel: {
    id: 'automation.modal.confirm-automation-remove-modal.cancel',
    defaultMessage: 'Cancel',
  },
  submit: {
    id: 'automation.modal.confirm-automation-remove-modal.submit',
    defaultMessage: 'Submit',
  },
});

interface ConfirmWorkflowRemoveModalType {
  open: boolean;
  onClose: () => void;
  id: string;
}

export const ConfirmWorkflowRemoveModal: React.FC<ConfirmWorkflowRemoveModalType> = ({
  open,
  onClose,
  id,
}) => {
  const { closeModal } = useModal();
  const dispatch = useAppDispatch();
  const navigate = useNavigation();

  const onSubmit = React.useCallback(async () => {
    await dispatch(deleteAutomation(id)).unwrap();
    onClose();
    closeModal(WorkflowsModalTypes.WorkflowSettingsModal);
    navigate(WebsiteRoutes.Workflows);
  }, [closeModal, navigate, dispatch, id, onClose]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        <FormattedMessage {...messages.title} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage {...messages.contentText} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="primary">
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button variant="contained" onClick={onSubmit} color="primary">
          <FormattedMessage {...messages.submit} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
