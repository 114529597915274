import * as React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const ContentLoaderWrapper = styled.div`
  margin-top: 150px;
  width: 100%;
  text-align: center;
`;

type TabsLoaderProps = {
  isLoading: boolean;
};

export const ContentLoader: React.FC<TabsLoaderProps> = ({ children, isLoading }) => {
  if (isLoading) {
    return (
      <ContentLoaderWrapper>
        <CircularProgress />
      </ContentLoaderWrapper>
    );
  }

  return <>{children}</>;
};
