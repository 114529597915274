import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import MUIAutocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { AddTagSelectWrapper, MUIAutocompleteWrapper } from './TagAutocomplete.style';

export interface Option {
  id?: string;
  name: string;
  inputValue?: string;
}

interface CreatableOption extends Option {
  inputValue: string;
}

interface AddTagSelectProps {
  options: Option[];
  notFilteredOptions: Option[];
  onChange: (option: Option | null) => void;
  onCreate: (option: string) => void;
}

const isSelectedOnEnterKeyPress = (option: Option | string | null): option is string => {
  return typeof option === 'string';
};

const isCreatableOption = (option: Option | CreatableOption | null): option is CreatableOption =>
  option ? !!option.inputValue : false;

const filter = createFilterOptions<Option>();

export const Autocomplete = ({
  options = [],
  notFilteredOptions = [],
  onChange,
  onCreate,
}: AddTagSelectProps) => {
  const notFilteredOptionsNames = notFilteredOptions.map((option) => option.name);

  const [value, setValue] = useState<Option | null>(null);

  return (
    <AddTagSelectWrapper>
      <MUIAutocompleteWrapper>
        <MUIAutocomplete
          value={value}
          onChange={(event, newValue) => {
            if (isSelectedOnEnterKeyPress(newValue)) {
              setValue({
                name: newValue,
              });
            } else if (isCreatableOption(newValue)) {
              // Create a new value from the user input
              onCreate(newValue.inputValue);
              setValue({
                name: newValue.inputValue,
              });
            } else {
              onChange(newValue);
              setValue(newValue);
            }
          }}
          filterOptions={(filterOptions, params) => {
            const filtered = filter(filterOptions, params);

            // Suggest the creation of a new value
            if (params.inputValue !== '' && !notFilteredOptionsNames.includes(params.inputValue)) {
              filtered.push({
                inputValue: params.inputValue,
                name: `Create "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={options}
          getOptionLabel={(option: Option) => {
            if (isSelectedOnEnterKeyPress(option)) {
              return option;
            }

            return isCreatableOption(option) ? option.inputValue : option.name;
          }}
          renderOption={(option) => option.name}
          freeSolo
          renderInput={(params) => <TextField {...params} label="Add tags" variant="outlined" />}
        />
      </MUIAutocompleteWrapper>
    </AddTagSelectWrapper>
  );
};
