import { ApplicationRole } from 'types/roles';

export enum MemberRoles {
  VIEW = 3,
}

export enum ApplicationRoles {
  SUPER_ADMIN = 10,
  USER = 11,
  ADMIN = 12,
}

export const defaultApplicationUserRole: ApplicationRole = {
  id: ApplicationRoles.USER,
  name: 'application.user',
};
