import React from 'react';

import { useModalState } from 'modules/modals';

const HOVER_ACTION_TIMEOUT = 200;

export const useMouseHover = (timeout: number = HOVER_ACTION_TIMEOUT) => {
  const ref = React.useRef<Maybe<NodeJS.Timeout>>(undefined);
  const [isActionsOpen, openActionsOpen, closeActionsOpen] = useModalState();

  const onMouseLeave = () => {
    if (ref.current) {
      clearTimeout(ref.current);
    }

    ref.current = setTimeout(closeActionsOpen, timeout);
  };

  const onMouseEnter = () => {
    if (ref.current) {
      clearTimeout(ref.current);
    }

    ref.current = setTimeout(openActionsOpen, timeout);
  };

  return {
    isActionsOpen,
    onMouseLeave,
    onMouseEnter,
    closeActionsOpen,
  };
};
