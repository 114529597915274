import { createSlice } from '@reduxjs/toolkit';

import {
  createTableSettingsReducers,
  getTableSettingsInitialValue,
  TableSettingsType,
} from 'types/table';

import {
  fetchDataset,
  fetchDatasetPropertyTypes,
  fetchDatasetsList,
  updateDataset,
  updateDatasetSettings,
} from './datasetsActions';

export type DatasetsStateType = {
  datasets: IndexApiModel[];
  dataset: Maybe<IndexApiModel>;
  types: DatasetPropertyTypeType[];
  tableSettings: TableSettingsType;
};

const initialState: DatasetsStateType = {
  datasets: [],
  types: [],
  dataset: undefined,
  tableSettings: getTableSettingsInitialValue(),
};

export const datasetsSlice = createSlice({
  name: 'datasets',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    ...createTableSettingsReducers<DatasetsStateType>(initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDatasetsList.fulfilled, (state, { payload }) => {
      state.datasets = payload.results;
      state.tableSettings.count = payload.count;
    });
    builder.addCase(fetchDatasetPropertyTypes.fulfilled, (state, { payload }) => {
      state.types = payload;
    });
    builder.addCase(fetchDataset.fulfilled, (state, { payload }) => {
      state.dataset = payload;
    });
    builder.addCase(updateDataset.fulfilled, (state, { payload }) => {
      if (state.dataset) {
        state.dataset = payload;
      }
    });
    builder.addCase(updateDatasetSettings.fulfilled, (state, { payload }) => {
      state.datasets = state.datasets.map((item) => (item.pk === payload.pk ? payload : item));
    });
  },
});

export const { reset, changeSorting, updateSize, updateSearch, goToSelectedPage } =
  datasetsSlice.actions;
export default datasetsSlice.reducer;
