import { createSlice } from '@reduxjs/toolkit';

import type { WorkspaceApiModel, MemberApiModel, GroupApiModel } from './types';
import {
  fetchWorkspace,
  addUserProjectPermission,
  deleteUserProjectPermission,
  fetchGroupTypes,
} from './workspaceActions';

type StateType = {
  members: MemberApiModel[];
  groups: GroupApiModel[];
  workspace?: WorkspaceApiModel;
  users: UserApiModel[];
  loading: boolean;
};

const initialState: StateType = {
  members: [],
  groups: [],
  users: [],
  workspace: undefined,
  loading: false,
};

export const { reducer, actions } = createSlice({
  name: 'workspace',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWorkspace.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchWorkspace.fulfilled, (state, { payload }) => {
      state.users = payload.users;
      state.groups = payload.groups;
      state.members = payload.members;
      state.workspace = payload.project;
      state.loading = false;
    });
    builder.addCase(fetchGroupTypes.fulfilled, (state, { payload }) => {
      state.groups = payload;
    });
    builder.addCase(addUserProjectPermission.fulfilled, (state, { payload }) => {
      state.members.push(payload);
    });
    builder.addCase(deleteUserProjectPermission.fulfilled, (state, { payload }) => {
      state.members = state.members.filter((item) => item.user_id !== payload);
    });
  },
});

export default reducer;
