import styled from 'styled-components';

import { AppBar, Box, Select, Toolbar, withStyles } from '@material-ui/core';

import { appBarHeight } from '../constants';

export const AppBarStyled = styled(AppBar)`
  box-shadow: none;
  height: ${appBarHeight};
  background: ${({ theme }) => theme.palette.secondary.main};
`;

export const ToolbarStyled = styled(Toolbar)`
  min-height: ${appBarHeight};
  height: 100%;
  width: 100%;
  padding: 0;
  background: ${({ theme }) => theme.palette.secondary.main}
}

;
`;

export const HeaderWrapper = styled(Box)`
  margin-left: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const WorkspaceSelector = withStyles({
  root: {
    background: 'white',
    padding: '8px 32px 8px 8px',
    width: '180px',
  },
})(Select);
