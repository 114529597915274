import React, { memo } from 'react';

import { PageContentWrapper, getContentWrapperOffset } from 'components/Layout/Layout.style';

import { ConnectedModal } from 'modules/modals/ConnectedModal';
import { WorkspaceCardList } from 'modules/workspaces/components/WorkspaceCardList';
import { getProjectModalByType } from 'modules/workspaces/getProjectModalByType';
import { WorkspacesHeader } from 'modules/workspaces/components/WorkspacesHeader';
import { WorkspacesPageTitle } from 'modules/workspaces/components/WorkspacesPageTitle';

export const WorkspacesPage = memo(() => {
  return (
    <div className="page-wrapper">
      <ConnectedModal modalCreator={getProjectModalByType} />
      <WorkspacesHeader />
      <PageContentWrapper offset={getContentWrapperOffset(false)}>
        <WorkspacesPageTitle />
        <WorkspaceCardList />
      </PageContentWrapper>
    </div>
  );
});
