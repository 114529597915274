import * as React from 'react';
import { defineMessages } from 'react-intl';

import { useAppDispatch, useTypedSelector } from 'store';
import { useModal, WorkflowsModalTypes } from 'modules/modals';
import { TextField } from 'components/Table/TextField';
import { Modal } from 'components/modal';

import { selectAutomationVersionById } from '../workflowSelectors';
import { updateAutomationVersion } from '../automationActions';

const messages = defineMessages({
  title: {
    id: 'automation.edit-automation-version-modal.title',
    defaultMessage: 'Edit automation version',
  },
  update: {
    id: 'automation.edit-automation-version-modal.update',
    defaultMessage: 'Update',
  },
  cancel: {
    id: 'automation.edit-automation-version-modal.cancel',
    defaultMessage: 'Close',
  },
});

type EditAutomationVersionProps = {
  automationId: string;
};

export const EditAutomationVersion: React.FC<EditAutomationVersionProps> = ({ automationId }) => {
  const { closeModal } = useModal();
  const dispatch = useAppDispatch();
  const automation = useTypedSelector(selectAutomationVersionById(automationId));
  const [automationData, setAutomationData] = React.useState<AutomationApiModel>(automation);

  const onClose = React.useCallback(() => {
    closeModal(WorkflowsModalTypes.EditAutomationVersion);
  }, [closeModal]);

  const onSubmit = React.useCallback(async () => {
    await dispatch(updateAutomationVersion(automationData)).unwrap();

    closeModal(WorkflowsModalTypes.EditAutomationVersion);
  }, [closeModal, dispatch, automationData]);

  const onChange = React.useCallback(
    (key: string, value: boolean | string | number) => {
      setAutomationData((item) => ({ ...item, [key]: value }));
    },
    [setAutomationData],
  );

  React.useEffect(() => {
    if (automationData.id !== automation.id) {
      setAutomationData(automation);
    }
  }, [automationData, automation]);

  return (
    <Modal
      title={messages.title}
      submitMessage={messages.update}
      cancelMessage={messages.cancel}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <TextField
        name="name"
        label="Automation name"
        value={automationData.name}
        onChange={onChange}
      />
      <TextField
        name="description"
        label="Automation description"
        value={automationData.description}
        onChange={onChange}
      />
      <TextField
        name="comment"
        label="Automation comment"
        value={automationData.comment}
        onChange={onChange}
      />
    </Modal>
  );
};
