import * as React from 'react';
import { Tooltip } from '@material-ui/core';

import { ConnectionTableFilterType } from '../../types';

type SummaryItemType = {
  icon: string;
  isActive: boolean;
  filterType: ConnectionTableFilterType;
  tooltipText: string;
  onClick: (e: React.SyntheticEvent<HTMLElement>) => void;
};

export const SummaryItem: React.FC<SummaryItemType> = React.memo(
  ({ icon, filterType, isActive, tooltipText, children, onClick }) => (
    <li className="summary-item">
      <Tooltip title={tooltipText}>
        <div
          role="presentation"
          data-type={filterType}
          className={`summary-item__icon ${filterType?.toLocaleLowerCase() || 'connections'}-icon ${
            isActive ? 'active' : ''
          }`}
          onClick={onClick}
        >
          <img src={icon} alt="connections icon" />
        </div>
      </Tooltip>
      <div className="summary-item__description">{children}</div>
    </li>
  ),
);
