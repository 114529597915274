import React from 'react';

import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';

import { useAppDispatch } from 'store';
import { deleteAlert, getAlerts } from 'store/alertMediaSlice';
import { SettingsModalTypes, useModal } from 'modules/modals/index';

import './styles.scss';

export type ConfirmDialogProps = {
  id: string;
};

export const ConfirmDeleteAlertDialog = ({ id }: ConfirmDialogProps) => {
  const { closeModal } = useModal();
  const dispatch = useAppDispatch();

  const onCancel = React.useCallback(() => {
    closeModal(SettingsModalTypes.ConfirmDeleteDialog);
  }, [closeModal]);

  const onSubmit = React.useCallback(async () => {
    await dispatch(deleteAlert({ id }));

    dispatch(getAlerts());
    closeModal(SettingsModalTypes.ConfirmDeleteDialog);
  }, [dispatch, closeModal, id]);

  return (
    <Dialog open aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle className="warning-title" id="alert-dialog-title">
        <Warning />
        <span>Confirm delete</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Alert media will be removed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} color="secondary" variant="outlined">
          Delete and exit
        </Button>
        <Button onClick={onCancel} color="primary" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
