import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { useProjectId } from 'hooks';
import { getRoutePath } from 'utils/path';

import { Modal } from 'components/modal';
import { FormTextField } from 'components/FormTextField';
import { WebsiteRoutes } from 'constants/routes';
import { WorkflowsModalTypes, useModal } from 'modules/modals';

import { createWorkflow } from '../automationApi';

export const FormTextLabel = styled.p`
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.main};
`;

type InitialValuesType = {
  name: string;
  description: string;
};

export const initialValues: InitialValuesType = {
  name: '',
  description: '',
};

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Name of automation should not be empty'),
    description: Yup.string().required('Description of automation should not be empty'),
  });

const messages = defineMessages({
  title: {
    id: 'automation.create-automation-modal.title',
    defaultMessage: 'New Automation',
  },
  cancelButtonText: {
    id: 'automation.create-automation-modal.cancel-button-text',
    defaultMessage: 'Cancel',
  },
  submitButtonText: {
    id: 'automation.create-automation-modal.submit-button-text',
    defaultMessage: 'Create Automation',
  },
  nameLabel: {
    id: 'automation.create-automation-modal.submit-button-text',
    defaultMessage: 'Automation name',
  },
  descriptionLabel: {
    id: 'automation.create-automation-modal.submit-button-text',
    defaultMessage: 'Automation description',
  },
});

export const CreateWorkflowModal: React.FC = () => {
  const { projectId } = useProjectId();
  const { closeModal } = useModal();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const history = useHistory();

  const onSubmit = React.useCallback(
    async ({ name, description }: InitialValuesType) => {
      try {
        if (isLoading) return;
        setIsLoading(true);

        const { id } = await createWorkflow(name, description);

        closeModal(WorkflowsModalTypes.CreateWorkflowModal);
        history.push(
          getRoutePath(WebsiteRoutes.WorkflowEdit, {
            id,
            projectId,
          }),
        );
      } catch (e) {
        setIsLoading(false);
      }
    },
    [projectId, history, isLoading, setIsLoading, closeModal],
  );

  const onClose = React.useCallback(() => {
    if (isLoading) return;
    closeModal(WorkflowsModalTypes.CreateWorkflowModal);
  }, [closeModal, isLoading]);

  const { handleSubmit, errors, handleChange, values } = useFormik<InitialValuesType>({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  return (
    <Modal
      title={messages.title}
      cancelMessage={messages.cancelButtonText}
      submitMessage={messages.submitButtonText}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <form onSubmit={handleSubmit}>
        <FormTextLabel>
          <FormattedMessage {...messages.nameLabel} />
        </FormTextLabel>
        <FormTextField
          name="name"
          value={values.name}
          variant="outlined"
          fullWidth
          size="small"
          errorText={errors.name}
          onChange={handleChange}
        />
        <p className="has-top-margin">
          <FormTextLabel>
            <FormattedMessage {...messages.descriptionLabel} />
          </FormTextLabel>
          <FormTextField
            name="description"
            value={values.description}
            variant="outlined"
            fullWidth
            size="small"
            errorText={errors.description}
            onChange={handleChange}
          />
        </p>
      </form>
    </Modal>
  );
};
