import React, { useEffect, useState } from 'react';

import { useDebounce } from './useDebounce';

export const MIN_SEARCH_STRING_LENGTH = 3;

export const useDebouncedSearch = (onSearch: (value: string) => void) => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 750);

  useEffect(() => {
    if (
      debouncedSearchValue?.length >= MIN_SEARCH_STRING_LENGTH ||
      debouncedSearchValue?.length === 0
    ) {
      onSearch(debouncedSearchValue);
    }
  }, [onSearch, debouncedSearchValue]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return {
    onChange,
    searchValue,
  };
};
