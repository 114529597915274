import React, { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Input } from '@material-ui/core';

import { getTableSettingsInitialValue } from 'types/table';
import { defaultTableParams } from 'constants/table';
import { LogsLevelSelector } from 'components/LogsLevelSelector';
import { CommonTable } from 'components/Table/CommonTable';
import { Message } from 'components/Message';

import { useDebounce, useLogsTableContent, useTable } from 'hooks';

import { AutomationEditParams } from '../../types';
import { fetchAutomationLogs } from '../../automationApi';

type ProcessorLogsViewType = {
  processorId: string;
};

export const ProcessorLogsView: FC<ProcessorLogsViewType> = ({ processorId }) => {
  const { id: automationId } = useParams<AutomationEditParams>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [level, setLevel] = useState<LogsLevel>('all');
  const [logs, setLogs] = useState<LogApiModel[]>([]);
  const { tableParams, onSizeChange, onSearchChange, onCountChange, onPageChange, setTableParams } =
    useTable();

  const { search, page, size } = tableParams;

  const tableContent = useLogsTableContent(logs);
  const debouncedSearch = useDebounce(search, 300);

  const onLevelChange = useCallback(
    (value) => {
      setLevel(value);
      onPageChange(defaultTableParams.page);
      if (value === 'all') {
        setTableParams(getTableSettingsInitialValue());
      }
    },
    [setLevel, onPageChange, setTableParams],
  );

  const getConnectionLogs = () => {
    setLoading(true);

    fetchAutomationLogs({ automationId, tableParams, processorId, level })
      .then(({ results, count }) => {
        onCountChange(count);
        setLogs(results);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getConnectionLogs();
  }, [debouncedSearch, level, page, size]);

  return (
    <>
      <div className="processor-logs-control-panel">
        <Input placeholder="Search" onChange={onSearchChange} value={tableParams.search} />
        <LogsLevelSelector level={level} onChange={onLevelChange} />
      </div>
      <CommonTable
        content={tableContent}
        totalItems={tableParams.count}
        size={tableParams.size}
        page={tableParams.page}
        goToPage={onPageChange}
        onSizeChange={onSizeChange}
        isPagination
      />
      <Message loading={isLoading} count={tableParams.count} search={tableParams.search || level} />
    </>
  );
};
