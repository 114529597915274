import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import without from 'lodash/without';

import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';

type FetchNodeListResponse = {
  processors: ProcessorApiModel[];
  common: ProcessorApiModel[];
  user_components: ProcessorApiModel[];
  connection: ProcessorApiModel[];
};

export const fetchNodeList = createAsyncThunk('nodes/fetchNodeList', async () => {
  const response = await requestApi<FetchNodeListResponse>({
    service: Services.NifiManager,
    submethod: 'flow/components/',
  });

  return response.data;
});

export interface NodesStateType {
  favoriteNodes: string[];
  userNodes: ProcessorApiModel[];
  error: string;
  loading: boolean;
  lastUpdated: string;
}

const initialState: NodesStateType = {
  userNodes: [],
  favoriteNodes: [],
  error: '',
  loading: false,
  lastUpdated: new Date().toISOString(),
};

export const nodesSlice = createSlice({
  name: 'nodes',
  initialState,
  reducers: {
    updateNodeList(state) {
      state.lastUpdated = new Date().toISOString();
    },
    addElementsToFavorite(state, { payload }: PayloadAction<{ elements: string[] }>) {
      state.favoriteNodes = state.favoriteNodes.concat(payload.elements);
      return state;
    },
    removeElementsFromFavorite(state, { payload }: PayloadAction<{ elements: string[] }>) {
      state.favoriteNodes = without(state.favoriteNodes, ...payload.elements);
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNodeList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNodeList.fulfilled, (state, { payload }) => {
      state.userNodes = payload.user_components || [];

      state.loading = false;
    });
  },
});

export const { addElementsToFavorite, removeElementsFromFavorite } = nodesSlice.actions;

export default nodesSlice.reducer;
