import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { useConnectionsWorkflows } from 'hooks';

import { Message } from 'components/Message';

import { WorkflowsTabCell } from '../WorkflowsTabCell';

type PermissionsTabProps = {
  pk: string;
};

export const WorkflowsTab: React.FC<PermissionsTabProps> = ({ pk }) => {
  const { workflows, isWorkflowsLoading } = useConnectionsWorkflows(pk);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Automation name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workflows.map(({ id, name }) => (
            <TableRow key={id}>
              <WorkflowsTabCell id={id} name={name} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Message loading={isWorkflowsLoading} search="" count={workflows.length} />
    </TableContainer>
  );
};
