import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Paper, Chip, Button } from '@material-ui/core';

import icon from 'assets/icon.png';
import { useProjectId } from 'hooks';
import { getRouteById, WebsiteRoutes } from 'constants/routes';

import { WorkspaceCardActionsPopover } from './WorkspaceCardActionsPopover';
import '../../styles.scss';
import './styles.scss';
import { WorkspaceAvatar } from './WorkspaceAvatar';
import { WorkspaceApiModel } from '../../types';

const messages = defineMessages({
  dataModels: { id: 'workspaces.workspace-card.data-models', defaultMessage: 'Data Models' },
  connections: { id: 'workspaces.workspace-card.connections', defaultMessage: 'Connections' },
  automations: { id: 'workspaces.workspace-card.automations', defaultMessage: 'Automations' },
});

type WorkspaceCardType = {
  workspace: WorkspaceApiModel;
};

export const WorkspaceCard: React.FC<WorkspaceCardType> = React.memo(({ workspace }) => {
  const history = useHistory();
  const { setProjectId } = useProjectId();

  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      setProjectId(workspace.pk);

      history.push(getRouteById(WebsiteRoutes.Workflows, workspace.pk));
    },
    [history, setProjectId, workspace.pk],
  );

  return (
    <Paper className="workspace-card">
      <header className="workspace-card__title">
        <Button className="workspace-card__title-button" onClick={onClick}>
          <img className="workspace-card__logo" alt="workspace logo" src={icon} />
          <h3>{workspace.name}</h3>
          <WorkspaceAvatar name={workspace.name} src={workspace.project_image_url} />
        </Button>
        <WorkspaceCardActionsPopover pk={workspace.pk} archived={workspace.archived} />
      </header>
      <p className="workspace-card__description">{workspace.description}</p>
      {workspace.archived && (
        <div className="has-top-mar gin">
          <Chip label="Archived" />
        </div>
      )}
      <ul className="workspace-card__info-list">
        <li className="workspace-card__info-list-item">
          <FormattedMessage tagName="p" {...messages.automations} />
          <span>{workspace.automation_count}</span>
        </li>
        <li className="workspace-card__info-list-item">
          <FormattedMessage tagName="p" {...messages.connections} />
          <span>{workspace.connection_count}</span>
        </li>
        <li className="workspace-card__info-list-item">
          <FormattedMessage tagName="p" {...messages.dataModels} />
          <span>{workspace.dataset_count}</span>
        </li>
      </ul>
    </Paper>
  );
});
