import { Schema } from 'schemas/types';

export type FormData = {
  name: string;
  description: string;
};

export const initialFormDataState: FormData = {
  name: '',
  description: '',
};

export const projectSchema: Schema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      title: 'Name',
    },
    description: {
      type: 'string',
      title: 'Description',
    },
  },
};
