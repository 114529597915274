import * as React from 'react';

import { Tooltip } from '@material-ui/core';
import { Star, StarBorder } from '@material-ui/icons';

import { useAppDispatch } from 'store';
import { addElementsToFavorite, removeElementsFromFavorite } from 'store/nodesSlice';

type FavoriteIconType = { isFavorite: boolean; name: string };

export const FavoriteIcon: React.FC<FavoriteIconType> = ({ name, isFavorite }) => {
  const dispatch = useAppDispatch();

  const onClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      const payload = {
        elements: [name],
      };
      if (isFavorite) {
        dispatch(removeElementsFromFavorite(payload));
      } else {
        dispatch(addElementsToFavorite(payload));
      }
    },
    [dispatch, isFavorite, name],
  );

  if (isFavorite) {
    return (
      <Tooltip title="Remove node from favorite">
        <Star style={{ height: '20px' }} onClick={onClick} />
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Add node to favorite">
      <StarBorder style={{ height: '20px' }} onClick={onClick} />
    </Tooltip>
  );
};
