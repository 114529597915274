import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { List, ListItem, ListItemText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useAppDispatch } from 'store';
import type { OnChangeEvent } from 'types';
import { useProjectId } from 'hooks';

import { ApplicationRoles } from 'constants/roles';
import { ModalWithoutFooter } from 'components/modal';
import { Message } from 'components/Message';

import { useModal, useFlag, ModalTypes } from 'modules/modals';

import { GroupApiModel } from '../../types';
import { WorkspaceApi, UserWorkspace } from '../../WorkspaceApi';
import { UserRoleApi } from '../../UserRoleApi';
import { updateUserProjectPermission, fetchGroupTypes } from '../../workspaceActions';
import { RoleSelectorImp } from '../RoleSelector';
import { UserWorkspacesListItem } from './UserWorkspacesListItem';
import { UserWorkspaceSelectorForm } from './UserWorkspaceSelectorForm';

const messages = defineMessages({
  title: {
    id: 'automation.user-settings-modal.title',
    defaultMessage: 'User settings',
  },
  subtitle: {
    id: 'automation.user-settings-modal.title',
    defaultMessage: 'User workspaces roles:',
  },
  warning: {
    id: 'automation.set-project-permission-modal.warning',
    defaultMessage: 'All changes will be applied immediately.',
  },
});

type UserWorkspacesPermissionModalType = {
  userId: string;
};

export const UserWorkspacesPermissionModal: React.FC<UserWorkspacesPermissionModalType> = ({
  userId,
}) => {
  const { closeModal } = useModal();
  const dispatch = useAppDispatch();
  const { showFlag } = useFlag();
  const { projectId } = useProjectId();
  const [applicationGroups, setApplicationGroups] = React.useState<GroupApiModel[]>([]);
  const [userApplicationRole, setUserApplicationRole] = React.useState<number>(
    ApplicationRoles.USER,
  );
  const [userWorkspaces, setUserWorkspaces] = React.useState<UserWorkspace[]>([]);

  const onApplicationRoleChange = React.useCallback(
    async (event: OnChangeEvent) => {
      if (event.target.value) {
        try {
          setUserApplicationRole(event.target.value as number);
          await UserRoleApi.updateUserRole(event.target.value as number, userId);

          showFlag({
            title: 'Permission updated',
          });
        } catch {
          showFlag({
            title: 'Something went wrong.',
            variant: 'error',
          });
        }
      }
    },
    [userId, showFlag, setUserApplicationRole],
  );

  const refetchUserWorkspaces = React.useCallback(() => {
    WorkspaceApi.fetchUserWorkspaces(userId).then(setUserWorkspaces);
  }, [userId, setUserWorkspaces]);

  const onGroupChange = React.useCallback(
    async ({ workspaceId, group }: { workspaceId: string; group: number }) => {
      try {
        await dispatch(
          updateUserProjectPermission({
            workspaceId,
            params: {
              groups: [group],
              user_id: userId,
            },
          }),
        ).unwrap();

        showFlag({
          title: 'Permission updated',
        });
      } catch {
        showFlag({
          title: 'Something went wrong.',
          variant: 'error',
        });
      }

      refetchUserWorkspaces();
    },
    [userId, refetchUserWorkspaces, dispatch, showFlag],
  );

  const onClose = React.useCallback(() => {
    closeModal(ModalTypes.UserWorkspacesPermissionModal);
  }, [closeModal]);

  React.useEffect(() => {
    dispatch(fetchGroupTypes(projectId));

    refetchUserWorkspaces();
    UserRoleApi.fetchRoles().then(setApplicationGroups);
    UserRoleApi.fetchUserRole(userId).then((role) => setUserApplicationRole(role.id));
  }, [refetchUserWorkspaces, dispatch, projectId, userId]);

  return (
    <ModalWithoutFooter width={600} title={messages.title} onClose={onClose}>
      <Alert className="has-bottom-margin" severity="info">
        <FormattedMessage tagName="p" {...messages.warning} />
      </Alert>
      <ListItem style={{ padding: '0' }}>
        <ListItemText primary="User application role:" />
        <RoleSelectorImp
          value={userApplicationRole}
          error={false}
          onChange={onApplicationRoleChange}
          groups={applicationGroups}
        />
      </ListItem>
      <UserWorkspaceSelectorForm
        userId={userId}
        workspacesIds={userWorkspaces.map((item) => item.object_pk)}
        refetchUserWorkspaces={refetchUserWorkspaces}
      />
      <div style={{ fontSize: 20, margin: '12px 0 4px', fontWeight: 500 }}>
        <FormattedMessage tagName="p" {...messages.subtitle} />
        <List disablePadding>
          {userWorkspaces.map((userWorkspace) => (
            <UserWorkspacesListItem
              key={userWorkspace.id}
              workspaceId={userWorkspace.object_pk}
              onGroupChange={onGroupChange}
              workspaceName={userWorkspace.workspace}
              group={userWorkspace.groups[0]}
            />
          ))}
        </List>
        <Message loading={false} entityName="user workspaces" count={userWorkspaces.length} />
      </div>
    </ModalWithoutFooter>
  );
};
