import { defaultTableParams, OrderDirection } from 'constants/table';

import { Params } from './Params.types';

export const defaultParams: Params = {
  page: defaultTableParams.page,
  size: 10000,
  orderBy: 'created_at',
  search: '',
  order: OrderDirection.ASC,
};

export const getDefaultParams = () => {
  return { ...defaultParams };
};

type GetIsAscType = { columnName: string } & Pick<Params, 'orderBy' | 'order'>;

export const getOrder = ({ columnName, order, orderBy }: GetIsAscType): OrderDirection => {
  const isASC = orderBy === columnName && order === defaultTableParams.order;

  return isASC ? OrderDirection.DESC : OrderDirection.ASC;
};
