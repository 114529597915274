import React, { memo } from 'react';

import { Layout } from 'components/Layout';

import { ConnectedModal } from 'modules/modals';
import { AutomationsTable } from 'modules/automation/components/AutomationsTable/AutomationsTable';
import { getAutomationModalByType } from 'modules/automation/modals/getAutomationModalByType';
import { AutomationsPageTitle } from 'modules/automation/components/AutomationsPageTitle';

export const AutomationsPage = memo(() => {
  return (
    <Layout>
      <ConnectedModal modalCreator={getAutomationModalByType} />
      <AutomationsPageTitle />
      <AutomationsTable />
    </Layout>
  );
});
