import React, { FC, createContext, useContext } from 'react';

import { useTypedSelector } from 'store';

import { selectNodes } from './workflowSelectors';
import { AutomationContext } from './AutomationContext';
import {
  ProcessorWarning,
  validateProcessorConnections,
  validateProcessorFields,
} from './components/utils/validateProcessorField';

type AutomationWarningsContextType = {
  warnings: ProcessorWarning[];
};

export const AutomationWarningsContext = createContext<AutomationWarningsContextType>({
  warnings: [],
});

export const AutomationWarningsContextProvider: FC = ({ children }) => {
  const nodes = useTypedSelector(selectNodes);
  const { connections } = useContext(AutomationContext);

  const warnings: ProcessorWarning[] = React.useMemo(() => {
    return [...validateProcessorConnections(nodes, connections), ...validateProcessorFields(nodes)];
  }, [connections, nodes]);

  return (
    <AutomationWarningsContext.Provider value={{ warnings }}>
      {children}
    </AutomationWarningsContext.Provider>
  );
};
