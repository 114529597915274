import React, { FC, useState, useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Alert } from '@material-ui/lab';

import { useAppDispatch, useTypedSelector } from 'store';

import { useModal, DatasetsModalTypes } from 'modules/modals';
import { Modal } from 'components/modal';

import { fetchDatasetsList } from '../datasetsActions';
import { DatasetApi } from '../DatasetApi';

const messages = defineMessages({
  title: {
    id: 'automation.delete-datasets-modal.title',
    defaultMessage: 'Delete Datasets',
  },
  confirmation: {
    id: 'automation.delete-datasets-modal.confirmation',
    defaultMessage: 'Are you sure that you want to delete this dataset?',
  },
  warning: {
    id: 'automation.delete-datasets-modal.confirmation',
    defaultMessage: 'This dataset still is in use. Please remove all use cases first',
  },
  continue: {
    id: 'automation.delete-datasets-modal.create',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'automation.delete-datasets-modal.cancel',
    defaultMessage: 'Cancel',
  },
});

type DeleteDatasetModalType = {
  id: string;
  automationCount: number;
};

export const DeleteDatasetModal: FC<DeleteDatasetModalType> = ({ id, automationCount }) => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();
  const tableSettings = useTypedSelector((state) => state.datasets.tableSettings);

  const [disabled] = useState<boolean>(automationCount !== 0);

  const onSubmit = useCallback(async () => {
    await DatasetApi.delete(id);

    dispatch(fetchDatasetsList(tableSettings));
    closeModal(DatasetsModalTypes.DeleteDatasetModal);
  }, [dispatch, closeModal, id, tableSettings]);

  const onClose = React.useCallback(() => {
    closeModal(DatasetsModalTypes.DeleteDatasetModal);
  }, [closeModal]);

  return (
    <Modal
      title={messages.title}
      cancelMessage={messages.cancel}
      submitMessage={messages.continue}
      onClose={onClose}
      onSubmit={onSubmit}
      submitDisabled={disabled}
    >
      {disabled && (
        <Alert severity="warning">
          <FormattedMessage {...messages.warning} />
        </Alert>
      )}
      <div className="has-top-margin has-bottom-margin">
        <FormattedMessage tagName="p" {...messages.confirmation} />
      </div>
    </Modal>
  );
};
