import React, { useState, useEffect } from 'react';

import { Paper } from '@material-ui/core';

import { useTable } from 'hooks';
import { CommonTable } from 'components/Table/CommonTable';

import { DatasetDataType } from './types';
import { useDatasetDataTableContent } from './useDatasetDataTableContent';
import { DatasetDocumentApi } from '../DatasetDocumentApi';

type DatasetDataPanelProps = {
  dataset: IndexApiModel;
};

export const DatasetDataPanel = ({ dataset }: DatasetDataPanelProps) => {
  const { tableParams, onSizeChange, onPageChange, setTableParams } = useTable();
  const [fields, setFields] = useState<DatasetDataType[]>([]);
  const tableContent = useDatasetDataTableContent(fields);

  const fetchDatasetData = () => {
    DatasetDocumentApi.getDatasetDocument(dataset.pk, tableParams).then((data) => {
      setFields(data.results);
      setTableParams((item) => ({ ...item, count: dataset.entries }));
    });
  };

  useEffect(() => {
    fetchDatasetData();
  }, [dataset.pk, tableParams.size, tableParams.page]);

  return (
    <div className="dataset-edit-data-panel">
      <Paper>
        <CommonTable
          content={tableContent}
          totalItems={dataset.entries}
          size={tableParams.size}
          page={tableParams.page}
          goToPage={onPageChange}
          onSizeChange={onSizeChange}
          isPagination
        />
      </Paper>
    </div>
  );
};
