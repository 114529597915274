import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getDefaultParams, getOrder, Params } from 'common/Params';
import { TabContent } from 'components/Tabs';
import { ModalTitleStyled } from 'components/modal/ModalHeader';

import { WorkspaceVariablesApi } from 'modules/variables/WorkspaceVariablesApi';
import { VariablesTable } from 'modules/variables/VariablesTable';
import { ParamInput } from 'modules/variables/ParamInput';
import { useFlag } from 'modules/modals';
import { Variable } from 'modules/variables/types';

import { AutomationVariablesApi } from '../../AutomationVariablesApi';
import { AutomationEditParams } from '../../types';

const nope = async () => {};

export const AutomationVariables = () => {
  const { id } = useParams<AutomationEditParams>();
  const [globalVariables, setGlobalVariables] = useState<Variable[]>([]);
  const [params, setParams] = React.useState<Params>(getDefaultParams);
  const [variables, setVariables] = useState<Variable[]>([]);
  const { showFlag } = useFlag();

  const onCreateParam = useCallback(
    async (param: Variable) => {
      const createdParam = await AutomationVariablesApi.createParam(id, param);

      setVariables((items) => {
        return [createdParam, ...items];
      });

      showFlag({ title: 'Created!' });
    },
    [id, showFlag],
  );

  const onUpdateParam = useCallback(
    async (param: Variable) => {
      await AutomationVariablesApi.updateParam(id, param);

      setVariables((items) => items.map((item) => (item.id === param.id ? param : item)));

      showFlag({ title: 'Updated!' });
    },
    [id, setVariables, showFlag],
  );

  const onRemove = useCallback(
    async (param: Variable) => {
      await AutomationVariablesApi.removeParam(id, param.id);

      setVariables((items) => items.filter((item) => item.id !== param.id));

      showFlag({
        title: 'Removed!',
      });
    },
    [id, setVariables, showFlag],
  );

  const onClickSort = useCallback((columnName: string) => {
    setParams((item) => {
      return {
        ...item,
        orderBy: columnName,
        order: getOrder({
          orderBy: item.orderBy,
          order: item.order,
          columnName,
        }),
      };
    });
  }, []);

  useEffect(() => {
    WorkspaceVariablesApi.fetchParams().then((items) => setGlobalVariables(items));
  }, []);

  useEffect(() => {
    AutomationVariablesApi.fetchParams(id, params).then((items) => setVariables(items));
  }, [id, params]);

  return (
    <>
      <TabContent>
        <div>
          <ModalTitleStyled>Local variables</ModalTitleStyled>
          <ParamInput createParam={onCreateParam} />
          <VariablesTable
            sortable
            params={params}
            items={variables}
            removeParam={onRemove}
            updateParam={onUpdateParam}
            onClickSort={onClickSort}
            showActions
          />
        </div>
        {globalVariables.length !== 0 && (
          <div className="has-top-margin">
            <ModalTitleStyled>Global variables</ModalTitleStyled>
            <VariablesTable items={globalVariables} removeParam={nope} updateParam={nope} />
          </div>
        )}
      </TabContent>
    </>
  );
};
