import React, { FC } from 'react';
import get from 'lodash/get';

import { generateId } from 'utils/generateId';

import { LocalDatasetPropertyType } from './types';
import { DatasetPropertyList } from './DatasetPropertyList';
import { DatasetPropertyForm } from './DatasetPropertyForm';

const createEmptyDatasetProperty = () => ({
  id: generateId(),
  name: '',
  type: 'text',
  formData: {},
});

type DatasetPropertyListPanelProps = {
  dataset: IndexApiModel;
  addPropertyToDataset: (property: LocalDatasetPropertyType) => void;
};

export const DatasetPropertyListPanel: FC<DatasetPropertyListPanelProps> = ({
  dataset,
  addPropertyToDataset,
}) => {
  const [selected, setSelected] = React.useState<Maybe<LocalDatasetPropertyType>>(undefined);
  const [localProperties, setLocalProperties] = React.useState<LocalDatasetPropertyType[]>([]);

  const addDataset = React.useCallback(() => {
    setLocalProperties([...localProperties, createEmptyDatasetProperty()]);
  }, [localProperties, setLocalProperties]);

  const updateLocalDataset = React.useCallback(
    (data: LocalDatasetPropertyType): void => {
      setLocalProperties((properties: LocalDatasetPropertyType[]): LocalDatasetPropertyType[] =>
        properties.map((item: LocalDatasetPropertyType): LocalDatasetPropertyType => {
          if (selected?.id === data.id) {
            setSelected(data);
          }

          return item.id === data.id ? data : item;
        }),
      );
    },
    [setLocalProperties, setSelected, selected],
  );

  const onSave = React.useCallback(
    (data: LocalDatasetPropertyType) => {
      addPropertyToDataset({
        ...data,
        name: selected?.name || data.name,
        type: selected?.type || data.type,
      });

      setSelected(undefined);
      setLocalProperties((items) => items.filter((item) => item.id !== data.id));
    },
    [selected, addPropertyToDataset, setLocalProperties],
  );

  const onCancel = React.useCallback(() => {
    setSelected(undefined);
  }, [setSelected]);

  const onSelect = React.useCallback(
    (name: string, saved: boolean) => {
      if (saved) {
        const { type, ...formData } = get(dataset, ['mapping', 'properties', name]);

        setSelected({
          id: 'saved',
          name,
          type,
          formData,
        } as LocalDatasetPropertyType);
      } else {
        const property = localProperties.find((item) => item.id === name);

        setSelected(property);
      }
    },
    [localProperties, setSelected, dataset],
  );

  return (
    <div className="dataset-edit-schema-panel">
      <DatasetPropertyList
        addDataset={addDataset}
        dataset={dataset}
        onSelect={onSelect}
        updateLocalDataset={updateLocalDataset}
        localProperties={localProperties}
      />
      {selected && <DatasetPropertyForm property={selected} save={onSave} onCancel={onCancel} />}
    </div>
  );
};
