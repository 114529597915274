import { requestApi } from 'utils/httpClient';
import { buildRequestParams } from 'utils/buildRequestParams';

import { Services } from 'constants/services';

export const fetchApiKeys = async (
  params: TableFilterParams,
): Promise<PaginatedResponse<ApiKeyApiModel>> => {
  const res = await requestApi<PaginatedResponse<ApiKeyApiModel>>({
    service: Services.CustomProcessor,
    submethod: 'api-key/',
    params: buildRequestParams(params),
  });

  return res.data;
};
