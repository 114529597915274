import { createAsyncThunk } from '@reduxjs/toolkit';

import { WorkspaceApi } from './WorkspaceApi';
import type { WorkspaceApiModel, MemberApiModel, GroupApiModel } from './types';

import type {
  UpdateUserProjectPermissionPayload,
  DeleteUserProjectPermissionPayload,
} from './WorkspaceApi';
import { fetchUsers } from './fetchUsers';

export const fetchWorkspace = createAsyncThunk('workspaces/fetchWorkspace', async (id: string) => {
  const [project, users, members, groups] = await Promise.all([
    WorkspaceApi.fetchProject(id),
    fetchUsers(),
    WorkspaceApi.fetchMembers(id),
    WorkspaceApi.fetchGroupTypes(id),
  ]);

  return {
    users,
    members,
    groups,
    project,
  };
});

export const updateWorkspace = createAsyncThunk(
  'workspace/updateWorkspace',
  WorkspaceApi.updateWorkspace,
);
export const fetchWorkspaces = createAsyncThunk<
  PaginatedResponse<WorkspaceApiModel>,
  TableFilterParams
>('workspace/fetchWorkspaces', WorkspaceApi.fetchWorkspaces);

export const deleteWorkspace = createAsyncThunk<string, string>(
  'workspaces/deleteWorkspace',
  WorkspaceApi.deleteWorkspace,
);

export const updateUserProjectPermission = createAsyncThunk<
  MemberApiModel,
  UpdateUserProjectPermissionPayload
>('workspace/updateUserProjectPermission', WorkspaceApi.updateUserProjectPermission);

export const addUserProjectPermission = createAsyncThunk<
  MemberApiModel,
  UpdateUserProjectPermissionPayload
>('workspace/addUserProjectPermission', WorkspaceApi.updateUserProjectPermission);

export const deleteUserProjectPermission = createAsyncThunk<
  string,
  DeleteUserProjectPermissionPayload
>('workspace/deleteUserProjectPermission', WorkspaceApi.deleteUserProjectPermissionApi);

export const fetchGroupTypes = createAsyncThunk<GroupApiModel[], string>(
  'workspaces/fetchGroupTypesAction',
  WorkspaceApi.fetchGroupTypes,
);
