import { createTheme } from '@material-ui/core/styles';

import { overrides } from './overrides';
import { palette } from './palette';

const zIndex = {
  appBar: 1250,
  drawer: 1200,
};

export const theme = createTheme({
  palette,
  overrides,
  zIndex,
});
