import * as React from 'react';
import { AxiosError } from 'axios';

import { ConfirmDeleteAlertDialog } from 'modules/modals/components/AlertMediaSettingsTab/ConfirmDeleteAlertDialog';
import { CreateEditAlertDialog } from 'modules/modals/components/AlertMediaSettingsTab/CreateEditAlertDialog';

import { ModalStoreType } from './modalsSlice';
import { ModalTypes, SettingsModalTypes, WorkspaceModalTypes } from './constants';
import { ErrorFlag } from './components/ErrorFlag';
import { UserSettingModal } from './components/UserSettingModal';
import { ApplicationSettingsModal } from '../workspaces/components/AdminSettingsModal/ApplicationSettingsModal';
import { UserWorkspacesPermissionModal } from '../workspaces/components/AdminSettingsModal/UserPermissionModal';
import {
  EditWorkspaceModal,
  EditWorkspaceModalType,
} from '../workspaces/components/EditWorkspaceModal/EditWorkspaceModal';

export const getModalByType = (item: ModalStoreType, index: number): React.ReactNode | null => {
  switch (item.type) {
    case ModalTypes.ErrorSnackbar:
      return <ErrorFlag key={`error-snackbar-${index}`} error={item?.data?.error as AxiosError} />;
    case ModalTypes.UserSettingModal:
      return <UserSettingModal key="user-settings" />;
    case ModalTypes.ApplicationSettingsModal:
      return <ApplicationSettingsModal key="application-settings" />;
    case ModalTypes.UserWorkspacesPermissionModal:
      return (
        <UserWorkspacesPermissionModal
          key="user-permissions-settings"
          userId={item?.data?.userId as string}
        />
      );
    case SettingsModalTypes.ConfirmDeleteDialog:
      return (
        <ConfirmDeleteAlertDialog key="settings-delete-dialog" id={item?.data?.id as string} />
      );
    case SettingsModalTypes.CreateEditAlertDialog:
      return (
        <CreateEditAlertDialog
          key="settings-create-edit-dialog"
          id={item?.data?.id as Maybe<string>}
        />
      );
    case WorkspaceModalTypes.EditWorkspaceModal:
      return (
        <EditWorkspaceModal key="edit-workspace-modal" {...(item.data as EditWorkspaceModalType)} />
      );

    default:
      return null;
  }
};
