import { persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';

import workflows, { WorkflowStateType } from 'modules/automation/workflowsSlice';
import modals from 'modules/modals/modalsSlice';
import workflow from 'modules/automation/workflowSlice';
import connections, { ConnectionStateType } from 'modules/connections/connectionsSlice';
import connection from 'modules/connections/connectionSlice';
import datasets, { DatasetsStateType } from 'modules/datasets/datasetsSlice';
import workspaces, { WorkspacesStateType } from 'modules/workspaces/workspacesSlice';

import workspace from 'modules/workspaces/workspaceSlice';
import credentials from 'modules/credentials/credentialsSlice';
import customComponents, {
  CustomComponentsStateType,
} from 'modules/custom-components/customComponentsSlice';

import { createPersistConfig } from './createPersistConfig';

import tags from './tagsSlice';
import nodes, { NodesStateType } from './nodesSlice';
import userSettings, { UserSettingsState } from './userSettingsSlice';
import alertMedias from './alertMediaSlice';

export const persistedReducer = combineReducers({
  workflow,
  tags,
  connection,
  workspace,
  alertMedias,
  modals,
  workflows: persistReducer(createPersistConfig<WorkflowStateType>('workflows'), workflows),
  nodes: persistReducer(createPersistConfig<NodesStateType>('nodes', ['favoriteNodes']), nodes),
  connections: persistReducer(createPersistConfig<ConnectionStateType>('connections'), connections),
  datasets: persistReducer(createPersistConfig<DatasetsStateType>('datasets'), datasets),
  workspaces: persistReducer(createPersistConfig<WorkspacesStateType>('workspaces'), workspaces),
  userSettings: persistReducer(
    createPersistConfig<UserSettingsState>('userSettings', ['workspaceId']),
    userSettings,
  ),
  customComponents: persistReducer(
    createPersistConfig<CustomComponentsStateType>('customComponents'),
    customComponents,
  ),
  credentials,
});
