import React, { FC, useCallback, useState } from 'react';
import { Method } from 'axios';

import { Button } from '@material-ui/core';

import { ErrorIcon, SuccessIcon } from 'components/icons';

import { isTestHubspotErrorResponse, testHotspotApi } from '../../../automationApi';
import { ProcessorWarning, WarningTypes } from '../../../components/utils/validateProcessorField';

import type { HubspotType } from './types';
import { createHubspotData } from './utils';

export type HubspotControlPanelProps = {
  processorId: string;
  method?: Method;
  apikey?: string;
  url?: string;
  setWarnings: (ws: ProcessorWarning[]) => void;
};

export const HubspotControlPanel: FC<HubspotControlPanelProps> = ({
  method,
  apikey,
  url,
  setWarnings,
  processorId,
}) => {
  const [hubspot, setHubspot] = useState<HubspotType>(createHubspotData('none'));

  const onTestHotspotClick = useCallback(async () => {
    if (!apikey || !url || !method) {
      return;
    }

    setHubspot(createHubspotData('none'));

    try {
      const res = await testHotspotApi({
        method,
        apikey,
        url,
      });

      if (isTestHubspotErrorResponse(res)) {
        setWarnings([
          {
            processorId,
            processorType: 'Hubspot',
            name: res.category || 'ERROR',
            issue: 'invalid_value',
            message: res.message,
            type: WarningTypes.ERROR,
          },
        ]);

        setHubspot(createHubspotData('error'));
        return;
      }

      setWarnings([]);
      setHubspot({
        selectedData: res,
        data: res,
        status: 'success',
      });
    } catch (e) {
      setHubspot(createHubspotData('error'));
    }
  }, [setWarnings, apikey, url, method, processorId]);

  return (
    <div className="hubspot-control-panel">
      <div className="has-flex-space-between">
        <div className="hubspot-control-buttons">
          <Button variant="contained" color="primary" onClick={onTestHotspotClick}>
            Test
          </Button>
        </div>
        {hubspot.status === 'error' && <ErrorIcon />}
        {hubspot.status === 'success' && <SuccessIcon />}
      </div>
    </div>
  );
};
