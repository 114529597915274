import * as React from 'react';

export type EditProcessorModalContextType = {
  isOpen: boolean;
  selectedProcessorId: Maybe<string>;
  setSelectedProcessorId: (value: Maybe<string>) => void;
};

export const EditProcessorModalContext = React.createContext<EditProcessorModalContextType>({
  isOpen: false,
  selectedProcessorId: undefined,
  setSelectedProcessorId: () => {},
});

export const EditProcessorModalContextProvider: React.FC = ({ children }) => {
  const [selectedProcessorId, setSelectedProcessorId] = React.useState<Maybe<string>>(undefined);

  return (
    <EditProcessorModalContext.Provider
      value={{
        isOpen: Boolean(selectedProcessorId),
        selectedProcessorId,
        setSelectedProcessorId,
      }}
    >
      {children}
    </EditProcessorModalContext.Provider>
  );
};
