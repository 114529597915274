import * as React from 'react';

import '../AutomationHeader/styles.scss';

import { StatisticStatusType } from '../../types';

type AutomationStatisticSectionType = {
  automationStatisticStatus: StatisticStatusType;
};

export const AutomationStatisticSection: React.FC<AutomationStatisticSectionType> = React.memo(
  ({ automationStatisticStatus }) => (
    <section className="automation-statistic-section">
      <span>
        Queued: <span className="statistic-value">{automationStatisticStatus.queued}</span>
      </span>
      <span>
        Read/Write:
        <span className="statistic-value">
          {automationStatisticStatus.read} / {automationStatisticStatus.written}
        </span>
      </span>
    </section>
  ),
);
