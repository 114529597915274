import React, { useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { useTypedSelector } from 'store';
import { WarningIcon } from 'components/icons';

import { StatisticStatusType } from '../../types';
import {
  selectAutomationStatisticStatus,
  selectIsAutomationRunning,
} from '../../workflowSelectors';
import { AutomationStatisticSection } from './AutomationStatisticSection';
import { AutomationEditFooterWrapper } from './AutomationEditFooter.style';

const messages = defineMessages({
  runningWarning: {
    id: 'running-warning',
    defaultMessage: 'Automation should be running to show statistic.',
  },
  warning: {
    id: 'no-entity-id-warning',
    defaultMessage: 'Statistic is not available.',
  },
});

export const AutomationEditFooter = () => {
  const isAutomationRunning = useTypedSelector(selectIsAutomationRunning);
  const automationStatisticStatus: Maybe<StatisticStatusType> = useTypedSelector(
    selectAutomationStatisticStatus,
  );

  const content = useMemo(() => {
    if (!isAutomationRunning) {
      return (
        <>
          <WarningIcon /> <FormattedMessage {...messages.runningWarning} />
        </>
      );
    }

    return automationStatisticStatus ? (
      <AutomationStatisticSection automationStatisticStatus={automationStatisticStatus} />
    ) : (
      <>
        <WarningIcon /> <FormattedMessage {...messages.warning} />
      </>
    );
  }, [isAutomationRunning, automationStatisticStatus]);

  return <AutomationEditFooterWrapper>{content}</AutomationEditFooterWrapper>;
};
