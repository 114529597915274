import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useTypedSelector } from 'store';
import { fetchNodeList } from 'store/nodesSlice';

import { ConnectedModal } from 'modules/modals';

import {
  fetchAutomationStatistic,
  getWorkflowDataAction,
} from 'modules/automation/automationActions';
import { EditProcessorModalContextProvider } from 'modules/automation/modals/EditProcessorModal/EditProcessorModalContextProvider';
import { getAutomationModalByType } from 'modules/automation/modals/getAutomationModalByType';
import { reset } from 'modules/automation/workflowSlice';
import { AutomationEditParams } from 'modules/automation/types';
import { AutomationCanvas } from 'modules/automation/components/AutomationCanvas/AutomationCanvas';
import { AutomationHeader } from 'modules/automation/components/AutomationHeader/AutomationHeader';
import { selectIsAutomationRunning } from 'modules/automation/workflowSelectors';
import { AutomationEditFooter } from 'modules/automation/components/AutomationFooter/AutomationEditFooter';
import { INFO_TIMER_INTERVAL } from 'modules/automation/components/constants';
import { AutomationContextProvider } from 'modules/automation/AutomationContext';
import { AutomationWarningsContextProvider } from 'modules/automation/AutomationWarnigsContext';

export const AutomationEditPage = React.memo(() => {
  const dispatch = useAppDispatch();
  const { id } = useParams<AutomationEditParams>();
  const infoTimerRef = useRef<Maybe<NodeJS.Timer>>(undefined);
  const isAutomationRunning = useTypedSelector(selectIsAutomationRunning);

  React.useEffect(() => {
    dispatch(getWorkflowDataAction(id));
    dispatch(fetchNodeList());

    return () => {
      dispatch(reset());
    };
  }, [dispatch, id]);

  React.useEffect(() => {
    if (isAutomationRunning && !infoTimerRef.current) {
      dispatch(fetchAutomationStatistic(id));

      infoTimerRef.current = setInterval(() => {
        dispatch(fetchAutomationStatistic(id));
      }, INFO_TIMER_INTERVAL);
    }

    return () => {
      if (infoTimerRef.current) {
        clearInterval(infoTimerRef.current);
      }
    };
  }, [isAutomationRunning, id, dispatch]);

  return (
    <div className="page-wrapper has-direction-column">
      <AutomationContextProvider>
        <AutomationWarningsContextProvider>
          <EditProcessorModalContextProvider>
            <ConnectedModal modalCreator={getAutomationModalByType} />
            <AutomationHeader />
            <AutomationCanvas />
            <AutomationEditFooter />
          </EditProcessorModalContextProvider>
        </AutomationWarningsContextProvider>
      </AutomationContextProvider>
    </div>
  );
});
