import React from 'react';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

import { useModal, ModalTypes } from 'modules/modals/index';
import { generateId } from 'utils/generateId';

export const ErrorFlag = ({ error }: { error: AxiosError }) => {
  const { closeModal } = useModal();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let details = '';

  if (error.response) {
    if (error.response.data) {
      details = JSON.stringify(error.response.data);
    }
  }

  const key = generateId();

  enqueueSnackbar(
    <div>
      <p>{error.message}</p>
      <p style={{ whiteSpace: 'pre-line' }}>{details}</p>
    </div>,
    { key, variant: 'error' },
  );

  setTimeout(() => {
    closeSnackbar(key);
  }, 3000);

  React.useEffect(() => {
    closeModal(ModalTypes.ErrorSnackbar);
  }, [closeModal]);

  return <></>;
};
