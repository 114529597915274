import * as React from 'react';
import { MessageDescriptor, FormattedMessage } from 'react-intl';

import { ModalStyled, ModalContent, ModalWrapper } from './common';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';

const style = { height: 550, maxHeight: 550 };

type ModalProps = {
  title: MessageDescriptor;
  cancelMessage?: MessageDescriptor;
  submitMessage: MessageDescriptor;
  children: React.ReactNode | React.ReactNodeArray;
  isLoading?: boolean;
  submitDisabled?: boolean;
  width?: number;
  height?: number;
  onClose(): void;
  onSubmit(): void;
};

export const Modal = React.memo(
  ({
    onClose,
    onSubmit,
    children,
    title,
    submitMessage,
    cancelMessage,
    isLoading = false,
    submitDisabled = false,
    width = 420,
    height = 600,
  }: ModalProps) => {
    return (
      <ModalStyled open onClose={onClose}>
        <ModalWrapper style={{ width, height }}>
          <ModalHeader onClose={onClose}>
            <FormattedMessage {...title} />
          </ModalHeader>
          <ModalContent style={style}>{children}</ModalContent>
          <ModalFooter
            submitDisabled={submitDisabled}
            cancelMessage={cancelMessage}
            submitMessage={submitMessage}
            onCancel={onClose}
            onSubmit={onSubmit}
            isLoading={isLoading}
          />
        </ModalWrapper>
      </ModalStyled>
    );
  },
);
