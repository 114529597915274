import { createAsyncThunk } from '@reduxjs/toolkit';

import { requestApi } from 'utils/httpClient';
import { buildRequestParams } from 'utils/buildRequestParams';
import { Services } from 'constants/services';

export const fetchDockerCredentials = createAsyncThunk<
  PaginatedResponse<DockerCredentialApiModel>,
  TableFilterParams
>('credential/fetchDockerCredentials', async (params) => {
  const res = await requestApi<PaginatedResponse<DockerCredentialApiModel>>({
    service: Services.CustomProcessor,
    submethod: 'docker-credentials/',
    params: buildRequestParams(params),
  });

  return res.data;
});

export type CreateDockerCredentialType = {
  name: string;
  username: string;
  password: string;
  server: string;
};

export const createDockerCredential = createAsyncThunk<
  DockerCredentialApiModel,
  CreateDockerCredentialType
>('credential/createDockerCredential', async (data) => {
  const res = await requestApi<DockerCredentialApiModel>({
    method: 'POST',
    service: Services.CustomProcessor,
    submethod: 'docker-credentials/',
    data,
  });

  return res.data;
});

export const updateDockerCredential = createAsyncThunk<
  DockerCredentialApiModel,
  DockerCredentialApiModel
>('credential/updateDockerCredential', async (data) => {
  const res = await requestApi<DockerCredentialApiModel>({
    method: 'PUT',
    service: Services.CustomProcessor,
    submethod: 'docker-credentials/',
    data,
  });

  return res.data;
});

export const deleteDockerCredential = createAsyncThunk<string, string>(
  'credential/deleteDockerCredential',
  async (pk: string) => {
    await requestApi({
      method: 'DELETE',
      service: Services.CustomProcessor,
      submethod: `docker-credentials/${pk}/`,
    });

    return pk;
  },
);
