import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  Button,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { useAppDispatch, useTypedSelector } from 'store';
import { getAlerts, reset } from 'store/alertMediaSlice';

import { Message } from 'components/Message';
import { TabContent } from 'components/Tabs';
import { ModalTitleStyled } from 'components/modal/ModalHeader';

import { useModal, SettingsModalTypes } from 'modules/modals/index';

import { AlertMediaActionsCell } from './AlertMediaActionsCell';

const messages = defineMessages({
  title: {
    id: 'modal.components.alert-media-settings-modal.general-settings-tab.title',
    defaultMessage: 'Alert Media Settings',
  },
  create: {
    id: 'modal.components.alert-media-settings-modal.general-settings-tab.create',
    defaultMessage: 'Create',
  },
});

export const AlertMediaSettingsTab: React.FC = () => {
  const { openModal } = useModal();

  const dispatch = useAppDispatch();
  const medias = useTypedSelector(({ alertMedias }) => alertMedias.alertMedias);

  React.useEffect(() => {
    dispatch(getAlerts());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const onCreateClick = React.useCallback(() => {
    openModal({
      type: SettingsModalTypes.CreateEditAlertDialog,
    });
  }, [openModal]);

  return (
    <>
      <TabContent>
        <ModalTitleStyled>
          <FormattedMessage {...messages.title} />
        </ModalTitleStyled>
        <TableContainer className="has-top-margin">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Config Type</TableCell>
                <TableCell>Email</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            {medias.length > 0 && (
              <TableBody>
                {medias.map((mediaAlert) => (
                  <TableRow key={mediaAlert.pk}>
                    <TableCell>{mediaAlert.name}</TableCell>
                    <TableCell>{mediaAlert.config_type}</TableCell>
                    <TableCell>{mediaAlert.config.email}</TableCell>
                    <TableCell>
                      <AlertMediaActionsCell id={mediaAlert.pk} />
                    </TableCell>
                  </TableRow>
                ))}
                <Message loading={false} count={medias.length} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </TabContent>
      <DialogActions>
        <Button variant="outlined" disableElevation color="primary" onClick={onCreateClick}>
          <FormattedMessage {...messages.create} />
        </Button>
      </DialogActions>
    </>
  );
};
