export enum TagsTableSectionStrings {
  DeleteModalTitle = 'Are you sure you want to delete tag from this workflow?',
  DeleteModalText = 'This action can’t be undone',
  DeletePrimaryButton = 'Delete it',
  DeleteSecondaryButton = 'Keep it, I changed my mind',
  AddModalTitle = 'Tags manager',
  AddPrimaryButton = 'Add it',
  AddSecondaryButton = 'Cancel',

  TagName = 'Enter tag name',
  TagNameError = 'Tag is taken',
  TagDescription = 'Enter tag description',
  TagColor = 'Tag color',
  EditPrimaryButton = 'Save',
  EditSecondaryButton = 'Cancel',
  TagNameRequired = 'Name is required',
}
