import React, { memo } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';

import { WebsiteRoutes } from 'constants/routes';

import { ConnectedModal } from 'modules/modals';

import { ProjectRoute } from 'routing/ProjectRoute';
import { ProtectedRoute } from 'routing/ProtectedRoute';

import { DatasetEditPage } from 'pages/DatasetEditPage';
import { AutomationEditPage } from 'pages/AutomationEditPage';
import { AutomationsPage } from 'pages/AutomationsPage';
import { ConnectionsPage } from 'pages/ConnectionsPage';
import { DatasetsPage } from 'pages/DatasetsPage';
import { WorkspacesPage } from 'pages/WorkspacesPage';
import { CredentialsPage } from 'pages/CredentialsPage';
import { CustomComponentsPage } from 'pages/CustomComponentsPage';

export const Routes = memo(() => (
  <Router>
    <ConnectedModal />
    <Switch>
      <ProtectedRoute exact path={WebsiteRoutes.Workspaces}>
        <WorkspacesPage />
      </ProtectedRoute>
      <ProjectRoute exact path={WebsiteRoutes.Workflows}>
        <AutomationsPage />
      </ProjectRoute>
      <ProjectRoute exact path={WebsiteRoutes.WorkflowEdit}>
        <AutomationEditPage />
      </ProjectRoute>
      <ProjectRoute exact path={WebsiteRoutes.Connections}>
        <ConnectionsPage />
      </ProjectRoute>
      <ProjectRoute exact path={WebsiteRoutes.Datasets}>
        <DatasetsPage />
      </ProjectRoute>
      <ProjectRoute exact path={WebsiteRoutes.DatasetsEdit}>
        <DatasetEditPage />
      </ProjectRoute>
      <ProjectRoute exact path={WebsiteRoutes.CustomComponents}>
        <CustomComponentsPage />
      </ProjectRoute>
      <ProjectRoute exact path={WebsiteRoutes.Credentials}>
        <CredentialsPage />
      </ProjectRoute>
      <Redirect to={WebsiteRoutes.Workspaces} />
    </Switch>
  </Router>
));
