import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { requestApi } from 'utils/httpClient';

export const checkPermissions = createAsyncThunk(
  'auth/checkPermissions',
  async ({ projectId }: { projectId: string }) => {
    const response = await requestApi({
      service: 'project/permission/project',
      projectId,
      params: {
        permissions: 'project_level_can_edit',
      },
    });

    return response.data;
  },
);

export type UserSettingsState = {
  workspaceId: string;
  hasPermissions: boolean;
  permissionsChecked: boolean;
};

const initialState: UserSettingsState = {
  workspaceId: '',
  hasPermissions: false,
  permissionsChecked: false,
};

const { reducer, actions } = createSlice({
  name: 'userSettingSlice',
  initialState,
  reducers: {
    updateProjectId(state: UserSettingsState, { payload }: PayloadAction<string>) {
      localStorage.setItem('PROJECT_ID', payload); // TODO remove it and usage in httpClient latter
      state.workspaceId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkPermissions.fulfilled, (state) => {
      state.hasPermissions = true;
      state.permissionsChecked = true;
    });
    builder.addCase(checkPermissions.rejected, (state) => {
      state.hasPermissions = false;
      state.permissionsChecked = true;
    });
  },
});

export const selectWorkspaceId = (state: RootState) => state.userSettings.workspaceId;
export const selectHasPermissions = (state: RootState) => state.userSettings.hasPermissions;
export const selectPermissionsChecked = (state: RootState) => state.userSettings.permissionsChecked;

export const { updateProjectId } = actions;

export default reducer;
