import { SchemaProperty } from 'schemas/types';

import { fillEntityProperty } from './fillEntityProperty';
import { EntityMapName, isEntityName } from '../../types';
import { fillDataModelPropertyByTypes } from './fillDataModelPropertyByTypes';
import { findDatasetPropertiesById } from './findDatasetPropertiesById';

export const fillSchemaProperty = (
  property: SchemaProperty,
  entities: EntityMapName,
  dataModel: DataModel,
  datasetId?: string,
) => {
  if (property.entityName && isEntityName(property.entityName)) {
    fillEntityProperty(property, entities[property.entityName]);
  }

  if (property.entityName === 'data_model') {
    fillEntityProperty(
      property,
      Object.keys(dataModel).map((name) => ({ name, pk: name })),
    );
  }

  if (property.fillSchemaFieldTypes) {
    fillDataModelPropertyByTypes(property, dataModel, property.fillSchemaFieldTypes);
  }

  if (
    typeof property?.items?.properties?.dataset_property !== 'boolean' &&
    property?.items?.properties?.dataset_property?.enum &&
    typeof property?.items?.properties?.datamodel_property !== 'boolean' &&
    property?.items?.properties?.datamodel_property?.enum
  ) {
    property.items.properties.dataset_property.enum = datasetId
      ? Object.keys(findDatasetPropertiesById(entities.dataset, datasetId))
      : [];
    property.items.properties.datamodel_property.enum = Object.keys(dataModel);
  }
};
