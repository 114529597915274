import styled from 'styled-components';
import { Paper } from '@material-ui/core';

const paperSize = 40;

export const AddProcessorButtonPaper = styled(Paper)`
  position: absolute;
  margin: 56px 8px 8px;
  width: ${paperSize}px;
  height: ${paperSize}px;
  z-index: 1000;
  transition: left 0.2s;

  button {
    padding: 0;
    max-width: ${paperSize}px;
    min-width: ${paperSize}px;
    width: ${paperSize}px;
    height: ${paperSize}px;
  }

  img {
    margin: 10px auto;
    width: 20px;
    height: 20px;
  }
`;
