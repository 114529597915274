import { Schema } from 'schemas/types';

export const IfSchema: Schema = {
  type: 'object',
  properties: {
    label: {
      title: 'Label',
      type: 'string',
      default: '',
    },
    attribute: {
      title: 'Data attribute',
      type: 'string',
      default: '',
      enum: [],
      fillSchemaFieldTypes: ['string'],
    },
    function: {
      title: 'Match Function',
      type: 'string',
      enum: [
        'startsWith',
        'endsWith',
        'contains',
        'equals',
        'equalsIgnoreCase',
        'gt',
        'ge',
        'lt',
        'le',
      ],
    },
    value: {
      type: 'string',
      title: 'Value',
      default: '',
    },
  },
};
