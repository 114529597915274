import React from 'react';

import { TableCell, TableRow as MuiTableRow } from '@material-ui/core';

import type { TableRowParams } from './Table.types';

const ignoredKeys = ['id', 'pk', ''];

type TableRowProps = {
  row: TableRowParams;
};

export const TableRow: React.FC<TableRowProps> = ({ row }) => {
  const cells: JSX.Element[] = [];

  for (const columnName of Object.keys(row)) {
    const cell = row[columnName];

    if (cell && !ignoredKeys.includes(columnName)) {
      cells.push(<TableCell key={columnName}>{cell}</TableCell>);
    }
  }

  return <MuiTableRow>{cells}</MuiTableRow>;
};
