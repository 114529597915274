import * as React from 'react';

import { useTypedSelector } from 'store';

import { ProgressBar, AutomationEditPanelWrapper } from './AutomationEditPanel.style';
import { AddProcessorButton } from './AddProcessorButton';
import { DeployStatusContainer } from './DeployStatus.container';

export const AutomationPanel = () => {
  const loading = useTypedSelector(({ workflow }) => workflow.status === 'loading');
  const deploying = useTypedSelector(({ workflow }) => workflow.deployStatus === 'deploying');

  return (
    <AutomationEditPanelWrapper>
      {(loading || deploying) && <ProgressBar />}
      <DeployStatusContainer />
      <AddProcessorButton />
    </AutomationEditPanelWrapper>
  );
};
