import styled from 'styled-components';

import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

export const DeployStatusWrapper = styled.section<{ deployStatus: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  background: ${(props) => {
    if (props.deployStatus === 'failed') {
      return 'rgba(250,65,0,0.2)';
    }

    return 'rgba(95, 210, 85, 0.2)';
  }};
`;

export const DeployStateText = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 8px;
  }
`;

export const FinishedIcon = styled(CheckCircleOutlineIcon)`
  color: #107c10;
`;

export const FailedIcon = styled(WarningIcon)`
  color: #d83b01;
`;
