import React from 'react';

import type { TableContent, TableRowParams } from 'components/Table';

import { UserPermissionActionsCell } from './UserPermissionActiosCell';

export interface ConnectionsContent extends TableRowParams {
  id: string;
  name: JSX.Element;
  actions: JSX.Element;
}

const userTableContent: TableContent<ConnectionsContent> = {
  header: {
    name: {
      width: '15%',
      name: 'User name',
      sortable: true,
    },
    actions: {
      name: '',
      sortable: false,
    },
  },
  rows: [],
};

export const useUserPermissionTableContent = (
  items: UserApiModel[],
): TableContent<ConnectionsContent> => {
  userTableContent.rows = items.map((user) => ({
    id: user.sub,
    name: <p>{user.preferred_username}</p>,
    actions: <UserPermissionActionsCell pk={user.sub} />,
  }));

  return userTableContent;
};
