import * as React from 'react';
import { defineMessages } from 'react-intl';

import { useAppDispatch } from 'store';

import { Modal } from 'components/modal';
import { WorkspaceModalTypes } from 'modules/modals/constants';
import { useModal } from 'modules/modals/hooks/useModal';

import { fetchWorkspace } from '../workspaceActions';
import { UserPermissionList } from './UserPermissionList';

const messages = defineMessages({
  title: {
    id: 'automation.create-project-modal.title',
    defaultMessage: 'Edit workspace permissions',
  },
  continue: {
    id: 'automation.set-project-permission-modal.create',
    defaultMessage: 'Continue',
  },
  cancel: {
    id: 'automation.set-project-permission-modal.cancel',
    defaultMessage: 'Close',
  },
});

export type SetProjectPermissionModalProps = {
  workspaceId: string;
};

export const SetProjectPermissionModal = React.memo(
  ({ workspaceId }: SetProjectPermissionModalProps) => {
    const dispatch = useAppDispatch();
    const { closeModal, openModal } = useModal();

    const onClose = React.useCallback(() => {
      closeModal(WorkspaceModalTypes.SetWorkspacePermissionModal);
    }, [closeModal]);

    const onContinue = React.useCallback(() => {
      openModal({ type: WorkspaceModalTypes.FirstStepModal, data: { id: workspaceId } });
      closeModal(WorkspaceModalTypes.SetWorkspacePermissionModal);
    }, [openModal, closeModal, workspaceId]);

    React.useEffect(() => {
      dispatch(fetchWorkspace(workspaceId));
    }, [dispatch, workspaceId]);

    return (
      <Modal
        width={600}
        title={messages.title}
        submitMessage={messages.continue}
        onClose={onClose}
        cancelMessage={messages.cancel}
        onSubmit={onContinue}
      >
        <UserPermissionList
          listClassName="create-workspace-modal-permission-list"
          workspaceId={workspaceId}
        />
      </Modal>
    );
  },
);
