export const HtmlToText: ProcessorApiModel = {
  name: 'HtmlToText',
  processor_type: 'HtmlToText',
  description: 'HtmlToText',
  type: 'processor',
  tags: ['Transform'],
  data_model: {
    title: {
      type: 'string',
    },
    text: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    published: {
      type: 'string',
    },
    modified: {
      type: 'string',
    },
  },
  displayText: 'Convert html to text from :data parameter.',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/HtmlToText-icon.png',
  connections: {
    inputPort: true,
    outputPort: true,
  },
};
