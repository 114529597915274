import * as React from 'react';

import English from '../lang/en.json';

type LanguageType = {
  locale: string;
  lang: Record<string, string>;
};

enum Languages {
  EN = 'en',
}

export const useLanguage = (): LanguageType => {
  const locale = React.useRef<string>(navigator.language);

  let lang;
  switch (locale.current) {
    case Languages.EN:
      lang = English;
      break;
    default:
      lang = English;
  }

  return {
    locale: locale.current,
    lang,
  };
};
