import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { TabContent } from 'components/Tabs';
import { ModalTitleStyled } from 'components/modal/ModalHeader';
import { CommonTable } from 'components/Table/CommonTable';

import { fetchUsers } from '../../fetchUsers';

import { useUserPermissionTableContent } from './useUserPermissionTableContent';

const messages = defineMessages({
  title: {
    id: 'automation.set-project-permission-modal.title',
    defaultMessage: 'User permissions',
  },
  close: {
    id: 'automation.set-project-permission-modal.close',
    defaultMessage: 'Close',
  },
});

type UserPermissionTabType = {
  onClose: () => void;
};

export const UserPermissionTab: React.FC<UserPermissionTabType> = () => {
  const [users, setUsers] = React.useState<UserApiModel[]>([]);

  const content = useUserPermissionTableContent(users);

  React.useEffect(() => {
    fetchUsers().then(setUsers);
  }, [setUsers]);

  return (
    <TabContent style={{ height: 570 }}>
      <ModalTitleStyled>
        <FormattedMessage {...messages.title} />
      </ModalTitleStyled>
      <CommonTable content={content} />
    </TabContent>
  );
};
