import { Method } from 'axios';

import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';
import { TableSettingsType } from 'types/table';

import { toDot } from './components/utils';
import type { DeployWorkflowPayload } from './types';
import { buildFetchConnectionLogsRequestParams } from '../connections/utils';
import { createEmptyGraph } from './components/utils/dot';

export const createWorkflow = async (
  name: string,
  description: string,
): Promise<AutomationApiModel> => {
  const createdWorkflowPayload = await requestApi<AutomationApiModel>({
    method: 'POST',
    service: Services.NifiManager,
    submethod: `flow/`,
    data: {
      name,
      description,
      dot_content: createEmptyGraph(),
    },
  });

  return createdWorkflowPayload.data;
};

export const updateAutomation = async (data: AutomationApiModel): Promise<AutomationApiModel> => {
  const res = await requestApi<AutomationApiModel>({
    method: 'PATCH',
    service: Services.NifiManager,
    submethod: `flow/${data.id}/`,
    data,
  });

  return res.data;
};

export const revertAutomationVersion = async (
  automationId: string,
  revertedAutomationId: string,
): Promise<string> => {
  const res = await requestApi<{ actual_automation_id: string }>({
    method: 'POST',
    service: Services.NifiManager,
    submethod: `flow/${automationId}/revert/`,
    data: {
      revert_id: revertedAutomationId,
    },
  });

  return res.data.actual_automation_id;
};

export const saveAutomation = async ({
  id,
  nodes = [],
  edges = [],
}: DeployWorkflowPayload): Promise<AutomationApiModel> => {
  const dotContent = toDot(nodes, edges);

  const { data: updatedWorkflow } = await requestApi<AutomationApiModel>({
    service: Services.NifiManager,
    method: 'POST',
    submethod: `flow/${id}/save/`,
    data: {
      dot_content: dotContent,
    },
  });

  return updatedWorkflow;
};

export const deleteWorkflowApi = async (automationId: string): Promise<string> => {
  await requestApi({
    service: Services.NifiManager,
    method: 'DELETE',
    submethod: `flow/${automationId}/`,
  });

  return automationId;
};

export const fetchAutomationConnections = async (
  automationId: string,
): Promise<AssociatedEntity[]> => {
  const res = await requestApi<AssociatedEntity[]>({
    service: Services.Connectors,
    submethod: `connections/${automationId}/`,
    isAnonymousMethod: true,
  });

  return res.data;
};

export const fetchAutomationApiKeys = async (automationId: string): Promise<AssociatedEntity[]> => {
  const res = await requestApi<AssociatedEntity[]>({
    service: Services.CustomProcessor,
    submethod: `apikeys/${automationId}/`,
  });

  return res.data;
};

export const fetchAutomationDatasets = async (
  automationId: string,
): Promise<AssociatedEntity[]> => {
  const res = await requestApi<AssociatedEntity[]>({
    service: Services.Search,
    submethod: `datasets/${automationId}/`,
  });

  return res.data;
};

type FetchAutomationLogsParams = {
  automationId: string;
  tableParams: TableSettingsType;
  level: LogsLevel;
  processorId?: string;
};

export const fetchAutomationLogs = async ({
  automationId,
  tableParams,
  level,
  processorId,
}: FetchAutomationLogsParams): Promise<PaginatedResponse<LogApiModel>> => {
  const res = await requestApi<PaginatedResponse<LogApiModel>>({
    service: Services.LogsManager,
    submethod: `automation/${automationId}/`,
    isAnonymousMethod: true,
    params: buildFetchConnectionLogsRequestParams(tableParams, level, processorId),
  });

  return res.data;
};

type TestHubspotApiPayload = {
  method: Method;
  apikey: string;
  url: string;
};

type TestHubspotErrorResponse = {
  category: string;
  correlationId: string;
  status: 'error';
  message: string;
};

type TestHubspotResponse = TestHubspotErrorResponse | Record<string, unknown>;

export const isTestHubspotErrorResponse = (
  res: TestHubspotResponse,
): res is TestHubspotErrorResponse => {
  return (<TestHubspotErrorResponse>res).status === 'error';
};

export const testHotspotApi = async ({
  method,
  apikey,
  url,
}: TestHubspotApiPayload): Promise<TestHubspotResponse> => {
  const res = await requestApi<TestHubspotResponse>({
    method: 'POST',
    service: `${Services.CustomProcessor}/general`,
    submethod: 'test-api/',
    data: {
      apikey_uuid: apikey,
      http_method: method,
      url,
    },
  });

  return res.data;
};
