import React, { useState } from 'react';
import { useAppDispatch, useTypedSelector } from 'store';

import { updateWorkflowsTable } from 'modules/automation/workflowsSlice';
import { updateWorkflowTagAction } from 'modules/automation/workflowsActions';
import { createTagAction, updateTagAction, UpdateTagRequest } from 'store/tagsSlice';

import { TagsTableSectionStrings } from 'components/TagsCell/en';

import { TagsCell } from './TagsCell';
import { TagModal } from './TagModal';
import { Option } from './TagAutocomplete/Autocomplete';
import { EditTagForm } from './EditTagForm/EditTagForm';
import { ActionNames, filterWorkflowTags, getFilteredTags, ModalData } from './TableTags.helper';

interface TagsCellProps {
  workflow: AutomationApiModel;
}

export const TableTags = ({ workflow }: TagsCellProps) => {
  const dispatch = useAppDispatch();
  const { workflows } = useTypedSelector((state) => state.workflows);
  const { tags } = useTypedSelector((state) => state.tags);
  const filteredTags = getFilteredTags(workflow.tags, tags);

  const [targetTagId, setTargetTagId] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [workflowTags, setWorkflowTags] = useState(workflow.tag_objects);
  const [editableTag, setEditableTag] = useState<TagApiModel | null>(null);
  const [actionName, setActionName] = useState<ActionNames>(ActionNames.Empty);

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const onClickDeleteTagHandler = (tagId: string) => {
    setActionName(ActionNames.Delete);
    toggleModal();
    setTargetTagId(tagId);
  };

  const onDeleteHandler = () => {
    const newWorkflowTags = filterWorkflowTags(workflows, workflow.id, targetTagId);
    dispatch(
      updateWorkflowTagAction({ workflowId: workflow.id, data: { tags: newWorkflowTags } }),
    ).then(() => {
      toggleModal();
    });
    setWorkflowTags(workflowTags.filter((el) => el.id !== targetTagId));
  };

  const onCreateTagHandler = async (tagName: string) => {
    const response = await dispatch(createTagAction(tagName));
    const createdTag = response.payload as TagApiModel;
    setWorkflowTags([...workflowTags, createdTag]);

    await dispatch(
      updateWorkflowTagAction({
        workflowId: workflow.id,
        data: { tags: [...workflow.tags, createdTag.id] },
      }),
    );

    setActionName(ActionNames.Empty);
  };

  const onClickAddTagHandler = () => {
    setActionName(ActionNames.Add);
  };

  const onClickEditTagHandler = (tag: TagApiModel) => {
    setEditableTag(tag);
    setActionName(ActionNames.Edit);
    toggleModal();
  };

  const onChangeAddTagHandler = async (option: Option | null) => {
    if (option && option.id) {
      await dispatch(
        updateWorkflowTagAction({
          workflowId: workflow.id,
          data: { tags: [...workflow.tags, option.id] },
        }),
      );

      const addedTag = tags.find((el) => el.id === option.id);
      setWorkflowTags([...workflowTags, addedTag as TagApiModel]);
    }
    setActionName(ActionNames.Empty);
  };

  const onUpdateEditTagHandler = async (tag: UpdateTagRequest) => {
    const tagId = editableTag ? editableTag.id : '';
    await dispatch(updateTagAction({ ...tag, id: tagId }));
    setWorkflowTags(
      workflowTags.map((el) => {
        if (el.id === tagId) {
          return {
            ...tag,
            id: tagId,
          };
        }

        return el;
      }),
    );

    setActionName(ActionNames.Empty);
    toggleModal();
    dispatch(updateWorkflowsTable());
  };

  const onCancelEditTagHandler = () => {
    toggleModal();
  };

  const modalData: ModalData = {
    [ActionNames.Delete]: {
      title: TagsTableSectionStrings.DeleteModalTitle,
      content: TagsTableSectionStrings.DeleteModalText,
      buttons: [
        {
          text: TagsTableSectionStrings.DeletePrimaryButton,
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
          onClick: onDeleteHandler,
        },
        {
          text: TagsTableSectionStrings.DeleteSecondaryButton,
          variant: 'outlined',
          color: 'primary',
          autoFocus: true,
          onClick: toggleModal,
        },
      ],
    },
    [ActionNames.Edit]: {
      title: TagsTableSectionStrings.AddModalTitle,
      content: (
        <EditTagForm
          onSubmit={onUpdateEditTagHandler}
          editableTag={editableTag}
          onCancel={onCancelEditTagHandler}
          tags={tags}
        />
      ),
      buttons: [],
    },
  };

  return (
    <>
      {isOpenModal && actionName !== ActionNames.Empty && (
        <TagModal modalData={modalData[actionName]} open={isOpenModal} onClose={toggleModal} />
      )}

      <TagsCell
        workflowTags={workflowTags}
        tags={tags}
        filteredTags={filteredTags}
        onDelete={onClickDeleteTagHandler}
        onClickAdd={onClickAddTagHandler}
        onChangeTag={onChangeAddTagHandler}
        onCreateTag={onCreateTagHandler}
        onClickEdit={onClickEditTagHandler}
        actionName={actionName}
      />
    </>
  );
};
