import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import './styles.scss';

import { ModalStyled, ModalContent, ModalWrapper } from 'components/modal/common';
import { ModalFooter } from 'components/modal/ModalFooter';
import { ModalHeader } from 'components/modal/ModalHeader';

import { Action, Actions } from './Action';

const messages = defineMessages({
  title: {
    id: 'automation.create-project-modal.title',
    defaultMessage: '{projectName} Project',
  },
  continue: {
    id: 'automation.set-project-permission-modal.create',
    defaultMessage: 'Continue',
  },
  cancel: {
    id: 'automation.set-project-permission-modal.cancel',
    defaultMessage: 'Back',
  },
  subtitle: {
    id: 'automation.set-project-permission-modal.subtitle',
    defaultMessage: 'What do you want to begin with?',
  },
  description: {
    id: 'automation.set-project-permission-modal.description',
    defaultMessage:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames magnis mattis et pellentesque augue cras. Tellus augue mauris eget porttitor tellus imperdiet proin sit massa.',
  },
  newAutomation: {
    id: 'automation.set-project-permission-modal.newAutomation',
    defaultMessage: 'New Automation',
  },
  newConnection: {
    id: 'automation.set-project-permission-modal.newConnection',
    defaultMessage: 'New Connection',
  },
  newDataModel: {
    id: 'automation.set-project-permission-modal.newDataModel',
    defaultMessage: 'New Data model',
  },
});

export type FirstStepModalType = {
  selectedAction: Actions;
  onClose: () => void;
  goBack: () => void;
  onContinue: () => void;
  onSelect: (action: Actions) => void;
  projectName: string;
};

export const FirstStepModal: React.FC<FirstStepModalType> = React.memo(
  ({ selectedAction, onClose, projectName, onContinue, goBack, onSelect }) => (
    <ModalStyled open onClose={onClose}>
      <ModalWrapper
        style={{
          width: 545,
          height: 700,
        }}
      >
        <ModalHeader onClose={onClose}>
          <FormattedMessage {...messages.title} values={{ projectName }} />
        </ModalHeader>
        <ModalContent>
          <section className="first-step-modal-content">
            <h3 className="first-step-modal-content__subtitle">
              <FormattedMessage {...messages.subtitle} />
            </h3>
            <FormattedMessage tagName="p" {...messages.description} />

            <div className="new-project-action-list">
              <Action
                key="new-automation"
                action={Actions.NEW_AUTOMATION}
                isActive={Actions.NEW_AUTOMATION === selectedAction}
                onSelect={onSelect}
              >
                <FormattedMessage {...messages.newAutomation} />
              </Action>
              <Action
                key="new-connection"
                action={Actions.NEW_CONNECTION}
                isActive={Actions.NEW_CONNECTION === selectedAction}
                onSelect={onSelect}
              >
                <FormattedMessage {...messages.newConnection} />
              </Action>
              <Action
                key="new-data-model"
                action={Actions.NEW_DATA_MODEL}
                isActive={Actions.NEW_DATA_MODEL === selectedAction}
                onSelect={onSelect}
              >
                <FormattedMessage {...messages.newDataModel} />
              </Action>
            </div>
          </section>
        </ModalContent>
        <ModalFooter
          cancelMessage={messages.cancel}
          submitMessage={messages.continue}
          onCancel={goBack}
          onSubmit={onContinue}
        />
      </ModalWrapper>
    </ModalStyled>
  ),
);
