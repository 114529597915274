import cloneDeep from 'lodash/cloneDeep';

import { Schema, SchemaProperty } from 'schemas/types';

import { CustomComponentApiModel } from 'modules/custom-components/types';

const parseSchema = (value: string): Record<string, SchemaProperty> => {
  try {
    return JSON.parse(value) as Record<string, SchemaProperty>;
  } catch (e) {
    return {};
  }
};

export const applyCustomComponentProperties = (
  schema: Schema,
  customComponents: CustomComponentApiModel[],
  customComponentId: string,
) => {
  const customComponent: Maybe<CustomComponentApiModel> = customComponents.find(
    (item) => item.pk === customComponentId,
  );

  if (customComponent) {
    const parsedSchema = cloneDeep<Record<string, SchemaProperty>>(
      parseSchema(customComponent.schema),
    );

    schema.properties = cloneDeep({ ...schema.properties, ...parsedSchema });
  }
};
