import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'store';
import { useNavigation } from 'hooks';

import { useModal, WorkflowsModalTypes } from 'modules/modals';

import { CommonActionsCell } from 'components/CommonActionsCell';
import { ModalLink } from 'components/Table/ModalLink';

import { deleteAutomationVersion } from '../../automationActions';
import { revertAutomationVersion } from '../../automationApi';
import { WebsiteRoutes } from '../../../../constants/routes';

const messages = defineMessages({
  rollBack: {
    id: 'automation.automation-history-modal.rollBack',
    defaultMessage: 'Roll back',
  },
  remove: {
    id: 'automation.automation-history-modal.remove',
    defaultMessage: 'Remove',
  },
  edit: {
    id: 'automation.automation-history-modal.edit',
    defaultMessage: 'Edit',
  },
});

type AutomationHistoryItemActionsProps = {
  currentVersionId: string;
  automationId: string;
  isLastVersion: boolean;
};

export const AutomationHistoryItemActions: React.FC<AutomationHistoryItemActionsProps> = ({
  isLastVersion,
  currentVersionId,
  automationId,
}) => {
  const dispatch = useAppDispatch();
  const { closeModal, openModal } = useModal();
  const navigate = useNavigation();

  const onRollBackAutomationClick = React.useCallback(async () => {
    const revertedAutomationVersionId = await revertAutomationVersion(
      currentVersionId,
      automationId,
    );

    closeModal(WorkflowsModalTypes.AutomationHistoryModal);

    navigate(WebsiteRoutes.WorkflowEdit, { id: revertedAutomationVersionId });
  }, [closeModal, navigate, currentVersionId, automationId]);

  const onDelete = React.useCallback(() => {
    dispatch(deleteAutomationVersion(automationId));
  }, [dispatch, automationId]);

  const onEdit = React.useCallback(() => {
    openModal({ type: WorkflowsModalTypes.EditAutomationVersion, data: { automationId } });
  }, [openModal, automationId]);

  return (
    <CommonActionsCell>
      <ModalLink fullWidth disabled={isLastVersion} onClick={onRollBackAutomationClick}>
        <FormattedMessage {...messages.rollBack} />
      </ModalLink>
      <ModalLink fullWidth onClick={onEdit}>
        <FormattedMessage {...messages.edit} />
      </ModalLink>
      <ModalLink disabled={isLastVersion} fullWidth onClick={onDelete}>
        <FormattedMessage {...messages.remove} />
      </ModalLink>
    </CommonActionsCell>
  );
};
