import React, { useState, useLayoutEffect, useRef, useCallback } from 'react';
import { defineMessages } from 'react-intl';
import OauthPopup from 'react-oauth-popup';

import Form from '@rjsf/material-ui';

import { uiSchema } from 'schemas';
import type { FormDataChangeEvent } from 'types';

import { ConnectionsModalTypes, useModal } from 'modules/modals';

import { Modal } from 'components/modal';

import type { GmailAuthDataSourceType } from '../api/GmailConnectorApi';
import { useConnections } from '../useConnections';
import { gmailConnectionSchema } from './schemas/gmailConnectionSchema';
import { ConnectionApi } from '../api/ConnectionApi';
import { GmailConnectorApi } from '../api/GmailConnectorApi';

const messages = defineMessages({
  title: {
    id: 'automation.create-connection-modal.title',
    defaultMessage: 'Gmail Connection',
  },
  nodeSetup: {
    id: 'automation.create-connection-modal.node-setup',
    defaultMessage: 'Node Setup',
  },
  cancel: {
    id: 'automation.create-connection-modal..cancel-button',
    defaultMessage: 'Cancel',
  },
  addNode: {
    id: 'automation.create-connection-modal..add-node',
    defaultMessage: 'Create',
  },
});

const initialFormDataState: ConnectionModel = {
  name: '',
  description: '',
  scheduler: true,
  cron: '* * * * *',
};

export const CreateGoogleConnectionModal: React.FC = () => {
  const { closeModal, openModal } = useModal();
  const fetchConnections = useConnections();
  const googleButtonRef = useRef<HTMLInputElement | null>(null);
  const [connected, setConnected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<ConnectionModel>(initialFormDataState);
  const [gmailAuthDataSource, setGmailAuthDataSource] =
    useState<GmailAuthDataSourceType | null>(null);

  const onSubmit = useCallback(async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      const connection = await ConnectionApi.createConnection({
        name: formData.name,
        description: formData.description,
        source_type: 'gmail',
        datasource_model: 'gmail',
      });

      const response = await GmailConnectorApi.fetchGmailAuthDataSourceApi({
        id: connection.pk,
        cron: formData.cron,
        scheduler: formData.scheduler,
      });

      setGmailAuthDataSource({
        ...response,
        connectionId: connection.pk,
      });
    } catch (e) {
      setIsLoading(false);
    }
  }, [setIsLoading, isLoading, formData]);

  const onSuccessGoogleLogin = useCallback(
    async (code: string) => {
      try {
        if (gmailAuthDataSource?.origin_redirect_url) {
          const { state, redirect_url: redirectUrl, connectionId, scope } = gmailAuthDataSource;
          await GmailConnectorApi.addGmailConnectorSource({
            id: connectionId,
            query_params: {
              code,
              scope,
              state,
            },
            redirect_url: redirectUrl,
          });

          setIsLoading(false);

          fetchConnections();

          openModal({
            type: ConnectionsModalTypes.ConnectionFolderEditModal,
            data: { connectionId },
          });
          closeModal(ConnectionsModalTypes.CreateGoogleConnectionModal);
        }
      } catch (e) {
        setIsLoading(false);
      }
    },
    [openModal, fetchConnections, setIsLoading, closeModal, gmailAuthDataSource],
  );

  const onClose = useCallback(() => {
    closeModal(ConnectionsModalTypes.CreateGoogleConnectionModal);
  }, [closeModal]);

  const onFailureGoogleLogin = useCallback(() => {
    setConnected(false);
    setGmailAuthDataSource(null);
    setIsLoading(false);
  }, [setIsLoading, setGmailAuthDataSource, setConnected]);

  const onChange = useCallback(
    (data: FormDataChangeEvent<ConnectionModel>) => {
      setFormData(data.formData);
    },
    [setFormData],
  );

  useLayoutEffect(() => {
    if (!connected && gmailAuthDataSource?.origin_redirect_url) {
      setConnected(true);
      googleButtonRef.current?.click?.();
    }
  }, [setConnected, connected, isLoading, gmailAuthDataSource]);

  return (
    <Modal
      title={messages.title}
      cancelMessage={messages.cancel}
      submitMessage={messages.addNode}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Form
        uiSchema={uiSchema}
        schema={gmailConnectionSchema}
        formData={formData}
        onChange={onChange}
      >
        <></>
      </Form>
      <OauthPopup
        title="Chose user"
        width={500}
        height={700}
        url={gmailAuthDataSource?.origin_redirect_url || ''}
        onCode={onSuccessGoogleLogin}
        onClose={onFailureGoogleLogin}
      >
        <div className="has-display-none" ref={googleButtonRef} />
      </OauthPopup>
    </Modal>
  );
};
