import cloneDeep from 'lodash/cloneDeep';

import { Schema } from 'schemas/types';

export const fillDockerCredentialsField = (
  emptySchema: Schema,
  credentials: DockerCredentialApiModel[],
): Schema => {
  const schema: Schema = cloneDeep<Schema>(emptySchema);

  if (schema?.properties?.docker_credentials.enum) {
    schema.properties.docker_credentials.enum = credentials.map((item) => item.pk);
    schema.properties.docker_credentials.enumNames = credentials.map((item) => item.name);
  }

  return schema;
};
