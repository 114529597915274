import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

export const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const { initialized, keycloak } = useKeycloak();

  if (!initialized) {
    return null;
  }

  if (!keycloak.authenticated) {
    keycloak.login();

    return null;
  }

  return <Route {...props} />;
};
