import React, { useCallback, useState } from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';

import { TextField } from 'components/Table/TextField';
import { WarningIcon } from 'components/icons';

import { ParamContainer, ParamInputsContainer } from './ParamInput.style';
import { createEmptyParam } from './utils';
import { Variable } from './types';

type ParamInputProps = {
  createParam: (param: Variable) => Promise<void>;
};

export const ParamInput = ({ createParam }: ParamInputProps) => {
  const [param, setParam] = useState<Nullable<Variable>>(null);

  const onChange = React.useCallback(
    (key: string, value: string | number) => {
      if (param) {
        setParam({ ...param, [key]: value as string });
      }
    },
    [param, setParam],
  );

  const onSave = useCallback(async () => {
    if (param) {
      await createParam(param);

      setParam(null);
    }
  }, [createParam, param]);

  const onCancel = useCallback(() => {
    setParam(null);
  }, []);

  const onAddParam = useCallback(() => {
    setParam(createEmptyParam());
  }, []);

  return param ? (
    <ParamContainer>
      <ParamInputsContainer>
        <TextField name="key" label="Key" value={param.key} onChange={onChange} />
        <TextField name="value" label="Value" value={param.value} onChange={onChange} />
      </ParamInputsContainer>
      <div>
        <IconButton>
          <Tooltip title="Params is not saved">
            <WarningIcon />
          </Tooltip>
        </IconButton>
        <IconButton onClick={onSave}>
          <Save />
        </IconButton>
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </div>
    </ParamContainer>
  ) : (
    <Button variant="outlined" onClick={onAddParam}>
      Add new param
    </Button>
  );
};
