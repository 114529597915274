export const DetectCategory: ProcessorApiModel = {
  name: 'Detect Category',
  processor_type: 'DetectCategory',
  description: 'Detect Category',
  type: 'processor',
  tags: ['NLP'],
  data_model: {
    category: {
      type: 'string',
    },
  },
  displayText: 'Test value',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/DetectCategory-icon.png',
  connections: {
    inputPort: true,
    outputPort: true,
  },
};
