export enum ModalTypes {
  CreateNodeMenu = 'CreateNodeMenu',
  ErrorSnackbar = 'ErrorSnackbar',
  UserSettingModal = 'UserSettingModal',
  ApplicationSettingsModal = 'ApplicationSettingsModal',
  UserWorkspacesPermissionModal = 'UserWorkspacesPermissionModal',
}

export enum CustomComponentModalTypes {
  CreateCustomComponentModal = 'CreateCustomComponentModal',
  EditCustomComponentModal = 'EditCustomComponentModal',
  DeleteCustomComponentModal = 'DeleteCustomComponentModal',
}

export enum ConnectionsModalTypes {
  EditConnectionModal = 'ConnectionEditModal',
  ReplaceConnectionModal = 'ReplaceConnectionModal',
  DeleteConnectionModal = 'DeleteConnectionModal',
  CreateGoogleConnectionModal = 'CreateGoogleConnectionModal',
  CreateEmailConnectorModal = 'CreateEmailConnectorModal',
  CreateLinkedinConnectionModal = 'CreateLinkedinConnectionModal',
  CreateScraperConnectionModal = 'CreateScraperConnectionModal',
  CreateConnectionModal = 'CreateConnectionModal',
  ConnectionFolderEditModal = 'ConnectionFolderEditModal',
  ConfirmDeleteModal = 'ConfirmDeleteModal',
  LinkedinPinModal = 'LinkedinPinModal',
}

export enum SettingsModalTypes {
  ConfirmDeleteDialog = 'ConfirmDeleteDialog',
  CreateEditAlertDialog = 'CreateEditAlertDialog',
}

export enum WorkspaceModalTypes {
  CreateWorkspaceModal = 'CreateWorkspaceModal',
  SetWorkspacePermissionModal = 'SetWorkspacePermissionModal',
  FirstStepModal = 'FirstStepModal',
  DeleteWorkspaceModal = 'DeleteWorkspaceModal',
  EditWorkspaceModal = 'EditWorkspaceModal',
}

export enum WorkflowsModalTypes {
  CreateWorkflowModal = 'CreateWorkflowModal',
  ConfirmAwayEditWorkflowDialog = 'ConfirmAwayEditWorkflowDialog',
  WorkflowSettingsModal = 'WorkflowSettingsModal',
  AutomationHistoryModal = 'AutomationHistoryModal',
  DeleteStepModal = 'DeleteStepModal',
  CreateNodeModal = 'CreateNodeModal',
  EditAutomationVersion = 'EditAutomationVersion',
}

export enum DatasetsModalTypes {
  CreateDatasetsModal = 'CreateDatasetsModal',
  DeleteDatasetModal = 'DeleteDatasetModal',
  EditDatasetsModal = 'EditDatasetsModal',
}

export enum CredentialsModalTypes {
  CreateDockerCredentialModal = 'CreateDockerCredentialModal',
  EditDockerCredentialModal = 'EditDockerCredentialModal',
  CreateApiKeyCredentialModal = 'CreateApiKeyCredentialModal',
  EditApiKeyCredentialModal = 'EditApiKeyCredentialModal',
}

export type AllModalTypes =
  | ModalTypes
  | WorkspaceModalTypes
  | SettingsModalTypes
  | WorkflowsModalTypes
  | DatasetsModalTypes
  | ConnectionsModalTypes
  | CredentialsModalTypes
  | CustomComponentModalTypes;
