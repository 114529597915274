import React from 'react';
import { withStyles } from '@material-ui/core';

import Popover, { PopoverProps } from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { Container } from 'components/Table/NameCell.style';

const PopoverStyled = withStyles({
  root: {
    '& .MuiPopover-paper': {
      width: '140px',
    },
  },
})(Popover);

export const popoverOrigin: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

type CommonActionsCellType = {
  children: React.ReactNode[] | React.ReactNode;
};

export const CommonActionsCell: React.FC<CommonActionsCellType> = React.memo(({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null);

  const onClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Container style={{ justifyContent: 'flex-end' }}>
      <IconButton color="primary" onClick={onClick}>
        <MoreHorizIcon />
      </IconButton>
      <PopoverStyled
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        {...popoverOrigin}
      >
        {children}
      </PopoverStyled>
    </Container>
  );
});
