import { JSONSchema7 } from 'json-schema';

type JSONSchema = Omit<JSONSchema7, 'items'>;

export const allowedEntityNames = ['connection', 'dataset', 'custom-component', 'api-key'] as const;

export type EntityName = typeof allowedEntityNames[number];

export type Filter = {
  fieldName: string;
  value: string;
};

export interface SchemaProperty extends JSONSchema {
  enumNames?: string[];
  fillSchemaFieldTypes?: string[];
  entityName?: EntityName | 'data_model';
  items?: SchemaProperty | Schema;
  enum?: string[];
  filter?: Filter;
}

export interface Schema extends JSONSchema {
  properties: Record<string, SchemaProperty>;
  dependencies?: Record<string, SchemaProperty>;
}
