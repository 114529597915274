import React, { useContext, FC, useState, useCallback } from 'react';

import { IconButton, Popover, PopoverProps } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import { useTypedSelector } from 'store';

import { WarningTypes } from '../utils/validateProcessorField';
import {
  EditProcessorModalContextType,
  EditProcessorModalContext,
} from '../../modals/EditProcessorModal/EditProcessorModalContextProvider';
import { WarningList } from '../WarningList';
import { selectAutomationBulletins } from '../../workflowSelectors';
import { AutomationBulletinsTooltip } from '../AutomationCanvas/AutomationBulletinsTooltip';
import { useProcessorsWarnings } from '../../hooks/useProcessorsWarnings';

const origin: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export const DeployWarning: FC = React.memo(() => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLButtonElement>>(null);
  const { setSelectedProcessorId } =
    useContext<EditProcessorModalContextType>(EditProcessorModalContext);
  const warnings = useProcessorsWarnings();
  const bulletins = useTypedSelector(selectAutomationBulletins);

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onWarningItemClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setSelectedProcessorId(e.currentTarget.dataset.id as string);
      setAnchorEl(null);
    },
    [setSelectedProcessorId],
  );

  if (warnings.length === 0) {
    return null;
  }

  const color = warnings[0]?.type === WarningTypes.ERROR ? '#f30000' : '#f39200';

  return (
    <>
      <IconButton
        style={{
          marginLeft: 'auto',
        }}
        onClick={onClick}
      >
        <WarningIcon style={{ color }} />
      </IconButton>
      <Popover
        id="warning-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        {...origin}
      >
        <WarningList warnings={warnings} onClick={onWarningItemClick} />
      </Popover>
      {bulletins.length !== 0 && <AutomationBulletinsTooltip bulletins={bulletins} />}
    </>
  );
});
