import styled from 'styled-components';

import { Box, IconButton } from '@material-ui/core';

import { drawerCollapsedWidth } from '../constants';

export const DrawerButtonWrapper = styled(Box)`
  width: ${drawerCollapsedWidth};
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const DrawerToggleButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.common.white};
`;
