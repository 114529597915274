import React, { useCallback, FC } from 'react';

import MenuItem from '@material-ui/core/MenuItem';

import { OnChangeEvent } from '../types';
import { Selector } from './common';

const logTypeSelectorValues: { title: string; value: LogsLevel }[] = [
  {
    title: 'All',
    value: 'all',
  },
  {
    title: 'Info',
    value: 'info',
  },
  {
    title: 'Warning',
    value: 'warning',
  },
  {
    title: 'Error',
    value: 'error',
  },
];

type LogsLevelSelectorProps = {
  level: LogsLevel;
  onChange: (value: LogsLevel) => void;
  fullWidth?: boolean;
};

export const LogsLevelSelector: FC<LogsLevelSelectorProps> = ({
  level,
  onChange,
  fullWidth = false,
}) => {
  const onLevelChange = useCallback(
    (e: OnChangeEvent) => {
      if (e.target.value) {
        onChange(e.target.value as LogsLevel);
      }
    },
    [onChange],
  );

  return (
    <Selector fullWidth={fullWidth} name="level" value={level} onChange={onLevelChange}>
      {logTypeSelectorValues.map(({ value, title }) => (
        <MenuItem key={value} value={value}>
          {title}
        </MenuItem>
      ))}
    </Selector>
  );
};
