import * as Yup from 'yup';

import { MemberRoles } from 'constants/roles';

export interface InviteMemberFormValuesType {
  email: string;
  group: number;
}

export const initialValues: InviteMemberFormValuesType = {
  email: '',
  group: MemberRoles.VIEW,
};

export const inviteMemberFormValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string().email('Email should be valid').required('Email is required'),
    group: Yup.string().min(1, 'Should not be empty').required('Role is required'),
  });
