import React, { FC, useCallback, createContext, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import { ApplicationRole } from 'types/roles';

import { UserRoleApi } from 'modules/workspaces/UserRoleApi';

import { ApplicationRoles, defaultApplicationUserRole } from '../constants/roles';

type UserProfileType = {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  preferred_username: string;
  sub: string;
};

const nullUserObject: UserProfileType = {
  email: '',
  email_verified: false,
  family_name: '',
  given_name: '',
  name: '',
  preferred_username: '',
  sub: '',
};

export type UserProfileContextType = {
  userApplicationRole: ApplicationRole;
  isApplicationAdmin: boolean;
  userProfile: UserProfileType;
  logout: () => void;
};

const initialValue: UserProfileContextType = {
  userApplicationRole: defaultApplicationUserRole,
  isApplicationAdmin: false,
  userProfile: nullUserObject,
  logout: () => {},
};

export const UserProfileContext = createContext<UserProfileContextType>(initialValue);

export const UserProfileProvider: FC = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const [userProfile, setUserProfile] = useState<UserProfileType>(nullUserObject);
  const [userApplicationRole, setUserApplicationRole] = useState<ApplicationRole>(
    defaultApplicationUserRole,
  );

  const getUserInfo = useCallback(async () => {
    if (initialized) {
      const res = (await keycloak.loadUserInfo()) as UserProfileType;

      setUserProfile(res);
    }
  }, [keycloak, initialized]);

  const logout = useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  React.useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  React.useEffect(() => {
    if (userProfile.sub) {
      UserRoleApi.fetchUserRole(userProfile.sub).then(setUserApplicationRole);
    }
  }, [userProfile.sub]);

  return (
    <UserProfileContext.Provider
      value={{
        userProfile,
        logout,
        userApplicationRole,
        isApplicationAdmin: userApplicationRole.id === ApplicationRoles.SUPER_ADMIN,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};
