import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAppDispatch, useTypedSelector } from 'store';
import { selectWorkspaceId, updateProjectId } from 'store/userSettingsSlice';

type UseProjectType = {
  projectId: string;
  setProjectId: (id: string) => void;
};

export const useProjectId = (): UseProjectType => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const workspaceId = useTypedSelector(selectWorkspaceId);

  const setProjectId = React.useCallback(
    (id: string) => {
      dispatch(updateProjectId(id));
      history.push(location.pathname.replace(workspaceId, id));
    },
    [history, location, dispatch, workspaceId],
  );

  return {
    projectId: workspaceId,
    setProjectId,
  };
};
