import * as React from 'react';

import { useAppDispatch } from 'store';

import { AllModalTypes } from '../constants';
import { ModalStoreType, modalSlice } from '../modalsSlice';

type UseModalType = () => {
  openModal: (arg: ModalStoreType) => void;
  closeModal: (type: AllModalTypes) => void;
};

export const useModal: UseModalType = () => {
  const dispatch = useAppDispatch();

  const openModal = React.useCallback(
    ({ type, data = {} }: ModalStoreType) => {
      dispatch(modalSlice.actions.openModal({ type, data }));
    },
    [dispatch],
  );

  const closeModal = React.useCallback(
    (type: AllModalTypes) => {
      dispatch(modalSlice.actions.closeModal({ type }));
    },
    [dispatch],
  );

  return {
    openModal,
    closeModal,
  };
};
