import { useCallback } from 'react';
import { useStoreState } from 'react-flow-renderer';

import { useAppDispatch } from 'store';
import { useNavigation } from 'hooks/useNavigation';
import { WebsiteRoutes } from 'constants/routes';

import { deployWorkflow } from '../automationActions';
import { selectReactFlowEdges, selectReactFlowNodes } from '../workflowSelectors';
import { saveAutomation } from '../automationApi';

export const useDeploy = (id: string) => {
  const dispatch = useAppDispatch();
  const nodes = useStoreState(selectReactFlowNodes);
  const edges = useStoreState(selectReactFlowEdges);
  const navigate = useNavigation();

  const deploy = useCallback(async () => {
    const workflow = await dispatch(deployWorkflow({ id, nodes, edges })).unwrap();

    navigate(WebsiteRoutes.WorkflowEdit, {
      id: workflow.id,
    });
  }, [navigate, id, nodes, edges, dispatch]);

  const save = useCallback(async () => {
    const workflow = await saveAutomation({ id, nodes, edges });

    navigate(WebsiteRoutes.WorkflowEdit, {
      id: workflow.id,
    });
  }, [navigate, id, nodes, edges]);

  return {
    deploy,
    save,
  };
};
