import { Button, withStyles } from '@material-ui/core';

export const ModalLink = withStyles({
  root: {
    display: 'block',
    margin: '8px',
    width: 'calc(100% - 16px)',
    textAlign: 'left',
  },
})(Button);
