import * as React from 'react';
import { useParams } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';

import { useAppDispatch, useTypedSelector } from 'store';
import { useFlag } from 'modules/modals';

import { AutomationEditParams } from '../../types';
import { selectAutomationStatus, selectIsAutomationRunning } from '../../workflowSelectors';
import { setEnableAutomationStatus } from '../../automationActions';

export const AutomationStatusSwitcher = React.memo(() => {
  const { id } = useParams<AutomationEditParams>();
  const { showFlag } = useFlag();
  const dispatch = useAppDispatch();
  const automationStatus = useTypedSelector(selectAutomationStatus);
  const isAutomationRunning = useTypedSelector(selectIsAutomationRunning);

  const onChange = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (automationStatus === 'not deployed') {
        showFlag({ title: `You need deploy automation at first.` });
        return;
      }

      if (automationStatus === 'error') {
        showFlag({ title: `Automation has error.`, variant: 'error' });
        return;
      }
      const enable = event.target.checked;

      await dispatch(setEnableAutomationStatus({ automationId: id, enable })).unwrap();

      showFlag({ title: `Automation was ${enable ? 'enabled' : 'stopped'}.` });
    },
    [automationStatus, showFlag, dispatch, id],
  );

  return (
    <Switch
      checked={isAutomationRunning}
      onChange={onChange}
      name="enabled"
      inputProps={{ 'aria-label': 'is automation enabled' }}
    />
  );
});
