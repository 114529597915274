import React, { useState } from 'react';

import { Button } from '@material-ui/core';

import { useAppDispatch, useTypedSelector } from 'store';

import { TableTags } from 'components/TagsCell/TableTags.container';
import { TextField } from 'components/Table/TextField';
import { TabContent } from 'components/Tabs';
import { DialogActions, ModalLabel } from 'components/modal';

import { patchWorkflow } from 'modules/automation/automationActions';
import { useModal } from 'modules/modals/hooks/useModal';
import { WorkflowsModalTypes } from 'modules/modals/constants';

import { WorkflowDeleteButton } from './WorkflowSettingsModal.style';
import { ConfirmWorkflowRemoveModal } from '../ConfirmWorkflowRemoveModal';

import { AutomationPublicUrl } from './AutomationPublicUrl';
import { emptyAutomation } from '../../workflowSlice';

export const AutomationSettings = () => {
  const { closeModal } = useModal();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const automation = useTypedSelector((state) => state.workflow.automation || emptyAutomation);
  const [automationData, setAutomationData] = React.useState<AutomationApiModel>(automation);

  const toggleModal = React.useCallback(() => {
    setIsOpenModal((value) => !value);
  }, [setIsOpenModal]);

  const onGeneralSubmit = React.useCallback(async () => {
    await dispatch(patchWorkflow(automationData)).unwrap();

    closeModal(WorkflowsModalTypes.WorkflowSettingsModal);
  }, [closeModal, dispatch, automationData]);

  const onChange = React.useCallback(
    (key: string, value: boolean | string | number) => {
      setAutomationData((item) => ({ ...item, [key]: value }));
    },
    [setAutomationData],
  );

  return (
    <>
      <TabContent>
        <TextField
          name="name"
          label="Automation name"
          value={automationData.name}
          onChange={onChange}
        />
        <TextField
          name="description"
          label="Automation description"
          value={automationData.description}
          onChange={onChange}
        />
        <TextField
          name="comment"
          label="Automation comment"
          value={automationData.comment}
          onChange={onChange}
        />
        <section className="has-top-margin">
          <ModalLabel htmlFor="workflow-tags">Automation tags</ModalLabel>
          <TableTags workflow={automationData} />
        </section>
        <AutomationPublicUrl />
        {isOpenModal && (
          <ConfirmWorkflowRemoveModal open onClose={toggleModal} id={automationData.id} />
        )}
      </TabContent>
      <DialogActions>
        <WorkflowDeleteButton onClick={toggleModal}>Delete Automation</WorkflowDeleteButton>
        <Button variant="contained" disableElevation color="primary" onClick={onGeneralSubmit}>
          Save Changes
        </Button>
      </DialogActions>
    </>
  );
};
