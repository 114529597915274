import { toApiOrdering } from './apiMappers';

export type RequestParams = {
  page?: number;
  page_size?: number;
  ordering: Nullable<string>;
  q?: string;
  status?: string;
};

export const buildRequestParams = (tableParams: TableFilterParams): RequestParams => {
  const params: RequestParams = {
    page: tableParams.page,
    page_size: tableParams.size,
    ordering: toApiOrdering(tableParams.order, tableParams.orderBy || 'updated_at'),
  };

  if (tableParams.search) {
    params.q = tableParams.search;
  }

  return params;
};
