import React, { memo } from 'react';

import { Layout } from 'components/Layout';
import { ConnectedModal } from 'modules/modals/ConnectedModal';
import { getDatasetsModalByType } from 'modules/datasets/modals/getDatasetsModalbyType';
import { DatasetsTable } from 'modules/datasets/DatasetsTable/DatasetsTable';
import { DatasetsPageTitle } from 'modules/datasets/DatasetsPageTitle';

export const DatasetsPage = memo(() => {
  return (
    <Layout>
      <ConnectedModal modalCreator={getDatasetsModalByType} />
      <DatasetsPageTitle />
      <DatasetsTable />
    </Layout>
  );
});
