import React from 'react';

import type { TableContent, TableRowParams } from 'components/Table';
import { EditItemCell } from 'modules/custom-components/components/CustomComponentsTable/EditItemCell';
import { CustomComponentModalTypes } from 'modules/modals';

import { CustomComponentApiModel } from '../../types';
import { CustomComponentActionCell } from './CustomComponentActionCell';

export interface CustomComponentsContent extends TableRowParams {
  id: string;
  name: JSX.Element;
  actions: JSX.Element;
}

const customComponentTableContent: TableContent<CustomComponentsContent> = {
  header: {
    name: {
      width: '20%',
      name: 'Name',
      sortable: true,
    },
    description: {
      width: '20%',
      name: 'Description',
      sortable: true,
    },
    actions: {
      name: '',
      sortable: false,
    },
  },
  rows: [],
};

export const useCustomComponentsContent = (
  items: CustomComponentApiModel[],
): TableContent<CustomComponentsContent> => {
  customComponentTableContent.rows = items.map(({ pk, name, description = '' }) => ({
    id: pk,
    name: (
      <EditItemCell
        title={name}
        pk={pk}
        modalType={CustomComponentModalTypes.EditCustomComponentModal}
      />
    ),
    description: <p>{description}</p>,
    actions: <CustomComponentActionCell pk={pk} />,
  }));

  return customComponentTableContent;
};
