import React from 'react';

import { Delete, Edit, Sync } from '@material-ui/icons';

import { isGmailReaderType } from 'precessors/utils';

import { useFlag, useModal, WorkflowsModalTypes } from 'modules/modals';
import { ConnectorApi } from 'modules/connections/api/ConnectorApi';

import { NodeActionsWrapper, NodeAction } from './NodeActions.styles';
import {
  EditProcessorModalContext,
  EditProcessorModalContextType,
} from '../../../modals/EditProcessorModal/EditProcessorModalContextProvider';

type NodeActionsType = {
  id: string;
  processorType: ProcessorTypes;
  connection_uuid?: string;
  closeActionsOpen: () => void;
};

export const NodeActions: React.FC<NodeActionsType> = ({
  id,
  closeActionsOpen,
  processorType,
  connection_uuid,
}) => {
  const { showFlag } = useFlag();
  const { openModal } = useModal();
  const { setSelectedProcessorId } =
    React.useContext<EditProcessorModalContextType>(EditProcessorModalContext);

  const onForceSyncClick = React.useCallback(async () => {
    if (isGmailReaderType(processorType) && connection_uuid) {
      await ConnectorApi.forceSyncConnection(connection_uuid);
      showFlag({
        title: 'Force sync started.',
      });
    }
  }, [showFlag, processorType, connection_uuid]);

  const onRemoveNodeClick = React.useCallback(() => {
    openModal({
      type: WorkflowsModalTypes.DeleteStepModal,
      data: { id, type: processorType },
    });

    closeActionsOpen();
  }, [openModal, closeActionsOpen, id, processorType]);

  const onEditClick = React.useCallback(() => {
    setSelectedProcessorId(id);
    closeActionsOpen();
  }, [setSelectedProcessorId, id, closeActionsOpen]);

  return (
    <NodeActionsWrapper count={isGmailReaderType(processorType) ? 3 : 2}>
      <NodeAction key="edit-node-action-key" onClick={onEditClick}>
        <Edit className="action-icon" />
      </NodeAction>
      {isGmailReaderType(processorType) && (
        <NodeAction key="sync-node-action-key" onClick={onForceSyncClick}>
          <Sync className="action-icon" />
        </NodeAction>
      )}
      <NodeAction key="delete-node-action-key" onClick={onRemoveNodeClick}>
        <Delete className="action-icon" />
      </NodeAction>
    </NodeActionsWrapper>
  );
};
