import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';
import { defaultApplicationUserRole } from 'constants/roles';
import { ApplicationRole } from 'types/roles';

import { GroupApiModel } from './types';

export const UserRoleApi = {
  fetchRoles: async (): Promise<GroupApiModel[]> => {
    const res = await requestApi<PaginatedResponse<GroupApiModel>>({
      service: Services.Project,
      submethod: 'project/app-roles/',
      isAnonymousMethod: true,
    });

    return res.data.results;
  },
  fetchUserRole: async (userId: string): Promise<ApplicationRole> => {
    const res = await requestApi<ApplicationRole>({
      service: Services.Project,
      submethod: `project/user/${userId}/app-role/`,
      isAnonymousMethod: true,
    });

    return res.data || defaultApplicationUserRole;
  },
  updateUserRole: async (roleId: number, userId: string): Promise<unknown> => {
    return requestApi({
      method: 'PATCH',
      service: Services.Project,
      submethod: `project/app-roles/${roleId}/`,
      isAnonymousMethod: true,
      notifyError: false,
      data: {
        user: userId,
      },
    });
  },
} as const;
