import { requestApi } from 'utils/httpClient';

export const fetchUsers = async (): Promise<UserApiModel[]> => {
  const response = await requestApi<PaginatedResponse<UserApiModel>>({
    service: 'auth/user',
    isAnonymousMethod: true,
  });

  return response.data.results;
};
