import React, { useState } from 'react';
import { defineMessages } from 'react-intl';

import Input from '@material-ui/core/Input';
import List from '@material-ui/core/List';
import TablePagination from '@material-ui/core/TablePagination';

import { defaultTableParams, Pagination } from 'constants/table';
import { toOneBasedIndex, toZeroBasedIndex } from 'utils/apiMappers';

import { AttachedWorkflow } from 'modules/automation/workflowsActions';
import { useModal, ConnectionsModalTypes } from 'modules/modals';
import { ConnectionItem } from 'modules/automation/modals/ConnectionItem/ConnectionItem';

import { Modal } from 'components/modal';
import { Message } from 'components/Message';

import { ConnectionsCounter } from './ConnectionsCounter';
import { ReplaceConnectionModalSubHeader } from './ReplaceConnectionModalHeader';
import { ConnectionApi } from '../../api/ConnectionApi';

const messages = defineMessages({
  continue: {
    id: 'automation.create-connection-node-modal.continue',
    defaultMessage: 'Continue',
  },
  cancel: {
    id: 'automation.create-connection-node-modal.cancel',
    defaultMessage: 'Cancel',
  },
  replace: {
    id: 'automation.create-connection-node-modal.replace',
    defaultMessage: 'Replace connection',
  },
  delete: {
    id: 'automation.create-connection-node-modal.delete',
    defaultMessage: 'Delete connection',
  },
});

type ReplaceConnectionModalType = {
  connectionData: ConnectionApiModel;
  isDeleteModal: boolean;
  workflows: AttachedWorkflow[];
};

export const ReplaceConnectionModal: React.FC<ReplaceConnectionModalType> = ({
  connectionData,
  isDeleteModal,
  workflows,
}) => {
  const { closeModal, openModal } = useModal();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);
  const [connections, setConnections] = React.useState<ConnectionApiModel[]>([]);
  const [tableParams, setTableParams] = React.useState<Pagination & { search: string }>({
    ...defaultTableParams,
    search: '',
  });
  const [destination, setDestination] = React.useState<Maybe<ConnectionApiModel>>(undefined);

  const handleChange = React.useCallback(
    (pk: string) => {
      const connection = connections.find((el) => el.pk === pk);

      if (connection) {
        setDestination(connection);
      }
    },
    [connections],
  );

  const onSubmit = React.useCallback(() => {
    if (!destination) {
      return;
    }

    openModal({
      type: ConnectionsModalTypes.ConfirmDeleteModal,
      data: {
        source: connectionData,
        destination,
        workflows,
        isDeleteModal,
      },
    });
  }, [connectionData, openModal, destination, workflows, isDeleteModal]);

  const onClose = React.useCallback(() => {
    closeModal(ConnectionsModalTypes.ReplaceConnectionModal);
  }, [closeModal]);

  const handleChangeRowsPerPage = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTableParams((params) => ({ ...params, size: parseInt(event.target.value, 10) }));
    },
    [],
  );

  const handleChangePage = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setTableParams((params) => ({ ...params, page: toOneBasedIndex(newPage) }));
    },
    [],
  );

  React.useEffect(() => {
    setIsLoading(true);
    ConnectionApi.fetchConnections(tableParams as unknown as TableFilterParams).then((res) => {
      setCount(res.count);
      setConnections(res.results.connection.filter((item) => item.pk !== connectionData.pk));
      setIsLoading(false);
    });
  }, [tableParams, connectionData.pk]);

  return (
    <Modal
      title={isDeleteModal ? messages.delete : messages.replace}
      onClose={onClose}
      submitMessage={messages.continue}
      cancelMessage={messages.cancel}
      onSubmit={onSubmit}
    >
      {isDeleteModal && <ReplaceConnectionModalSubHeader connectionName={connectionData.name} />}
      <div>
        <ConnectionsCounter connectionName={connectionData.name} workflows={workflows} />
        <p className="connection-subtitle">Replace with</p>
        <Input
          fullWidth
          className="has-top-margin"
          placeholder="Search"
          onInputCapture={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTableParams({ ...tableParams, search: e.target.value || '' });
          }}
        />
        <p className="connection-subtitle">All Connection</p>
        <List className="has-no-top-padding">
          {connections.map((el) => (
            <ConnectionItem
              selected={el.pk === destination?.pk}
              key={el.pk}
              pk={el.pk}
              name={el.name}
              description={el.description}
              onClick={handleChange}
            />
          ))}
        </List>
        <Message loading={isLoading} entityName="connections" count={connections.length} />
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          className="connection-pagination"
          component="div"
          count={count}
          page={toZeroBasedIndex(tableParams.page)}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPage={tableParams.size}
        />
      </div>
    </Modal>
  );
};
