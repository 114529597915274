import React, { FC, useCallback, useMemo, useState } from 'react';
import jp from 'jsonpath';
import { Button } from '@material-ui/core';
import { Method } from 'axios';
import ReactJson from 'react-json-view';
import Form from '@rjsf/material-ui';

import { useTypedSelector } from 'store';
import { Schema } from 'schemas/types';
import { ErrorIcon, SuccessIcon } from 'components/icons';

import { selectWorkflowElementById } from '../../../workflowSelectors';
import { AutomationContext } from '../../../AutomationContext';
import { isTestHubspotErrorResponse, testHotspotApi } from '../../../automationApi';
import { ProcessorWarning, WarningTypes } from '../../../components/utils/validateProcessorField';
import { WarningListWithTitle } from '../../../components/WarningList';

import type { HubspotType } from './types';
import { createHubspotData } from './utils';
import { theme } from '../themeJSONForm';
import { getHubspotTestSchema } from './hubspotTestSchema';

type FormDataType = {
  http_method: Method;
  apikey: string;
  url: string;
  jsonpath: string;
  body: Record<string, string>;
};

type TestHubspotType = {
  processorId: string;
};

export const TestHubspot: FC<TestHubspotType> = ({ processorId }) => {
  const processorData: WorkflowElementData = useTypedSelector(
    selectWorkflowElementById(processorId),
  );
  const { apiKeys } = React.useContext(AutomationContext);
  const [formData, setFormData] = React.useState<FormDataType>({
    http_method: processorData.parameters.http_method || 'GET',
    apikey: processorData.parameters.apikey_uuid || '',
    url: processorData.parameters.url || '',
    jsonpath: '$.results[0].properties.email',
    body: {},
  });
  const [warnings, setWarnings] = useState<ProcessorWarning[]>([]);

  const [parseError, setParseError] = useState(false);
  const [hubspot, setHubspot] = useState<HubspotType>(createHubspotData('none'));

  const hubspotTestSchema = useMemo((): Schema => {
    return getHubspotTestSchema(apiKeys);
  }, [apiKeys]);

  const onTestHotspotClick = useCallback(async () => {
    const { apikey, url, http_method: method } = formData;
    if (!apikey || !url || !method) {
      return;
    }

    setHubspot(createHubspotData('none'));

    try {
      const res = await testHotspotApi({
        method,
        apikey,
        url,
      });

      if (isTestHubspotErrorResponse(res)) {
        setWarnings([
          {
            processorId: 'no-id',
            processorType: 'Hubspot',
            name: res.category || 'ERROR',
            issue: 'invalid_value',
            message: res.message,
            type: WarningTypes.ERROR,
          },
        ]);

        setHubspot(createHubspotData('error'));
        return;
      }

      setWarnings([]);
      setHubspot({
        selectedData: res,
        data: res,
        status: 'success',
      });
    } catch (e) {
      setHubspot(createHubspotData('error'));
    }
  }, [setWarnings, formData]);

  const onApplyJSONPathClick = useCallback(() => {
    if (hubspot.data && formData.jsonpath) {
      try {
        setParseError(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const selectedData = jp.query(hubspot.data, formData.jsonpath);

        setHubspot((value) => ({ ...value, selectedData }));
      } catch (e) {
        setHubspot((value) => ({ ...value, selectedData: null }));
        setParseError(true);
      }
    }
  }, [hubspot.data, formData.jsonpath]);

  const onOriginalDataClick = useCallback(() => {
    setHubspot((value) => ({
      ...value,
      selectedData: value.data,
    }));
  }, []);

  const onChange = useCallback(
    (data: { formData: FormDataType }) => {
      if (data.formData.http_method === 'GET') {
        data.formData.body = {};
      }
      setFormData(data.formData);
    },
    [setFormData],
  );

  return (
    <div>
      <WarningListWithTitle warnings={warnings} showProcessorName={false} disablePadding />
      <Form
        className="application-form"
        liveValidate
        schema={hubspotTestSchema}
        onChange={onChange}
        formData={formData}
      >
        <></>
      </Form>
      <div className="hubspot-control-panel">
        <div className="has-flex-space-between">
          <div className="hubspot-control-buttons">
            <Button variant="contained" color="primary" onClick={onTestHotspotClick}>
              Test
            </Button>
            <Button variant="outlined" color="primary" onClick={onOriginalDataClick}>
              Show Origin data
            </Button>
            <Button variant="outlined" color="primary" onClick={onApplyJSONPathClick}>
              Apply JSONPath
            </Button>
          </div>
          {hubspot.status === 'error' && <ErrorIcon />}
          {hubspot.status === 'success' && <SuccessIcon />}
        </div>
        {hubspot.selectedData && typeof hubspot.selectedData === 'object' && (
          <div className="hubspot-data-section">
            <p className="hubspot-data-section__title">Selected data:</p>
            <ReactJson
              src={hubspot.selectedData}
              iconStyle="circle"
              displayDataTypes={false}
              enableClipboard={false}
              quotesOnKeys={false}
              theme={theme}
              name={null}
            />
          </div>
        )}
        {parseError && (
          <div className="hubspot-data-section">
            <p className="hubspot-data-section__title">Selected data:</p>
            <p>Parse error. Please update parse json field</p>
          </div>
        )}
      </div>
    </div>
  );
};
