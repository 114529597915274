import { Schema } from 'schemas/types';

export const JoltTransformationSchema: Schema = {
  type: 'object',
  properties: {
    label: {
      title: 'Label',
      type: 'string',
      default: '',
    },
    specification: {
      type: 'string',
      title: 'Specification',
    },
  },
};
