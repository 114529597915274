import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { WebsiteRoutes } from 'constants/routes';
import { getRoutePath, RouteParams } from 'utils/path';

export interface RouterLinkProps extends Omit<LinkProps, 'to'> {
  route: WebsiteRoutes;
  params?: RouteParams;
}

export const RouterLink = ({ route, params, ...restProps }: RouterLinkProps) => {
  const to = getRoutePath(route, params);

  return <Link {...restProps} to={to} />;
};
