import React from 'react';

import { useTabManager } from 'hooks';
import { a11yProps } from 'utils/a11yProps';

import { ModalHeader } from 'components/modal/ModalHeader';
import { useModal } from 'modules/modals/hooks/useModal';
import { WorkflowsModalTypes } from 'modules/modals/constants';
import { VerticalTab, VerticalTabs, VerticalTabsWrapper, TabPanel } from 'components/Tabs';
import { WideDialog } from 'components/modal';

import { AutomationSettings } from './AutomationSettings';
import { AutomationConnections } from './AutomationConnections';
import { AutomationDatasets } from './AutomationDatasets';
import { AutomationLogs } from './AutomationLogs';
import { AutomationApiKeys } from './AutomationApiKeys';
import { AutomationVariables } from './AutomationVariables';

enum WorkflowSettingsTabs {
  GENERAL,
  CONNECTIONS,
  DATASETS,
  VARIABLES,
  API_KEYS,
  LOGS,
}

export const WorkflowSettingsModal: React.FC = () => {
  const { closeModal } = useModal();
  const { tab, onTabChange } = useTabManager<WorkflowSettingsTabs>(WorkflowSettingsTabs.GENERAL);

  const onClose = React.useCallback(() => {
    closeModal(WorkflowsModalTypes.WorkflowSettingsModal);
  }, [closeModal]);

  return (
    <WideDialog id="workflow-settings-modal" open onClose={onClose}>
      <ModalHeader onClose={onClose}>Automation settings</ModalHeader>
      <VerticalTabsWrapper>
        <VerticalTabs
          orientation="vertical"
          value={tab}
          onChange={onTabChange}
          aria-label="Workflow settings tabs"
        >
          <VerticalTab label="Settings" {...a11yProps(0)} />
          <VerticalTab label="Connections List" {...a11yProps(1)} />
          <VerticalTab label="Datasets List" {...a11yProps(2)} />
          <VerticalTab label="Variables" {...a11yProps(3)} />
          <VerticalTab label="Api keys" {...a11yProps(4)} />
          <VerticalTab label="Logs" {...a11yProps(5)} />
        </VerticalTabs>
        <TabPanel value={tab} index={WorkflowSettingsTabs.GENERAL}>
          <AutomationSettings />
        </TabPanel>
        <TabPanel value={tab} index={WorkflowSettingsTabs.CONNECTIONS}>
          <AutomationConnections />
        </TabPanel>
        <TabPanel value={tab} index={WorkflowSettingsTabs.DATASETS}>
          <AutomationDatasets />
        </TabPanel>
        <TabPanel value={tab} index={WorkflowSettingsTabs.VARIABLES}>
          <AutomationVariables />
        </TabPanel>
        <TabPanel value={tab} index={WorkflowSettingsTabs.API_KEYS}>
          <AutomationApiKeys />
        </TabPanel>
        <TabPanel value={tab} index={WorkflowSettingsTabs.LOGS}>
          <AutomationLogs />
        </TabPanel>
      </VerticalTabsWrapper>
    </WideDialog>
  );
};
