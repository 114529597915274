import React from 'react';

import { useTabManager } from 'hooks';

import { TabPanel, Tabs, Tab } from 'components/Tabs';

import { GeneralEditProcessorParamsView } from './GeneralEditProcessorParamsView';
import { ProcessorDataModelView } from './ProcessorDataModelView';
import { ProcessorStatisticView } from './ProcessorStatisticView';
import { ProcessorLogsView } from './ProcessorLogsView';
import { TestHubspot } from './Hubspot/TestHubspot';

enum EditProcessorModalTabs {
  PROPERTIES = 'PROPERTIES',
  DATA_MODEL = 'DATA_MODEL',
  HUBSPOT_TEST = 'HUBSPOT_TEST',
  STATISTICS = 'STATISTICS',
  LOGS = 'LOGS',
}

type EditProcessorModalType = {
  processorId: string;
  processorType: ProcessorTypes;
};

export const EditProcessorModalContent: React.FC<EditProcessorModalType> = ({
  processorId,
  processorType,
}) => {
  const { tab, setTab, onTabChange } = useTabManager<EditProcessorModalTabs>(
    EditProcessorModalTabs.PROPERTIES,
  );

  React.useEffect(() => {
    setTab(EditProcessorModalTabs.PROPERTIES);
  }, [setTab, processorId]);

  return (
    <>
      <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={onTabChange}>
        <Tab label="Properties" value={EditProcessorModalTabs.PROPERTIES} />
        {processorType === 'Hubspot' && (
          <Tab label="Test Hubspot" value={EditProcessorModalTabs.HUBSPOT_TEST} />
        )}
        <Tab label="Data Model" value={EditProcessorModalTabs.DATA_MODEL} />
        <Tab label="Statistics" value={EditProcessorModalTabs.STATISTICS} />
        <Tab label="Logs" value={EditProcessorModalTabs.LOGS} />
      </Tabs>
      <div className="edit-processor-modal-tab-container">
        <TabPanel value={tab} index={EditProcessorModalTabs.PROPERTIES}>
          <GeneralEditProcessorParamsView processorId={processorId} processorType={processorType} />
        </TabPanel>
        {processorType === 'Hubspot' && (
          <TabPanel value={tab} index={EditProcessorModalTabs.HUBSPOT_TEST}>
            <TestHubspot processorId={processorId} />
          </TabPanel>
        )}
        <TabPanel value={tab} index={EditProcessorModalTabs.DATA_MODEL}>
          <ProcessorDataModelView processorId={processorId} />
        </TabPanel>
        <TabPanel value={tab} index={EditProcessorModalTabs.STATISTICS}>
          <ProcessorStatisticView processorId={processorId} />
        </TabPanel>
        <TabPanel value={tab} index={EditProcessorModalTabs.LOGS}>
          <ProcessorLogsView processorId={processorId} />
        </TabPanel>
      </div>
    </>
  );
};
