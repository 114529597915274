import * as React from 'react';

import { ModalStoreType } from 'modules/modals/modalsSlice';
import { WorkspaceModalTypes } from 'modules/modals/constants';

import { CreateWorkspaceModal } from './components/CreateWorkspaceModal';
import { SetProjectPermissionModal } from './components/SetProjectPermissionModal';
import {
  FirstStepModalContainer,
  FirstStepModalContainerType,
} from './components/FirstStepModal/FirstStepModal.container';
import { DeleteWorkspaceModal } from './components/DeleteWorkspaceModal';

export const getProjectModalByType = (item: ModalStoreType): React.ReactNode | null => {
  switch (item.type) {
    case WorkspaceModalTypes.CreateWorkspaceModal:
      return <CreateWorkspaceModal key="create-project-modal" />;
    case WorkspaceModalTypes.SetWorkspacePermissionModal:
      return (
        <SetProjectPermissionModal
          key="set-project-permission-modal"
          workspaceId={item.data?.workspaceId as string}
        />
      );
    case WorkspaceModalTypes.FirstStepModal:
      return (
        <FirstStepModalContainer
          key="first-step-modal"
          {...(item.data as FirstStepModalContainerType)}
        />
      );
    case WorkspaceModalTypes.DeleteWorkspaceModal:
      return (
        <DeleteWorkspaceModal
          key="delete-workspace-modal"
          pk={(item?.data?.pk as string) || 'test'}
        />
      );
    default:
      return null;
  }
};
