import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch } from 'store';
import { getAlerts } from 'store/alertMediaSlice';

import { Services } from 'constants/services';
import { requestApi } from 'utils/httpClient';
import { buildRequestParams } from 'utils/buildRequestParams';

import { toDot } from './components/utils';
import { deleteWorkflowApi, updateAutomation } from './automationApi';
import type { AutomationStatisticType, DeployWorkflowPayload } from './types';

export const fetchWorkflow = createAsyncThunk('automation/fetchWorkflow', async (id: string) => {
  const res = await requestApi<AutomationApiModel>({
    service: Services.NifiManager,
    submethod: `flow/${id}/`,
  });

  return res.data;
});

export const fetchAutomationList = createAsyncThunk<
  PaginatedResponse<AutomationApiModel>,
  TableFilterParams
>('workflows/fetchWorkflowList', async (params) => {
  const response = await requestApi<PaginatedResponse<AutomationApiModel>>({
    service: Services.NifiManager,
    submethod: 'flow/',
    params: buildRequestParams(params),
  });

  return response.data;
});

export const updateAutomationVersion = createAsyncThunk(
  'automation/updateAutomationVersion',
  updateAutomation,
);

export const patchWorkflow = createAsyncThunk('automation/patchWorkflow', updateAutomation);

export const deployWorkflow = createAsyncThunk(
  'automation/deployWorkflow',
  async ({ id, nodes = [], edges = [] }: DeployWorkflowPayload): Promise<AutomationApiModel> => {
    const dotContent = toDot(nodes, edges);

    const { data: updatedWorkflow } = await requestApi<AutomationApiModel>({
      service: Services.NifiManager,
      method: 'POST',
      submethod: `flow/${id}/deploy/`,
      data: {
        dot_content: dotContent,
      },
    });

    return updatedWorkflow;
  },
);

export const deleteAutomation = createAsyncThunk('automation/deleteWorkflow', deleteWorkflowApi);

export const deleteAutomationVersion = createAsyncThunk(
  'automation/deleteAutomationVersion',
  deleteWorkflowApi,
);

export type SetEnableAutomationStatusPayload = {
  automationId: string;
  enable: boolean;
};

export const setEnableAutomationStatus = createAsyncThunk<
  SetEnableAutomationStatusPayload,
  SetEnableAutomationStatusPayload
>('automation/setEnableAutomationStatus', async ({ automationId, enable }) => {
  await requestApi({
    method: 'PUT',
    service: Services.NifiManager,
    submethod: `flow/${automationId}/${enable ? 'start' : 'stop'}/`,
  });

  return { enable, automationId };
});

export const fetchWorkflowHistory = createAsyncThunk(
  'automation/fetchWorkflowHistory',
  async (automationId: string): Promise<AutomationApiModel[]> => {
    const res = await requestApi<AutomationApiModel[]>({
      service: Services.NifiManager,
      submethod: `flow/${automationId}/history/`,
    });

    return res.data;
  },
);

export const fetchAutomationStatistic = createAsyncThunk(
  'automation/fetchAutomationStatistic',
  async (id: string): Promise<AutomationStatisticType> => {
    const res = await requestApi<AutomationStatisticType>({
      service: Services.NifiManager,
      submethod: `flow/${id}/info/`,
      isAnonymousMethod: false,
    });

    return res.data;
  },
);

export const getWorkflowDataAction = (id: string) => (dispatch: AppDispatch) => {
  dispatch(fetchWorkflow(id));
  dispatch(fetchWorkflowHistory(id));
  dispatch(getAlerts());
};
