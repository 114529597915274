import { getProcessorDataModelByType } from '../../components';

const defaultType = {
  type: 'string',
};

const transformHubspotFieldsMapperToDataModel = (acc: DataModel, item: HubspotMapper): DataModel =>
  item.name
    ? {
        ...acc,
        [item.name]: defaultType,
      }
    : acc;

export const getHubspotDataModelById = (
  nodes: ProcessorApiModel[],
  mapper: HubspotMapper[],
): DataModel => {
  const hubspotDataModel = getProcessorDataModelByType('Hubspot');

  return mapper.reduce(transformHubspotFieldsMapperToDataModel, hubspotDataModel);
};
