import { RootState } from 'store';

import type { MemberApiModel, GroupApiModel, Member } from './types';

const prepareMembers = (users: UserApiModel[], members: MemberApiModel[]): Member[] =>
  members.map((member): Member => {
    const fullUser = users.find((m) => m.sub === member.user_id);

    return {
      id: member.user_id,
      name: fullUser?.preferred_username || '',
      groups: member.groups,
      permissionId: member.pk,
    };
  });

export const selectPreparedMembers = ({ workspace }: RootState): Member[] =>
  prepareMembers(workspace.users, workspace.members);

export const selectWorkspaceName = (store: RootState): string =>
  store.workspace.workspace?.name || '';

export const selectUsersThatNotMember = ({ workspace }: RootState): UserApiModel[] => {
  const memberIds = workspace.members.map((item) => item.user_id);

  return workspace.users.filter((item) => !memberIds.includes(item.sub));
};

export const selectIsWorkspaceLoading = ({ workspace }: RootState): boolean => workspace.loading;

export const selectWorkspaceGroups = ({ workspace }: RootState): GroupApiModel[] => {
  return workspace.groups;
};
