import React, { useEffect, useState } from 'react';

export const useDebounce = <T = string>(value: T, delay: number) => {
  const ref = React.useRef<Maybe<NodeJS.Timeout>>(undefined);
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    if (ref.current) {
      clearTimeout(ref.current);
    }

    ref.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
    };
  }, [delay, value]);

  return debouncedValue;
};
