import React, { FC, useCallback, useState } from 'react';

import BugReportIcon from '@material-ui/icons/BugReport';
import { IconButton, Popover, List, ListItem } from '@material-ui/core';
import { PopoverProps } from '@material-ui/core/Popover';

import './AutomationBulletinsTooltip.style.scss';
import { BulletinsStatusType } from '../../types';

export const popoverOrigin: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

type AutomationBulletinsTooltipType = {
  bulletins: BulletinsStatusType[];
};

export const AutomationBulletinsTooltip: FC<AutomationBulletinsTooltipType> = ({ bulletins }) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLButtonElement>>(null);

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Popover
        className="bulletins-popover"
        id="bulletins-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        {...popoverOrigin}
      >
        <List>
          {bulletins.map(({ id, message }) => (
            <ListItem key={id}>{message}</ListItem>
          ))}
        </List>
      </Popover>
      <IconButton className="bulletins" style={{ marginLeft: 0 }} onClick={onClick}>
        <BugReportIcon />
      </IconButton>
    </>
  );
};
