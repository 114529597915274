import { Schema } from 'schemas/types';

export const linkedinConnectionSchema: Schema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      title: 'Name',
    },
    description: {
      type: 'string',
      description: 'Connection description',
      title: 'Description',
      default: '',
    },
    scheduler: {
      type: 'boolean',
      default: false,
      description: 'Enable cron',
      title: 'Enable cron',
    },
    cron: {
      type: 'string',
      default: '* * * * *',
      description: 'Cron interval (min)',
      title: 'Cron interval',
    },
    config: {
      type: 'object',
      title: 'Configuration',
      properties: {
        username: {
          type: 'string',
          title: 'Username',
        },
        password: {
          type: 'string',
          title: 'Password',
        },
        mode: {
          type: 'string',
          title: 'mode',
          default: 'contacts',
        },
        contacts_scroll_limit: {
          type: 'number',
          title: 'Contacts scroll limit',
          default: 1,
        },
      },
    },
  },
};
