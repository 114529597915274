import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ModalTypes, useModal } from 'modules/modals/index';
import { ModalHeader } from 'components/modal/ModalHeader';
import { a11yProps } from 'utils/a11yProps';

import { VerticalTabs, VerticalTabsWrapper, VerticalTab, TabPanel } from 'components/Tabs';
import { WideDialog } from 'components/modal';

import { AlertMediaSettingsTab } from './AlertMediaSettingsTab/AlertMediaSettingsTab';

const messages = defineMessages({
  title: {
    id: 'automation.user-settings-modal.title',
    defaultMessage: 'User settings',
  },
});

enum UserSettingModalTabs {
  ALERT_MEDIA,
}

export const UserSettingModal: React.FC = () => {
  const { closeModal } = useModal();
  const [tab, setTab] = React.useState<UserSettingModalTabs>(UserSettingModalTabs.ALERT_MEDIA);

  const handleChange = React.useCallback(
    (event: unknown, newValue: UserSettingModalTabs) => {
      setTab(newValue);
    },
    [setTab],
  );

  const onClose = React.useCallback(() => {
    closeModal(ModalTypes.UserSettingModal);
  }, [closeModal]);

  return (
    <WideDialog id="user-settings-modal" open onClose={onClose}>
      <ModalHeader onClose={onClose}>
        <FormattedMessage {...messages.title} />
      </ModalHeader>
      <VerticalTabsWrapper>
        <VerticalTabs
          orientation="vertical"
          value={tab}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <VerticalTab label="Alert Medias" {...a11yProps(0)} />
        </VerticalTabs>
        <TabPanel index={UserSettingModalTabs.ALERT_MEDIA} value={tab}>
          <AlertMediaSettingsTab />
        </TabPanel>
      </VerticalTabsWrapper>
    </WideDialog>
  );
};
