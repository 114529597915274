import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';
import { ConnectionActivitiesType } from '../types';

export type TestConnectionPayload = {
  title: string;
  body: string;
  mail_from: string;
  with_error: boolean;
  count: number;
};

type CreateConnectorPayload = {
  pk: string;
  data: {
    cron: string;
    scheduler: boolean;
    config: EmailCredentials | ScraperConfig | LinkedinConfig;
  };
  type: 'linkedin' | 'email' | 'scrapper';
};

export type UpdateConnectorPayload = {
  id: string;
  connectorData: ConnectorApiModel | LinkedinConfig | ScraperConfig;
};

export type FetchConnectorResponse = {
  pk: string;
  notifyError?: boolean;
};

export type ProviderType = {
  name: string;
  type: ProviderTypes;
  server: string;
  port: number;
  ssl: boolean;
};

export const ConnectorApi = {
  createConnector: async ({
    pk,
    data,
    type,
  }: CreateConnectorPayload): Promise<ConnectorApiModel> => {
    const res = await requestApi<ConnectorApiModel>({
      method: 'POST',
      service: Services.Connectors,
      submethod: `${pk}/${type}/add-source/`,
      isAnonymousMethod: true,
      data,
    });

    return res.data;
  },
  updateConnector: async ({
    id,
    connectorData,
  }: UpdateConnectorPayload): Promise<ConnectorApiModel> => {
    const res = await requestApi<ConnectorApiModel>({
      method: 'PUT',
      service: Services.Connectors,
      submethod: `${id}/`,
      isAnonymousMethod: true,
      data: connectorData,
    });

    return res.data;
  },
  fetchConnector: async ({
    pk,
    notifyError,
  }: FetchConnectorResponse): Promise<ConnectorApiModel> => {
    const res = await requestApi<ConnectorApiModel>({
      service: Services.Connectors,
      submethod: `${pk}/`,
      isAnonymousMethod: true,
      notifyError,
    });

    return res.data;
  },
  fetchProviders: async () => {
    const res = await requestApi<PaginatedResponse<ProviderType>>({
      service: Services.Connectors,
      submethod: 'email/email-providers/',
      isAnonymousMethod: true,
    });

    return res.data.results;
  },
  forceSyncConnection: async (id: string): Promise<unknown> => {
    return requestApi({
      method: 'POST',
      service: Services.Connectors,
      submethod: 'task/',
      data: {
        data_sources: [id],
      },
    });
  },
  forceStopConnection: async (id: string): Promise<unknown> => {
    return requestApi({
      method: 'POST',
      service: Services.Connectors,
      submethod: 'task/stop/',
      data: {
        data_sources: [id],
      },
    });
  },
  fetchConnectorFolders: async (connectorId: string): Promise<FolderApiModel[]> => {
    const res = await requestApi<{ folders: FolderApiModel[] }>({
      service: Services.Connectors,
      submethod: `${connectorId}/gmail/gmail-folders/`,
      isAnonymousMethod: true,
    });

    return res.data.folders;
  },
  fetchConnectorActivities: async (id: string): Promise<ConnectionActivitiesType> => {
    const res = await requestApi<ConnectionActivitiesType>({
      service: Services.Connectors,
      submethod: `${id}/stats/`,
      isAnonymousMethod: true,
    });

    return res.data;
  },
  testConnector: async (connectionId: string, data: TestConnectionPayload) => {
    return requestApi({
      method: 'POST',
      service: Services.Connectors,
      submethod: `${connectionId}/gmail/send-test/`,
      data,
    });
  },
} as const;
