import { compile, parse } from 'path-to-regexp';

const PLACEHOLDER = `NA`;

const getFilledParameters = (
  displayText: string,
  parameters: WorkflowElementDataParameters,
): Record<string, string> => {
  return parse(displayText).reduce((acc: Record<string, string>, item) => {
    if (typeof item !== 'string' && item.name) {
      const key = item.name as string;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acc[key] = parameters[key] || PLACEHOLDER;
    }

    return acc;
  }, {});
};

export const getDisplayText = (
  displayText: string,
  parameters: WorkflowElementDataParameters,
): string => {
  try {
    return compile(displayText)(getFilledParameters(displayText, parameters));
  } catch (e) {
    return 'Something went wrong.';
  }
};
