import * as React from 'react';
import { defineMessages } from 'react-intl';

import { useAppDispatch, useTypedSelector } from 'store';
import { Modal } from 'components/modal';
import { useFlag, useModal, WorkspaceModalTypes } from 'modules/modals';

import { deleteWorkspace } from '../workspaceActions';

import { selectWorkspaceById } from '../workspacesSlice';

const messages = defineMessages({
  title: {
    id: 'workspace.delete-workspace-modal.title',
    defaultMessage: 'Workspace deleting',
  },
  submit: {
    id: 'workspace.delete-workspace-modal.title',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'workspace.delete-workspace-modal.title',
    defaultMessage: 'Cancel',
  },
});

type DeleteWorkspaceModalType = { pk: string };

export const DeleteWorkspaceModal: React.FC<DeleteWorkspaceModalType> = ({ pk }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { closeModal } = useModal();
  const { showFlag } = useFlag();

  const workspace = useTypedSelector(selectWorkspaceById(pk));

  const onClose = React.useCallback(() => {
    closeModal(WorkspaceModalTypes.DeleteWorkspaceModal);
  }, [closeModal]);

  const onSubmit = React.useCallback(async () => {
    try {
      if (isLoading) return;

      setIsLoading(true);

      await dispatch(deleteWorkspace(pk)).unwrap();

      showFlag({
        title: 'Workspace deleted.',
      });

      closeModal(WorkspaceModalTypes.DeleteWorkspaceModal);
    } catch (e) {
      showFlag({
        title: 'Something went wrong.',
        variant: 'error',
      });
    }
  }, [isLoading, pk, showFlag, closeModal, setIsLoading, dispatch]);

  return (
    <Modal
      title={messages.title}
      submitMessage={messages.submit}
      cancelMessage={messages.cancel}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <p>
        Are you sure you want to delete
        <span className="has-bolt-text">{` ${workspace?.name || ''} workspace`}</span>? This action
        cannot be undone.
      </p>
    </Modal>
  );
};
