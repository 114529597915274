import { Schema } from 'schemas/types';

export const FilterSchema: Schema = {
  type: 'object',
  properties: {
    label: {
      title: 'Label',
      type: 'string',
      default: '',
    },
    properties: {
      type: 'string',
      title: 'Properties',
      enum: [],
      fillSchemaFieldTypes: ['string'],
    },
    entities_property: {
      type: 'string',
      title: 'Properties',
      enum: [],
      fillSchemaFieldTypes: ['array'],
    },
  },
};
