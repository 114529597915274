import React from 'react';

import { useModal, SettingsModalTypes } from 'modules/modals/index';

import { CommonActionsCell } from 'components/CommonActionsCell';
import { ModalLink } from 'components/Table/ModalLink';

type ActionsCellType = {
  id: string;
};

export const AlertMediaActionsCell: React.FC<ActionsCellType> = React.memo(({ id }) => {
  const { openModal } = useModal();

  const onEditClick = React.useCallback(() => {
    openModal({
      type: SettingsModalTypes.CreateEditAlertDialog,
      data: {
        id,
      },
    });
  }, [openModal, id]);

  const onDeleteClick = React.useCallback(() => {
    openModal({
      type: SettingsModalTypes.ConfirmDeleteDialog,
      data: {
        id,
      },
    });
  }, [openModal, id]);

  return (
    <CommonActionsCell>
      <ModalLink onClick={onEditClick}>Edit</ModalLink>
      <ModalLink onClick={onDeleteClick}>Delete</ModalLink>
    </CommonActionsCell>
  );
});
