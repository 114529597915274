import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';

import { Button } from '@material-ui/core';

import { FormTextField } from 'components/FormTextField';

import { useFlag } from 'modules/modals';

import {
  initialValues,
  inviteMemberFormValidationSchema as validationSchema,
  InviteMemberFormValuesType,
} from './InviteMemberFormValidation';
import { RoleSelector } from '../RoleSelector';
import { WorkspaceApi } from '../../WorkspaceApi';

import './styles.scss';

const messages = defineMessages({
  title: {
    id: 'workspaces.invite-user-input.title',
    defaultMessage: 'Invite user:',
  },
  invite: {
    id: 'workspaces.invite-user-input.title',
    defaultMessage: 'Invite',
  },
});

type InviteMemberFormType = {
  workspaceId: string;
};

export const InviteMemberForm: React.FC<InviteMemberFormType> = ({ workspaceId }) => {
  const { showFlag } = useFlag();

  const onSubmit = React.useCallback(
    async ({ email }: InviteMemberFormValuesType) => {
      try {
        await WorkspaceApi.inviteUser(workspaceId, email);

        showFlag({
          title: 'User successfully invited.',
        });
      } catch (e) {
        showFlag({
          title: 'Something went wrong.',
          variant: 'error',
        });
      }
    },
    [showFlag, workspaceId],
  );

  const { values, handleChange, errors, handleSubmit } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema,
    validateOnChange: false,
    onSubmit,
  });

  return (
    <div className="has-top-margin has-bottom-margin">
      <FormattedMessage tagName="p" {...messages.title} />
      <form className="invite-member-form" onSubmit={handleSubmit}>
        <div className="has-top-margin">
          <FormTextField
            name="email"
            value={values.email}
            onChange={handleChange}
            errorText={errors.email}
          />
        </div>
        <RoleSelector error={Boolean(errors.group)} value={values.group} onChange={handleChange} />
        <Button size="small" type="submit" variant="contained">
          <FormattedMessage {...messages.invite} />
        </Button>
      </form>
    </div>
  );
};
