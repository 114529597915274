import * as React from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';

import { IconButton } from '@material-ui/core';

import { CloseIcon } from 'components/icons';

import {
  DeployStatusWrapper,
  DeployStateText,
  FinishedIcon,
  FailedIcon,
} from './DeployStatus.styles';

import type { DeployStatusType } from '../../types';

const messages = defineMessages({
  success: {
    id: 'automation.automation-edit-page.panel.success',
    defaultMessage: 'Workflow successfully deployed',
  },
  failed: {
    id: 'automation.automation-edit-page.panel.failed',
    defaultMessage: 'Invalid automation configuration',
  },
});

const ICON_MAP: Record<string, React.ReactNode> = {
  finished: <FinishedIcon />,
  failed: <FailedIcon />,
};

const MESSAGE_MAP: Record<string, MessageDescriptor> = {
  finished: messages.success,
  failed: messages.failed,
};

type DeployStatusPropsType = {
  deployStatus: DeployStatusType;
  onClose(): void;
};

export const DeployStatus: React.FC<DeployStatusPropsType> = React.memo(
  ({ deployStatus, onClose }) => {
    const icon = React.useMemo(() => deployStatus && ICON_MAP[deployStatus], [deployStatus]);
    const message = React.useMemo(() => deployStatus && MESSAGE_MAP[deployStatus], [deployStatus]);

    if (!deployStatus || !message) {
      return null;
    }

    return (
      <DeployStatusWrapper deployStatus={deployStatus}>
        <DeployStateText>
          {icon}
          <FormattedMessage tagName="p" {...message} />
        </DeployStateText>
        <IconButton style={{ padding: 0 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DeployStatusWrapper>
    );
  },
);
