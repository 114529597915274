import { Schema } from 'schemas/types';

export const IndexingSchema: Schema = {
  type: 'object',
  properties: {
    label: {
      title: 'Label',
      type: 'string',
      default: '',
    },
    properties: {
      type: 'string',
      title: 'Properties',
      enum: [],
      fillSchemaFieldTypes: ['string', 'array'],
    },
    timestamp_field: {
      type: 'number',
      title: 'Timestamp attribute',
      fillSchemaFieldTypes: ['number'],
    },
    model: {
      type: 'string',
      fillSchemaFieldTypes: ['string'],
    },
  },
};
