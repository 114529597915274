import * as React from 'react';
import { Button, Popover, Typography, withStyles } from '@material-ui/core';

import { AttachedWorkflow } from 'modules/automation/workflowsActions';

const ActionsPopoverStyled = withStyles({
  root: {
    '& .MuiPopover-paper': {
      padding: '10px',
    },
  },
})(Popover);

type ConnectionsCounterType = {
  workflows: AttachedWorkflow[];
  connectionName: string;
};

export const ConnectionsCounter: React.FC<ConnectionsCounterType> = ({
  workflows,
  connectionName,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null);

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div>
      <p id="connections-counter" className="has-top-margin">
        The <span className="has-bolt-text">{connectionName}</span> connection will be replaced in{' '}
        <span className="has-bolt-text">{workflows.length}</span> automations:
      </p>
      <Button className="has-top-margin" onClick={handleClick}>
        Click to see list of automation
      </Button>
      <ActionsPopoverStyled
        id="workflows-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ padding: '10px' }}
      >
        {workflows.map(({ id, name }: AttachedWorkflow) => (
          <Typography key={id}>{name}</Typography>
        ))}
      </ActionsPopoverStyled>
    </div>
  );
};
