import React, { useMemo } from 'react';

import { useTypedSelector } from 'store';

import { EntityMapName } from 'precessors/types';
import { Schema } from 'schemas/types';
import { getProcessorOutput, createFilledProcessorSchema } from 'precessors/utils';

import { selectGraph } from 'modules/automation/workflowSelectors';
import { AutomationContext, AutomationContextType } from 'modules/automation/AutomationContext';

type UseSchemaType = {
  parentId?: string;
  datasetId?: string;
  customComponentId?: string;
  processorType: ProcessorTypes;
};

export const useSchema = ({
  processorType,
  parentId,
  datasetId,
  customComponentId,
}: UseSchemaType): Schema => {
  const graph = useTypedSelector(selectGraph);
  const { connections, customComponents, datasets, apiKeys } =
    React.useContext<AutomationContextType>(AutomationContext);

  const dataModel = useMemo(
    () =>
      getProcessorOutput({
        graph,
        processorId: parentId,
        customComponents,
      }),
    [graph, parentId, customComponents],
  );

  return useMemo((): Schema => {
    const entities: EntityMapName = {
      dataset: datasets,
      connection: connections,
      'api-key': apiKeys,
      'custom-component': customComponents,
    };

    return createFilledProcessorSchema({
      processorType,
      dataModel,
      datasetId,
      customComponentId,
      entities,
    });
  }, [
    dataModel,
    processorType,
    apiKeys,
    datasetId,
    customComponentId,
    connections,
    customComponents,
    datasets,
  ]);
};
