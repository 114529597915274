import React, { FC } from 'react';
import Avatar from '@material-ui/core/Avatar';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const getAvatarContentName = (name: string): string => {
  if (name.includes(' ')) {
    return name.split(' ')[0][0] + name.split(' ')[1][0];
  }

  return name.split(' ')[0][0];
};

type WorkspaceAvatarType = {
  name: string;
  src?: string;
};

export const WorkspaceAvatar: FC<WorkspaceAvatarType> = ({ name, src }) => {
  return (
    <Avatar
      style={{ marginLeft: 'auto', background: stringToColor(name) }}
      alt={`${name} logo`}
      src={src}
    >
      {getAvatarContentName(name).toUpperCase()}
    </Avatar>
  );
};
