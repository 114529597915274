import React, { FC } from 'react';

import { useTypedSelector } from 'store';

import { getModalByType } from './getModalByType';
import { ModalStoreType } from './modalsSlice';

type ConnectedModalProps = {
  modalCreator?: (item: ModalStoreType) => unknown;
};

export const ConnectedModal: FC<ConnectedModalProps> = ({ modalCreator = getModalByType }) => {
  const modalsState = useTypedSelector((state) => state.modals.modals);

  const modals = React.useMemo(() => modalsState.map(modalCreator), [modalCreator, modalsState]);

  if (modals) {
    return <>{modals}</>;
  }

  return null;
};
