import React, { FC, useState, useCallback, useEffect } from 'react';
import { defineMessages } from 'react-intl';
import Form from '@rjsf/material-ui';

import { useAppDispatch, useTypedSelector } from 'store';
import { uiSchema } from 'schemas';
import { FormDataChangeEvent } from 'types';

import { CredentialsModalTypes } from 'modules/modals/constants';
import { useFlag, useModal } from 'modules/modals';
import { Modal } from 'components/modal';

import { apiKeySchema } from './schemas';
import { updateApiKey } from '../apiKeyCredenetialActions';

const messages = defineMessages({
  title: {
    id: 'credentials.update-docker-credential.title',
    defaultMessage: 'Update Credential',
  },
  update: {
    id: 'credentials.update-docker-credential.create',
    defaultMessage: 'Update',
  },
  cancel: {
    id: 'credentials.update-docker-credential.cancel',
    defaultMessage: 'Close',
  },
});

type EditCredentialModalType = {
  credentialId: string;
};

export const EditApiKeyCredentialModel: FC<EditCredentialModalType> = ({ credentialId }) => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();
  const { showFlag } = useFlag();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const credential = useTypedSelector((state) =>
    state.credentials.apiKeys.find((item) => item.pk === credentialId),
  );

  const [formData, setFormData] = useState<Maybe<ApiKeyApiModel>>(credential);

  const onSubmit = useCallback(async () => {
    try {
      if (isLoading || !formData) return;

      setIsLoading(true);

      await dispatch(updateApiKey(formData)).unwrap();

      showFlag({ title: 'Endpoint  is not ready' });
      closeModal(CredentialsModalTypes.EditApiKeyCredentialModal);
    } catch (e) {
      setIsLoading(false);
    }
  }, [showFlag, setIsLoading, isLoading, dispatch, closeModal, formData]);

  const onChange = useCallback(
    (data: FormDataChangeEvent<ApiKeyApiModel>) => {
      setFormData(data.formData);
    },
    [setFormData],
  );

  const onClose = useCallback(() => {
    if (isLoading) return;

    closeModal(CredentialsModalTypes.EditApiKeyCredentialModal);
  }, [isLoading, closeModal]);

  useEffect(() => {
    setFormData(credential);
  }, [credential]);

  return (
    <Modal
      title={messages.title}
      cancelMessage={messages.cancel}
      submitMessage={messages.update}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Form uiSchema={uiSchema} schema={apiKeySchema} onChange={onChange} formData={formData}>
        <></>
      </Form>
    </Modal>
  );
};
