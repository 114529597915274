import React from 'react';

import { useAppDispatch } from 'store';
import { useNavigation } from 'hooks';

import { WebsiteRoutes } from 'constants/routes';
import { CommonActionsCell } from 'components/CommonActionsCell';
import { ModalLink } from 'components/Table/ModalLink';
import { useFlag } from 'modules/modals';

import { deleteAutomation } from '../../automationActions';

type ActionsCellProps = {
  workflowId: string;
};

export const WorkflowTableActionsCell: React.FC<ActionsCellProps> = ({ workflowId }) => {
  const { showFlag } = useFlag();
  const dispatch = useAppDispatch();
  const navigate = useNavigation();
  const onEdit = React.useCallback(() => {
    navigate(WebsiteRoutes.WorkflowEdit, { id: workflowId });
  }, [navigate, workflowId]);

  const onDelete = React.useCallback(async () => {
    await dispatch(deleteAutomation(workflowId)).unwrap();

    showFlag({ title: 'Automation was deleted.' });
  }, [dispatch, workflowId, showFlag]);

  return (
    <CommonActionsCell>
      <ModalLink fullWidth onClick={onEdit}>
        Edit
      </ModalLink>
      <ModalLink fullWidth onClick={onDelete}>
        Delete
      </ModalLink>
    </CommonActionsCell>
  );
};
