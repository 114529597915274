import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'store';

import { ModalLink } from 'components/Table/ModalLink';
import { CommonActionsCell } from 'components/CommonActionsCell';
import { WorkspaceModalTypes, useModal, useFlag } from 'modules/modals';

import { updateWorkspaceArchiveStatus } from '../../workspacesActions';

const messages = defineMessages({
  edit: {
    id: 'workspaces.workspace-card.card-actions-popover.data-edit',
    defaultMessage: 'Edit',
  },
  archive: {
    id: 'workspaces.workspace-card.card-actions-popover.archive',
    defaultMessage: 'Archive',
  },
  unarchive: {
    id: 'workspaces.workspace-card.card-actions-popover.unarchive',
    defaultMessage: 'Unarchive',
  },
  delete: {
    id: 'workspaces.workspace-card.card-actions-popover.delete',
    defaultMessage: 'Delete',
  },
});

type WorkspaceCardActionsPopoverType = {
  pk: string;
  archived: boolean;
};

export const WorkspaceCardActionsPopover: React.FC<WorkspaceCardActionsPopoverType> = React.memo(
  ({ pk, archived }) => {
    const { openModal } = useModal();
    const { showFlag } = useFlag();
    const dispatch = useAppDispatch();

    const onDeleteClick = React.useCallback(() => {
      openModal({ type: WorkspaceModalTypes.DeleteWorkspaceModal, data: { pk } });
    }, [openModal, pk]);

    const onArchiveClick = React.useCallback(async () => {
      try {
        const archive = !archived;

        await dispatch(updateWorkspaceArchiveStatus({ pk, archive })).unwrap();

        showFlag({
          title: 'successfully achieved.',
        });
      } catch (e) {
        showFlag({
          title: 'Something went wrong.',
          variant: 'error',
        });
      }
    }, [showFlag, dispatch, pk, archived]);

    const onEditClick = React.useCallback(() => {
      openModal({ type: WorkspaceModalTypes.EditWorkspaceModal, data: { id: pk } });
    }, [openModal, pk]);

    return (
      <CommonActionsCell>
        <ModalLink disabled={archived} onClick={onEditClick}>
          <FormattedMessage {...messages.edit} />
        </ModalLink>
        <ModalLink onClick={onArchiveClick}>
          <FormattedMessage {...(archived ? messages.unarchive : messages.archive)} />
        </ModalLink>
        <ModalLink onClick={onDeleteClick}>
          <FormattedMessage {...messages.delete} />
        </ModalLink>
      </CommonActionsCell>
    );
  },
);
