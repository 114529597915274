export enum WebsiteRoutes {
  Workspaces = '/workspaces',
  Workflows = '/:projectId/automations',
  WorkflowEdit = '/:projectId/automations/:id',
  Connections = '/:projectId/connections',
  Datasets = '/:projectId/datasets',
  CustomComponents = '/:projectId/custom-components',
  Credentials = '/:projectId/credentials',
  DatasetsEdit = '/:projectId/datasets/:datasetId',
}

export const getRouteById = (route: string, id: string): string => route.replace(':projectId', id);
