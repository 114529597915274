import cloneDeep from 'lodash/cloneDeep';
import { Schema } from 'schemas/types';

import { Approve, ApproveSchema } from './Approve';
import { CustomComponent, CustomComponentSchema } from './CustomComponent';
import { DetectCategory, DetectCategorySchema } from './DetectCategory';
import { DetectEntities, DetectEntitiesSchema } from './DetectEntities';
import { DetectSentiment, DetectSentimentSchema } from './DetectSentiment';
import { DatasetWriter, DatasetWriterSchema } from './DatasetWriter';
import { End, EndSchema } from './End';
import { EmailSender, EmailSenderSchema } from './EmailSender';
import { EmailReader, EmailReaderSchema } from './EmailReader';
import { Filter, FilterSchema } from './Filter';
import { GmailReader, GmailReaderSchema } from './GmailReader';
import { GmailSender, GmailSenderSchema } from './GmailSender';
import { GraphDbSaver, GraphDbSaverSchema } from './GraphDbSaver';
import { HtmlToText, HtmlToTextSchema } from './HtmlToText';
import { Hubspot, HubspotSchema } from './Hubspot';
import { If, IfSchema } from './If';
import { Indexing, IndexingSchema } from './Indexing';
import { JoltTransformation, JoltTransformationSchema } from './JoltTransformation';
import { MergerContent, MergerContentSchema } from './MergerContent';
import { RawSaver, RawSaverSchema } from './RawSaver';
import { Wait, WaitSchema } from './Wait';
import { WebScraper, WebScraperSchema } from './WebScraper';
import { LinkedinReader, LinkedinReaderSchema } from './LinkedinReader';

const PROCESSORS_MAP: Record<ProcessorTypes, ProcessorApiModel> = {
  Approve,
  CustomComponent,
  DetectCategory,
  DetectEntities,
  DetectSentiment,
  DatasetWriter,
  End,
  EmailSender,
  EmailReader,
  Filter,
  GmailReader,
  GmailSender,
  GraphDbSaver,
  HtmlToText,
  Hubspot,
  If,
  Indexing,
  JoltTransformation,
  MergerContent,
  RawSaver,
  Wait,
  WebScraper,
  LinkedinReader,
};

const PROCESSOR_SCHEMAS_NAME_MAP: Record<ProcessorTypes, Schema> = {
  Approve: ApproveSchema,
  CustomComponent: CustomComponentSchema,
  DetectCategory: DetectCategorySchema,
  DetectEntities: DetectEntitiesSchema,
  DetectSentiment: DetectSentimentSchema,
  DatasetWriter: DatasetWriterSchema,
  End: EndSchema,
  EmailReader: EmailReaderSchema,
  EmailSender: EmailSenderSchema,
  Filter: FilterSchema,
  GmailReader: GmailReaderSchema,
  GmailSender: GmailSenderSchema,
  GraphDbSaver: GraphDbSaverSchema,
  HtmlToText: HtmlToTextSchema,
  Hubspot: HubspotSchema,
  If: IfSchema,
  Indexing: IndexingSchema,
  JoltTransformation: JoltTransformationSchema,
  MergerContent: MergerContentSchema,
  RawSaver: RawSaverSchema,
  Wait: WaitSchema,
  WebScraper: WebScraperSchema,
  LinkedinReader: LinkedinReaderSchema,
};

export const getProcessors = (): ProcessorApiModel[] => Object.values(PROCESSORS_MAP);

export const getProcessorByType = (processorType: ProcessorTypes): ProcessorApiModel => {
  return PROCESSORS_MAP[processorType];
};

export const getProcessorSchemaByType = (processorType: ProcessorTypes): Schema => {
  return PROCESSOR_SCHEMAS_NAME_MAP[processorType];
};

export const getProcessorDataModelByType = (processorType: ProcessorTypes): DataModel => {
  return PROCESSORS_MAP[processorType].data_model;
};

export const createEmptySchema = (processorType: ProcessorTypes): Schema => {
  const schema = cloneDeep<Schema>(getProcessorSchemaByType(processorType));
  schema.$id = `schema-${processorType}`;

  return schema;
};
