import * as Yup from 'yup';

import { TagsTableSectionStrings } from 'components/TagsCell/en';

export interface InitialEditFormValues {
  name: string;
  description?: string;
  color?: string;
}

export const initialValues: InitialEditFormValues = {
  name: '',
  description: '',
  color: '#ffffff',
};

export const validationSchema: InitialEditFormValues = () => {
  return Yup.object().shape({
    name: Yup.string()
      .max(500, 'max length 100 symbols')
      .required(TagsTableSectionStrings.TagNameRequired),
    description: Yup.string().max(500, 'max length 100 symbols').nullable(),
    color: Yup.string().nullable(),
  });
};
