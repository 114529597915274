import { Schema } from 'schemas/types';

export const EndSchema: Schema = {
  type: 'object',
  properties: {
    label: {
      title: 'Label',
      type: 'string',
      default: '',
    },
  },
};
