export const Approve: ProcessorApiModel = {
  name: 'Approve',
  processor_type: 'Approve',
  description: 'Approve',
  type: 'processor',
  tags: ['Other'],
  data_model: {},
  displayText: 'Test value',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/Approve-icon.png',
  connections: {
    inputPort: true,
    outputPort: true,
  },
};
