import { Schema } from 'schemas/types';

export const dockerCredentialSchema: Schema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      title: 'Credentials Name',
    },
    username: {
      type: 'string',
      title: 'Credentials Username',
    },
    password: {
      type: 'string',
      title: 'Credentials Password',
    },
    server: {
      type: 'string',
      title: 'Credentials Server',
    },
  },
};

export const apiKeySchema: Schema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      title: 'Credentials Name',
    },
    password: {
      type: 'string',
      title: 'Credentials Password',
    },
  },
};

export const apiKeyCredentialsSchema: Schema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      title: 'Credentials Name',
    },
    apikey: {
      type: 'string',
      title: 'Api key',
    },
  },
};
