export const EmailSender: ProcessorApiModel = {
  name: 'Email Sender',
  processor_type: 'EmailSender',
  description: 'Email Sender',
  type: 'connection',
  tags: ['Email'],
  data_model: {},
  displayText: 'Sent emails from :connection_uuid.',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/GmailWriter-icon.png',
  connections: {
    inputPort: true,
    outputPort: false,
  },
};
