import React from 'react';

import { CredentialsModalTypes } from 'modules/modals/constants';

import { ModalStoreType } from '../../modals/modalsSlice';
import { CreateCredentialModal } from './CreateCredentialModal';
import { EditCredentialModal } from './EditCredentialModal';
import { CreateApiKeyCredentialModal } from './CreateApiKeyCredentialModal';
import { EditApiKeyCredentialModel } from './EditApiKeyCredentialModel';

export const getCredentialsModalByType = ({
  type,
  data,
}: ModalStoreType): React.ReactNode | null => {
  switch (type) {
    case CredentialsModalTypes.CreateDockerCredentialModal:
      return <CreateCredentialModal key="create-credential-modal" />;
    case CredentialsModalTypes.EditDockerCredentialModal:
      return (
        <EditCredentialModal
          key="edit-credential-modal"
          credentialId={(data?.credentialId as string) || ''}
        />
      );
    case CredentialsModalTypes.CreateApiKeyCredentialModal:
      return <CreateApiKeyCredentialModal key="create-api-key-credential-modal" />;
    case CredentialsModalTypes.EditApiKeyCredentialModal:
      return (
        <EditApiKeyCredentialModel
          key="update-api-key-credential-modal"
          credentialId={(data?.credentialId as string) || ''}
        />
      );
    default: {
      return null;
    }
  }
};
