import React, { FC, useMemo, useContext } from 'react';
import ReactJson from 'react-json-view';

import { useTypedSelector } from 'store';

import { getProcessorOutput } from 'precessors/utils';

import './styles.scss';
import { selectGraph, selectParentNodeId } from '../../workflowSelectors';
import { AutomationContext } from '../../AutomationContext';

import { theme } from './themeJSONForm';

type ProcessorDataModelViewType = {
  processorId: string;
};

export const ProcessorDataModelView: FC<ProcessorDataModelViewType> = ({ processorId }) => {
  const graph = useTypedSelector(selectGraph);
  const parentId = useTypedSelector(selectParentNodeId(processorId));
  const { customComponents } = useContext(AutomationContext);

  const processorOutput = useMemo(() => {
    return getProcessorOutput({ graph, processorId: parentId, customComponents });
  }, [graph, parentId, customComponents]);

  return (
    <ReactJson
      src={processorOutput}
      iconStyle="circle"
      displayDataTypes={false}
      enableClipboard={false}
      quotesOnKeys={false}
      theme={theme}
      name={null}
    />
  );
};
