import { ProjectParams } from 'types';

export type DeployStatusType = 'deploying' | 'finished' | 'failed' | undefined;

export type DataTransferType = {
  processorType: ProcessorTypes;
  type: ComponentsTypes;
  source: string;
};

export interface AutomationEditParams extends ProjectParams {
  id: string;
}

export type StatisticComponentType = {
  running_count: number;
  stopped_count: number;
  invalid_count: number;
};

export type StatisticStatusType = {
  queued: number;
  read: number;
  written: number;
  received: number;
  transferred: number;
};

export type BulletinsStatusType = {
  id: number;
  level: LogsLevel;
  message: string;
};

type StatisticType = {
  bulletins: BulletinsStatusType[];
  component: StatisticComponentType;
  status: StatisticStatusType;
};

export type AutomationStatisticType = StatisticType & {
  sub_components_info: Record<string, StatisticType>;
};

export const emptyProcessorStatisticStatus: StatisticStatusType = {
  queued: 0,
  read: 0,
  written: 0,
  received: 0,
  transferred: 0,
};

export const emptyProcessorStatisticComponent: StatisticComponentType = {
  running_count: 0,
  stopped_count: 0,
  invalid_count: 0,
};

export type DeployWorkflowPayload = {
  id: string;
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
};
