import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Form from '@rjsf/material-ui';
import { defineMessages } from 'react-intl';

import { useAppDispatch, useTypedSelector } from 'store';
import { customProcessorUiSchema, Schema } from 'schemas';

import { Modal } from 'components/modal';
import { defaultFetchParams } from 'constants/table';

import { fetchDockerCredentials } from 'modules/credentials/credentialsActions';
import { CustomComponentModalTypes, useModal } from 'modules/modals';

import { updateCustomComponent } from '../../customComponentsActions';
import { selectCustomComponentByPk } from '../../customComponentsSelectors';
import { UpdateCustomComponentType } from '../../components/CustomComponentsTable/types';
import { customProcessorValidation } from '../customProcessorValidation';
import { fillDockerCredentialsField } from '../fillUpdateCustomProcessorSchema';
import { updateCustomProcessorSchema } from './updateCustomProcessorSchema';

const messages = defineMessages({
  title: {
    id: 'custom-component.update-custom-component-modal.title',
    defaultMessage: 'Edit Custom Processor',
  },
  create: {
    id: 'custom-component.update-custom-component-modal.create',
    defaultMessage: 'Edit',
  },
  cancel: {
    id: 'custom-component.update-custom-component-modal.cancel',
    defaultMessage: 'Cancel',
  },
});

type UpdateCustomComponentContainerType = {
  pk: string;
};

export const UpdateCustomComponentModal: React.FC<UpdateCustomComponentContainerType> = ({
  pk,
}) => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();

  const initFormData: Maybe<UpdateCustomComponentType> = useTypedSelector(
    selectCustomComponentByPk(pk),
  );

  const [formData, setFormData] = useState<Maybe<UpdateCustomComponentType>>(initFormData);

  const credentials: DockerCredentialApiModel[] = useTypedSelector(
    (store) => store.credentials.dockerCredentials,
  );

  const schema: Schema = useMemo(
    () => fillDockerCredentialsField(updateCustomProcessorSchema, credentials),
    [credentials],
  );

  const onChange = useCallback(
    (data: { formData: UpdateCustomComponentType }) => {
      setFormData(data.formData);
    },
    [setFormData],
  );

  const onSubmit = useCallback(async () => {
    if (formData) {
      await dispatch(updateCustomComponent(formData)).unwrap();

      closeModal(CustomComponentModalTypes.EditCustomComponentModal);
    }
  }, [dispatch, formData, closeModal]);

  const onClose = useCallback(() => {
    closeModal(CustomComponentModalTypes.EditCustomComponentModal);
  }, [closeModal]);

  useEffect(() => {
    dispatch(fetchDockerCredentials(defaultFetchParams));
  }, [dispatch]);

  if (!initFormData) {
    return null;
  }

  return (
    <Modal
      title={messages.title}
      onClose={onClose}
      cancelMessage={messages.cancel}
      submitMessage={messages.create}
      onSubmit={onSubmit}
    >
      <Form
        liveValidate
        validate={customProcessorValidation}
        uiSchema={customProcessorUiSchema}
        schema={schema}
        onChange={onChange}
        formData={formData}
      >
        <></>
      </Form>
    </Modal>
  );
};
