import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import {
  createTableSettingsReducers,
  getTableSettingsInitialValue,
  TableSettingsType,
} from 'types/table';

import { WorkspaceApiModel } from './types';
import { deleteWorkspace, fetchWorkspaces, updateWorkspace } from './workspaceActions';
import { updateWorkspaceArchiveStatus, uploadWorkspaceAvatar } from './workspacesActions';

export type WorkspacesStateType = {
  users: UserApiModel[];
  workspaces: WorkspaceApiModel[];
  tableSettings: TableSettingsType;
};

const initialState: WorkspacesStateType = {
  users: [],
  workspaces: [],
  tableSettings: getTableSettingsInitialValue(),
};

const { reducer, actions } = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    ...createTableSettingsReducers<WorkspacesStateType>(initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkspaces.fulfilled, (state, { payload }) => {
      state.workspaces = payload.results;
      state.tableSettings.count = payload.count;
      state.tableSettings.loading = false;
    });
    builder.addCase(updateWorkspace.fulfilled, (state, { payload }) => {
      state.workspaces = state.workspaces.map((workspace) => {
        if (payload.pk === workspace.pk) {
          return {
            ...payload,
          };
        }
        return workspace;
      });
    });
    builder.addCase(deleteWorkspace.fulfilled, (state, { payload }) => {
      state.workspaces = state.workspaces.filter((ws) => ws.pk !== payload);
    });

    builder.addCase(updateWorkspaceArchiveStatus.fulfilled, (state, { payload }) => {
      state.workspaces = state.workspaces.map((ws) => ({
        ...ws,
        archived: ws.pk === payload.pk ? payload.archive : ws.archived,
      }));
    });

    builder.addCase(uploadWorkspaceAvatar.fulfilled, (state, { payload }) => {
      state.workspaces = state.workspaces.map((ws) => ({
        ...ws,
        project_image_url: ws.pk === payload.workspaceId ? payload.avatarUrl : ws.project_image_url,
      }));
    });
  },
});

export const selectWorkspaceById =
  (id: string) =>
  (store: RootState): Maybe<WorkspaceApiModel> =>
    store.workspaces.workspaces.find((item) => item.pk === id);

export const { changeSorting, goToSelectedPage, updateSize, updateSearch } = actions;

export default reducer;
