import { withStyles } from '@material-ui/core';

import Input from '@material-ui/core/Input';
import styled from 'styled-components';
import MuiSelect from '@material-ui/core/Select';

export const SearchInput = withStyles({
  root: {
    width: '250px',
  },
})(Input);

export const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Selector = withStyles({
  root: {
    width: '250px',
  },
})(MuiSelect);
