import React from 'react';
import { Provider } from 'react-redux';
import { ReactFlowProvider } from 'react-flow-renderer';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { ConfigProvider } from 'react-avatar';
import { SnackbarProvider } from 'notistack';

import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';

import { theme } from 'theme/theme';
import store, { persistor } from 'store';
import { DrawerProvider, LanguageProvider, UserProfileProvider } from 'providers';
import keycloak from 'keycloak';

import { GlobalStyle } from 'GlobalStyle';
import { Routes } from './routing/routes';

const App = React.memo(() => (
  <ReactKeycloakProvider authClient={keycloak}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <UserProfileProvider>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <LanguageProvider>
                  <ConfigProvider>
                    <SnackbarProvider maxSnack={3}>
                      <ReactFlowProvider>
                        <DrawerProvider>
                          <GlobalStyle />
                          <Routes />
                        </DrawerProvider>
                      </ReactFlowProvider>
                    </SnackbarProvider>
                  </ConfigProvider>
                </LanguageProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </UserProfileProvider>
      </PersistGate>
    </Provider>
  </ReactKeycloakProvider>
));

export default App;
