import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ModalTitleStyled } from 'components/modal/ModalHeader';
import { TabContent } from 'components/Tabs';

import { UserPermissionList } from './UserPermissionList';

const messages = defineMessages({
  title: {
    id: 'automation.set-project-permission-modal.title',
    defaultMessage: 'Permission settings',
  },
  close: {
    id: 'automation.set-project-permission-modal.close',
    defaultMessage: 'Close',
  },
});

type UserPermissionListProps = {
  workspaceId: string;
  listClassName?: string;
};

export const UserPermissionListTab = React.memo(
  ({ listClassName, workspaceId }: UserPermissionListProps) => (
    <TabContent>
      <ModalTitleStyled>
        <FormattedMessage {...messages.title} />
      </ModalTitleStyled>
      <UserPermissionList workspaceId={workspaceId} listClassName={listClassName} />
    </TabContent>
  ),
);
