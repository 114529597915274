import React from 'react';

import { TableHead, TableRow } from '@material-ui/core';

import { OrderDirection } from 'constants/table';

import type { TableHeaderParams } from './Table.types';
import { SortableTableCell } from './SortableTableCell';

export type TableHeaderProps = {
  order?: OrderDirection;
  orderBy?: string;
  header: { [key: string]: TableHeaderParams };
  onClickSort?: (property: string) => void;
};

export const TableHeader: React.FC<TableHeaderProps> = ({
  header,
  order,
  orderBy,
  onClickSort = () => {},
}) => {
  const renderHeaderCells = () => {
    const headerCells: JSX.Element[] = [];

    for (const columnName of Object.keys(header)) {
      if (columnName in header) {
        const cell = header[columnName];

        headerCells.push(
          <SortableTableCell
            columnName={columnName}
            sortable={cell.sortable}
            orderBy={orderBy}
            order={order}
            onClickSort={onClickSort}
            width={cell.width}
          >
            {cell.name}
          </SortableTableCell>,
        );
      }
    }

    return headerCells;
  };

  return (
    <TableHead>
      <TableRow>{renderHeaderCells()}</TableRow>
    </TableHead>
  );
};
