import React, { useCallback, useEffect, useState } from 'react';

import { TabContent } from 'components/Tabs';
import { getDefaultParams, getOrder, Params } from 'common/Params';

import { useFlag } from 'modules/modals';
import { WorkspaceVariablesApi } from 'modules/variables/WorkspaceVariablesApi';
import { ParamInput } from 'modules/variables/ParamInput';
import { VariablesTable } from 'modules/variables/VariablesTable';
import { Variable } from 'modules/variables/types';

export const WorkspaceVariables = () => {
  const { showFlag } = useFlag();
  const [variables, setVariables] = useState<Variable[]>([]);
  const [params, setParams] = React.useState<Params>(getDefaultParams);

  const onCreateParam = useCallback(
    async (variable: Variable) => {
      const createdParam = await WorkspaceVariablesApi.createParam(variable);

      setVariables((items) => {
        return [createdParam, ...items];
      });

      showFlag({ title: 'Created!' });
    },
    [showFlag],
  );

  const onUpdateParam = useCallback(
    async (param: Variable) => {
      await WorkspaceVariablesApi.updateParam(param);

      setVariables((items) => items.map((item) => (item.id === param.id ? param : item)));

      showFlag({ title: 'Updated!' });
    },
    [setVariables, showFlag],
  );

  const onRemove = useCallback(
    async (variable: Variable) => {
      await WorkspaceVariablesApi.removeParam(variable.id);

      setVariables((items) => items.filter((item) => item.id !== variable.id));

      showFlag({
        title: 'Removed!',
      });
    },
    [setVariables, showFlag],
  );

  const onClickSort = useCallback((columnName: string) => {
    setParams((item) => {
      return {
        ...item,
        orderBy: columnName,
        order: getOrder({
          orderBy: item.orderBy,
          order: item.order,
          columnName,
        }),
      };
    });
  }, []);

  useEffect(() => {
    WorkspaceVariablesApi.fetchParams(params).then((items) => setVariables(items));
  }, [params]);

  return (
    <>
      <TabContent>
        <ParamInput createParam={onCreateParam} />
        <VariablesTable
          sortable
          onClickSort={onClickSort}
          items={variables}
          params={params}
          removeParam={onRemove}
          updateParam={onUpdateParam}
          showActions
        />
      </TabContent>
    </>
  );
};
