import { Schema } from 'schemas/types';

export const scraperConnectionSchema: Schema = {
  type: 'object',
  required: ['start_urls', 'allowed_domains'],
  properties: {
    name: {
      title: 'Name',
      type: 'string',
    },
    description: {
      type: 'string',
      description: 'Connection description',
      title: 'Description',
      default: '',
    },
    scheduler: {
      type: 'boolean',
      default: false,
      description: 'Enable cron',
      title: 'Enable cron',
    },
    cron: {
      type: 'string',
      default: '* * * * *',
      description: 'Cron interval (min)',
      title: 'Cron interval',
    },
    proxy_servers: {
      title: 'Proxy',
      type: 'array',
      default: [],
      items: {
        type: 'string',
        default: '',
      },
    },
    start_urls: {
      title: "URL's",
      type: 'array',
      default: [],
      items: {
        type: 'string',
        default: '',
      },
    },
    allowed_domains: {
      title: 'Allowed domains',
      type: 'array',
      default: [],
      items: {
        type: 'string',
        default: '',
      },
    },
    title_xpath: {
      type: 'string',
      title: 'Title xpath',
    },
    description_xpath: {
      type: 'string',
      title: 'Description xpath',
    },
    content_xpath: {
      type: 'string',
      title: 'Content xpath',
    },
    published_time_xpath: {
      type: 'string',
      title: 'Published time xpath',
    },
    modified_time_xpath: {
      type: 'string',
      title: 'Modified time xpath',
    },
    page_xpath_validation_rule: {
      type: 'string',
      title: 'Page validation xpath',
    },
    urls_on_page_xpath: {
      type: 'string',
      title: 'Urls on page xpath',
    },
  },
};
