import * as React from 'react';

import { getDisplayText } from './utils/getDisplayText';
import { NodeDetailsAccordion } from './NodeDetailsAccordion';
import { useFilledParams } from './useFilledParams';

type NodeBodyProps = {
  entityId?: string;
  displayText?: string;
  parameters: WorkflowElementDataParameters;
  processorId: string;
};

export const NodeBody: React.FC<NodeBodyProps> = ({
  entityId,
  displayText,
  parameters,
  processorId,
}) => {
  const filledParameters = useFilledParams(parameters);

  return (
    <>
      <div className="node-content">
        {displayText ? getDisplayText(displayText, filledParameters) : ''}
      </div>
      <NodeDetailsAccordion entityId={entityId} processorId={processorId} />
    </>
  );
};
