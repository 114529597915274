import React from 'react';

import {
  AttachedWorkflow,
  getWorkflowsByConnectionPk,
} from '../modules/automation/workflowsActions';

type UseConnectionsWorkflowsPayloadType = {
  isWorkflowsLoading: boolean;
  isWorkflowsError: boolean;
  workflows: AttachedWorkflow[];
};

export const useConnectionsWorkflows = (pk: string): UseConnectionsWorkflowsPayloadType => {
  const [isWorkflowsError, setIsError] = React.useState<boolean>(false);
  const [isWorkflowsLoading, setIsLoading] = React.useState<boolean>(false);

  const [workflows, setWorkflows] = React.useState<AttachedWorkflow[]>([]);

  React.useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setWorkflows([]);

    getWorkflowsByConnectionPk(pk)
      .then((items: AttachedWorkflow[]) => {
        setWorkflows(items);
        setIsLoading(false);
      })
      .catch(() => {
        setWorkflows([]);
        setIsLoading(false);
        setIsError(true);
      });
  }, [pk, setWorkflows, setIsLoading]);

  return {
    isWorkflowsLoading,
    workflows,
    isWorkflowsError,
  };
};
