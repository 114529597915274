import { CustomComponentApiModel } from 'modules/custom-components/types';

import { EntityName, allowedEntityNames } from 'schemas/types';

export const isEntityName = (name: string): name is EntityName => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return allowedEntityNames.includes(name);
};

export type EntityMapName = {
  connection: ConnectionApiModel[];
  dataset: IndexApiModel[];
  'custom-component': CustomComponentApiModel[];
  'api-key': ApiKeyApiModel[];
};
