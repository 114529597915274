import { useContext } from 'react';

import { ProcessorWarning } from '../components/utils/validateProcessorField';
import { AutomationWarningsContext } from '../AutomationWarnigsContext';

export const useProcessorsWarnings = (processorId?: string): ProcessorWarning[] => {
  const { warnings } = useContext(AutomationWarningsContext);

  if (processorId) {
    return warnings.filter((warning) => warning.processorId === processorId);
  }

  return warnings;
};
