import * as React from 'react';

import { ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import { AutomationHistoryItemActions } from './AutomationHistoryItemActions';
import { DataLabel } from './AutomationHistoryModal.styles';

const dateVersionFormatting = (date: string) => new Date(date).toDateString();

type AutomationHistoryItemType = {
  currentVersionId: string;
  isLastVersion: boolean;
  automation: AutomationApiModel;
};

export const AutomationHistoryItem: React.FC<AutomationHistoryItemType> = React.memo(
  ({ currentVersionId, automation, isLastVersion }) => (
    <ListItem>
      <ListItemText primary={automation.name} secondary={automation.comment} />
      {isLastVersion && (
        <Tooltip title="Last deployed version">
          <Info />
        </Tooltip>
      )}
      <DataLabel>{dateVersionFormatting(automation.updated_at)}</DataLabel>
      <AutomationHistoryItemActions
        isLastVersion={isLastVersion}
        automationId={automation.id}
        currentVersionId={currentVersionId}
      />
    </ListItem>
  ),
);
