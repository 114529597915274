import React, { useState, useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Form from '@rjsf/material-ui';
import { Button, DialogActions } from '@material-ui/core';

import { useAppDispatch } from 'store';
import { uiSchema } from 'schemas';
import { FormDataChangeEvent } from 'types';

import { ModalTitleStyled } from 'components/modal/ModalHeader';
import { TabContent } from 'components/Tabs';

import { useFlag } from 'modules/modals';
import { WorkspaceApiModel } from '../../types';
import { updateWorkspace } from '../../workspaceActions';
import { FormData, projectSchema } from '../projectSchema';
import { UploadWorkspaceAvatarForm } from './UploadWorkspaceAvatarForm';

const messages = defineMessages({
  title: {
    id: 'automation.set-project-permission-modal.title',
    defaultMessage: 'General settings',
  },
  save: {
    id: 'automation.set-project-permission-modal.create',
    defaultMessage: 'Save Changes',
  },
  cancel: {
    id: 'automation.set-project-permission-modal.cancel',
    defaultMessage: 'Cancel',
  },
});

type EditWorkspaceTabPanelProps = {
  workspace: WorkspaceApiModel;
};

export const EditWorkspaceTabPanel = ({ workspace }: EditWorkspaceTabPanelProps) => {
  const { showFlag } = useFlag();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<FormData>({
    name: workspace.name,
    description: workspace.description,
  });

  const onChange = useCallback(
    (data: FormDataChangeEvent<FormData>) => {
      setFormData(data.formData);
    },
    [setFormData],
  );

  const onSubmit = useCallback(async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      await dispatch(
        updateWorkspace({
          pk: workspace.pk,
          name: formData.name,
          description: formData.description,
        }),
      ).unwrap();

      showFlag({ title: 'Workspace settings updated.' });

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      showFlag({ title: 'Something went wrong.' });
    }
  }, [isLoading, showFlag, setIsLoading, dispatch, formData, workspace]);

  return (
    <>
      <TabContent>
        <ModalTitleStyled>
          <FormattedMessage {...messages.title} />
        </ModalTitleStyled>
        <Form formData={formData} uiSchema={uiSchema} schema={projectSchema} onChange={onChange}>
          <></>
        </Form>
        <UploadWorkspaceAvatarForm
          workspaceId={workspace.pk}
          workspaceName={workspace.name}
          workspaceAvatarUrl={workspace.project_image_url}
        />
      </TabContent>
      <DialogActions>
        <Button variant="contained" disableElevation color="primary" onClick={onSubmit}>
          <FormattedMessage {...messages.save} />
        </Button>
      </DialogActions>
    </>
  );
};
