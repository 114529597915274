import React, { useCallback, useMemo, useState } from 'react';
import Form from '@rjsf/material-ui';
import { defineMessages, FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';

import { useAppDispatch } from 'store';
import { createSchemaFromObject, Fields, Schema } from 'schemas';
import { useFormData } from 'hooks';
import { useFlag } from 'modules/modals';

import { TabContent } from 'components/Tabs';
import { ModalTitleStyled } from 'components/modal/ModalHeader';
import { DialogActions } from 'components/modal';

import { updateConnector } from '../../../connectionActions';

const messages = defineMessages({
  title: {
    id: 'connections.edit-connection-modal.title',
    defaultMessage: 'Config',
  },
  save: {
    id: 'connections.edit-connection-modal.save',
    defaultMessage: 'Save',
  },
});

type ConfigurationSettingsProps = {
  config: ConnectorConfigApiModel;
  connectorData: ConnectorApiModel;
};

export const ConfigurationSettings = ({ config, connectorData }: ConfigurationSettingsProps) => {
  const { showFlag } = useFlag();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { formData, onChange } = useFormData<ConnectorConfigApiModel>(config);
  const schema: Schema = useMemo(() => {
    return createSchemaFromObject(config as Fields);
  }, [config]);

  const onSubmit = useCallback(async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      await dispatch(
        updateConnector({
          id: connectorData.pk,
          connectorData: {
            ...connectorData,
            config: formData,
          },
        }),
      ).unwrap();

      setIsLoading(false);
      showFlag({ title: 'Settings was updated' });
    } catch (e) {
      setIsLoading(false);
      showFlag({ title: 'Something went wrong.' });
    }
  }, [dispatch, isLoading, formData, connectorData, showFlag]);

  return (
    <>
      <TabContent>
        <ModalTitleStyled>
          <FormattedMessage {...messages.title} />
        </ModalTitleStyled>
        <Form className="application-form" onChange={onChange} schema={schema} formData={formData}>
          <></>
        </Form>
      </TabContent>
      <DialogActions>
        <Button variant="contained" disableElevation color="primary" onClick={onSubmit}>
          <FormattedMessage {...messages.save} />
        </Button>
      </DialogActions>
    </>
  );
};
