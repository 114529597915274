import get from 'lodash/get';

import type { SchemaProperty, Filter } from 'schemas/types';

const applyFilter = (entities: Entity[], filter: Filter) => {
  const { fieldName, value } = filter;

  return entities.filter((item) => get(item, [fieldName], '') === value);
};

export const fillEntityProperty = (property: SchemaProperty, entities: Entity[]): void => {
  let items = entities;

  if ('filter' in property && property.filter) {
    items = applyFilter(entities, property.filter);
  }

  property.enumNames = items.map((item: Entity) => item.name);
  property.enum = items.map((item: Entity) => item.pk);
};
