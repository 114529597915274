import React, { memo, FC, SyntheticEvent } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

type SubmitButtonProps = {
  message: MessageDescriptor;
  onClick: (e: SyntheticEvent) => void;
  disabled?: boolean;
  isLoading: boolean;
};

export const SubmitButton: FC<SubmitButtonProps> = memo(
  ({ disabled, isLoading, onClick, message }) => (
    <Button
      disabled={disabled || isLoading}
      type="submit"
      onClick={onClick}
      variant="contained"
      color="primary"
      autoFocus={false}
    >
      {isLoading ? <CircularProgress size={18} /> : <FormattedMessage {...message} />}
    </Button>
  ),
);
