import * as React from 'react';

import { WorkflowsModalTypes } from 'modules/modals/constants';
import { ModalStoreType } from 'modules/modals/modalsSlice';

import { ConfirmAwayEditWorkflowDialog } from './ConfirmAwayEditWorkflowDialog';
import { WorkflowSettingsModal } from './WorkflowSettingsModal/WorkflowSettingsModal';
import { CreateWorkflowModal } from './CreateWorkflowModal';
import { AutomationHistoryModal } from './AutomationHistoryModal/AutomationHistoryModal';
import { DeleteStepModal, DeleteStepModalType } from './DeleteStepModal';
import { CreateNodeModal, CreateNodeModalType } from './CreateNodeModal';
import { EditAutomationVersion } from './EditAutomationVersion';

export const getAutomationModalByType = (item: ModalStoreType): React.ReactNode | null => {
  switch (item.type) {
    case WorkflowsModalTypes.CreateNodeModal:
      return <CreateNodeModal key="create-node-modal" {...(item.data as CreateNodeModalType)} />;
    case WorkflowsModalTypes.AutomationHistoryModal:
      return <AutomationHistoryModal key="automation-history-modal" />;
    case WorkflowsModalTypes.DeleteStepModal:
      return <DeleteStepModal key="delete-step-modal" {...(item.data as DeleteStepModalType)} />;
    case WorkflowsModalTypes.ConfirmAwayEditWorkflowDialog:
      return <ConfirmAwayEditWorkflowDialog key="confirm-away-edit-workflow-dialog" />;
    case WorkflowsModalTypes.WorkflowSettingsModal:
      return <WorkflowSettingsModal key="workflow-settings-modal" />;
    case WorkflowsModalTypes.CreateWorkflowModal:
      return <CreateWorkflowModal key="create-workflow-modal" />;
    case WorkflowsModalTypes.EditAutomationVersion:
      return (
        <EditAutomationVersion
          key="edit-automation-history"
          automationId={item?.data?.automationId as string}
        />
      );
    default:
      return null;
  }
};
