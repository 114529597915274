export enum Services {
  NifiManager = 'nifi-manager',
  CustomProcessor = 'processors/custom',
  Connectors = 'connectors',
  LogsManager = 'logs-manager',
  Project = 'project',
  Search = 'search',
  Alert = 'alert',
  Params = 'param-storage',
  Connection = 'project/datasource/project',
}
