import * as React from 'react';

import { DrawerContext, DrawerContextType } from 'providers';

import { PageContentWrapper, getContentWrapperOffset } from './Layout.style';
import { SideMenu } from '../SideMenu/SideMenu';
import { Header } from '../Header';

export const Layout: React.FC = React.memo(({ children }) => {
  const { isDrawerOpen } = React.useContext<DrawerContextType>(DrawerContext);

  return (
    <div className="page-wrapper">
      <Header />
      <SideMenu />
      <PageContentWrapper offset={getContentWrapperOffset(isDrawerOpen)}>
        {children}
      </PageContentWrapper>
    </div>
  );
});
