import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { SettingsOutlined, Restore, Save, ChevronLeft } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import { useTypedSelector } from 'store';
import { selectWorkspaceId } from 'store/userSettingsSlice';
import { WebsiteRoutes } from 'constants/routes';
import { getRoutePath } from 'utils/path';
import { AppBarStyled, HeaderWrapper, ToolbarStyled } from 'components/Header';
import { HeaderMenu } from 'components/HeaderMenu';
import { DrawerButtonWrapper } from 'components/HeaderHomeLink/HeaderHomeLink.style';

import { useModal, WorkflowsModalTypes } from 'modules/modals';

import {
  EditProcessorModalContextType,
  EditProcessorModalContext,
} from '../../modals/EditProcessorModal/EditProcessorModalContextProvider';
import { useDeploy } from '../../hooks/useDeploy';
import { selectIsSaved } from '../../workflowSelectors';
import type { AutomationEditParams } from '../../types';
import { Button } from './AutomationHeader.style';
import { DeployButton } from './DeployButton';
import { DeployWarning } from './DeployWarning';
import { AutomationStatusSwitcher } from './AutomationStatusSwitcher';

export const AutomationHeader = React.memo(() => {
  const history = useHistory();
  const { openModal } = useModal();
  const projectId = useTypedSelector(selectWorkspaceId);
  const { id } = useParams<AutomationEditParams>();
  const { deploy, save } = useDeploy(id);
  const { setSelectedProcessorId } =
    React.useContext<EditProcessorModalContextType>(EditProcessorModalContext);
  const isSaved = useTypedSelector(selectIsSaved);
  const deploying = useTypedSelector((state) => state.workflow.status === 'deploying');

  const onBackClick = React.useCallback(() => {
    if (isSaved) {
      history.push(getRoutePath(WebsiteRoutes.Workflows, { projectId }));
    } else {
      openModal({
        type: WorkflowsModalTypes.ConfirmAwayEditWorkflowDialog,
      });
    }
  }, [openModal, isSaved, history, projectId]);

  const onHistoryOpenClick = React.useCallback(() => {
    openModal({
      type: WorkflowsModalTypes.AutomationHistoryModal,
    });
  }, [openModal]);

  const onSettingsClick = React.useCallback(() => {
    openModal({
      type: WorkflowsModalTypes.WorkflowSettingsModal,
    });
  }, [openModal]);

  const onSavedClick = React.useCallback(async () => {
    await save();
    setSelectedProcessorId(undefined);
  }, [setSelectedProcessorId, save]);

  const onDeployClick = React.useCallback(async () => {
    await deploy();

    setSelectedProcessorId(undefined);
  }, [setSelectedProcessorId, deploy]);

  return (
    <AppBarStyled>
      <ToolbarStyled>
        <DrawerButtonWrapper>
          <IconButton onClick={onBackClick}>
            <ChevronLeft />
          </IconButton>
        </DrawerButtonWrapper>
        <HeaderWrapper>
          <Button key="save" onClick={onSavedClick} disabled={deploying || isSaved}>
            <Save />
          </Button>
          <DeployButton key="deploy" onDeploy={onDeployClick} isSaved={deploying || isSaved} />
          <Button key="history" onClick={onHistoryOpenClick} disabled={deploying}>
            <Restore />
          </Button>
          <Button key="settings" onClick={onSettingsClick}>
            <SettingsOutlined />
          </Button>
          <AutomationStatusSwitcher />
          <DeployWarning />
        </HeaderWrapper>
        <HeaderMenu />
      </ToolbarStyled>
    </AppBarStyled>
  );
});
