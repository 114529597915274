import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'store';

import { useModal, WorkflowsModalTypes } from 'modules/modals';
import { Modal } from 'components/modal';

import { removeNode } from '../workflowSlice';

const messages = defineMessages({
  title: {
    id: 'modal.delete-step-modal.title',
    defaultMessage: 'Remove Step',
  },
  text: {
    id: 'modal.delete-step-modal.text',
    defaultMessage: 'Are you sure you want to delete {name} processor.',
  },
  delete: {
    id: 'modal.delete-step-modal.delete',
    defaultMessage: 'Delete it',
  },
  cancel: {
    id: 'modal.delete-step-modal.delete',
    defaultMessage: 'Cancel',
  },
});

export type DeleteStepModalType = {
  id: string;
  type: string;
};

export const DeleteStepModal: React.FC<DeleteStepModalType> = ({ id, type }) => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModal();

  const onClose = React.useCallback(() => {
    closeModal(WorkflowsModalTypes.DeleteStepModal);
  }, [closeModal]);

  const onDelete = React.useCallback(() => {
    dispatch(removeNode(id));

    onClose();
  }, [dispatch, onClose, id]);

  return (
    <Modal
      width={560}
      height={250}
      title={messages.title}
      submitMessage={messages.delete}
      cancelMessage={messages.cancel}
      onClose={onClose}
      onSubmit={onDelete}
    >
      <FormattedMessage tagName="p" {...messages.text} values={{ name: type }} />
    </Modal>
  );
};
