export const DetectEntities: ProcessorApiModel = {
  name: 'Detect Entities',
  processor_type: 'DetectEntities',
  description: 'Detect Entities',
  type: 'processor',
  tags: ['NLP'],
  data_model: {
    entities: {
      type: 'array',
    },
  },
  displayText: 'Applying entities to :text parameter.',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/DetectEntities-icon.png',
  connections: {
    inputPort: true,
    outputPort: true,
  },
};
