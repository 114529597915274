import { requestApi } from '../../utils/httpClient';
import { Services } from '../../constants/services';
import { buildFetchConnectionLogsRequestParams } from './utils';
import { TableSettingsType } from '../../types/table';

type FetchConnectionLogsPayload = {
  connectionId: string;
  tableParams: TableSettingsType;
  level: LogsLevel;
};

export const LogsManagerApi = {
  fetchConnectionLogs: async ({
    connectionId,
    tableParams,
    level,
  }: FetchConnectionLogsPayload): Promise<PaginatedResponse<LogApiModel>> => {
    const res = await requestApi<PaginatedResponse<LogApiModel>>({
      service: Services.LogsManager,
      submethod: `connection/${connectionId}/`,
      isAnonymousMethod: true,
      params: buildFetchConnectionLogsRequestParams(tableParams, level),
    });

    return res.data;
  },
} as const;
