import { createAsyncThunk } from '@reduxjs/toolkit';

import { DatasetApi } from './DatasetApi';

import { DatasetDocumentApi } from './DatasetDocumentApi';

export const fetchDataset = createAsyncThunk<IndexApiModel, string>(
  'datasets/fetchDataset',
  DatasetApi.get,
);

export const updateDataset = createAsyncThunk<
  IndexApiModel,
  { id: string; dataset: Partial<IndexApiModel> }
>('updateDataset', DatasetApi.update);

export const updateDatasetSettings = createAsyncThunk<
  IndexApiModel,
  { id: string; dataset: Partial<IndexApiModel> }
>('updateDatasetSettings', DatasetApi.update);

export const fetchDatasetsList = createAsyncThunk<
  PaginatedResponse<IndexApiModel>,
  TableFilterParams
>('datasets/fetchDatasetsList', DatasetApi.getAll);

export const fetchDatasetPropertyTypes = createAsyncThunk<DatasetPropertyTypeType[]>(
  'datasets/fetchDatasetPropertyTypes',
  DatasetDocumentApi.getDatasetPropertyTypes,
);
