const webScraperDataModel: DataModel = {
  url: {
    type: 'string',
  },
  body: {
    type: 'string',
  },
  uuid: {
    type: 'string',
  },
  doc_uuid: {
    type: 'string',
  },
  timestamp: {
    type: 'number',
  },
  title: {
    type: 'string',
  },
  subproject: {
    type: 'string',
  },
  datasource_owner_uuid: {
    type: 'string',
  },
  mime_subtype: {
    type: 'string',
  },
};

export const WebScraper: ProcessorApiModel = {
  name: 'WebScraper',
  processor_type: 'WebScraper',
  description: 'WebScraper',
  type: 'connection',
  tags: ['Scraper'],
  data_model: webScraperDataModel,
  displayText: 'Scrape data from website',
  icon_url: 'https://minio.dev.algometrix.co/processor-icons/WebScraper-icon.png',
  connections: {
    inputPort: false,
    outputPort: true,
  },
};
