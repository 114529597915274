import { createAsyncThunk } from '@reduxjs/toolkit';

import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';

import type { ConnectionTableFilterType } from './types';
import { ConnectorApi } from './api/ConnectorApi';
import type { FetchConnectorResponse, UpdateConnectorPayload } from './api/ConnectorApi';
import { ConnectionApi } from './api/ConnectionApi';

export const fetchConnection = createAsyncThunk<
  BaseConnectionApiModel,
  {
    pk: string;
  }
>('connection/fetchConnection', ConnectionApi.fetchConnection);

export const removeConnection = async ({ pk }: { pk: string }) => {
  const response = await requestApi({
    method: 'DELETE',
    service: Services.Connection,
    submethod: `source/${pk}/`,
  });

  await requestApi({
    method: 'DELETE',
    service: Services.Connectors,
    submethod: `${pk}/`,
    isAnonymousMethod: true,
  });

  return response.status;
};

export const fetchConnectorData = createAsyncThunk<ConnectorApiModel, FetchConnectorResponse>(
  'connection/fetchConnectorData',
  ConnectorApi.fetchConnector,
);

export const updateConnection = createAsyncThunk(
  'connection/updateConnection',
  ConnectionApi.updateConnection,
);

export const updateConnector = createAsyncThunk<ConnectorApiModel, UpdateConnectorPayload>(
  'connection/updateGmailConnector',
  ConnectorApi.updateConnector,
);

export const fetchConnectionList = createAsyncThunk<
  PaginatedConnectionResponse<ConnectionApiModel>,
  TableFilterParams & { filter?: ConnectionTableFilterType }
>('connections/fetchConnectionList', ConnectionApi.fetchConnections);
