import * as React from 'react';

import { useAppDispatch, useTypedSelector } from 'store';
import { useProjectId } from 'hooks/useProjectId';

import { Message } from 'components/Message';
import { CommonTable } from 'components/Table/CommonTable';
import { OrderDirection } from 'constants/table';

import { changeSorting, updateSize, goToSelectedPage } from '../datasetsSlice';
import { useDatasetsTableContent } from './useDatasetsTableContent';
import { fetchDatasetsList } from '../datasetsActions';

export const DatasetsTable: React.FC = () => {
  const { projectId } = useProjectId();
  const dispatch = useAppDispatch();
  const datasets = useTypedSelector((state) => state.datasets.datasets);
  const { page, count, size, order, orderBy, search, loading } = useTypedSelector(
    (state) => state.datasets.tableSettings,
  );
  const contentTableContents = useDatasetsTableContent(datasets);

  React.useEffect(() => {
    dispatch(
      fetchDatasetsList({ order: OrderDirection.ASC, orderBy: 'created_at', page, size, search }),
    );
  }, [dispatch, page, count, size, search, projectId]);

  const onGoToPageHandler = React.useCallback(
    (targetPage: number) => {
      dispatch(goToSelectedPage(targetPage));
    },
    [dispatch],
  );

  const onSizeChangeHandler = React.useCallback(
    (targetSize: number) => {
      dispatch(updateSize(targetSize));
    },
    [dispatch],
  );

  const onClickSortHandler = React.useCallback(
    (columnName: string) => {
      dispatch(changeSorting(columnName));
    },
    [dispatch],
  );

  return (
    <>
      <CommonTable
        content={contentTableContents}
        page={page}
        totalItems={count}
        size={size}
        goToPage={onGoToPageHandler}
        onSizeChange={onSizeChangeHandler}
        onClickSort={onClickSortHandler}
        order={order}
        orderBy={orderBy}
        isPagination
      />
      <Message loading={loading} search={search} count={count} entityName="datasets" />
    </>
  );
};
