import React, { useCallback, useState } from 'react';
import isEqual from 'lodash/isEqual';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import Delete from '@material-ui/icons/Delete';
import Close from '@material-ui/icons/Close';

import { WarningIcon } from 'components/icons';

import { ParamField } from './ParamField';
import { Variable } from './types';

type VariablesTableRowProps = {
  showActions?: boolean;
  param: Variable;
  removeParam: (param: Variable) => void;
  updateParam: (param: Variable) => Promise<void>;
};

export const VariablesTableRow = (props: VariablesTableRowProps) => {
  const { showActions, param, removeParam, updateParam } = props;

  const [error, setError] = useState<boolean>(false);
  const [paramValue, setParamValue] = useState<Variable>(param);
  const [isDisabled, setIsDisabled] = useState(true);

  const onChange = React.useCallback(
    (key: string, value: string) => {
      setParamValue((item) => {
        return { ...item, [key]: value };
      });
    },
    [setParamValue],
  );

  const onRemove = useCallback(() => {
    if (isDisabled) {
      removeParam(param);
    } else {
      setParamValue(param);
      setIsDisabled(true);
      setError(false);
    }
  }, [isDisabled, removeParam, param, setParamValue]);

  const toggleEdit = useCallback(async () => {
    if (isDisabled) {
      setIsDisabled(false);
      return;
    }

    if (!isDisabled && isEqual(param, paramValue)) {
      setIsDisabled(true);
      return;
    }

    try {
      setError(false);
      await updateParam(paramValue);
      setIsDisabled(true);
    } catch (e) {
      setError(true);
    }
  }, [setIsDisabled, isDisabled, updateParam, paramValue, param]);

  return (
    <TableRow>
      <TableCell width={195}>
        <ParamField
          key="key"
          name="key"
          placeholder="Key"
          error={error}
          value={paramValue.key}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      </TableCell>
      <TableCell width={195}>
        <ParamField
          key="value"
          name="value"
          placeholder="Value"
          error={error}
          value={paramValue.value}
          isDisabled={isDisabled}
          onChange={onChange}
        />
      </TableCell>
      {showActions && (
        <TableCell key="actions" align="right">
          {!isDisabled && !isEqual(param, paramValue) && (
            <Tooltip title="Changes is not saved">
              <IconButton>
                <WarningIcon />
              </IconButton>
            </Tooltip>
          )}
          <IconButton onClick={toggleEdit}>{isDisabled ? <Edit /> : <Save />}</IconButton>
          <IconButton onClick={onRemove}>{isDisabled ? <Delete /> : <Close />}</IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};
