import * as React from 'react';

import { AppBarStyled, ToolbarStyled } from 'components/Header';
import { HeaderMenu } from 'components/HeaderMenu';
import { HeaderHomeLink } from 'components/HeaderHomeLink/HeaderHomeLink';

export const WorkspacesHeader = React.memo(() => (
  <AppBarStyled position="fixed">
    <ToolbarStyled>
      <HeaderHomeLink />
      <HeaderMenu />
    </ToolbarStyled>
  </AppBarStyled>
));
