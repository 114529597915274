import { XYPosition } from 'react-flow-renderer';

import { generateId } from 'utils/generateId';

const defaultPosition = {
  x: 100,
  y: 100,
};

type CreateNodeTypeArgs = {
  processorType: ProcessorTypes;
  position?: XYPosition;
  type: ComponentsTypes;
  hasYShift?: boolean;
  parameters?: Record<string, unknown>;
};

export const createNode = ({
  processorType,
  position = defaultPosition,
  parameters = {},
  type,
  hasYShift,
}: CreateNodeTypeArgs): WorkflowNode => {
  const id = generateId();

  return {
    id,
    type,
    data: {
      entityId: undefined,
      processorType,
      parameters,
    },
    position: hasYShift ? { x: position.x, y: position.y + 100 } : position,
  };
};
