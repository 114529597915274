import * as React from 'react';

import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  emptySearch: {
    id: 'workspaces.workspace-card-list.empty-search',
    defaultMessage: 'There are no {entityName} according to provided search query.',
  },
  emptyWorkspaceList: {
    id: 'workspaces.workspace-card-list.empty-workspace-list',
    defaultMessage: 'List of {entityName} is empty.',
  },
  loading: {
    id: 'workspaces.workspace-card-list.loading',
    defaultMessage: 'List of {entityName} is loading.',
  },
});

type MessageType = {
  loading: boolean;
  search?: string;
  count: number;
  entityName?: string;
};

export const Message: React.FC<MessageType> = ({
  loading,
  search = '',
  count,
  entityName = 'items',
}) => {
  if (loading) {
    return (
      <p className="message">
        <FormattedMessage {...messages.loading} values={{ entityName }} />
      </p>
    );
  }

  if (count === 0 && search !== '') {
    return (
      <p className="message">
        <FormattedMessage {...messages.emptySearch} values={{ entityName }} />
      </p>
    );
  }

  if (count === 0 && search === '') {
    return (
      <p className="message">
        <FormattedMessage {...messages.emptyWorkspaceList} values={{ entityName }} />
      </p>
    );
  }

  return null;
};
