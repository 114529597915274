import React, { useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { AutomationContext } from '../../../AutomationContext';

import { getEntityNameById } from './utils/getEntityNameById';

export const useFilledParams = (parameters: WorkflowElementDataParameters) => {
  const { datasets, customComponents, connections } = React.useContext(AutomationContext);

  return useMemo(() => {
    const parametersCopy = cloneDeep(parameters);

    if (parametersCopy.connection_uuid) {
      parametersCopy.connection_uuid = getEntityNameById(
        connections,
        parametersCopy.connection_uuid,
      );
    }

    if (parametersCopy.custom_processor_uuid) {
      parametersCopy.custom_processor_uuid = getEntityNameById(
        customComponents,
        parametersCopy.custom_processor_uuid,
      );
    }

    if (parametersCopy.dataset_uuid) {
      parametersCopy.dataset_uuid = getEntityNameById(datasets, parametersCopy.dataset_uuid);
    }

    return parametersCopy;
  }, [datasets, customComponents, connections, parameters]);
};
