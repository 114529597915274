import * as React from 'react';
import { defineMessages } from 'react-intl';
import Form from '@rjsf/material-ui';

import { FormDataChangeEvent } from 'types';
import { uiSchema } from 'schemas';

import { Modal } from 'components/modal';

import { initialFormDataState, FormData, projectSchema } from './projectSchema';
import { useModal, WorkspaceModalTypes } from '../../modals';
import { WorkspaceApi } from '../WorkspaceApi';

const messages = defineMessages({
  title: {
    id: 'automation.create-project-modal.title',
    defaultMessage: 'New Project',
  },
  create: {
    id: 'automation.create-project-modal.create',
    defaultMessage: 'Create',
  },
  cancel: {
    id: 'automation.create-project-modal.cancel',
    defaultMessage: 'Cancel',
  },
});

export const CreateWorkspaceModal = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { closeModal, openModal } = useModal();

  const [formData, setFormData] = React.useState<FormData>(initialFormDataState);

  const onClose = React.useCallback(() => {
    if (isLoading) return;
    closeModal(WorkspaceModalTypes.CreateWorkspaceModal);
  }, [isLoading, closeModal]);

  const onSubmit = React.useCallback(async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      const { name, description } = formData;
      const workspace = await WorkspaceApi.createWorkspace({
        name,
        description,
        resource_budget: 1,
      });

      setIsLoading(false);
      openModal({
        type: WorkspaceModalTypes.SetWorkspacePermissionModal,
        data: {
          id: workspace.pk,
        },
      });

      closeModal(WorkspaceModalTypes.CreateWorkspaceModal);
    } catch (e) {
      setIsLoading(false);
    }
  }, [formData, isLoading, openModal, closeModal, setIsLoading]);

  const onChange = React.useCallback(
    (data: FormDataChangeEvent<FormData>) => {
      setFormData(data.formData);
    },
    [setFormData],
  );

  return (
    <Modal
      title={messages.title}
      cancelMessage={messages.cancel}
      submitMessage={messages.create}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Form formData={formData} uiSchema={uiSchema} schema={projectSchema} onChange={onChange}>
        <></>
      </Form>
    </Modal>
  );
};
