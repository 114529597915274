import styled from 'styled-components';

export const AutomationEditFooterWrapper = styled.footer`
  box-sizing: border-box;
  padding: 0 8px;
  width: 100%;
  height: 40px;
  background: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  align-items: center;
  z-index: 1001;
`;
