import styled from 'styled-components';

import { InputBase, List, Paper } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import { appBarHeight } from 'components/constants';

export const CreateNodeMenuContainer = styled(Paper)`
  width: 300px;
  height: 100%;
  margin: 0;
  position: absolute;
  z-index: 1000;
  top: ${appBarHeight};
  overflow: hidden;
`;

export const InputSearch = styled(InputBase)`
  border: 1px solid #8a8886;
  width: 100%;
  padding-left: 40px;
`;

export const IFilterIcon = styled('i')`
  font-size: 24px;
  border-right: 1px solid #edebe9;
  padding: 0 24px;

  color: ${(props: { active: boolean }) => (props.active ? '#0078D4' : '#212121')};
`;

export const CategoryList = styled(List)`
  width: 100%;
  padding: 0;
`;

export const SearchIcon = styled(Search)`
  position: absolute;
  left: 14px;
  transform: rotate(90deg);
  top: 13px;
`;
