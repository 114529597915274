import * as React from 'react';
import { defineMessages } from 'react-intl';

import Form from '@rjsf/material-ui';

import { uiSchema } from 'schemas';
import { FormDataChangeEvent } from 'types';
import { useNavigation, useProjectId } from 'hooks';
import { Modal } from 'components/modal';
import { WebsiteRoutes } from 'constants/routes';
import { DatasetsModalTypes, useModal } from 'modules/modals';

import { datasetSchema } from './datasetSchema';
import { DatasetApi } from '../../DatasetApi';

const messages = defineMessages({
  title: {
    id: 'automation.create-datasets-modal.title',
    defaultMessage: 'New Data Models',
  },
  continue: {
    id: 'automation.create-datasets-modal.create',
    defaultMessage: 'Create',
  },
  cancel: {
    id: 'automation.create-datasets-modal.cancel',
    defaultMessage: 'Cancel',
  },
});

export type FormData = {
  name: string;
  description: string;
};

const initialFormDataState: FormData = {
  name: '',
  description: '',
};

export const CreateDatasetsModal = () => {
  const { projectId } = useProjectId();
  const { closeModal } = useModal();
  const navigate = useNavigation();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<FormData>(initialFormDataState);

  const onClose = React.useCallback(() => {
    closeModal(DatasetsModalTypes.CreateDatasetsModal);
  }, [closeModal]);

  const onSubmit = React.useCallback(async () => {
    try {
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      const dataset = await DatasetApi.create(formData, projectId);

      closeModal(DatasetsModalTypes.CreateDatasetsModal);

      navigate(WebsiteRoutes.DatasetsEdit, { id: dataset.pk });
    } catch (e) {
      setIsLoading(false);
    }
  }, [isLoading, navigate, formData, closeModal, projectId]);

  const onChange = React.useCallback(
    (data: FormDataChangeEvent<FormData>) => {
      setFormData(data.formData);
    },
    [setFormData],
  );

  return (
    <Modal
      title={messages.title}
      cancelMessage={messages.cancel}
      submitMessage={messages.continue}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Form uiSchema={uiSchema} schema={datasetSchema} formData={formData} onChange={onChange}>
        <></>
      </Form>
    </Modal>
  );
};
