import * as React from 'react';

import { CustomComponentModalTypes } from 'modules/modals';

import { ModalStoreType } from '../../modals/modalsSlice';

import { CreateCustomProcessorModal } from './CreateCustomProcessorModal/CreateCustomProcessorModal';
import { DeleteCustomComponentModal } from './DeleteCustomComponentModal';
import { UpdateCustomComponentModal } from './UpdateCustomComponentModal/UpdateCustomComponentModal';

export const getCustomComponentsModalByType = (item: ModalStoreType): React.ReactNode | null => {
  switch (item.type) {
    case CustomComponentModalTypes.CreateCustomComponentModal:
      return <CreateCustomProcessorModal key="create-custom-processor-modal" />;
    case CustomComponentModalTypes.EditCustomComponentModal:
      return (
        <UpdateCustomComponentModal
          key="update-custom-processor-modal"
          pk={item?.data?.pk as string}
        />
      );
    case CustomComponentModalTypes.DeleteCustomComponentModal:
      return (
        <DeleteCustomComponentModal
          key="delete-custom-processor-modal"
          pk={item?.data?.pk as string}
        />
      );
    default:
      return null;
  }
};
