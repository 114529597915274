import React from 'react';

import { Table, TableBody, TableContainer } from '@material-ui/core';

import { Message } from 'components/Message';
import { Sortable } from 'common/Params';

import { VariablesTableRow } from './VariablesTableRow';
import { VariablesTableHeader } from './VariablesTableHeader';
import { Variable } from './types';

const nope = () => {};

type VariablesTableProps = {
  showActions?: boolean;
  items: Variable[];
  removeParam: (param: Variable) => void;
  updateParam: (param: Variable) => Promise<void>;
} & Sortable;

export const VariablesTable = ({
  items,
  sortable,
  updateParam,
  removeParam,
  showActions,
  params,
  onClickSort = nope,
}: VariablesTableProps) => {
  return (
    <TableContainer>
      <Table>
        <VariablesTableHeader
          sortable={sortable}
          showActions={showActions}
          params={params}
          onClickSort={onClickSort}
        />
        <TableBody>
          {items.map((item) => (
            <VariablesTableRow
              key={item.id}
              showActions={showActions}
              param={item}
              removeParam={removeParam}
              updateParam={updateParam}
            />
          ))}
        </TableBody>
      </Table>
      {!items.length && (
        <Message loading={false} search="" count={items.length} entityName="params" />
      )}
    </TableContainer>
  );
};
