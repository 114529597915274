import * as React from 'react';

import { useTypedSelector } from 'store';

import { selectAutomationProcessorStatisticStatus } from '../../../workflowSelectors';

type NodeStatisticType = {
  entityId: string;
};

export const NodeStatistic: React.FC<NodeStatisticType> = ({ entityId }) => {
  const processorStatisticStatus = useTypedSelector(
    selectAutomationProcessorStatisticStatus(entityId),
  );

  return (
    <div className="statistic-list">
      <p className="statistic-list__title">Node statistic:</p>
      <p className="statistic-list__item">
        Queued: <span className="statistic-list__value">{processorStatisticStatus.queued}</span>
      </p>
      <p className="statistic-list__item">
        In: <span className="statistic-list__value">{processorStatisticStatus.received}</span>
      </p>
      <p className="statistic-list__item">
        Read/Write:
        <span className="statistic-list__value">
          {processorStatisticStatus.read} / {processorStatisticStatus.written}
        </span>
      </p>
      <p className="statistic-list__item">
        Out:
        <span className="statistic-list__value">{processorStatisticStatus.transferred}</span>
      </p>
    </div>
  );
};
