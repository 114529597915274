import styled from 'styled-components';
import { RouterLink } from 'components/RouterLink';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const RouterLinkStyled = styled(RouterLink)`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
