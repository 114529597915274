import React from 'react';

export enum ActionNames {
  Delete,
  Add,
  Edit,
  Empty = '',
}

interface ActionButtonData {
  text: string;
  variant: 'text' | 'outlined' | 'contained' | undefined;
  color: 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
  autoFocus: boolean;
  onClick: () => void;
}

export interface ActionData {
  title: string;
  content: string | React.ReactNode;
  buttons: ActionButtonData[];
}

export interface ModalData {
  [key: string]: ActionData;
}

export const filterWorkflowTags = (
  workflows: AutomationApiModel[],
  workflowId: string,
  tagId: string,
) => {
  const selectedWorkflow = workflows.find(
    (workflow) => workflow.id === workflowId,
  ) as AutomationApiModel;
  return selectedWorkflow.tags.filter((tag) => tag !== tagId);
};

export const getFilteredTags = (workflowsTags: string[], tags: TagApiModel[] = []) => {
  return tags.filter((tag) => !workflowsTags.includes(tag.id));
};
