import React, { FC, useCallback } from 'react';

import { useAppDispatch } from 'store';

import { CredentialsModalTypes, useModal } from 'modules/modals';

import { CommonActionsCell } from 'components/CommonActionsCell';
import { ModalLink } from 'components/Table/ModalLink';

import { deleteDockerCredential } from '../credentialsActions';
import { deleteApiKey } from '../apiKeyCredenetialActions';

type CredentialActionCellType = {
  credentialId: string;
  credentialType: CredentialTypes;
};

export const CredentialActionCell: FC<CredentialActionCellType> = ({
  credentialId,
  credentialType,
}) => {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();

  const onEditClick = useCallback(() => {
    openModal({
      type:
        credentialType === 'docker'
          ? CredentialsModalTypes.EditDockerCredentialModal
          : CredentialsModalTypes.EditApiKeyCredentialModal,
      data: { credentialId },
    });
  }, [openModal, credentialId, credentialType]);

  const onDeleteClick = useCallback(() => {
    if (credentialType === 'docker') {
      dispatch(deleteDockerCredential(credentialId));
    }
    if (credentialType === 'api-key') {
      dispatch(deleteApiKey(credentialId));
    }
  }, [dispatch, credentialId, credentialType]);

  return (
    <CommonActionsCell>
      <ModalLink onClick={onEditClick}>Edit</ModalLink>
      <ModalLink onClick={onDeleteClick}>Delete</ModalLink>
    </CommonActionsCell>
  );
};
