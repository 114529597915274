import { Schema } from 'schemas/types';

export const gmailConnectionSchema: Schema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      description: 'Connection name',
      title: 'Name',
      default: '',
    },
    description: {
      type: 'string',
      description: 'Connection description',
      title: 'Description',
      default: '',
    },
    scheduler: {
      type: 'boolean',
      default: false,
      description: 'Enable cron',
      title: 'Enable cron',
    },
    cron: {
      type: 'string',
      default: '* * * * *',
      description: 'Cron interval (min)',
      title: 'Cron interval',
    },
  },
};
