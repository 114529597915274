import { requestApi } from 'utils/httpClient';
import { Services } from 'constants/services';

import { GMAIL_REDIRECT_URL } from 'env';
import { ApiResponse } from 'types/types';

export type FetchGmailAuthDataSourceType = {
  origin_redirect_url: string;
  redirect_url: string;
  state: string;
  scope: string;
};

export type GmailAuthDataSourceType = { connectionId: string } & FetchGmailAuthDataSourceType;

type FetchGmailAuthDataSourceResponseType = ApiResponse<{
  redirect_url: string;
}>;

type FetchGmailAuthDataSourcePayload = {
  id: string;
  cron: string;
  scheduler: boolean;
};

type QueryParamsType = {
  code: string;
  state: string;
  scope: string;
};

type AddGmailConnectorSourcePayload = {
  id: string;
  redirect_url: string;
  query_params: QueryParamsType;
};

export const GmailConnectorApi = {
  fetchGmailAuthDataSourceApi: async ({
    id,
    cron,
    scheduler,
  }: FetchGmailAuthDataSourcePayload): Promise<FetchGmailAuthDataSourceType> => {
    const response: FetchGmailAuthDataSourceResponseType = await requestApi({
      method: 'POST',
      service: Services.Connectors,
      submethod: `${id}/gmail/auth-source/`,
      data: {
        redirect_url: GMAIL_REDIRECT_URL,
        cron,
        scheduler,
      },
      isAnonymousMethod: true,
    });

    const url = new URLSearchParams(response.data.redirect_url);

    return {
      origin_redirect_url: response.data.redirect_url,
      redirect_url: url.get('redirect_uri') || '',
      state: url.get('state') || '',
      scope: url.get('scope') || '',
    };
  },
  addGmailConnectorSource: async ({
    id,
    query_params,
    redirect_url,
  }: AddGmailConnectorSourcePayload): Promise<Record<string, ScalarValue>> => {
    const res = await requestApi<{ credentials: Record<string, ScalarValue> }>({
      method: 'POST',
      service: Services.Connectors,
      submethod: `${id}/gmail/add-source/`,
      isAnonymousMethod: true,
      data: {
        redirect_url,
        query_params,
      },
    });

    return res.data.credentials;
  },
} as const;
