import React from 'react';
import styled from 'styled-components';

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useDebouncedSearch } from 'hooks';

const SearchInput = styled(InputBase)`
  padding-left: 10px;
  width: 300px;
  border-radius: 4px;
  border: 1px solid gray;
`;

type SearchProps = {
  onSearch: (value: string) => void;
};

export const Search = ({ onSearch }: SearchProps) => {
  const { searchValue, onChange } = useDebouncedSearch(onSearch);

  return (
    <SearchInput
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      value={searchValue}
      onChange={onChange}
      placeholder="Search"
    />
  );
};
