import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { ActionData } from './TableTags.helper';

interface TagModalProps {
  open: boolean;
  onClose: () => void;
  modalData: ActionData;
}

export const TagModal = ({ open, onClose, modalData }: TagModalProps) => {
  const { title, content, buttons } = modalData;
  const dialogContent =
    typeof content === 'string' ? <DialogContentText>{content}</DialogContentText> : content;
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      {!!buttons.length && (
        <DialogActions>
          {buttons.map((button) => (
            <Button
              key={button.text}
              variant={button.variant}
              onClick={button.onClick}
              color="primary"
              autoFocus={button.autoFocus}
            >
              {button.text}
            </Button>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};
