import React, { useCallback, useEffect, useState, SyntheticEvent } from 'react';
import { useParams } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { useNavigation } from 'hooks';
import { WebsiteRoutes } from 'constants/routes';
import { Message } from 'components/Message';

import { AutomationEditParams } from '../../types';
import { fetchAutomationDatasets } from '../../automationApi';

export const AutomationDatasets = () => {
  const { id, projectId } = useParams<AutomationEditParams>();
  const navigate = useNavigation();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [datasets, setDatasets] = useState<AssociatedEntity[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchAutomationDatasets(id)
      .then((items) => {
        setDatasets(items);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [id]);

  const onClick = useCallback(
    (e: SyntheticEvent<HTMLTableCellElement>) => {
      const datasetId = e.currentTarget.dataset.id as string;

      if (datasetId) {
        navigate(WebsiteRoutes.DatasetsEdit, { projectId, datasetId });
      }
    },
    [navigate, projectId],
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Dataset name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datasets.map(({ id: datasetId, name }) => (
            <TableRow key={datasetId}>
              <TableCell data-id={datasetId} onClick={onClick} style={{ cursor: 'pointer' }}>
                {name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Message loading={isLoading} search="" entityName="datasets" count={datasets.length} />
    </TableContainer>
  );
};
