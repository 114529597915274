import React from 'react';
import { WarningIcon, InfoIcon, ErrorIcon } from 'components/icons';

const STATUS_ICON_MAP: Record<string, JSX.Element> = {
  INFO: <InfoIcon />,
  ERROR: <ErrorIcon />,
  WARNING: <WarningIcon />,
};

export const getLogStatusIcon = (message: string): JSX.Element => {
  const status = message.split(':')[0];

  return STATUS_ICON_MAP[status] || <ErrorIcon />;
};
