import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { useTabManager } from 'hooks';

import { a11yProps } from 'utils/a11yProps';
import { ModalHeader } from 'components/modal/ModalHeader';
import { WideDialog } from 'components/modal';
import { VerticalTab, TabPanel, VerticalTabs, VerticalTabsWrapper } from 'components/Tabs';

import { ModalTypes, useModal } from 'modules/modals';

import { UserPermissionTab } from './ApplicationUsersSettingsTab';

const messages = defineMessages({
  title: {
    id: 'automation.user-settings-modal.title',
    defaultMessage: 'Application settings',
  },
});

enum ApplicationSettingsModalTabs {
  USER_PERMISSION,
}

export const ApplicationSettingsModal: React.FC = () => {
  const { closeModal } = useModal();
  const { tab, onTabChange } = useTabManager<ApplicationSettingsModalTabs>(
    ApplicationSettingsModalTabs.USER_PERMISSION,
  );

  const onClose = React.useCallback(() => {
    closeModal(ModalTypes.ApplicationSettingsModal);
  }, [closeModal]);

  return (
    <WideDialog id="application-settings-modal" open onClose={onClose}>
      <ModalHeader onClose={onClose}>
        <FormattedMessage {...messages.title} />
      </ModalHeader>
      <VerticalTabsWrapper>
        <VerticalTabs
          orientation="vertical"
          value={tab}
          onChange={onTabChange}
          aria-label="Vertical tabs example"
        >
          <VerticalTab label="Users" {...a11yProps(0)} />
        </VerticalTabs>
        <TabPanel index={ApplicationSettingsModalTabs.USER_PERMISSION} value={tab}>
          <UserPermissionTab onClose={onClose} />
        </TabPanel>
      </VerticalTabsWrapper>
    </WideDialog>
  );
};
