import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';

import { ModalLabel } from 'components/modal';
import { ModalTitleStyled } from 'components/modal/ModalHeader';
import { Selector } from 'components/common';
import { ConnectorApi } from '../../../api/ConnectorApi';

type ConnectionFoldersSettingsProps = {
  connectionId: string;
  folder: string[];
  onConnectorDataChange: (key: string, value: ScalarValue) => void;
};

export const ConnectionFoldersSettings: React.FC<ConnectionFoldersSettingsProps> = ({
  connectionId,
  folder,
  onConnectorDataChange,
}) => {
  const [folders, setFolders] = React.useState<FolderApiModel[]>([]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      onConnectorDataChange('folder', event.target.value as string);
    },
    [onConnectorDataChange],
  );

  React.useEffect(() => {
    ConnectorApi.fetchConnectorFolders(connectionId).then(setFolders);
  }, [connectionId, setFolders]);

  return (
    <div className="has-top-margin">
      <ModalTitleStyled>Folder Settings</ModalTitleStyled>
      <ModalLabel className="has-top-margin">Folder name</ModalLabel>
      <Selector
        multiple
        value={folder}
        variant="outlined"
        onChange={handleChange}
        style={{ width: 252, padding: 6 }}
      >
        {folders.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Selector>
    </div>
  );
};
