import React from 'react';
import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

import { useAppDispatch } from 'store';

import { setEnableAutomationStatus } from '../../automationActions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

interface StatusCellProps {
  automationId: string;
  status: AutomationStatuses;
}

const shouldAutomationEnabled = (status: AutomationStatuses): boolean => {
  return ['stopped', 'error'].includes(status);
};

export const AutomationStatusCell: React.FC<StatusCellProps> = ({ status, automationId }) => {
  const dispatch = useAppDispatch();

  const onClick = React.useCallback(async () => {
    await dispatch(
      setEnableAutomationStatus({ automationId, enable: shouldAutomationEnabled(status) }),
    ).unwrap();
  }, [dispatch, automationId, status]);

  return (
    <Container>
      {status}
      <IconButton onClick={onClick} color="primary" aria-label="status" component="span">
        {status === 'running' ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
    </Container>
  );
};
