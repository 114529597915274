import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import cloneDeep from 'lodash/cloneDeep';

import { ResponseStatutes } from 'types';

import { fromDot } from './components/utils';
import {
  fetchWorkflow,
  patchWorkflow,
  deployWorkflow,
  setEnableAutomationStatus,
  SetEnableAutomationStatusPayload,
  fetchWorkflowHistory,
  deleteAutomationVersion,
  updateAutomationVersion,
  fetchAutomationStatistic,
} from './automationActions';
import type { AutomationStatisticType, DeployStatusType } from './types';
import {
  addNodesReducer,
  rearrangeElementsReducer,
  removeEdgeReducer,
  removeNodeReducer,
  updateElementParametersReducer,
  updateElementPositionReducer,
} from './workflowReducers';

export const emptyAutomation: AutomationApiModel = {
  id: '',
  name: '',
  dot_content: '',
  comment: '',
  description: '',
  status: 'not deployed',
  tags: [],
  tag_objects: [],
  updated_at: '',
};

export interface WorkflowState {
  automation: AutomationApiModel;
  automationStatistic?: AutomationStatisticType;
  graph: WorkflowElement[];
  snapshotGraph: WorkflowElement[];
  isSaved: boolean;
  status: ResponseStatutes | 'deploying';
  deployStatus: DeployStatusType;
  automationVersions: AutomationApiModel[];
}

const initialState: WorkflowState = {
  automation: emptyAutomation,
  automationStatistic: undefined,
  status: 'loading',
  deployStatus: undefined,
  graph: [],
  snapshotGraph: [],
  isSaved: true,
  automationVersions: [],
};

const { reducer, actions } = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    reset(store) {
      return {
        ...initialState,
        deployStatus: store.deployStatus,
      };
    },
    removeEdge: removeEdgeReducer,
    addNodes: addNodesReducer,
    updateElementParameters: updateElementParametersReducer,
    removeNode: removeNodeReducer,
    updateElementPosition: updateElementPositionReducer,
    rearrangeElements: rearrangeElementsReducer,
    updateDeployStatus(state, { payload }: { payload: { deployStatus: DeployStatusType } }) {
      state.deployStatus = payload.deployStatus;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkflow.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchWorkflow.fulfilled, (state, { payload: automation }) => {
      state.automation = automation;
      state.graph = fromDot(automation.dot_content);
      state.snapshotGraph = fromDot(automation.dot_content);
      state.isSaved = true;
      state.status = 'finished';
    });
    builder.addCase(fetchWorkflow.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(deployWorkflow.pending, (state) => {
      state.status = 'deploying';
      state.deployStatus = 'deploying';
    });
    builder.addCase(deployWorkflow.fulfilled, (state) => {
      state.status = 'finished';
      state.deployStatus = 'finished';
      state.isSaved = true;
      state.snapshotGraph = cloneDeep(state.graph);
    });
    builder.addCase(deployWorkflow.rejected, (state) => {
      state.status = 'failed';
      state.deployStatus = 'failed';
    });
    builder.addCase(patchWorkflow.pending, (state) => {
      state.status = 'deploying';
    });
    builder.addCase(patchWorkflow.fulfilled, (state) => {
      state.status = 'finished';
    });
    builder.addCase(
      updateAutomationVersion.fulfilled,
      (state, { payload }: PayloadAction<AutomationApiModel>) => {
        state.automationVersions = state.automationVersions.map((item) =>
          item.id === payload.id ? payload : item,
        );
      },
    );
    builder.addCase(patchWorkflow.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(
      setEnableAutomationStatus.fulfilled,
      (state, { payload }: PayloadAction<SetEnableAutomationStatusPayload>) => {
        state.automation.status = payload.enable ? 'running' : 'stopped';
      },
    );
    builder.addCase(
      fetchWorkflowHistory.fulfilled,
      (state, { payload }: PayloadAction<AutomationApiModel[]>) => {
        state.automationVersions = payload;
      },
    );
    builder.addCase(
      deleteAutomationVersion.fulfilled,
      (state, { payload }: PayloadAction<string>) => {
        state.automationVersions = state.automationVersions.filter((item) => item.id !== payload);
      },
    );
    builder.addCase(fetchAutomationStatistic.fulfilled, (state, { payload }) => {
      state.automationStatistic = payload;
    });
  },
});

export const {
  reset,
  updateDeployStatus,
  removeEdge,
  addNodes,
  updateElementParameters,
  updateElementPosition,
  removeNode,
  rearrangeElements,
} = actions;
export default reducer;
