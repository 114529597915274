import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { getRouteById, WebsiteRoutes } from 'constants/routes';
import { getRoutePath } from 'utils/path';
import { DrawerContext, DrawerContextType } from 'providers';

import { ListItemStyled } from './SideMenu.style';
import { checkIsActiveItem, IMenuItem } from './SideMenu.helper';

type SideMenuitemProps = {
  item: IMenuItem;
  projectId: string;
};

export const SideMenuItem: React.FC<SideMenuitemProps> = ({ item, projectId }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { setIsDrawerOpen } = React.useContext<DrawerContextType>(DrawerContext);
  const isItemActive = checkIsActiveItem(pathname, getRoutePath(item.route, { projectId }));

  const onClickItemHandler = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement>) => {
      const route = e.currentTarget.dataset.route as WebsiteRoutes;

      if (history.location.pathname !== getRouteById(route, projectId)) {
        history.push(getRoutePath(route, { projectId }));
      }

      setIsDrawerOpen(false);
    },
    [history, projectId, setIsDrawerOpen],
  );

  return (
    <ListItemStyled
      selected={isItemActive}
      button
      data-route={item.route}
      onClick={onClickItemHandler}
    >
      <ListItemIcon>{isItemActive ? item.iconActive : item.icon}</ListItemIcon>
      <ListItemText primary={<FormattedMessage {...item.text} />} />
    </ListItemStyled>
  );
};
