export const theme = {
  base00: 'white',
  base01: '#ddd',
  base02: 'transparent',
  base03: '#aaa',
  base04: '#aaa',
  base05: '#201F1E',
  base06: '#201F1E',
  base07: '#201F1E',
  base08: '#201F1E',
  base09: '#201F1E',
  base0A: '#201F1E',
  base0B: '#201F1E',
  base0C: '#201F1E',
  base0D: '#201F1E',
  base0E: '#201F1E',
  base0F: '#201F1E',
};
