import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  List,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';

import { Folder as FolderIcon, HighlightOff as HighlightOffIcon } from '@material-ui/icons';

import { useAppDispatch, useTypedSelector } from 'store';
import { useFlag } from 'modules/modals';

import type { Member, GroupApiModel } from '../types';
import { updateUserProjectPermission, deleteUserProjectPermission } from '../workspaceActions';
import {
  selectIsWorkspaceLoading,
  selectWorkspaceGroups,
  selectPreparedMembers,
} from '../workspaceSelectors';
import { MemberSelectorForm } from './MemberSelectorForm/MemberSelectorForm';
import { InviteMemberForm } from './InviteMemberForm/InviteMemberForm';

const messages = defineMessages({
  description: {
    id: 'automation.set-project-permission-modal.description',
    defaultMessage:
      'For each service, you can replace with existing or create new authentifications Choose below the actions for each authentification.',
  },
  warning: {
    id: 'automation.set-project-permission-modal.warning',
    defaultMessage: 'All changes will be applied immediately.',
  },
});

type UserPermissionListItemType = {
  workspaceId: string;
  member: Member;
};

export const UserPermissionListItem: React.FC<UserPermissionListItemType> = ({
  workspaceId,
  member,
}) => {
  const { showFlag } = useFlag();
  const dispatch = useAppDispatch();
  const groups: GroupApiModel[] = useTypedSelector(selectWorkspaceGroups);

  const onChange = React.useCallback(
    async (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const { name, value } = event.target;

      if (name) {
        await dispatch(
          updateUserProjectPermission({
            workspaceId,
            params: {
              groups: [value as number],
              user_id: name,
            },
          }),
        ).unwrap();

        showFlag({ title: 'Role updated' });
      }
    },
    [showFlag, workspaceId, dispatch],
  );

  const onDeleteCLick = React.useCallback(() => {
    dispatch(
      deleteUserProjectPermission({
        workspaceId,
        permissionId: member.permissionId,
        memberId: member.id,
      }),
    );
  }, [dispatch, workspaceId, member]);

  return (
    <ListItem key={member.id}>
      <ListItemAvatar>
        <Avatar>
          <FolderIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={member.name} />
      <Select name={member.id} style={{ width: 150 }} value={member.groups[0]} onChange={onChange}>
        {groups.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      <IconButton onClick={onDeleteCLick}>
        <HighlightOffIcon />
      </IconButton>
    </ListItem>
  );
};

type UserPermissionListType = {
  workspaceId: string;
  listClassName?: string;
};

export const UserPermissionList: React.FC<UserPermissionListType> = ({
  workspaceId,
  listClassName = '',
}) => {
  const loading: boolean = useTypedSelector(selectIsWorkspaceLoading);
  const members: Member[] = useTypedSelector(selectPreparedMembers);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="has-top-margin">
      <FormattedMessage tagName="p" {...messages.description} />
      <InviteMemberForm workspaceId={workspaceId} />
      <MemberSelectorForm workspaceId={workspaceId} />
      <FormattedMessage tagName="p" {...messages.warning} />
      <List className={listClassName}>
        {members.map((member: Member) => (
          <UserPermissionListItem key={member.id} workspaceId={workspaceId} member={member} />
        ))}
      </List>
    </div>
  );
};
