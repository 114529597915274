import { Schema } from 'schemas/types';

import { EntityMapName } from '../types';
import { createEmptySchema } from '../components';
import { fillSchemaProperties } from './fillSchemaProperties';
import { applyCustomComponentProperties } from './applyCustomComponentProperties';

type CreateFilledSchemaPayload = {
  processorType: ProcessorTypes;
  datasetId?: string;
  dataModel: DataModel;
  entities: EntityMapName;
  customComponentId?: string;
};

export const createFilledProcessorSchema = ({
  dataModel,
  datasetId,
  entities,
  processorType,
  customComponentId,
}: CreateFilledSchemaPayload): Schema => {
  const schema = createEmptySchema(processorType);

  if (customComponentId) {
    applyCustomComponentProperties(schema, entities['custom-component'], customComponentId);
  }

  fillSchemaProperties({
    properties: schema.properties,
    entities,
    datasetId,
    dataModel,
  });

  if (schema.dependencies) {
    for (const property of Object.keys(schema.dependencies)) {
      if (property in schema.dependencies) {
        if (schema.dependencies[property].oneOf) {
          schema.dependencies[property].oneOf?.forEach((item) => {
            fillSchemaProperties({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              properties: item?.properties || {},
              entities,
              datasetId,
              dataModel,
            });
          });
        }
      }
    }
  }
  return schema;
};
