import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Form from '@rjsf/material-ui';
import Button from '@material-ui/core/Button';

import { useAppDispatch } from 'store';
import { useFormData } from 'hooks';
import { Schema } from 'schemas/types';
import { useFlag } from 'modules/modals';

import { TabContent } from 'components/Tabs';
import { DialogActions } from 'components/modal';
import { ModalTitleStyled } from 'components/modal/ModalHeader';

import { updateConnection } from '../../../connectionActions';

const messages = defineMessages({
  title: {
    id: 'connections.edit-connection-modal.title',
    defaultMessage: 'Basic',
  },
  save: {
    id: 'connections.edit-connection-modal.save',
    defaultMessage: 'Save',
  },
});

const uiSchema = {
  source_type: {
    'ui:disabled': true,
    'ui:disable': true,
  },
};

const schema: Schema = {
  type: 'object',
  properties: {
    name: {
      title: 'Name',
      type: 'string',
    },
    description: {
      title: 'Description',
      type: 'string',
    },
    source_type: {
      title: 'Type',
      type: 'string',
    },
  },
};

type GeneralSettingsTabProps = {
  connectionData: ConnectionApiModel;
};

export const GeneralSettingsTab: React.FC<GeneralSettingsTabProps> = ({ connectionData }) => {
  const { showFlag } = useFlag();
  const { formData, onChange } = useFormData<ConnectionApiModel>(connectionData);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onSubmit = React.useCallback(async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      await dispatch(updateConnection(formData)).unwrap();

      setIsLoading(false);
      showFlag({ title: 'Settings was updated' });
    } catch (e) {
      setIsLoading(false);
      showFlag({ title: 'Something went wrong.' });
    }
  }, [showFlag, isLoading, dispatch, formData]);

  return (
    <>
      <TabContent>
        <ModalTitleStyled>
          <FormattedMessage {...messages.title} />
        </ModalTitleStyled>
        <Form
          className="application-form"
          uiSchema={uiSchema}
          onChange={onChange}
          schema={schema}
          formData={formData}
        >
          <></>
        </Form>
      </TabContent>
      <DialogActions>
        <Button variant="contained" disableElevation color="primary" onClick={onSubmit}>
          <FormattedMessage {...messages.save} />
        </Button>
      </DialogActions>
    </>
  );
};
